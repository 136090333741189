import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getRef } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const standardizedRoadMapTest: SubHeadingItem = {
  key: 'standardizedRoadMapTest',
  label: 'Standardized Road-Map Test of Direction Sense',
  fields: [
    {
      key: 'numberOfErrors',
      label: 'Number of errors',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 32</Typography>,
    },
    {
      key: 'completionTime',
      label: 'Time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'standardized road-map test',
      key: 'standardizedRoadMapTestFile',
      fieldType: 'UPLOAD',
    },
    {
      key: 'increasedRiskOfFailing',
      label: 'Increased risk of failing specialized on-road test?',
      fieldType: 'RADIO',
      options: YES_NO,
      valueDependsOn: ['numberOfErrors'],
      units: getRef(
        'Ref.: 4 or more errors suggests increased risk of failing specialized on-road test; Mazer et al (1998).'
      ),
    },
    ...Results,
  ],
};

export default standardizedRoadMapTest;
