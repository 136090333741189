import React, { FC } from 'react';

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';

interface TablePaginationCompProps {
  currentPage: number;
  rowsPerPage: number;
  rowsCount: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const TablePaginationComp: FC<TablePaginationCompProps> = ({
  currentPage,
  rowsPerPage,
  rowsCount,
  handleChangePage,
  handleChangeRowsPerPage,
}) => (
  <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Grid item>
      <TablePagination
        component="div"
        count={rowsCount}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[1, 3, 5, 10, 20, 30, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  </Container>
);

export default TablePaginationComp;
