import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

import useToken from '../useToken';

export const WAIT = 1000;
export const SHORT_WAIT = 500;
export const MAX_WAIT = 3000;

export const generateAuthorizationHeader = (token: string) => ({
  headers: { Authorization: token },
});

// Axios Calls
export const axiosGetCall = (url: string, token: string) =>
  axios
    .get(url, generateAuthorizationHeader(token))
    .then((response) => response.data[0] || {});

export const axiosGetAllCall = (url: string, token: string) =>
  axios
    .get(url, generateAuthorizationHeader(token))
    .then((response) => response.data);

export const axiosPutCall = (url: string, data: any, token: string) =>
  axios
    .put(url, { ...data }, generateAuthorizationHeader(token))
    .then((response) => response.data);

export const axiosPostCall = (url: string, data: any, token: string) =>
  axios
    .post(url, { ...data }, generateAuthorizationHeader(token))
    .then((response) => response.data);

export const axiosDeleteCall = (url: string, token: string) =>
  axios
    .delete(url, generateAuthorizationHeader(token))
    .then((response) => response.data);

// Query Helpers
export const useQueryWithToken = (
  queryKeys: QueryKey,
  axiosCall: (token: string) => Promise<any>
) => {
  const getSilentToken = useToken();

  return useQuery(
    queryKeys,
    () =>
      getSilentToken
        .then((response) => axiosCall(response.accessToken))
        .catch((error) => {
          throw new Error(error);
        }),
    { useErrorBoundary: true }
  );
};

export const useMutationWithToken = (
  queryKeys: QueryKey,
  axiosCall: (mutationParameter: any, token: string) => Promise<any>
) => {
  const getSilentToken = useToken();
  const queryClient = useQueryClient();

  return useMutation(
    (mutationParameter: any) =>
      getSilentToken
        .then((response) => axiosCall(mutationParameter, response.accessToken))
        .then((response) => response.data),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys),
    }
  );
};
