import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, getIntersectionOptions } from './utils';

const TYPE_OF_TRAFFIC_CIRCLES_LABELS = ['Single lane', 'Double lane'];

export const TRAFFIC_CIRCLE_META: SubHeadingItem = {
  key: 'trafficCircle',
  label: 'Traffic Circle',
  fields: [
    {
      label: 'Type of Traffic Circle',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(TYPE_OF_TRAFFIC_CIRCLES_LABELS),
    },
  ],
};

const TRAFFIC_CIRCLE_ADJUSTMENT_TO_STIMULI_AND_SIGNS = [
  'Failed to yield when entering',
  'Failed to yield to left lane',
];

export const TRAFFIC_CIRCLE_ERRORS: SubHeadingItem = {
  key: 'trafficCircle',
  label: 'Traffic Circle',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'trafficCircleAdjustmentToStimuliTraffic',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(
        TRAFFIC_CIRCLE_ADJUSTMENT_TO_STIMULI_AND_SIGNS
      ),
    },
    {
      label: 'Lane Maintenance',
      key: 'trafficCircleLaneMaintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Entered incorrect lane',
        'Exited from incorrect lane',
        'Sharp turn into lane',
        'Changed lanes while in the circle',
      ]),
    },
    ...getCommonSignallingFields(`trafficCircle`),
    ...getIntersectionOptions('trafficCircle'),
  ],
};
