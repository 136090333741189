import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const WOMAC_CATEGORIES = [
  { name: 'Pain', score: 20 },
  { name: 'Stiffness', score: 8 },
  { name: 'Physical function', score: 68 },
].map((item) => ({
  label: `${item.name} score`,
  key: `${convertLabelToKey(item.name)}Score`,
  fieldType: 'INPUT-NUM' as FieldTypes,
  units: <Typography>/ {item.score} Points</Typography>,
}));

const theWesternOntarioMcMaster: SubHeadingItem = {
  label: 'The Western Ontario and McMaster Universities Osteoarthritis Index',
  key: 'theWesternOntarioMcMaster',
  fields: [
    ...WOMAC_CATEGORIES,
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 96 Points</Typography>,
      valueDependsOn: getKeysFromOptions(WOMAC_CATEGORIES),
    },
    ...Results,
  ],
};

export default theWesternOntarioMcMaster;
