import { Conditional } from '../../../models/forms';

const anyAdditionalMedicationsClientNotTaking = (values: any) =>
  values.anyAdditionalMedicationsClientNotTaking === 'yes';
const supportPersonDoesntConfirmsMedications = (values: any) =>
  values.supportPersonConfirmsMedications === 'no' ||
  values.supportPersonConfirmsMedications === 'unknown';

const hasOtherMedicationSideEffects = (values: any) =>
  values.medicationSideEffectsOtherSideEffects === true ||
  values.medicationSideEffectsOtherSideEffects === '1';
const hasMedicationSideEffects = (values: any) =>
  values.medicationSideEffectsOtherSideEffects === true ||
  values.medicationSideEffectsOtherSideEffects === '1' ||
  values.medicationSideEffectsBlurredVision === true ||
  values.medicationSideEffectsBlurredVision === '1' ||
  values.medicationSideEffectsDizziness === true ||
  values.medicationSideEffectsDizziness === '1' ||
  values.medicationSideEffectsDrowsiness === true ||
  values.medicationSideEffectsDrowsiness === '1' ||
  values.medicationSideEffectsHeadaches === true ||
  values.medicationSideEffectsHeadaches === '1';

export const MEDICATIONS_CONDITIONALS: Conditional[] = [
  {
    key: 'otherMedicationSideEffectsDetails',
    label: 'Other side effects',
    fieldType: 'TEXT-AREA',
    condition: hasOtherMedicationSideEffects,
  },
  {
    key: 'medicationEffectOnDriving',
    label: 'Any medications effect on driving?',
    fieldType: 'TEXT-AREA',
    condition: hasMedicationSideEffects,
  },
];

export const SUPPORT_PERSON_DOESNT_CONFIRM: Conditional[] = [
  {
    key: 'supportPersonDoesntConfirmsMedicationsDetails',
    label: 'Please provide details',
    fieldType: 'TEXT-AREA',
    condition: supportPersonDoesntConfirmsMedications,
  },
];
export const ADDITIONAL_PRESCRIBED_MEDICATIONS: Conditional[] = [
  {
    key: 'anyAdditionalMedicationsClientNotTakingDetails',
    label: 'Please provide details',
    fieldType: 'TEXT-AREA',
    condition: anyAdditionalMedicationsClientNotTaking,
  },
];
