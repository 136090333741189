import React, { FC } from 'react';

import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';

const GenericClientsFallback: FC<FallbackProps> = ({ error }) => {
  const { t } = useTranslation(allNamespaces);

  return <>{getTranslationLabel('errors', 'genericClientsFallback', t)}</>;
};

export default GenericClientsFallback;
