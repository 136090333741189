import React from 'react';

import { Typography } from '@mui/material';

import { IOption, MainHeadingItem } from '../../../models/forms';
import {
  PhysicalResults,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getNumericOptionsXToY,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const NONE_MILD_MODERATE_SEVERE: IOption[] = getOptionsFromLabels([
  'None',
  'Mild',
  'Moderate',
  'Severe',
]);

const otherPhysicalStatus: MainHeadingItem = {
  key: 'otherPhysicalStatus',
  label: 'Other Physical Status',
  subheadings: [
    {
      key: 'enduranceSection',
      label: 'Endurance and Activity Tolerance',
      fields: [
        {
          key: 'endurance',
          label: 'Endurance and activity tolerance',
          fieldType: 'CHOICE_CHIPS',
          options: getOptionsFromLabels(['Normal', 'Limited']),
        },
        {
          key: 'shortnessOfBreath',
          label: 'Shortness of breath',
          fieldType: 'CHOICE_CHIPS',
          options: YES_NO,
        },
        {
          key: 'timeBeforeTakingABreakLabel',
          label: 'Amount of time the client is able to engage without a break',
          fieldType: 'LABEL',
        },
        {
          key: 'timeBeforeTakingABreak',
          label: 'Time',
          fieldType: 'INPUT-NUM',
          units: <Typography>min</Typography>,
        },
        {
          key: 'reasonForTakingABreak',
          label: 'If client needed a break, it was due to ...',
          fieldType: 'RADIO',
          options: getOptionsFromLabels([
            'Anxiety',
            'Pain',
            'Cognitive fatique',
            'Physical fatigue',
            'Incontinence',
            'Other',
          ]),
        },
      ],
    },
    {
      key: 'painSection',
      label: 'Pain',
      fields: [
        {
          key: 'painRating',
          label: 'Pain rating',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(1, 10),
        },
        {
          key: 'painAreas',
          label: 'List of areas in which client has pain',
          fieldType: 'TEXT-AREA',
        },
      ],
    },
    {
      key: 'muscleToneAndSpasticitySection',
      label: 'Muscle Tone and Spasticity',
      fields: [
        {
          key: 'muscleTone',
          label: 'Muscle tone',
          fieldType: 'CHOICE_CHIPS',
          options: getOptionsFromLabels(['Low', 'Normal', 'High']),
        },
        {
          key: 'muscleToneLocation',
          label: 'Location(s)',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'spasticity',
          label: 'Spasticity',
          fieldType: 'CHOICE_CHIPS',
          options: NONE_MILD_MODERATE_SEVERE,
        },
        {
          key: 'spasticityLocation',
          label: 'Location(s)',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'whenIsSpasticityObserved',
          label: 'When is spasticity observed?',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'muscleToneInterventions',
          label: 'Interventions',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels([
            'Botox',
            'Medication',
            'Tendon surgery',
            'Other (specify in comments)',
          ]),
        },
        {
          key: 'tremor',
          label: 'Tremor',
          fieldType: 'CHOICE_CHIPS',
          options: NONE_MILD_MODERATE_SEVERE,
        },
        {
          key: 'typeOfTremor',
          label: 'Type of tremor',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels(['Intention tremor', 'Resting tremor']),
        },
      ],
    },
    {
      key: 'edemaSection',
      label: 'Edema',
      fields: [
        {
          key: 'edema',
          label: 'Edema',
          fieldType: 'CHOICE_CHIPS',
          options: NONE_MILD_MODERATE_SEVERE,
        },
        {
          key: 'edemaLocation',
          label: 'Location(s)',
          fieldType: 'TEXT-AREA',
        },
      ],
    },
    {
      key: 'reflexesSection',
      label: 'Reflexes',
      fields: [
        {
          key: 'startleReflex',
          label: 'Startle reflex',
          fieldType: 'CHOICE_CHIPS',
          options: getOptionsFromLabels(['Present', 'Absent']),
        },
        {
          key: 'recoveryTimeFromStartleReflex',
          label: 'Recovery time',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: PhysicalResults,
    },
  ],
};

export default otherPhysicalStatus;
