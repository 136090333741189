import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const FUNCTIONAL_STATUS_CATEGORIES = [
  { name: 'Physical function (ADL)', score: 87 },
  { name: 'Intermediate ADL', score: 77 },
  { name: 'Psychological function', score: 70 },
  { name: 'Social / role function', score: 78 },
  { name: 'Social activity', score: 78 },
  { name: 'Quality of social interaction', score: 69 },
];

const functionalStatus: MainHeadingItem = {
  label: 'Functional Status',
  key: 'functionalStatus',
  subheadings: [
    ...FUNCTIONAL_STATUS_CATEGORIES.map((category) => ({
      key: `${convertLabelToKey(category.name)}Section`,
      label: category.name,
      fields: [
        {
          key: `${convertLabelToKey(category.name)}Score`,
          label: 'Score',
          fieldType: 'INPUT-NUM' as FieldTypes,
        },
        {
          key: `${convertLabelToKey(category.name)}Interpretation`,
          label: 'Interpretation',
          fieldType: 'RADIO' as FieldTypes,
          options: [
            {
              key: `${convertLabelToKey(category.name)}Good`,
              val: `${convertLabelToKey(category.name)}Good`,
              label: `Good (${category.score + 1}-100)`,
            },
            {
              key: `${convertLabelToKey(category.name)}WarningZone`,
              val: `${convertLabelToKey(category.name)}WarningZone`,
              label: `Warning zone (0-${category.score})`,
            },
          ],
          valueDependsOn: [`${convertLabelToKey(category.name)}Score`],
        },
      ],
    })),
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: Results,
    },
  ],
};

export default functionalStatus;
