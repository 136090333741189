import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const charronTest: SubHeadingItem = {
  label: 'Charron Test (Visual Attention Processing)',
  key: 'charronTest',
  fields: [
    {
      label: 'Identical pairs score',
      key: 'identicalPairScore',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 19</Typography>,
    },
    {
      label: 'Non identical pairs score',
      key: 'nonIdenticalPairsScore',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 37</Typography>,
    },
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 56</Typography>,
      valueDependsOn: ['identicalPairScore', 'nonIdenticalPairsScore'],
    },
    ...Results,
  ],
};

export default charronTest;
