import {
  IStandardRoute,
  IStandardRouteManeuver,
} from '../../../models/on-road/route';
import { useUsername } from '../../../utils/utils';
import {
  axiosDeleteCall,
  axiosGetAllCall,
  axiosPostCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const standardRouteKeys = {
  all: ['standardRoutes'] as const,
  standardRouteId: (standardRouteId: string) =>
    ['standardRoutes', standardRouteId] as const,
};

const standardRouteManeuverKeys = (standardRouteId: string) =>
  ['standardRoutes', standardRouteId, 'maneuvers'] as const;

const standardRoutesUrl = (username: string) =>
  `/api/settings/${username}/standard-route`;

const standardRouteUrl = (username: string, standardRouteId: string) =>
  `/api/settings/${username}/standard-route/${standardRouteId}`;

const standardRouteManeuversUrl = (username: string, standardRouteId: string) =>
  `/api/settings/${username}/standard-route/${standardRouteId}/maneuver`;

const standardRouteManeuverUrl = (
  username: string,
  standardRouteId: string,
  standardManeuverId: string
) =>
  `/api/settings/${username}/standard-route/${standardRouteId}/maneuver/${standardManeuverId}`;

// Standard Route Hooks
export const useStandardRoute = (standardRouteId: string) => {
  const username = useUsername();
  return useQueryWithToken(
    standardRouteKeys.standardRouteId(standardRouteId),
    (token: string) =>
      axiosGetAllCall(standardRouteUrl(username, standardRouteId), token)
  );
};

export const useStandardRoutes = () => {
  const username = useUsername();
  return useQueryWithToken(standardRouteKeys.all, (token: string) =>
    axiosGetAllCall(standardRoutesUrl(username), token)
  );
};

export const useCreateStandardRoute = () => {
  const username = useUsername();
  return useMutationWithToken(
    standardRouteKeys.all,
    (standardRoute: IStandardRoute, token: string) =>
      axiosPostCall(standardRoutesUrl(username), standardRoute, token)
  );
};

export const useDeleteStandardRoute = () => {
  const username = useUsername();
  return useMutationWithToken(
    standardRouteKeys.all,
    (data: IStandardRoute, token: string) =>
      axiosDeleteCall(standardRouteUrl(username, data.standardRouteId), token)
  );
};

// Standard Route Maneuver Hooks
export const useStandardRouteManeuvers = (standardRouteId: string) => {
  const username = useUsername();
  return useQueryWithToken(
    standardRouteManeuverKeys(standardRouteId),
    (token: string) =>
      axiosGetAllCall(
        standardRouteManeuversUrl(username, standardRouteId),
        token
      )
  );
};

export const useCreateStandardRouteManeuver = (standardRouteId: string) => {
  const username = useUsername();
  return useMutationWithToken(
    standardRouteKeys.all,
    (standardRouteManeuver: IStandardRouteManeuver, token: string) =>
      axiosPostCall(
        standardRouteManeuversUrl(
          username,
          standardRouteManeuver.standardRouteId
        ),
        standardRouteManeuver,
        token
      )
  );
};

export const useDeleteStandardRouteManeuver = (standardRouteId: string) => {
  const username = useUsername();
  return useMutationWithToken(
    standardRouteKeys.all,
    (standardRouteManeuver: IStandardRouteManeuver, token: string) =>
      axiosDeleteCall(
        standardRouteManeuverUrl(
          username,
          standardRouteManeuver.standardRouteId,
          standardRouteManeuver.standardManeuverId
        ),
        token
      )
  );
};
