import { useQueryClient } from '@tanstack/react-query';

import { IRoadTest } from '../../../models/on-road/on-road';
import { useReactQueryAutoSync } from '../../auto-sync/useReactQueryAutoSync';
import useToken from '../../useToken';
import { MAX_WAIT, WAIT, axiosGetCall, axiosPutCall } from '../utils';

const roadTestKeys = (roadTestId: string) => ['roadTest', roadTestId] as const;

const roadTestUrl = (clientId: string) => `/api/on-road/${clientId}`;

const useRoadTest = (clientId: string) => {
  const getSilentToken = useToken();
  const queryClient = useQueryClient();

  return useReactQueryAutoSync({
    queryOptions: {
      queryKey: roadTestKeys(clientId),
      queryFn: () =>
        getSilentToken
          .then((response) =>
            axiosGetCall(roadTestUrl(clientId), response.accessToken)
          )
          .catch((err) => {
            throw new Error(err);
          }),
    },
    mutationOptions: {
      mutationFn: (roadTest: IRoadTest) =>
        getSilentToken
          .then((response) =>
            axiosPutCall(roadTestUrl(clientId), roadTest, response.accessToken)
          )
          .catch((err) => {
            throw new Error(err);
          }),
      onSuccess: () => queryClient.invalidateQueries(roadTestKeys(clientId)),
    },
    autoSaveOptions: {
      wait: WAIT,
      maxWait: MAX_WAIT,
    },
    merge: (remote, local) => ({
      ...remote,
      ...local,
    }),
  });
};

export default useRoadTest;
