import React from 'react';

import { Typography } from '@mui/material';

import { IOption, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const BELLS_TEST_SEARCH_PATTERNS: IOption[] = [
  'Horizontal scanning',
  'Veritcal scanning',
  'Constricting circle',
  'Inefficient',
  'Scattered',
].map((item, i) => ({
  key: `${convertLabelToKey(item)}SearchPattern`,
  val: `${convertLabelToKey(item)}SearchPattern`,
  label: item,
}));

const bellsTest: SubHeadingItem = {
  key: 'bellsTest',
  label: 'Bells Test',
  fields: [
    {
      label: 'Task completed?',
      key: 'isTaskCompleted',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      label: 'Was the client given an "are you sure" prompt to finish?',
      key: 'areYouSurePromptGiven',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      label: 'Number of bells circled',
      key: 'numberOfBellsCircled',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 35 max</Typography>,
    },
    {
      label: 'Number of commissions',
      key: 'numberOfCommissions',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of omissions (left)',
      key: 'numberOfOmissionsLeft',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of omissions (right)',
      key: 'numberOfOmissionsRight',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Time taken',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      key: 'bellsTestFile',
      label: 'bells test form',
      fieldType: 'UPLOAD',
    },
    {
      label: 'Search pattern',
      key: 'searchPattern',
      fieldType: 'CHECKBOX',
      options: BELLS_TEST_SEARCH_PATTERNS,
    },
    {
      label: 'Analysis of the raw score',
      key: 'analysisOfRawScoreBellsTest',
      fieldType: 'RADIO',
      options: [
        {
          key: 'pass',
          val: 'pass',
          label: 'Pass',
        },
        {
          key: 'undetermined',
          val: 'undetermined',
          label: 'Undetermined',
        },
        {
          key: 'failAttentionalDeficit',
          val: 'failAttentionalDeficit',
          label: 'Fail (attentional deficit)',
        },
        {
          key: 'failUnilateralSpatialNeglect',
          val: 'failUnilateralSpatialNeglect',
          label: 'Fail (unilateral spatial neglect)',
        },
      ],
      units: getRef(
        'Must be completed in under 183 seconds with at most 3 omission errors according to Gauthier L. et al. 1989'
      ),
      valueDependsOn: [
        'isTaskCompleted',
        'numberOfBellsCircled',
        'time',
        'numberOfOmissionsLeft',
        'numberOfOmissionsRight',
      ],
    },
    ...Results,
  ],
};

export default bellsTest;
