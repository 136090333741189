import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const ninePegHoleTest: SubHeadingItem = {
  key: 'ninePegHoleTest',
  label: 'Nine Hole Peg Test (NHPT)',
  fields: [
    {
      label: 'Time taken',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Number of pegs per second',
      key: 'numberOfPegsPerSecond',
      fieldType: 'INPUT-NUM',
    },
    ...Results,
  ],
};

export default ninePegHoleTest;
