import React from 'react';

import { Grid } from '@mui/material';

import ClientsHeader from './ClientsHeader';
import ClientsTable from './ClientsTable';

const ClientsView = () => (
  <Grid container direction="column" spacing={3}>
    <ClientsHeader />
    <ClientsTable />
  </Grid>
);

export default ClientsView;
