import React from 'react';

import { Container } from '@mui/material';

import { containerWidth } from './constants';

const now = new Date();

const UnderMaintenance = () => (
  <Container
    maxWidth={false}
    sx={{
      width: `${containerWidth}%`,
    }}
  >
    <h1>App is Currently Under Maintenance</h1>
    <h2>
      {now.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })}
    </h2>
    <p>
      The DriveEval app is currently under maintenance and is unavailable as we
      deploy the newest version of the app. DriveEval will be avaliable as soon
      as possible. We apologize for any inconvenience.
    </p>
  </Container>
);

export default UnderMaintenance;
