import React from 'react';

import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { containerWidth, gray, light, medium } from '../constants';
import line from '../img/line.png';

const Footer = () => {
  const { t } = useTranslation(websiteNamespaces);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <img src={line} width="100%" height="1.5px" alt="Diving line" />
      <Typography
        sx={{
          fontFamily: 'Work Sans',
          fontSize: medium,
          fontWeight: light,
          color: gray,
          textTransform: 'none',
        }}
      >
        &copy; {getTranslationLabel('website/general', 'copyright', t)}
      </Typography>
    </Container>
  );
};

export default Footer;
