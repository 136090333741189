import React from 'react';

import { Typography } from '@mui/material';

import {
  FieldsLayout,
  FieldTypes,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../../models/forms';
import {
  MOBILITY_AIDS,
  TRANSFER_OPTIONS,
} from '../../../assessment-definitions/Physical/mobilityAndBalance';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../../common/FieldContainer/MyTable-Utils';
import {
  RATING_SCALE_OPTIONS,
  RATING_SCALE_OPTIONS_CABIN,
} from '../ON-ROAD-CONSTANTS';

const existHandControls = (values: any) =>
  values.leftHandControls === true ||
  values.leftHandControls === '1' ||
  values.rightHandControls === true ||
  values.rightHandControls === '1';

const MOBILITY_AIDS_OPTIONAL = [
  ...MOBILITY_AIDS,
  ...getOptionsFromLabels(['None']),
];

const MOBILITY_AIDS_COLUMN_HEADINGS = [
  '',
  'Mobility aid 1',
  'Mobility aid 2',
  'Mobility aid 3',
];
const STORAGE_LOCATIONS = getOptionsFromLabels([
  'Trunk',
  'Beside them',
  'Back seat',
  'Truck bed',
  'Remains in mobility aid',
  'Other (specify in comments)',
]);

const VEHICLE_INGRESS_EGRESS_AIDS_COLUMN_HEADINGS = [
  '',
  'Vehicle Ingress Mobility Aid 1',
  'Vehicle Egress Mobility Aid 1',
  'Vehicle Ingress Mobility Aid 2',
  'Vehicle Egress Mobility Aid 2',
];
const VEHICLE_INGRESS_EGRESS_AIDS_ROW_TITLES = [
  'Type of aid',
  'Result',
  'Comments',
];

const getVehicleIngressEgressAidRowTitle = (key: string) => [
  {
    rowTitle: 'Type of aid',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: MOBILITY_AIDS_OPTIONAL,
      size: 200,
    },
  },
  {
    rowTitle: 'Result',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: TRANSFER_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];

const LOADING_UNLOADING_MOBILITY_AIDS_ROW_TITLES = [
  'Type of aid',
  'Storage location',
  'Result',
  'Comments',
];

const getLoadingUnloadingMobilityAidsRowsSpec = (key: string) => [
  {
    rowTitle: 'Result',
    element: {
      key: `${key}LoadingUnloading`,
      label: '',
      fieldType: 'RADIO' as FieldTypes,
      options: getOptionsFromLabels([
        'Functional',
        'Requires assistance',
        'Not functional',
        'Not observed',
      ]),
      size: 200,
    },
  },
  {
    rowTitle: 'Type of aid',
    element: {
      key: `${key}LoadingUnloading`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: MOBILITY_AIDS_OPTIONAL,
      size: 200,
    },
  },
  {
    rowTitle: 'Storage location',
    element: {
      key: `${key}LoadingUnloading`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: STORAGE_LOCATIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}LoadingUnloading`,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];

export const ON_ROAD_EQUIPMENT: FieldsLayout[] = [
  {
    key: 'equipmentUsed',
    label: 'Equipment Used',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'No Equipment Used',
      'Left Foot Accelerator',
      'Pedal Extensions',
      'Turn Signal Extension',
      'Turn Signal Cross Over',
      'Spinner Knob',
      'Left Hand Controls',
      'Right Hand Controls',
      'Prehension Aid',
      'Custom Seating',
      'Convex Mirrors',
      'Other equipment',
    ]),
    conditionals: [
      {
        key: 'typeOfHandControl',
        label: 'Type of hand control',
        fieldType: 'CHOICE_CHIPS',
        options: getOptionsFromLabels(['Mechanical', 'Electronic']),
        condition: existHandControls,
      },
      {
        key: 'typeOfMechanicalHandControl',
        label: 'Type of mechanical hand control',
        fieldType: 'CHOICE_CHIPS',
        options: getOptionsFromLabels([
          'Push pull',
          'Push rock',
          'Push right angle',
          'Push twist',
        ]),
        condition: (values: any) =>
          existHandControls(values) &&
          values.typeOfHandControl === 'mechanical',
      },
      {
        key: 'typeOfElectronicHandControl',
        label: 'Type of electronic hand control',
        fieldType: 'INPUT',
        condition: (values: any) =>
          existHandControls(values) &&
          values.typeOfHandControl === 'electronic',
      },
    ],
  },
];

export const ON_ROAD_CABIN: FieldsLayout[] = [
  {
    label: 'Ignition',
    key: 'ignition',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS_CABIN,
  },
  {
    label: 'Rear View Mirrors',
    key: 'rearViewMirrors',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS_CABIN,
  },
  {
    label: 'Side Mirrors',
    key: 'sideMirrors',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS_CABIN,
  },
  {
    label: 'Seat Belt',
    key: 'seatBelt',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS_CABIN,
  },
  {
    label: 'Seat Adjustments',
    key: 'seatAdjustments',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS_CABIN,
  },
  {
    label: 'Parking Brake',
    key: 'parkingBrake',
    fieldType: 'CHOICE_CHIPS',
    options: [...RATING_SCALE_OPTIONS_CABIN, ...getOptionsFromLabels(['N/A'])],
  },
];

export const ON_ROAD_PRIMARY_CONTROL: FieldsLayout[] = [
  {
    label: 'Accelerator',
    key: 'accelerator',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Brake',
    key: 'brake',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Steering Wheel',
    key: 'steeringWheel',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Left Hand position',
    key: 'leftHandPosition',
    fieldType: 'INPUT-NUM',
    units: <Typography>o&apos;clock</Typography>,
  },
  {
    label: 'Right Hand position',
    key: 'rightHandPosition',
    fieldType: 'INPUT-NUM',
    units: <Typography>o&apos;clock</Typography>,
  },
  {
    label: 'Gear Shift',
    key: 'gearShift',
    fieldType: 'CHOICE_CHIPS',
    options: [...RATING_SCALE_OPTIONS, ...getOptionsFromLabels(['N/A'])],
  },
  {
    label: 'Clutch',
    key: 'clutch',
    fieldType: 'CHOICE_CHIPS',
    options: [...RATING_SCALE_OPTIONS, ...getOptionsFromLabels(['N/A'])],
  },
];

export const ON_ROAD_SECONDARY_CONTROL: FieldsLayout[] = [
  {
    label: 'Windshield Wipers',
    key: 'windshieldWipers',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Washer Fluid Switch',
    key: 'washerFluidSwitch',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Defrost',
    key: 'defrost',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Horn',
    key: 'horn',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'High/Low Beams',
    key: 'highLowBeams',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
  {
    label: 'Cruise Control',
    key: 'cruiseControl',
    fieldType: 'CHOICE_CHIPS',
    options: [...RATING_SCALE_OPTIONS, ...getOptionsFromLabels(['N/A'])],
  },
  {
    label: 'Singals',
    key: 'signals',
    fieldType: 'CHOICE_CHIPS',
    options: RATING_SCALE_OPTIONS,
  },
];

const OPERATIONAL_ABILITIES: SubHeadingItem[] = [
  {
    label: 'Equipment',
    key: 'onRoadEquipment',
    fields: ON_ROAD_EQUIPMENT,
  },
  {
    key: 'vehicleIngressAndEgressAndMobilityAids',
    label: 'Vehicle Ingress and Egress and Mobility Aids',
    fields: [
      {
        key: 'vehicleIngressAndEgressAndMobilityAidsTable',
        label: '',
        fieldType: 'TABLE',
        columnHeadings: VEHICLE_INGRESS_EGRESS_AIDS_COLUMN_HEADINGS,
        cells: generateCellsForTableWithUniformCells(
          VEHICLE_INGRESS_EGRESS_AIDS_COLUMN_HEADINGS,
          VEHICLE_INGRESS_EGRESS_AIDS_ROW_TITLES,
          getVehicleIngressEgressAidRowTitle,
          undefined
        ),
      },
    ],
  },
  {
    key: 'loadingAndUnloadingOfMobilityAids',
    label: 'Loading and Unloading of Mobility Aids',
    fields: [
      {
        key: 'loadingAndUnloadingOfMobilityAidsTable',
        label: '',
        fieldType: 'TABLE',
        columnHeadings: MOBILITY_AIDS_COLUMN_HEADINGS,
        cells: generateCellsForTableWithUniformCells(
          MOBILITY_AIDS_COLUMN_HEADINGS,
          LOADING_UNLOADING_MOBILITY_AIDS_ROW_TITLES,
          getLoadingUnloadingMobilityAidsRowsSpec,
          undefined
        ),
      },
    ],
  },
  {
    label: 'Cockpit',
    key: 'cockpit',
    fields: ON_ROAD_CABIN,
    selectAll: 'functional',
  },
  {
    label: 'Primary Control',
    key: 'primaryControl',
    fields: ON_ROAD_PRIMARY_CONTROL,
    selectAll: 'functional',
  },
  {
    label: 'Secondary Control',
    key: 'secondaryControl',
    fields: ON_ROAD_SECONDARY_CONTROL,
    selectAll: 'functional',
  },
];

export const OPERATIONAL_SECTION: MainHeadingItem = {
  key: 'operational',
  label: 'Operational',
  subheadings: OPERATIONAL_ABILITIES,
};

export default OPERATIONAL_ABILITIES;
