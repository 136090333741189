import React, { FC } from 'react';

import { Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import { ReactQueryAutoSyncSaveStatus } from '../../../hooks/auto-sync/utils/ReactQueryAutoSyncStatus';
import useManeuverOptions from '../../../hooks/database/on-road/maneuverOptions';
import { IForm, FieldsLayout, IOption } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import AutoSave from '../../common/AutoSave';
import FormSection from '../../common/FormSection';

interface DefaultManeuverTypesProps {
  handleSave: (maneuverTypes: any) => void;
  saveStatus: ReactQueryAutoSyncSaveStatus;
  defaultManeuverTypes: any;
}

const DefaultManeuverTypes: FC<DefaultManeuverTypesProps> = ({
  handleSave,
  saveStatus, // TODO
  defaultManeuverTypes,
}) => {
  const maneuverOptions = useManeuverOptions();

  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({ defaultValues: defaultManeuverTypes });

  if (maneuverOptions.isLoading) {
    <LoadingComponent />;
  }

  if (maneuverOptions.isError) {
    <ErrorComponent error={maneuverOptions.error} />;
  }

  let fieldManeuverOptions: IOption[] = [];
  if (maneuverOptions.isSuccess) {
    fieldManeuverOptions = maneuverOptions.data
      .sort((a: any, b: any) => a.position - b.position)
      .map((option: any) => ({
        key: option.type,
        val: option.type,
        label: option.label,
      }));
  }

  const fields: FieldsLayout[] = [
    {
      label: 'Default Maneuver Types',
      key: 'defaultManeuverTypes',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: fieldManeuverOptions,
    },
  ];

  const form: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  return (
    <Stack direction="column" spacing={3}>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit((values) => handleSave(values))}>
          <Grid item container direction="column" spacing={3}>
            <Grid item>
              <FormSection
                fields={fields}
                namespace="maneuvers/defaultManeuverTypes"
                values={watch()}
                sectionKey="onRoadSettings"
                commentsPosition="none"
              />
            </Grid>
            <Grid item>
              <AutoSave
                watch={watch}
                handleSave={(values) => handleSave(values)}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Stack>
  );
};

export default DefaultManeuverTypes;
