import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { IForm } from '../../models/forms';
import { IRoadTest } from '../../models/on-road/on-road';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import AutoSave from '../common/AutoSave';
import Column from './Common/Column';
import {
  SEC4_CONDITIONS,
  SEC3_CLIENT_BACKGROUND,
  SEC1_PRE_TEST_DETAILS,
  SEC2_VEHICLE_INFO,
} from './Constants/ON-ROAD-FIELDS/PRE-TEST';

interface Tab1PreTestDetailsProps {
  roadTest: IRoadTest;
  handleSave: (onRoad: IRoadTest) => void;
}

const Tab1PreTestDetails: FC<Tab1PreTestDetailsProps> = ({
  roadTest,
  handleSave,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({
      defaultValues: roadTest,
    });
  const onRoadForm: IForm = { register, control, setValue, getValues, watch };

  return (
    <Grid item container justifyContent="space-around">
      <FormContext.Provider value={onRoadForm}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid item container>
            <Grid item container direction="column" lg={4} spacing={3}>
              <Column
                title={
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/preTestRouteDetails',
                      'testingDetailsLabel',
                      t
                    )}
                  </Typography>
                }
                namespace="onroad/preTestRouteDetails"
                fields={SEC1_PRE_TEST_DETAILS}
                values={watch()}
                sectionKey="testingDetails"
              />
              <Column
                title={
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/preTestRouteDetails',
                      'vechileDetailsLabel',
                      t
                    )}
                  </Typography>
                }
                namespace="onroad/preTestRouteDetails"
                fields={SEC2_VEHICLE_INFO}
                values={watch()}
                sectionKey="testingVehicleDetails"
              />
            </Grid>
            <Grid item container direction="column" lg={4}>
              <Column
                title={
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/preTestRouteDetails',
                      'preparationLabel',
                      t
                    )}
                  </Typography>
                }
                namespace="onroad/preTestRouteDetails"
                fields={SEC3_CLIENT_BACKGROUND}
                values={watch()}
                sectionKey="preparation"
              />
            </Grid>
            <Grid item container direction="column" lg={4}>
              <Column
                title={
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/preTestRouteDetails',
                      'conditionsLabel',
                      t
                    )}
                  </Typography>
                }
                namespace="onroad/preTestRouteDetails"
                fields={SEC4_CONDITIONS}
                values={watch()}
                sectionKey="roadConditions"
              />
              <Grid item>
                <AutoSave watch={watch} handleSave={handleSave} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Grid>
  );
};

export default Tab1PreTestDetails;
