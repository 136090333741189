import React, { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import BasicCard from '../../../common/BasicCard';
import FieldContainer from '../../../common/FieldContainer/FieldContainer';
import { ROAD_TYPE_OPTIONS } from '../../Constants/ON-ROAD-CONSTANTS';
import { TASK_OPTIONS } from '../../Constants/utils';
import ManeuverMeta from '../../Tab4TacticalManeuvers/OpenManeuverColumn/ManeuverMeta';

interface AddTaskProps {
  addTask: () => void;
  taskType: string;
  subType: string;
}

const AddTask: FC<AddTaskProps> = ({ addTask, taskType, subType }) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Grid item xs={6}>
      <BasicCard
        id="addTask"
        actions={
          <Button
            color="secondary"
            sx={{
              marginLeft: 'auto',
            }}
            onClick={addTask}
          >
            <AddIcon />
          </Button>
        }
      >
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h2">
              {getTranslationLabel('onroad/routeBuilder', 'addManeuver', t)}
            </Typography>
          </Grid>
          <Grid item>
            <FieldContainer
              label="Road Type"
              namespace="onroad/routeBuilder"
              name="roadType"
              type="CHOICE_CHIPS"
              options={ROAD_TYPE_OPTIONS}
            />
          </Grid>
          <Grid item>
            <FieldContainer
              label="Location"
              namespace="onroad/routeBuilder"
              name="location"
              type="INPUT"
            />
          </Grid>
          <Grid item>
            <FieldContainer
              label="Task Type"
              namespace="maneuvers/defaultManeuverTypes"
              name="taskKey"
              type="CHOICE_CHIPS"
              options={TASK_OPTIONS}
            />
          </Grid>
          <Grid item>
            <ManeuverMeta maneuverType={taskType} subType={subType} />
          </Grid>
        </Grid>
      </BasicCard>
    </Grid>
  );
};

export default AddTask;
