import { Conditional, FieldTypes } from '../../../models/forms';
import {
  OfficialClientsPerspective,
  TRUE_FALSE_OPTIONS,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const getLicensingSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
    },
  },
];

const noLicence = (values: any) =>
  values.hasDriversLicence && values.hasDriversLicence !== 'yes';
const unknownLicence = (values: any) =>
  values.hasDriversLicence === 'unknown' ||
  values.everHadLicence === 'unknown' ||
  values.licenceStatus === 'unknown';
const noLicenceEver = (values: any) =>
  values.hasDriversLicence &&
  values.hasDriversLicence !== 'yes' &&
  values.everHadLicence &&
  values.everHadLicence !== 'yes';
const haveOrHadLicence = (values: any) =>
  values.hasDriversLicence === 'yes' ||
  (values.hasDriversLicence &&
    values.hasDriversLicence !== 'yes' &&
    values.everHadLicence === 'yes');

const hadPreviousTraining = (values: any) =>
  noLicenceEver(values) && values.anyPreviousExperience === 'yes';

const haveOrHadLicenceAndRestrictions = (values: any) =>
  haveOrHadLicence(values) && values.hasDrivingRestrictions === 'yes';
const unknownDrivingRestrictions = (values: any) =>
  haveOrHadLicence(values) && values.hasDrivingRestrictions === 'unknown';
const restrictedToXKm = (values: any) =>
  haveOrHadLicenceAndRestrictions(values) &&
  (values.drivingRestrictionsXKmRadiusOfAddress === true ||
    values.drivingRestrictionsXKmRadiusOfAddress === '1');
const customRestrictions = (values: any) =>
  haveOrHadLicenceAndRestrictions(values) &&
  (values.drivingRestrictionsCustomRestrictions === true ||
    values.drivingRestrictionsCustomRestrictions === '1');

const hadAccidents = (values: any) =>
  haveOrHadLicence(values) &&
  (values.officialPerspectiveNumberOfAccidentsLast5Years > 0 ||
    values.clientsPerspectiveNumberOfAccidentsLast5Years > 0);
const hadViolations = (values: any) =>
  haveOrHadLicence(values) &&
  (values.officialPerspectiveNumberOfViolationsLast5Years > 0 ||
    values.clientsPerspectiveNumberOfViolationsLast5Years);

const hadAnInterruption = (values: any) =>
  haveOrHadLicence(values) && values.interruptionsInDrivingPrivileges === 'yes';

const LICENCE_CONDITIONAL: Conditional[] = [
  {
    label: 'Have they ever had a licence?',
    key: 'everHadLicence',
    fieldType: 'RADIO',
    options: TRUE_FALSE_OPTIONS,
    condition: noLicence,
  },
  {
    label: 'Any previous experience with driver training / practice?',
    key: 'anyPreviousExperience',
    fieldType: 'RADIO',
    options: TRUE_FALSE_OPTIONS,
    condition: noLicenceEver,
  },
  {
    label: 'Please specify details of training / practice',
    key: 'detailsOfPreviousDrivingExperience',
    fieldType: 'TEXT-AREA',
    condition: hadPreviousTraining,
  },
  {
    label: 'When?',
    key: 'dateOfPreviousDrivingExperience',
    fieldType: 'DATE_SHORT',
    condition: hadPreviousTraining,
  },
  {
    label: 'Licence status ',
    key: 'licenceStatus',
    fieldType: 'RADIO',
    options: getOptionsFromLabels(['Valid', 'Invalid', 'Suspended', 'Unknown']),
    condition: haveOrHadLicence,
  },
  {
    label: 'Why is their licence status unknown?',
    key: 'whyLicenceStatusUnknown',
    fieldType: 'TEXT-AREA',
    condition: unknownLicence,
  },
  {
    label: 'Class ',
    key: 'licenceClass',
    fieldType: 'INPUT',
    condition: haveOrHadLicence,
  },
  {
    label: 'Number of years driving',
    key: 'yearsOfDriving',
    fieldType: 'INPUT-NUM',
    condition: haveOrHadLicence,
  },
  {
    label: 'Age when started driving',
    key: 'ageWhenStartedDriving',
    fieldType: 'INPUT-NUM',
    condition: haveOrHadLicence,
  },
  {
    label: 'Do they have any driving restrictions?',
    key: 'hasDrivingRestrictions',
    fieldType: 'RADIO',
    options: TRUE_FALSE_OPTIONS,
    condition: haveOrHadLicence,
  },
  {
    label: 'Why are their restrictions unknown?',
    key: 'whyDrivingRestrictionsUnknown',
    fieldType: 'TEXT-AREA',
    condition: unknownDrivingRestrictions,
  },
  {
    label: 'Driving restrictions ',
    key: 'drivingRestrictions',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(
      [
        'Glasses or contact lenses',
        'Night',
        'X km radius of address',
        'Outside city limits',
        'Rear view mirrors',
        'Custom Restrictions',
        'None',
      ],
      undefined,
      'drivingRestrictions'
    ),
    condition: haveOrHadLicenceAndRestrictions,
  },
  {
    label: 'Specify X',
    key: 'drivingRestrictionsSpecifyX',
    fieldType: 'INPUT',
    condition: restrictedToXKm,
  },
  {
    label: 'Specify custom restrictions',
    key: 'customRestrictionsDescription',
    fieldType: 'TEXT-AREA',
    condition: customRestrictions,
  },
  {
    label: 'Endorsements',
    key: 'endorsements',
    fieldType: 'TEXT-AREA',
    condition: haveOrHadLicence,
  },
  {
    label: '',
    key: 'numberOfAccidentsViolationsTable',
    fieldType: 'TABLE',
    columnHeadings: OfficialClientsPerspective,
    cells: generateCellsForTableWithUniformCells(
      OfficialClientsPerspective,
      [
        'Number of accidents (last 5 years)',
        'Number of violations (last 5 years)',
      ],
      getLicensingSpec,
      undefined
    ),
    condition: haveOrHadLicence,
  },
  {
    label: 'Describe recent accidents',
    key: 'recentAccidents',
    fieldType: 'TEXT-AREA',
    condition: hadAccidents,
  },
  {
    label: 'Describe recent violations',
    key: 'recentViolations',
    fieldType: 'TEXT-AREA',
    condition: hadViolations,
  },
  {
    label: 'Sources of accident / violation info',
    key: 'accidentInformationSource',
    fieldType: 'TEXT-AREA',
    condition: haveOrHadLicence,
  },
  {
    label: 'Any interruptions in driving privileges?',
    key: 'interruptionsInDrivingPrivileges',
    fieldType: 'RADIO',
    options: TRUE_FALSE_OPTIONS,
    condition: haveOrHadLicence,
  },
  {
    label: 'When?',
    key: 'dateOfInterruptionsInDrivingPrivileges',
    fieldType: 'DATE_SHORT',
    condition: hadAnInterruption,
  },
  {
    label: 'Please provide details',
    key: 'reasonWhyInterruptionsInDrivingPrivileges',
    fieldType: 'TEXT-AREA',
    condition: hadAnInterruption,
  },
];

export default LICENCE_CONDITIONAL;
