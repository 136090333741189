import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, IOption, MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';

const WEIGL_SCORES: number[] = [0, 1, 2, 3];

const WEIGL_SCORES_OPTIONS: IOption[] = WEIGL_SCORES.map((score) => ({
  key: score.toString(),
  val: score.toString(),
  label: score.toString(),
}));

const WEIGL_CATEGORIES = ['Colour', 'Shape', 'Symbol', 'Texture'];

const getWeiglSubsection = (category: string) => ({
  key: `${category.toLowerCase()}Category`,
  label: `${category} Category`,
  fields: [
    {
      label: 'Sorting score',
      key: `${category.toLowerCase()}SortingScore`,
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: WEIGL_SCORES_OPTIONS,
    },
    {
      label: 'Is client able to describe their sorting strategy?',
      key: `${category.toLowerCase()}ConceptualReasoningScore`,
      fieldType: 'RADIO' as FieldTypes,
      options: YES_NO,
    },
  ],
});

const weiglSortingTask: MainHeadingItem = {
  key: 'weiglSortingTask',
  label: 'Weigl Sorting Task',
  subheadings: [
    getWeiglSubsection('Colour'),
    getWeiglSubsection('Shape'),
    getWeiglSubsection('Symbol'),
    getWeiglSubsection('Texture'),
    {
      key: 'scoreTotals',
      label: 'Score Totals',
      fields: [
        {
          label: 'Total sorting score',
          key: 'totalSortingScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 12 points</Typography>,
          valueDependsOn: WEIGL_CATEGORIES.map(
            (item) => `${item.toLowerCase()}SortingScore`
          ),
        },
        {
          label: 'Total conceptual reasoning score',
          key: 'totalConceptualReasoningScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 4 points</Typography>,
          valueDependsOn: WEIGL_CATEGORIES.map(
            (item) => `${item.toLowerCase()}ConceptualReasoningScore`
          ),
        },
        {
          label: 'Perseveration score',
          key: 'perseverationScore',
          fieldType: 'INPUT-NUM',
          units: (
            <Typography>
              Number of times client reverted to a previously used category (not
              included in total)
            </Typography>
          ),
        },
        {
          label: 'Total score',
          key: 'totalScore',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 16 points</Typography>,
          valueDependsOn: [
            'totalSortingScore',
            'totalConceptualReasoningScore',
          ],
        },
        ...Results,
      ],
    },
  ],
};

export default weiglSortingTask;
