import React, { FC } from 'react';

import { Typography } from '@mui/material';

import BasicDialog from '../BasicDialog';

export interface FileOpInProgressModalProps {
  show: boolean;
  title: string;
  text: string;
}

export const FileOpInProgress: FC<FileOpInProgressModalProps> = ({
  show,
  title,
  text,
}) => (
  <BasicDialog
    open={show}
    handleClose={() => {}} // User shouldn't be able to close upload/donwload notification modal
    label={
      <Typography variant="h2" component="span">
        {title}
      </Typography>
    }
    maxWidth="sm"
  >
    <Typography>{text}</Typography>
  </BasicDialog>
);
