import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const beckDepressionInventory: SubHeadingItem = {
  key: 'beckDepressionInventory',
  label: 'Beck Depression Inventory',
  fields: [
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 63</Typography>,
    },
    {
      key: 'interpretationBDI',
      label: 'Interpretation of the score',
      fieldType: 'RADIO',
      options: [
        {
          key: 'normal',
          val: 'normal',
          label: 'These ups and downs are considered normal (1-10)',
        },
        { key: 'mild', val: 'mild', label: 'Mild mood disturbance (11-16)' },
        {
          key: 'borderline',
          val: 'borderline',
          label: 'Borderline clinical depression (17-20)',
        },
        {
          key: 'moderate',
          val: 'moderate',
          label: 'Moderate depression (21-30)',
        },
        { key: 'severe', val: 'severe', label: 'Severe depression (31-40)' },
        {
          key: 'extreme',
          val: 'extreme',
          label: 'Extreme depression (over 40)',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    ...Results,
  ],
};

export default beckDepressionInventory;
