import React from 'react';

import { Typography } from '@mui/material';

import {
  FieldsLayout,
  FieldTypes,
  IOption,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getKeysFromOptions,
  getLanguageField,
  getNumbersXToY,
  getNumericOptionsXToY,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const MOCA_SUBTRACTION_COLUMN_HEADINGS = [
  '',
  ...getNumbersXToY(1, 5).map((item) => `Subtraction No. ${item}`),
];

const MOCA_SUBTRACTION_ROW_TITLES = ['Answer Given', 'Result'];

const getValueDependsOn = (rowTitle: number) =>
  rowTitle === 1
    ? [
        'numberToBeSubtractedInSubtraction',
        'startingNumberForSubtraction',
        `subtractionNo${rowTitle}AnswerGiven`,
      ]
    : [
        'numberToBeSubtractedInSubtraction',
        `subtractionNo${rowTitle - 1}AnswerGiven`,
        `subtractionNo${rowTitle}AnswerGiven`,
      ];

const getMoCASubtractionTableRowSpec = (key: string, columnHeading: string) => [
  {
    rowTitle: 'Answer Given',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 100,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'LABEL' as FieldTypes,
      valueDependsOn: getValueDependsOn(
        parseInt(columnHeading.substring(columnHeading.length - 1), 10)
      ),
    },
  },
];

export const MOCA_ADMINISTRATIVE_SECTION: SubHeadingItem = {
  key: 'administrativeSection',
  label: 'Administrative',
  fields: [
    {
      key: 'testVersion',
      label: 'Test version (e.g. 8.1)',
      fieldType: 'INPUT-NUM',
    },
    getLanguageField(),
  ],
};

export const ARRAY_OF_WORDS: string[][] = [
  ['First', 'One'],
  ['Second', 'Two'],
  ['Third', 'Three'],
  ['Fourth', 'Four'],
  ['Fifth', 'Five'],
];

const getAnimalOptions = (noOfAnimals: number) =>
  ARRAY_OF_WORDS.slice(0, noOfAnimals).map((item) => ({
    key: `animal${item[1]}`,
    val: `animal${item[1]}`,
    label: `${item[0]} animal`,
  }));

export const getAnimalNamingFields = (noOfAnimals: number): FieldsLayout[] => [
  {
    label: 'Check animals named correctly',
    key: 'animalNaming',
    fieldType: 'CHECKBOX',
    options: getAnimalOptions(noOfAnimals),
  },
  {
    label: 'Total naming points',
    key: 'totalNamingPoints',
    fieldType: 'INPUT-NUM',
    units: <Typography>/ {noOfAnimals}</Typography>,
    valueDependsOn: getKeysFromOptions(getAnimalOptions(noOfAnimals)),
  },
];

export const getFiveWords = (section: string) =>
  ARRAY_OF_WORDS.map((item) => ({
    key: `word${item[1]}${section}`,
    val: `word${item[1]}${section}`,
    label: `${item[0]} word`,
  }));

export const MEMORY: FieldsLayout[] = [
  {
    label: 'Check words registered in 1st trial',
    key: 'memoryFirstTrial',
    fieldType: 'CHECKBOX',
    options: getFiveWords('MemoryFirstTrial'),
  }, // no points for this section
  {
    label: 'Check words registered in 2nd trial',
    key: 'memorySecondTrial',
    fieldType: 'CHECKBOX',
    options: getFiveWords('MemorySecondTrial'),
  }, // no points for this section
];

const getCategoriesOptions = (noOfCategories: number) =>
  ARRAY_OF_WORDS.slice(0, noOfCategories).map((item) => ({
    key: `${item[0].toLowerCase()}CategoryIdentifiedCorrectly`,
    val: `${item[0].toLowerCase()}CategoryIdentifiedCorrectly`,
    label: `${item[0]} category identified correctly`,
  }));

export const getAbstractionSection = (
  noOfCategories: number
): SubHeadingItem => ({
  label: 'Abstraction ',
  key: 'abstraction',
  fields: [
    {
      label: 'Category identification',
      key: 'categoryIdentification',
      fieldType: 'CHECKBOX',
      options: getCategoriesOptions(noOfCategories),
    },
    {
      label: 'Total abstraction points',
      key: 'totalAbstractionPoints',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ {noOfCategories}</Typography>,
      valueDependsOn: getKeysFromOptions(getCategoriesOptions(noOfCategories)),
    },
  ],
});

export const DELAYED_RECALL_FIELDS: FieldsLayout[] = [
  {
    label: 'Check words recalled correctly without cues',
    key: 'delayedRecallNoCues',
    fieldType: 'CHECKBOX',
    options: getFiveWords('DelayedRecallNoCues'),
  }, // only points for this section
  {
    label: 'Total uncued points',
    key: 'totalUncuedRecallPoints',
    fieldType: 'INPUT-NUM',
    units: <Typography>/ 5</Typography>,
    valueDependsOn: getKeysFromOptions(getFiveWords('DelayedRecallNoCues')),
  },
  {
    label: 'Check words recalled with category cue',
    key: 'recallCategoryCue',
    fieldType: 'CHECKBOX',
    options: getFiveWords('RecallCategoryCue'),
  },
  {
    label: 'Check words recalled with multiple choice cue',
    key: 'recallMultipleChoiceCue',
    fieldType: 'CHECKBOX',
    options: getFiveWords('RecallMultipleChoiceCue'),
  }, // no points for this section
];

export const MOCA_ALL_MIS_OPTIONS = [
  ...getKeysFromOptions(getFiveWords('DelayedRecallNoCues')),
  ...getKeysFromOptions(getFiveWords('RecallCategoryCue')),
  ...getKeysFromOptions(getFiveWords('RecallMultipleChoiceCue')),
];

const ORIENTATION_LIST: string[] = [
  'Date',
  'Month',
  'Year',
  'Day',
  'Place',
  'City',
];

export const ORIENTATION_LIST_OPTIONS: IOption[] = ORIENTATION_LIST.map(
  (item) => ({
    key: `knows${item}`,
    val: `knows${item}`,
    label: item,
  })
);

export const INTERPRETATION_OF_MOCA = {
  label: 'Interpretation of the score',
  key: 'interpretationMoCA',
  fieldType: 'RADIO' as FieldTypes,
  options: [
    { key: 'normal', val: 'normal', label: '26+: Normal' },
    {
      key: 'mildCognitiveImpairment',
      val: 'mildCognitiveImpairment',
      label: '18-25: Mild cognitive impairment',
    },
    {
      key: 'moderateCognitiveImpairment',
      val: 'moderateCognitiveImpairment',
      label: '10-17: Moderate cognitive impairment',
    },
    {
      key: 'severeCognitiveImpairment',
      val: 'severeCognitiveImpairment',
      label: '0-9: Severe cognitive impairment',
    },
  ],
  units: getRef(
    'Ref.: see https://www.mocatest.org/faq/ - "Interpretation of MoCA" section'
  ),
  valueDependsOn: ['scaledScoreOutOf30'],
};

const moca: MainHeadingItem = {
  label: 'MoCA',
  key: 'moca',
  subheadings: [
    MOCA_ADMINISTRATIVE_SECTION,
    {
      label: 'Visuospatial / Executive',
      key: 'visuospatialExecutive',
      fields: [
        {
          label: 'Trail making and copying tasks',
          key: 'trailMakingAndCopyingTasks',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'trailMakingCompleted',
              val: 'trailMakingCompleted',
              label: 'Trail making completed',
            },
            {
              key: 'copyTaskCompleted',
              val: 'copyTaskCompleted',
              label: 'Copy task completed',
            },
          ],
        },
        {
          label: 'Clock drawing points',
          key: 'clockDrawingPoints',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(0, 3),
        },
        {
          label: 'trail making and clock drawing',
          key: 'mOCATrailMakingAndClockDrawingFile',
          fieldType: 'UPLOAD',
        },
        {
          label: 'Total visuospatial / executive points',
          key: 'totalVisuospatialExecutivePoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 5</Typography>,
          valueDependsOn: [
            'trailMakingCompleted',
            'copyTaskCompleted',
            'clockDrawingPoints',
          ],
        },
      ],
    },
    {
      label: 'Naming',
      key: 'naming',
      fields: getAnimalNamingFields(3),
    },
    {
      label: 'Memory',
      key: 'memory',
      fields: MEMORY,
    },
    {
      label: 'Attention',
      key: 'attention',
      fields: [
        {
          label: 'Repeat digits',
          key: 'repeatDigits',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'forwardRepeatCompletedCorrectly',
              val: 'forwardRepeatCompletedCorrectly',
              label: 'Forward repeat completed correctly',
            },
            {
              key: 'backwardRepeatCompletedCorrectly',
              val: 'backwardRepeatCompletedCorrectly',
              label: 'Backward repeat completed correctly',
            },
          ],
        },
        {
          label: 'Total digit repeat points',
          key: 'totalRepeatDigitPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 2</Typography>,
          valueDependsOn: [
            'forwardRepeatCompletedCorrectly',
            'backwardRepeatCompletedCorrectly',
          ],
        },
        {
          label: 'Letter tapping task',
          key: 'letterTappingTask',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'letterTappingTaskCorrect',
              val: 'letterTappingTaskCorrect',
              label: 'Letter tapping task completed correctly',
            },
          ],
        },
        {
          label: 'Total letter tapping task points',
          key: 'totalLetterTappingTaskPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 1</Typography>,
          valueDependsOn: ['letterTappingTaskCorrect'],
        },
      ],
    },
    {
      label: 'Subtraction',
      key: 'subtraction',
      fields: [
        {
          label: 'Enter number from which to start',
          key: 'startingNumberForSubtraction',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Enter number to be subtracted',
          key: 'numberToBeSubtractedInSubtraction',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'subtractionTable',
          label: '',
          fieldType: 'TABLE',
          columnHeadings: MOCA_SUBTRACTION_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            MOCA_SUBTRACTION_COLUMN_HEADINGS,
            MOCA_SUBTRACTION_ROW_TITLES,
            getMoCASubtractionTableRowSpec,
            undefined
          ),
        },
        {
          label: 'Total subtraction points',
          key: 'totalSubtractionPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 3</Typography>,
          valueDependsOn: [
            'startingNumberForSubtraction',
            'numberToBeSubtractedInSubtraction',
            ...getNumbersXToY(1, 5).map(
              (no) => `subtractionNo${no}AnswerGiven`
            ),
          ],
        },
      ],
    },
    {
      label: 'Language',
      key: 'language',
      fields: [
        {
          label: 'Language repeat',
          key: 'languageRepeat',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'sentenceOneRepeatCompletedCorrectly',
              val: 'sentenceOneRepeatCompletedCorrectly',
              label: 'First sentence repeat completed correctly',
            },
            {
              key: 'sentenceTwoRepeatCompletedCorrectly',
              val: 'sentenceTwoRepeatCompletedCorrectly',
              label: 'Second sentence repeat completed correctly',
            },
          ],
        },
        {
          label: 'Total repeat points',
          key: 'totalRepeatPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 2</Typography>,
          valueDependsOn: [
            'sentenceOneRepeatCompletedCorrectly',
            'sentenceTwoRepeatCompletedCorrectly',
          ],
        },
        {
          label: 'Fluency: number of words named',
          key: 'fluencyNumersOfWordsNamed',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Total fluency points',
          key: `totalFluencyPoints`,
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 1</Typography>,
          valueDependsOn: ['fluencyNumersOfWordsNamed'],
        },
      ],
    },
    getAbstractionSection(2),
    {
      label: 'Delayed recall',
      key: 'delayedRecall',
      fields: DELAYED_RECALL_FIELDS,
    },
    {
      label: 'Orientation ',
      key: 'orientation',
      fields: [
        {
          label: 'Check correct answers',
          key: 'orientationCheckboxes',
          fieldType: 'CHECKBOX',
          options: ORIENTATION_LIST_OPTIONS,
        },
        {
          label: 'Total orientation points',
          key: 'totalOrientationPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 6</Typography>,
          valueDependsOn: getKeysFromOptions(ORIENTATION_LIST_OPTIONS),
        },
      ],
    },
    {
      label: 'Results',
      key: 'results',
      fields: [
        {
          label: 'Total score',
          key: 'totalScore',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: [
            'totalVisuospatialExecutivePoints',
            'totalNamingPoints',
            'totalRepeatDigitPoints',
            'totalLetterTappingTaskPoints',
            'totalSubtractionPoints',
            'totalRepeatPoints',
            'totalFluencyPoints',
            'totalAbstractionPoints',
            'totalUncuedRecallPoints',
            'totalOrientationPoints',
            'lessThan12OfFormalEducation',
          ],
        },
        {
          label: 'Total score out of',
          key: 'totalScoreOutOf',
          fieldType: 'INPUT-NUM',
          defaultValue: 30,
        },
        {
          label: 'Scaled score out of 30',
          key: 'scaledScoreOutOf30',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 30</Typography>,
          valueDependsOn: ['totalScore', 'totalScoreOutOf'],
        },
        {
          label: 'Bonus points',
          key: 'bonusPoints',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'lessThan12OfFormalEducation',
              val: 'lessThan12OfFormalEducation',
              label: '+1 if 12 years or less of formal education',
            },
          ],
        },
        {
          label: 'Memory index score (MIS)',
          key: 'memoryIndexScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 15</Typography>,
          valueDependsOn: MOCA_ALL_MIS_OPTIONS,
        },
        INTERPRETATION_OF_MOCA,
        ...Results,
      ],
    },
  ],
};

export default moca;
