import React, { FC, useEffect } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';

import BasicAlert from '../components/common/BasicAlert';
import useAlert from '../hooks/useAlert';

interface AsyncErrorWrapperProps {
  mutation: UseMutationResult<any, any, any, any>;
  pairedMutation?: UseMutationResult<any, any, any, any>;
  loadingComp?: JSX.Element;
  errorMessage?: string;
  successMessage?: string;
  showSuccess?: boolean;
  onSuccess?: () => void;
}

/*
A wrapper for mutations to show a loading component, and error/success alerts based on the mutations state

  - pairedMutation will only show a success if both the mutation and the pairedMutation have been successful, 
    otherwise, will show the error of whatever mutation failed.
*/
const AsyncErrorWrapper: FC<AsyncErrorWrapperProps> = ({
  mutation,
  pairedMutation,
  loadingComp = (
    <Backdrop invisible open>
      <CircularProgress color="inherit" sx={{ position: 'sticky' }} />
    </Backdrop>
  ),
  errorMessage = 'Your changes could not be saved, please try again.',
  successMessage = 'Saved successfully!',
  showSuccess = true,
  onSuccess,
  children,
}) => {
  const { alertState, handleCloseAlert, showAlert } = useAlert();

  useEffect(() => {
    if (mutation.error) {
      showAlert(
        'error',
        `${errorMessage}
        ${mutation.error}
        `
      );
    }
  }, [errorMessage, mutation.error, showAlert]);

  useEffect(() => {
    if (pairedMutation?.error) {
      showAlert(
        'error',
        `${errorMessage}
        ${pairedMutation?.error}
        `
      );
    }
  }, [errorMessage, pairedMutation?.error, showAlert]);

  useEffect(() => {
    if (mutation.isLoading || (pairedMutation && pairedMutation.isLoading)) {
      showAlert('', `Working, please wait...`);
    }
  }, [
    mutation.isLoading,
    pairedMutation,
    pairedMutation?.isLoading,
    showAlert,
  ]);

  useEffect(() => {
    if (pairedMutation ? pairedMutation.isSuccess : true) {
      if (mutation.isSuccess && showAlert && showSuccess) {
        showAlert('success', `${successMessage}`);
      }
      if (mutation.isSuccess && onSuccess && showSuccess) {
        onSuccess();
      }
    }
  }, [
    mutation.isSuccess,
    onSuccess,
    pairedMutation,
    showAlert,
    showSuccess,
    successMessage,
  ]);

  return (
    <>
      {mutation.isLoading ||
        (pairedMutation && pairedMutation.isLoading && loadingComp)}
      {mutation.isSuccess && (!pairedMutation || pairedMutation.isSuccess)}
      {children}
      {alertState.open && (
        <BasicAlert
          alertKey="new-client"
          open={alertState.open}
          variant={alertState.variant}
          message={alertState.message}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
};

export default AsyncErrorWrapper;
