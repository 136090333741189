import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getKeysFromOptions,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  DELAYED_RECALL_FIELDS,
  ORIENTATION_LIST_OPTIONS,
  MEMORY,
  getAnimalNamingFields,
  MOCA_ALL_MIS_OPTIONS,
  INTERPRETATION_OF_MOCA,
  MOCA_ADMINISTRATIVE_SECTION,
  getAbstractionSection,
} from './moca';

const mocab: MainHeadingItem = {
  label: 'MoCA-B',
  key: 'mocab',
  subheadings: [
    MOCA_ADMINISTRATIVE_SECTION,
    {
      label: 'Executive Function',
      key: 'executiveFunction',
      fields: [
        {
          label: 'Trail making',
          key: 'trailMaking',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'trailMakingCompleted',
              val: 'trailMakingCompleted',
              label: 'Trail making completed',
            },
          ],
        },
        {
          label: 'trail drawing',
          key: 'mOCABTrailDrawingFile',
          fieldType: 'UPLOAD',
        },
        {
          label: 'Total executive points',
          key: 'totalExecutivePoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 1</Typography>,
          valueDependsOn: ['trailMakingCompleted'],
        },
      ],
    },
    {
      label: 'Immediate Recall',
      key: 'immediateRecall',
      fields: MEMORY,
    },
    {
      label: 'Fluency',
      key: 'fluency',
      fields: [
        {
          label: 'Fluency: number of words',
          key: 'fluencyNumberOfWords',
          fieldType: 'INPUT-NUM' as FieldTypes,
        },
        {
          label: 'Total fluency points',
          key: 'totalFluencyPointsMoCAB',
          fieldType: 'INPUT-NUM' as FieldTypes,
          units: <Typography>/ 2</Typography>,
          valueDependsOn: ['fluencyNumberOfWords'],
        },
      ],
    },
    {
      label: 'Orientation',
      key: 'orientation',
      fields: [
        {
          label: 'Check correct answers',
          key: 'orientationCheckboxes',
          fieldType: 'CHECKBOX',
          options: [
            { key: 'knowsTime', val: 'knowsTime', label: 'Time' },
            ...ORIENTATION_LIST_OPTIONS.slice(1, 6),
          ],
        },
        {
          label: 'Total orientation points',
          key: 'totalOrientationPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 6</Typography>,
          valueDependsOn: [
            'knowsTime',
            ...getKeysFromOptions(ORIENTATION_LIST_OPTIONS.slice(1, 6)),
          ],
        },
      ],
    },
    {
      label: 'Calculation',
      key: 'calculation',
      fields: [
        {
          label: 'Number of ways to pay identified',
          key: 'numberOfWaysToPay',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(0, 3),
        },
        {
          label: 'Total caculation points',
          key: 'totalCaculationPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 3</Typography>,
          valueDependsOn: ['numberOfWaysToPay'],
        },
      ],
    },
    getAbstractionSection(3),
    {
      label: 'Delayed Recall',
      key: 'delayedRecall',
      fields: DELAYED_RECALL_FIELDS,
    },
    {
      label: 'Visuoperception',
      key: 'visuoperception',
      fields: [
        {
          label: 'Number of drawings identified',
          key: 'totalDrawingsIdentified',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(0, 10),
        },
        {
          label: 'Total visuoperception points',
          key: 'totalVisuoperceptionPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 3</Typography>,
          valueDependsOn: ['totalDrawingsIdentified'],
        },
      ],
    },
    {
      label: 'Naming',
      key: 'naming',
      fields: getAnimalNamingFields(4),
    },
    {
      label: 'Attention',
      key: 'attention',
      fields: [
        {
          label: 'Numbers of errors: circles',
          key: 'numberOfErrorsCircles',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Numbers of errors: circles and squares',
          key: 'numberOfErrorsCirclesAndSquares',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Total attention points',
          key: 'totalAttentionPoints',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 3</Typography>,
          valueDependsOn: [
            'numberOfErrorsCircles',
            'numberOfErrorsCirclesAndSquares',
          ],
        },
      ],
    },
    {
      label: 'Results',
      key: 'results',
      fields: [
        {
          label: 'Total score',
          key: 'totalScore',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 30</Typography>,
          valueDependsOn: [
            'totalExecutivePoints',
            'totalFluencyPointsMoCAB',
            'totalOrientationPoints',
            'totalCaculationPoints',
            'totalAbstractionPoints',
            'totalUncuedRecallPoints',
            'totalVisuoperceptionPoints',
            'totalNamingPoints',
            'totalAttentionPoints',
            'lessThan4OfFormalEducation',
            'isIlliterate',
          ],
        },
        {
          label: 'Total score out of',
          key: 'totalScoreOutOf',
          fieldType: 'INPUT-NUM',
          defaultValue: 30,
        },
        {
          label: 'Scaled score out of 30',
          key: 'scaledScoreOutOf30',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 30</Typography>,
          valueDependsOn: ['totalScore', 'totalScoreOutOf'],
        },
        {
          label: 'Bonus points',
          key: 'bonusPoints',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'lessThan4OfFormalEducation',
              val: 'lessThan4OfFormalEducation',
              label: '+1 if 4 years or less of formal education',
            },
            {
              key: 'isIlliterate',
              val: 'isIlliterate',
              label: '+1 if illiterate',
            },
          ],
        },
        {
          label: 'Total time',
          key: 'totalTime',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Memory index score (MIS)',
          key: 'memoryIndexScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 15</Typography>,
          valueDependsOn: MOCA_ALL_MIS_OPTIONS,
        },
        INTERPRETATION_OF_MOCA,
        ...Results,
      ],
    },
  ],
};

export default mocab;
