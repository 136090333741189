import React, { FC } from 'react';

import { Grid, Paper, Typography } from '@mui/material';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { SubHeadingItem } from '../../../models/forms';
import { getTranslationLabel } from '../../../utils/utils';
import FormSection from '../../common/FormSection';

export interface AssessmentFormMainHeadingProps {
  clientId: string;
  assessmentKey: string;
  section: SubHeadingItem;
  watch: UseFormWatch<FieldValues>;
}

export const AssessmentFormMainHeading: FC<AssessmentFormMainHeadingProps> = ({
  clientId,
  assessmentKey,
  section,
  watch,
}) => {
  const { t } = useTranslation(allNamespaces);

  return section.label ? (
    <Grid item key={section.key}>
      <Paper variant="outlined" style={{ padding: '20px', margin: '10px' }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">
              {getTranslationLabel(
                `assessments/${assessmentKey}`,
                section.key,
                t
              )}
            </Typography>
          </Grid>
          <FormSection
            fields={section.fields}
            namespace={`assessments/${assessmentKey}`}
            values={watch()}
            clientId={clientId}
            commentsPosition="bottom"
            sectionKey={section.key}
          />
        </Grid>
      </Paper>
    </Grid>
  ) : (
    <Grid item key={section.key}>
      <Grid container direction="column">
        <FormSection
          fields={section.fields}
          namespace={`assessments/${assessmentKey}`}
          values={watch()}
          clientId={clientId}
          commentsPosition="none"
          sectionKey={section.key}
        />
      </Grid>
    </Grid>
  );
};
