import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';

interface DeleteClientMessageProps {
  clientId: string;
  messageKey: string;
  handleClose: (newStatus: boolean) => void;
}

const DeleteClientMessage: FC<DeleteClientMessageProps> = ({
  clientId,
  messageKey,
  handleClose,
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <BasicDialog
      label={
        <Typography variant="h2">
          {getTranslationLabel('generalNs', `${messageKey}Title`, t)}
        </Typography>
      }
      actions={
        <>
          <Button onClick={() => handleClose(false)}>OK</Button>
        </>
      }
      maxWidth="sm"
      open
      handleClose={() => handleClose(false)}
    >
      {getTranslationLabel('clientsGeneral', 'client', t)} {clientId}{' '}
      {getTranslationLabel('generalNs', messageKey, t)}
    </BasicDialog>
  );
};

export default DeleteClientMessage;
