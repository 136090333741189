import React from 'react';

import { Button, Grid, Paper, Typography } from '@mui/material';
import { t } from 'i18next';

import useToken from '../../hooks/useToken';
import { getTranslationLabel } from '../../utils/utils';
import { FileOpInProgress } from '../common/FileUpload/FileOpInProgressModal';

const Help: React.FC = () => {
  const [isFileDownloadInProgress, setIsFileDownloadInProgress] =
    React.useState<boolean>(false);
  const getSilentToken = useToken();

  const getFile = (type: string, filename: string) => {
    setIsFileDownloadInProgress(true);
    getSilentToken.then((response) => {
      const header = new Headers();
      const bearer = response.accessToken;
      header.append('Authorization', bearer);

      return fetch(`/api/manuals/${type}`, {
        headers: header,
      })
        .then((fileRecd: any) => fileRecd.blob())
        .then((resp: any) => {
          setIsFileDownloadInProgress(false);
          const fileUrl = window.URL.createObjectURL(resp);

          const linkElement = document.createElement('a');
          linkElement.href = fileUrl;
          linkElement.setAttribute('download', `${filename}`);
          document.body.appendChild(linkElement);
          linkElement.click();
          if (linkElement.parentNode) {
            linkElement.parentNode.removeChild(linkElement);
          }

          window.URL.revokeObjectURL(fileUrl);
        });
    });
  };

  return (
    <>
      <Grid item style={{ paddingBottom: '20px' }}>
        <Typography variant="h1">
          {getTranslationLabel('help', 'help', t)}
        </Typography>
      </Grid>
      <Paper square style={{ padding: '20px' }}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography variant="h2">
              {getTranslationLabel('help', 'mainTitle', t)}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => getFile('main', 'DriveEvalUsersManual')}>
              {getTranslationLabel('help', 'mainGuide', t)}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => getFile('consent-form', 'USaskConsentForm')}>
              {getTranslationLabel('help', 'consentForm', t)}
            </Button>
          </Grid>

          {/* Ministry Guides */}
          <Grid item>
            <Typography variant="h2">
              {getTranslationLabel('help', 'ministryGuidesTitles', t)}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => getFile('mto-medical', 'MTOMedicalFormGuide')}
            >
              {getTranslationLabel('help', 'ontarioMedicalForm', t)}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => getFile('mto-vw', 'MTOVisionWaiverGuide')}>
              {getTranslationLabel('help', 'ontarioVisionWaiverForm', t)}
            </Button>
          </Grid>

          <Grid item>
            <Typography variant="h3">
              {getTranslationLabel('help', 'feedback', t)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <FileOpInProgress
        show={isFileDownloadInProgress}
        title={getTranslationLabel('results', 'downloadingHeading', t)}
        text={getTranslationLabel('results', 'pleaseWaitText', t)}
      />
    </>
  );
};

export default Help;
