import React, { FC } from 'react';

import { Chip, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { getLabelText } from './AssessmentCard-constants';

const getAssesmentCardChipStyle = (chipType: string, theme: Theme) => {
  if (chipType === 'pass') {
    return {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    };
  }
  if (chipType === 'warning') {
    return {
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    };
  }
  if (chipType === 'fail') {
    return {
      color: theme.palette.error.dark,
      backgroundColor: theme.palette.error.light,
    };
  }
  return {};
};

const getAssesmentCardColor = (chipType: string) => {
  if (chipType === 'pass') {
    return 'primary';
  }
  if (chipType === 'warning') {
    return 'warning';
  }
  if (chipType === 'fail') {
    return 'secondary';
  }
  return 'info';
};

export type AssessmentCardChipProps = {
  resultSummary: string | undefined;
  type: string;
};

export const AssessmentCardChip: FC<AssessmentCardChipProps> = ({
  resultSummary,
  type,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(allNamespaces);

  return (
    <Chip
      label={getLabelText(resultSummary, t)}
      color={getAssesmentCardColor(type)}
      style={getAssesmentCardChipStyle(type, theme)}
      size="small"
    />
  );
};

export const AssessmentDefaultCardChip: FC<AssessmentCardChipProps> = ({
  resultSummary,
  type,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Chip
      label={
        resultSummary === 'undetermined'
          ? getTranslationLabel(
              'assessmentsGeneral/assessmentLabels',
              'undeterminedUC',
              t
            )
          : getTranslationLabel(
              'assessmentsGeneral/assessmentLabels',
              'incomplete',
              t
            )
      }
      variant="outlined"
      size="small"
    />
  );
};
