import React, { useEffect, useMemo, useState } from 'react';

import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { containerWidth, darkBlue, paddingTopVal } from '../constants';
import FallbackNoLogo from '../FallBackNoLogo';
import main1 from '../img/main-1.jpg';
import main2 from '../img/main-2.jpg';
import main3 from '../img/main-3.jpg';
import playbutton from '../img/play-button.png';
import Banner from './Helpers/Banner';
import MyCard from './Helpers/MainPageCard';

const Main = () => {
  const { t } = useTranslation(websiteNamespaces);
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const IMAGES = useMemo(() => [main1, main2, main3, playbutton], []);

  useEffect(() => {
    const loadImage = (image: any) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          resolve(image.url);
        };
        img.onerror = (err) => reject(err);
      });

    Promise.all(IMAGES.map((image) => loadImage(image))).then(() =>
      setImgsLoaded(true)
    );
  }, [IMAGES]);

  return imgsLoaded ? (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <Banner
        text={getTranslationLabel('website/main', 'bannerText', t)}
        variant="h1"
        padding="2.5%"
        textAlign="center"
      />

      <MyCard
        text={getTranslationLabel('website/main', 'ourTeam', t)}
        url="/about"
        image={main1}
        rightSide
      />

      <MyCard
        text={getTranslationLabel('website/main', 'weBuiltDriveEval', t)}
        url="/driveeval"
        image={main2}
      />

      {/* Third image */}
      <div
        style={{
          position: 'relative',
          paddingTop: '2vw', // 175px
        }}
      >
        <Grid container justifyContent="flex-end">
          <Box
            component="img"
            sx={{
              width: 'min(100%, calc(100vw * 0.51515 + 290.909px))', // 1460px at 1920
              height: 'auto',
            }}
            src={main3}
          />
        </Grid>
        <Grid container justifyContent="flex-start">
          <div
            style={{
              backgroundColor: darkBlue,
              opacity: 0.89,
              height: '22.5vw', // 500px = 26vw
              width: 'min(100%, calc(100vw * 0.34848 + 390.909px))', // 1060px at 1920
              padding: '2.5%',
              position: 'absolute',
              transform: 'translateY(-75%)',
            }}
          >
            <Typography variant="h3">
              {getTranslationLabel(
                'website/main',
                'driveEvalCanBeCustomized',
                t
              )}
            </Typography>

            <Link component={NavLink} to="/driveeval">
              <Grid container alignItems="center" sx={{ marginTop: '1vh' }}>
                <Box
                  component="img"
                  src={playbutton}
                  title="Click to learn more"
                  alt="Click to learn more"
                  sx={{
                    width: '2vw', // 40px
                    height: '2vw',
                  }}
                />
                <Typography
                  style={{
                    fontSize: '2.44vw', // 47px
                    fontWeight: 500,
                    color: 'white',
                    opacity: 0.68,
                    marginLeft: '10px',
                  }}
                >
                  {getTranslationLabel('website/main', 'learnMore', t)}
                </Typography>
              </Grid>
            </Link>
          </div>
        </Grid>
      </div>

      {/* Footer content */}
      <div
        style={{
          position: 'relative',
          paddingTop: '8vw', // 175px
        }}
      >
        <Typography
          variant="h3"
          color={darkBlue}
          sx={{ paddingTop: paddingTopVal }}
        >
          {getTranslationLabel(
            'website/main',
            'driveEvalAvailableForPurchase',
            t
          )}
        </Typography>
      </div>
    </Container>
  ) : (
    <FallbackNoLogo />
  );
};

export default Main;
