import { SubHeadingItem } from '../../../models/forms';
import { PassFailResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOfPercent } from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://journals-sagepub-com.cyber.usask.ca/doi/pdf/10.1177/153331759801300305
const trafficSignRecognitionTest: SubHeadingItem = {
  key: 'trafficSignRecognitionTest',
  label: 'Traffic Sign Recognition Test',
  fields: [...getScoreAndOutOfPercent('Traffic sign'), ...PassFailResults],
};

export default trafficSignRecognitionTest;
