import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const MCGILL_PAIN_FIELDS: FieldsLayout[] = [
  { name: 'Sensory', score: 30 },
  { name: 'Affective', score: 15 },
  { name: 'Evaluative', score: 3 },
  { name: 'Miscellaneous', score: 12 },
].map((item) => ({
  key: `${convertLabelToKey(item.name)}Score`,
  label: `${item.name} score`,
  fieldType: 'INPUT-NUM',
  units: <Typography>/ {item.score}</Typography>,
}));

const mcGillPainQuestionnaire: SubHeadingItem = {
  key: 'mcGillPainQuestionnaire',
  label: 'McGill Pain Questionnaire (OT-DORA)',
  fields: [
    ...MCGILL_PAIN_FIELDS,
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 60</Typography>,
      valueDependsOn: getKeysFromOptions(MCGILL_PAIN_FIELDS),
    },
    ...Results,
  ],
};

export default mcGillPainQuestionnaire;
