import React, { FC, useCallback, useContext } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';

import { IOption } from '../../models/forms';
import FormContext from '../../utils/formContext';
import ChoiceChipsArray from '../on-road/Common/ChoiceChipsArray';

interface MultiSelectChipsProps {
  chipData: IOption[];
  namespace: string;
}

const MultiSelectChips: FC<MultiSelectChipsProps> = ({
  chipData,
  namespace,
}) => {
  const { setValue, getValues, watch } = useContext(FormContext);

  const handleDeSelect = (keyToDelete: any) => {
    setValue(keyToDelete, 0);
  };

  const handleOnClick = (key: string) => {
    const currValue = getValues(key) || 0;
    if (currValue === 1 || currValue === '1') {
      handleDeSelect(key);
    } else {
      setValue(key, 1);
    }
  };

  const isSelected = useCallback(
    (key: string) => {
      const valAtKey = watch(key);
      if (typeof valAtKey === 'string') {
        return valAtKey === '1';
      }
      return valAtKey === 1;
    },
    [watch]
  );

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '2px',
        margin: '1px',
      }}
    >
      <ChoiceChipsArray
        chipData={chipData}
        namespace={namespace}
        onClick={handleOnClick}
        selectedIcon={<CheckIcon fontSize="small" />}
        isSelected={isSelected}
      />
    </Grid>
  );
};

export default MultiSelectChips;
