import React, { FC, useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { AssessmentCategoryKeys } from '../../../../models/assessments/assessments';
import { IOption } from '../../../../models/forms';
import { getTranslationLabel } from '../../../../utils/utils';
import PrimitiveField from '../../../common/FieldContainer/PrimitiveField';

export const isDefaultGroup = (group: string): boolean =>
  AssessmentCategoryKeys.includes(group as any);

interface RenderCustomListProps {
  name: string;
  label: string;
  options: IOption[];
  checked: {
    [x: string]: any;
  };
  onChangeSection: (
    event: React.ChangeEvent<HTMLInputElement>,
    asmtsInSection: IOption[]
  ) => void;
}

const RenderCustomList: FC<RenderCustomListProps> = ({
  name,
  label,
  options,
  checked,
  onChangeSection,
}) => {
  const { t } = useTranslation(allNamespaces);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    const checkIsAllSelected = options
      ? options.reduce(
          (result, item) => result && checked[item.key] === true,
          true
        )
      : false;
    setIsAllSelected(checkIsAllSelected);
  }, [checked, options]);

  return (
    <Stack direction="column">
      <Divider />
      {label && (
        <FormLabel
          component="legend"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          {label}
        </FormLabel>
      )}
      {isAllSelected !== undefined && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllSelected}
              onChange={(event) => onChangeSection(event, options)}
              name={name}
              checkedIcon={<AddBoxIcon />}
              icon={<AddBoxOutlinedIcon />}
            />
          }
          label={getTranslationLabel('generalNs', 'selectAll', t)}
          style={{ paddingBottom: '10px' }}
        />
      )}
      <PrimitiveField
        label=""
        name={name}
        namespace="assessmentsGeneral/assessmentTitles"
        type="CHECKBOX"
        options={options}
      />
    </Stack>
  );
};

export default RenderCustomList;
