import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, IOption, MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getNumericOptionsXToY,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { getFiveWords } from '../Cognitive/moca';

// I wonder if we should split this test into a number of different tests... and let OTs
// mix and match the individual tests as they like
const EYE_RESPONSES: IOption[] = [
  '1: No eye opening',
  '2: Eye opening in response to pain',
  '3: Eye opening to speech',
  '4: Eyes opening spontaneously',
].map((item, i) => ({
  key: `eyeResponse${i}`,
  val: i.toString(),
  label: item,
}));

const VERBAL_RESPONSES: IOption[] = [
  '1: No verbal response',
  '2: Incomprehensible sounds',
  '3: Inappropriate words',
  '4: Confused',
  '5: Oriented',
].map((item, i) => ({
  key: `verbalResponse${i}`,
  val: i.toString(),
  label: item,
}));

const MOTOR_RESPONSES: IOption[] = [
  '1: No motor response',
  '2: Extension to pain',
  '3: Abnormal flexion to pain',
  '4: Flexion / Withdrawal to pain',
  '5: Localizes to pain',
  '6: Obeys commands',
].map((item, i) => ({
  key: `motorResponse${i}`,
  val: i.toString(),
  label: item,
}));

const MADDOCKS_QUESTIONS: FieldsLayout[] = [
  'What venue are we at today?',
  'Which half is it now?',
  'Who scored last in this match?',
  'What team did you play last week / game?',
  'Did your team win the last game?',
].map((item, i) => ({
  key: `maddocksQuestion${i + 1}`,
  label: item,
  fieldType: 'CHOICE_CHIPS',
  options: getNumericOptionsXToY(0, 1),
}));

const HOW_DO_YOU_FEEL_QUESTIONS: FieldsLayout[] = [
  'Headache',
  'Pressure in head',
  'Neck pain',
  'Nausea or vomiting',
  'Dizziness',
  'Blurred vision',
  'Balance problems',
  'Sensitivity to light',
  'Sensitivity to noise',
  'Feeling slowed down',
  `Feeling like "in a fog"`,
  `Don't feel right`,
  'Difficulty concentrating',
  'Difficulty remembering',
  'Fatigue or low energy',
  'Confusion',
  'Drowsiness',
  'Trouble falling asleep',
  'More emotional',
  'Irritability',
  'Sadness',
  'Nervous or anxious',
].map((item, i) => ({
  key: `howDoYouFeelQuestion${i + 1}`,
  label: item,
  fieldType: 'CHOICE_CHIPS',
  options: getNumericOptionsXToY(0, 6),
}));

const ORIENTATION_QUESTIONS: FieldsLayout[] = [
  'What month is it?',
  'What is the date today?',
  'What is the day of the week?',
  'What year is it?',
  'What time is it right now?',
].map((item, i) => ({
  key: `orientationQuestion${i + 1}`,
  label: item,
  fieldType: 'CHOICE_CHIPS',
  options: getNumericOptionsXToY(0, 1),
}));

const IMMEDIATE_MEMORY_QUESTIONS: FieldsLayout[] = [1, 2, 3].map((item) => ({
  key: `memoryTrial${item}`,
  label: `Words recalled during trial ${item}`,
  fieldType: 'CHECKBOX',
  options: getFiveWords(`MemoryTrial${item}`),
}));

const DIGITS_OPTIONS: IOption[] = ['Three', 'Four', 'Five', 'Six'].map(
  (item) => ({
    key: `number${item}DigitsLongReadCorrectly`,
    val: `number${item}DigitsLongReadCorrectly`,
    label: `${item} digits-long number read correctly`,
  })
);

const NECK_QUESTIONS: FieldsLayout[] = [
  'Range of motion',
  'Tenderness',
  'Upper / lower limb sensation / strength',
].map((item, i) => ({
  key: `neckQuestion${i + 1}`,
  label: item,
  fieldType: 'INPUT-NUM',
}));

const LEFT_RIGHT: IOption[] = getOptionsFromLabels(['Left', 'Right']);

const BALANCE_LEG_CONDITIONS: FieldsLayout[] = [
  'Double leg stance',
  'Single leg stance (non-dominant)',
  'Tandem stance',
].map((item, i) => ({
  key: `${convertLabelToKey(item)}NoOfErrors`,
  label: item,
  fieldType: 'INPUT-NUM',
}));

const sportConcussionAssessmentTool3: MainHeadingItem = {
  key: 'sportConcussionAssessmentTool3',
  label: 'Sport Concussion Assessment Tool 3 (SCAT3)',
  subheadings: [
    {
      key: 'glasgowComaScaleSection',
      label: 'Glasgow Coma Scale (GCS)',
      fields: [
        {
          key: 'gCSEyeResponse',
          label: 'Best eye response (E)',
          fieldType: 'RADIO',
          options: EYE_RESPONSES,
        },
        {
          key: 'gCSVerbalResponse',
          label: 'Best verbal response (V)',
          fieldType: 'RADIO',
          options: VERBAL_RESPONSES,
        },
        {
          key: 'gCSMotorResponse',
          label: 'Best motor response (M)',
          fieldType: 'RADIO',
          options: MOTOR_RESPONSES,
        },
        {
          key: 'totalGlasgowComaScaleScore',
          label: 'Total Glasgow coma score (E + V + M)',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 15</Typography>,
        },
      ],
    },
    {
      key: 'maddocksQuestionsSection',
      label: 'Maddocks Questions',
      fields: [
        ...MADDOCKS_QUESTIONS,
        {
          key: 'totalMaddocksScore',
          label: 'Total Maddocks score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 5</Typography>,
        },
      ],
    },
    {
      key: 'howDoYouFeelQuestionsSection',
      label: 'How Do You Feel?',
      fields: [
        {
          key: 'howDoYouFeelRatings',
          label: '0 = None, 1-2 = Mild, 3-4 = Moderate, 5-6 = Severe',
          fieldType: 'LABEL',
        },
        ...HOW_DO_YOU_FEEL_QUESTIONS,
        {
          key: 'totalNumberOfSymptoms',
          label: 'Total number of symptoms',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 22</Typography>,
        },
        {
          key: 'symptomSeverityScore',
          label: 'Symptom severity score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 132</Typography>,
        },
        {
          key: 'howDoYouFeelAdditionalQuestion1',
          label: 'Do the symptoms get worse with physical activity?',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          key: 'howDoYouFeelAdditionalQuestion2',
          label: 'Do the symptoms get worse with mental activity? ',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          key: 'howDoYouFeelWho',
          label: 'Who conducted the rating?',
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'conductedBySelf',
              val: 'conductedBySelf',
              label: 'Self rated',
            },
            {
              key: 'conductedByClinicianInterview',
              val: 'conductedByClinicianInterview',
              label: 'Clinician interview',
            },
            {
              key: 'conductedBySelfAndClinicianMonitored',
              val: 'conductedBySelfAndClinicianMonitored',
              label: 'Self rated and clinician monitored',
            },
            {
              key: 'conductedBySelfAndParentInput',
              val: 'conductedBySelfAndParentInput',
              label: 'Self rated with parent input',
            },
          ],
        },
        {
          key: 'howDoYouFeelOverallRating',
          label: `Overall rating: If you know the athlete prior to the injury,
            how different is the athlete acting compared to his or her usual self?`,
          fieldType: 'SCALE',
          options: [
            { key: 'noDifferent', val: 'noDifferent', label: 'No different' },
            {
              key: 'veryDifferent',
              val: 'veryDifferent',
              label: 'Very different',
            },
            { key: 'unsure', val: 'unsure', label: 'Unsure' },
            { key: 'na', val: 'na', label: 'N/A' },
          ],
        },
      ],
    },
    {
      key: 'cognitiveAssessmentSection',
      label: 'Cognitive Assessment',
      fields: [
        {
          key: 'orientationLabel',
          label: 'Orientation',
          fieldType: 'LABEL',
        },
        ...ORIENTATION_QUESTIONS,
        {
          key: 'immediateMemory',
          label: 'Immediate memory',
          fieldType: 'LABEL',
        },
        ...IMMEDIATE_MEMORY_QUESTIONS,
        {
          key: 'immediateMemoryScore',
          label: 'Immediate memory score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 15</Typography>,
        },
        {
          key: 'digitalsBackward',
          label: 'Concentration: digits backward',
          fieldType: 'CHECKBOX',
          options: DIGITS_OPTIONS,
        },
        {
          key: 'monthInReverseOrderCorrect',
          label: 'Concentration: month in reverse order',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(0, 1),
        },
      ],
    },
    {
      key: 'neckExamination',
      label: 'Neck Examination',
      fields: [...NECK_QUESTIONS],
    },
    {
      key: 'balanceExamination',
      label: 'Balance examination',
      fields: [
        {
          key: 'footwearForBalanceExamination',
          label: 'Footwear',
          fieldType: 'INPUT',
        },
        {
          key: 'doOneOrBothLabel',
          label: 'Do one or both of the following tests.',
          fieldType: 'LABEL',
        },
        {
          key: 'bessLabel',
          label: 'Modified balance error scoring system (BESS) testing',
          fieldType: 'LABEL',
        },
        {
          key: 'whichFootWasTestedInBalanceExamination',
          label: 'Which foot was tested?',
          fieldType: 'RADIO',
          options: LEFT_RIGHT,
        },
        {
          key: 'testingSurfaceForBalanceExamination',
          label: 'Testing surface',
          fieldType: 'INPUT',
        },
        {
          key: 'conditionLabel',
          label: 'Condition (number of errors)',
          fieldType: 'LABEL',
        },
        ...BALANCE_LEG_CONDITIONS,
        {
          key: 'tandemGaitLabel',
          label: 'Tandem gait',
          fieldType: 'LABEL',
        },
        {
          key: 'tandemGaitTime',
          label: 'Time (best of 4 trials)',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
      ],
    },
    {
      key: 'coordinationExamination',
      label: 'Coordination examination',
      fields: [
        {
          key: 'whichArmWasTestedInCoordinationExamination',
          label: 'Which arm was tested?',
          fieldType: 'RADIO',
          options: LEFT_RIGHT,
        },
        {
          key: 'coordinationScore',
          label: 'Coordination score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 1</Typography>,
        },
      ],
    },
    {
      key: 'SACDelayedRecall',
      label: 'SAC Delayed Recall',
      fields: [
        {
          key: 'delayedRecallScore',
          label: 'Delayed recall score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 5</Typography>,
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: Results,
    },
  ],
};

export default sportConcussionAssessmentTool3;
