import { FieldsLayout, MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getNumbersXToY,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const ACCIDENT_FEAR_QUESTIONNAIRE_YESNO_QUESTIONS: string[] = [
  '1. During the accident did you fear for your life?',
  '2. During the accident did you see anyone injured or killed?',
  '3. During the accident did you lose consciousness?',
  '4. Do you have nightmares about the accident?',
  '5. Are you nervous before trips?',
  '6. Do you easily get upset in the car?',
  '7. Do you tell the driver what to do?',
  '8. Do you drive less than you used to?',
  '9. Do you expect another accident soon?',
  '10. Would most people feel after an accident the way you do?',
];

const ACCIDENT_FEAR_QUESTIONNAIRE_RANKED_QUESTIONS: string[] = [
  '11. Driving as a passenger',
  '12. Driving yourself',
  '13. Riding in a particular seat',
  '14. Driving on certain roads',
  '15. Riding with certain drivers',
  '16. Driving in certain weather conditions',
  '17. Hearing news of accidents',
  '18. Seeing wounds and injuries',
  '19. Crossing streets alone',
  '20. Riding a bus or streetcar',
];

const ACCIDENT_FEAR_QUESTIONNAIRE_YESNO_FIELDS: FieldsLayout[] =
  ACCIDENT_FEAR_QUESTIONNAIRE_YESNO_QUESTIONS.map((item, i) => ({
    key: `question${i + 1}`,
    label: item,
    fieldType: 'CHOICE_CHIPS',
    options: YES_NO,
  }));

const ACCIDENT_FEAR_QUESTIONNAIRE_RANKED_FIELDS: FieldsLayout[] =
  ACCIDENT_FEAR_QUESTIONNAIRE_RANKED_QUESTIONS.map((item, i) => ({
    key: `question${i + 11}`,
    label: item,
    fieldType: 'CHOICE_CHIPS',
    options: getNumericOptionsXToY(0, 8),
  }));

const accidentFearQuestionnaire: MainHeadingItem = {
  key: 'accidentFearQuestionnaire',
  label: 'Accident Fear Questionnaire',
  subheadings: [
    {
      key: 'questions110',
      label: 'Questions 1-10',
      fields: ACCIDENT_FEAR_QUESTIONNAIRE_YESNO_FIELDS,
    },
    {
      key: 'questions1120',
      label: 'Questions 11-20',
      fields: [
        {
          label: '0 = Would not avoid it, 8 = Always avoid it',
          key: 'q11-20Label',
          fieldType: 'LABEL',
        },
        ...ACCIDENT_FEAR_QUESTIONNAIRE_RANKED_FIELDS,
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          key: 'totalYesResponses',
          label: 'Total "Yes" responses (questions 1-10)',
          fieldType: 'INPUT-NUM',
          valueDependsOn: getNumbersXToY(1, 10).map((i) => `question${i}`),
        },
        {
          key: 'totalQuestions1120',
          label: 'Total for questions 11-20',
          fieldType: 'INPUT-NUM',
          valueDependsOn: getNumbersXToY(11, 20).map((i) => `question${i}`),
        },
        ...Results,
      ],
    },
  ],
};

export default accidentFearQuestionnaire;
