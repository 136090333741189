import { FieldsLayout, MainHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

export const SEC1_PRE_TEST_DETAILS: FieldsLayout[] = [
  {
    label: 'Date of Road Test',
    key: 'dateOfRoadTest',
    fieldType: 'DATE',
  },
  {
    label: 'Driving Instructor Name',
    key: 'drivingInstructorName',
    fieldType: 'INPUT',
  },
  {
    label: 'Testing Stage',
    key: 'testingStage',
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Initial Evaluation',
      'Progress Visit',
      'Final Evaluation',
    ]),
  },
];

export const SEC3_CLIENT_BACKGROUND: FieldsLayout[] = [
  {
    label: 'Type of Client',
    key: 'typeOfClient',
    fieldType: 'RADIO',
    options: getOptionsFromLabels([
      'New driver',
      'Novice driver',
      'Experienced driver',
      'Other type of client',
    ]),
    conditionals: [
      {
        key: 'otherTypeOfClient',
        label: 'Other type of client',
        fieldType: 'TEXT-AREA',
        condition: (values: any) =>
          values.typeOfClient && values.typeOfClient.includes('other'),
      },
    ],
  },
  {
    label: 'Type of Deficits',
    key: 'typeOfDeficits',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Physical',
      'Cognitive-Perceptual',
      'Behavioural-Psychological',
      'Other deficits',
    ]),
    conditionals: [
      {
        key: 'otherTypeOfDeficit',
        label: 'Other deficits',
        fieldType: 'TEXT-AREA',
        condition: (values: any) =>
          values.otherDeficits === true || values.otherDeficits === '1',
      },
    ],
  },
  {
    label: 'Pay Attention To',
    key: 'payAttentionTo',
    fieldType: 'TEXT-AREA',
  },
  {
    label: 'Required Accomodations',
    key: 'requiredAccomodations',
    fieldType: 'TEXT-AREA',
  },
];

export const SEC4_CONDITIONS: FieldsLayout[] = [
  {
    label: 'Road Conditions',
    key: 'roadConditions',
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Dry',
      'Wet',
      'Slippery',
      'Snow covered',
      'Mixed',
      'Other road conditions',
    ]),
  },
  {
    label: 'Weather Conditions',
    key: 'weatherConditions',
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Clear',
      'Cloudy',
      'Overcast',
      'Rain',
      'Snow',
      'Fog',
      'Other weather conditions',
    ]),
  },
  {
    label: 'Time of Day',
    key: 'timeOfDay',
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels(['Day', 'Dusk', 'Night', 'Other']),
  },
  {
    label: 'Volume of Traffic',
    key: 'volumeOfTraffic',
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels(['Light', 'Moderate', 'Heavy']),
  },
];

export const SEC2_VEHICLE_INFO: FieldsLayout[] = [
  {
    label: 'Vehicle Make',
    key: 'vehicleMake',
    fieldType: 'INPUT',
  },
  {
    label: 'Vehicle Model',
    key: 'vehicleModel',
    fieldType: 'INPUT',
  },
  {
    label: 'Transmission',
    key: 'transmission',
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels(['Automatic', 'Manual']),
  },
];

export const PRE_TEST: MainHeadingItem = {
  label: 'Pre Road Test',
  key: 'preRoadTest',
  subheadings: [
    {
      key: 'testingDetails',
      label: 'Testing Details',
      fields: SEC1_PRE_TEST_DETAILS,
    },
    {
      key: 'testingVehicleDetails',
      label: 'Testing Vehicle Details',
      fields: SEC2_VEHICLE_INFO,
    },
    {
      key: 'preparation',
      label: 'Preparation',
      fields: SEC3_CLIENT_BACKGROUND,
    },
    {
      key: 'roadConditions',
      label: 'Road Conditions',
      fields: SEC4_CONDITIONS,
    },
  ],
};
