import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const DRIVEABLE_PRACTICE_OPTIONS = getOptionsFromLabels([
  'Understood task',
  'Unable to understand task',
  'Refused to do task',
]);

const DRIVEABLE_OPTIONS = getOptionsFromLabels([
  'Task completed well',
  'Had difficulty completing task',
  'Unable to complete task',
]);

const isPractice = (task: string) => task.substring(0, 4) === 'Prac';

const getdriveABLEFields = (subheading: string, tasks: string[]) =>
  tasks
    .map((task) =>
      isPractice(task)
        ? [
            {
              key: `${subheading}${capitalizeFirstLetter(
                convertLabelToKey(task)
              )}Score`,
              label: task,
              fieldType: 'RADIO' as FieldTypes,
              options: DRIVEABLE_PRACTICE_OPTIONS,
            },
            {
              key: `${subheading}${capitalizeFirstLetter(
                convertLabelToKey(task)
              )}NumberOfPracticeRuns`,
              label: 'Number of practice runs completed',
              fieldType: 'INPUT-NUM' as FieldTypes,
            },
          ]
        : [
            {
              key: `${subheading}${capitalizeFirstLetter(
                convertLabelToKey(task)
              )}Score`,
              label: task,
              fieldType: 'RADIO' as FieldTypes,
              options: DRIVEABLE_OPTIONS,
            },
          ]
    )
    .flat(1);

const driveABLECognitiveAssessmentTool: MainHeadingItem = {
  key: 'driveABLECognitiveAssessmentTool',
  label: 'DriveABLE Cognitive Assessment Tool (DCAT)',
  subheadings: [
    {
      key: 'motorSpeedControlTask',
      label: 'Motor Speed Control Task',
      fields: getdriveABLEFields('motorSpeedControlTask', [
        'Practice (white star)',
        'Task (blue square)',
      ]),
    },
    {
      key: 'spanOfAttentionTask',
      label: 'Span of Attention Task',
      fields: getdriveABLEFields('spanOfAttentionTask', [
        'Practice No. 1',
        'Without distractor',
        'Practice No. 2',
        'Distractor',
      ]),
    },
    {
      key: 'spatialJudgementAndDecisionMaking',
      label: 'Spatial Judgement and Decision Making',
      fields: getdriveABLEFields('spatialJudgementAndDecisionMaking', [
        'Practice trial',
        'Test trial',
      ]),
    },
    {
      key: 'speedOfAttentionShiftingTask',
      label: 'Speed of Attention Shifting Task',
      fields: getdriveABLEFields('speedOfAttentionShiftingTask', [
        'Practice trials',
        'Central trials',
        'Peripheral trials',
      ]),
    },
    {
      key: 'executiveFunctionTask',
      label: 'Executive Function Task',
      fields: getdriveABLEFields('executiveFunctionTask', [
        '0 - Lag',
        '1 - Lag',
      ]),
    },
    {
      key: 'identificationOfDrivingSituations',
      label: 'Identification of Driving Situations',
      fields: getdriveABLEFields('identificationOfDrivingSituations', [
        'Main task',
      ]),
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          label: 'Probability of failing the road test',
          key: 'probabilityOfFailingTheRoadTest',
          fieldType: 'INPUT-NUM',
          units: <Typography>%</Typography>,
        },
        {
          label: 'Result classification',
          key: 'resultClassification',
          fieldType: 'RADIO',
          options: [
            {
              key: 'pass',
              val: 'pass',
              label: 'No evidence of reduced competency (Pass)',
            },
            {
              key: 'undetermined',
              val: 'undetermined',
              label:
                'Indeterminate range requiring a road test to resolve competency',
            },
            {
              key: 'fail',
              val: 'fail',
              label: 'Driving cessation recommended (Fail)',
            },
          ],
        },
      ],
    },
  ],
};

export default driveABLECognitiveAssessmentTool;
