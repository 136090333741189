import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const pursuitRotorTask: SubHeadingItem = {
  key: 'pursuitRotorTask',
  label: 'Pursuit Rotor Task',
  fields: [
    {
      label: 'Score',
      key: 'score',
      fieldType: 'INPUT-NUM',
      units: <Typography>%</Typography>,
    },
    ...Results,
  ],
};

export default pursuitRotorTask;
