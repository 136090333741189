import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const epworthSleepinessScale: SubHeadingItem = {
  label: 'Epworth Sleepiness Scale  (ESS)',
  key: 'epworthSleepinessScale',
  fields: [
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 24</Typography>,
    },
    {
      key: 'interpretationESS',
      label: 'Interpretation of the score',
      fieldType: 'RADIO',
      options: [
        {
          key: 'lowerThanNormal',
          label: '0-5: Lower than normal daytime sleepiness',
          val: 'lowerThanNormal',
        },
        {
          key: 'higherThanNormal',
          label: '6-10: Higher than normal daytime sleepiness',
          val: 'higherThanNormal',
        },
        {
          key: 'mildExcessive',
          label: '11-12: Mild excessive daytime sleepiness',
          val: 'mildExcessive',
        },
        {
          key: 'moderateExcessive',
          label: '13-15: Moderate excessive daytime sleepiness',
          val: 'moderateExcessive',
        },
        {
          key: 'severeExcessive',
          label: '16-24: Severe excessive daytime sleepiness',
          val: 'severeExcessive',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    ...Results,
  ],
};

export default epworthSleepinessScale;
