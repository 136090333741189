import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';

export interface EmptySelectedAssessmentsProps {}

export const EmptySelectedAssessments: FC<EmptySelectedAssessmentsProps> =
  () => {
    const { t } = useTranslation(allNamespaces);

    return (
      <Grid item>
        <Typography>
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'notChosenAnyClinicalAssessments',
            t
          )}
        </Typography>
        <Typography>
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'getStartedChoosingAssessments',
            t
          )}
        </Typography>
      </Grid>
    );
  };
