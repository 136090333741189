import { SubHeadingItem } from '../../../models/forms';
import { PassFailResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOfPercent } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const rulesOfTheRoadTest: SubHeadingItem = {
  key: 'rulesOfTheRoadTest',
  label: 'Rules of the Road Test',
  fields: [...getScoreAndOutOfPercent('Rules of the road'), ...PassFailResults],
};

export default rulesOfTheRoadTest;
