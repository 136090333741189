import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { TRUE_FALSE_OPTIONS } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';
import {
  getDiagnosticConditionals,
  getImpairmentIsTemproraryConditional,
  REPORTED_TO_LICENSING_BODY_COND,
  TYPE_OF_DOCTORS,
} from './ReferralInformationConditionals';

const referralInformationTableHeadings = [
  '',
  'Medical condition no. 1',
  'Medical condition no. 2',
  'Medical condition no. 3',
];

const getReferralInformationRowSpec = (key: string) => [
  {
    rowTitle: 'Diagnosis',
    element: {
      label: '',
      key,
      fieldType: 'RADIO' as FieldTypes,
      options: getOptionsFromLabels(
        [
          'Age related',
          'Brain injury',
          'Central nervous system',
          'Cognitive',
          'Other reason for referral',
          'Physical',
          'Psychological',
        ],
        'Diagnosis'
      ),
      conditionals: getDiagnosticConditionals(key),
    },
  },
  {
    rowTitle: 'Diagnosis details',
    element: {
      label: '',
      key,
      fieldType: 'TEXT-AREA' as FieldTypes,
    },
  },
  {
    rowTitle: 'Date of onset',
    element: {
      key,
      label: '',
      fieldType: 'DATE' as FieldTypes,
    },
  },
  {
    rowTitle: 'Is the impairment expected to be temporary?',
    element: {
      label: '',
      key,
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: getImpairmentIsTemproraryConditional(key),
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
    },
  },
];

const REFERRAL_INFORMATION: SubHeadingItem = {
  label: 'Referral Information',
  key: 'category4',
  fields: [
    {
      label: 'Referral source',
      key: 'referralSource',
      fieldType: 'RADIO',
      options: TYPE_OF_DOCTORS,
    },
    {
      key: 'referralSourceDetails',
      label: 'Referral source details',
      fieldType: 'TEXT-AREA',
    },
    {
      key: 'dateOfReferral',
      label: 'Date of referral',
      fieldType: 'DATE',
    },
    {
      label: 'Has/have the condition(s) been reported to the licensing body?',
      key: 'hasTheMedicalConditionsBeenReported',
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: REPORTED_TO_LICENSING_BODY_COND,
    },
    {
      label: '',
      key: 'referralInformationTable',
      fieldType: 'TABLE',
      columnHeadings: referralInformationTableHeadings,
      cells: generateCellsForTableWithUniformCells(
        referralInformationTableHeadings,
        [
          'Diagnosis',
          'Diagnosis details',
          'Date of onset',
          'Is the impairment expected to be temporary?',
        ],
        getReferralInformationRowSpec,
        undefined
      ),
    },
    {
      label: 'Other medical conditions',
      key: 'otherMedicalConditions',
      fieldType: 'TEXT-AREA',
    },
  ],
};

export default REFERRAL_INFORMATION;
