import React, { FC } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import FormSection from '../../../common/FormSection';
import { getTaskErrorFields } from '../../Constants/utils';

interface ManeuverErrorsProps {
  maneuverType: string;
  values: () => any;
}

const ManeuverErrors: FC<ManeuverErrorsProps> = ({ maneuverType, values }) => {
  const { t } = useTranslation(allNamespaces);
  const taskErrorFields = getTaskErrorFields(maneuverType);

  return (
    <Grid item container direction="column">
      <Grid item>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Typography variant="h3">
            {getTranslationLabel(
              'maneuvers/maneuversGeneral',
              'errorDetails',
              t
            )}
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        {taskErrorFields && taskErrorFields.length > 0 ? (
          <FormSection
            fields={taskErrorFields}
            namespace={`maneuvers/${maneuverType}`}
            values={values}
            sectionKey={maneuverType}
          />
        ) : (
          <>We have not added any fields to this yet!</>
        )}
      </Grid>
    </Grid>
  );
};

export default ManeuverErrors;
