import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';
import BasicDialog from './BasicDialog';

interface ConfirmationDialogProps {
  isOpen: boolean;
  confirmationHeading: string;
  confirmationButtonMessage: string;
  confirmationMessage: string;
  handleClose: () => void;
  handleCancel: () => void;
  handleConfirm: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  confirmationHeading,
  confirmationButtonMessage,
  confirmationMessage,
  handleClose,
  handleCancel,
  handleConfirm,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <BasicDialog
      open={isOpen}
      handleClose={handleClose}
      label={
        <Typography variant="subtitle1" component="span">
          {confirmationHeading}
        </Typography>
      }
      actions={
        <>
          <Button onClick={handleCancel}>
            {getTranslationLabel('generalNs', 'cancel', t)}
          </Button>
          <Button type="submit" onClick={handleConfirm}>
            {confirmationButtonMessage}
          </Button>
        </>
      }
      fullWidth={false}
    >
      <Typography>{confirmationMessage}</Typography>
    </BasicDialog>
  );
};

export default ConfirmationDialog;
