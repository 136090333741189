import React, { FC } from 'react';

import { Grid } from '@mui/material';

import { FieldsLayout } from '../../../models/forms';
import FormSection from '../../common/FormSection';

interface ColumnProps {
  title: JSX.Element;
  namespace: string;
  fields: FieldsLayout[];
  values: any;
  sectionKey: string;
}

const Column: FC<ColumnProps> = ({
  title,
  namespace,
  fields,
  values,
  sectionKey,
}) => (
  <>
    <Grid item>{title}</Grid>
    <FormSection
      fields={fields}
      namespace={namespace}
      values={values}
      sectionKey={sectionKey}
    />
  </>
);

export default Column;
