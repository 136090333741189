import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { PhysicalResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const reactionTimeTester: SubHeadingItem = {
  label: 'Reaction Time Tester (Cumulative)',
  key: 'reactionTimeTesterCumulative',
  fields: [
    {
      key: 'extremityTested',
      label: 'Extremity tested (e.g. right foot)',
      fieldType: 'SELECT',
      options: getOptionsFromLabels([
        'Right foot',
        'Left foot',
        'Right hand',
        'Left hand',
      ]),
    },
    {
      key: 'cumulativeReactionTime',
      label: 'Cumulative brake reaction time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      key: 'cumulativeNumberOfTrials',
      label: 'Number of trials',
      fieldType: 'INPUT-NUM',
    },
    {
      key: 'averageReactionTime',
      label: 'Average reaction time',
      fieldType: 'INPUT-SWITCH',
      valueDependsOn: ['cumulativeReactionTime', 'cumulativeNumberOfTrials'],
    },
    {
      label: 'Type of tester',
      key: 'typeOfTester',
      fieldType: 'SELECT',
      options: getOptionsFromLabels([
        'RT-2S',
        'AAA',
        'Other (specify in comments)',
      ]),
    },
    ...PhysicalResults,
  ],
};

export default reactionTimeTester;
