import { Conditional } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const hadPreviousCDE = (values: any) => values.previousCDE === 'yes';

const PREVIOUS_CDE_RESULTS = getOptionsFromLabels(
  [
    'Functional driving skills',
    'Functional driving skills (poor driving habits)',
    'Rehabilitation candidate',
    'Novice driver training required',
    'Driving not recommended (functional deficits)',
    'Driving not recommended (medical deficits)',
    'Driving not recommended (visual deficits)',
  ],
  undefined,
  'previousCDEResult'
);

const PREVIOUS_CDE_CONDITIONALS: Conditional[] = [
  {
    label: 'Client ID of previous CDE',
    key: 'clientIDOfPreviousCDE',
    fieldType: 'INPUT',
    condition: hadPreviousCDE,
  },
  {
    label: 'Date',
    key: 'dateOfPreviousCDE',
    fieldType: 'DATE',
    condition: hadPreviousCDE,
  },
  {
    label: 'Where',
    key: 'wherePreviousCDE',
    fieldType: 'INPUT',
    condition: hadPreviousCDE,
  },
  {
    label: 'Result',
    key: 'resultOfPreviousCDE',
    fieldType: 'CHECKBOX',
    options: PREVIOUS_CDE_RESULTS,
    condition: hadPreviousCDE,
  },
];

export default PREVIOUS_CDE_CONDITIONALS;
