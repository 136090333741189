import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOfPercent } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const logicalMemoryTest: SubHeadingItem = {
  key: 'logicalMemoryTest',
  label: 'Logical Memory Test',
  fields: [
    ...getScoreAndOutOfPercent('Immediate recall'),
    ...getScoreAndOutOfPercent('Delayed recall'),
    ...Results,
  ],
};

export default logicalMemoryTest;
