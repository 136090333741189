import { IResult } from '../../../models/results';
import {
  axiosGetAllCall,
  axiosPostCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const resultsKeys = (clientId: string) => ['results', clientId] as const;

const resultsUrl = (clientId: string | undefined) => `/api/results/${clientId}`;

export const useResults = (clientId: string) =>
  useQueryWithToken(resultsKeys(clientId), (token: string) =>
    axiosGetAllCall(resultsUrl(clientId), token)
  );

export const useCreateResults = (clientId: string) =>
  useMutationWithToken(
    resultsKeys(clientId),
    (results: IResult, token: string) =>
      axiosPostCall(resultsUrl(results.clientId), results, token)
  );

export const useUpdateResults = (clientId: string) =>
  useMutationWithToken(resultsKeys(clientId), (results: any, token: string) =>
    axiosPutCall(resultsUrl(results.clientId), results, token)
  );
