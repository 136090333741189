import {
  axiosGetCall,
  axiosPostCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const assessmentKeys = (assessmentId: string) =>
  ['assessment', assessmentId] as const;

const assessmentUrl = (assessmentKey: string, assessmentId: string) =>
  `/api/assessments/${assessmentKey}/${assessmentId}`;

export const useAssessment = (assessmentKey: string, assessmentId: string) =>
  useQueryWithToken(assessmentKeys(assessmentId), (token: string) =>
    axiosGetCall(assessmentUrl(assessmentKey, assessmentId), token)
  );

export const useCreateAssessment = (assessmentId: string) =>
  useMutationWithToken(
    assessmentKeys(assessmentId),
    (assessment: any, token: string) =>
      axiosPostCall(
        assessmentUrl(assessment.assessmentKey, assessmentId),
        assessment,
        token
      )
  );

export const useUpdateAssessment = (assessmentId: string) =>
  useMutationWithToken(
    assessmentKeys(assessmentId),
    (assessment: any, token: string) =>
      axiosPutCall(
        assessmentUrl(assessment.assessmentKey, assessmentId),
        assessment,
        token
      )
  );
