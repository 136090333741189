import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import NormalDistribution from 'normal-distribution';

import { SpecialCalculationFunction } from '../../../models/assessments/assessments';
import {
  allButLastRow,
  convertLabelToKey,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { getKeyWithoutAdditionalParameter } from '../../common/FieldContainer/MyTable-Utils';
import { DRIVING_TABLE_ROW_TITLES } from '../DrivingSpecific/drivingHabitQuestionnaire';
import { SARA_CATEGORIES } from '../Physical/scaleForTheAssessmentAndRatingOfAtaxia';
import {
  GDS5_ANSWERS,
  GDS15_ANSWERS,
  MVPT_ANSWERS,
} from './SPECIAL_FUNCTIONS_CONSTANTS';

const getCount = (values: any[]) =>
  values.reduce((result, v) => (v ? result + 1 : result), 0);

const getMocaSubtractionPoints = (values: string[]) => {
  let thereIsAtLeastOneInput = false;
  let count = 0;
  let prevValue = parseInt(values[0], 10);
  const delta = parseInt(values[1], 10);

  for (let i = 2; i < 7; i += 1) {
    const currentValue = parseInt(values[i], 10);
    if (!Number.isNaN(currentValue)) thereIsAtLeastOneInput = true;
    count += delta === prevValue - currentValue ? 1 : 0;
    prevValue = currentValue;
  }

  if (count === 4 || count === 5) return 3;
  if (count === 2 || count === 3) return 2;
  if (count === 1) return 1;
  if (thereIsAtLeastOneInput) return -1;
  return 0;
};

const checkMocaSubtraction = (values: string[]) => {
  const diff = parseInt(values[0], 10);
  const val1 = parseInt(values[1], 10);
  const val2 = parseInt(values[2], 10);
  const delta = val1 - val2;

  return delta === diff ? (
    <CheckCircleIcon color="secondary" />
  ) : (
    <ClearIcon color="error" fontSize="small" />
  );
};

const getMemoryIndexScore = (values: any[]) =>
  values.reduce((result, v, i) => {
    if (i < 5 && (v === '1' || v === true)) return result + 3;
    if (i < 10 && (v === '1' || v === true)) return result + 2;
    if (v === '1' || v === true) return result + 1;
    return result;
  }, 0);

const getMocaFluencyPoints = (values: number) => {
  if (values >= 11) return 1;
  return 0;
};
const getMocaBFluencyPoints = (values: number) => {
  if (values >= 13) return 2;
  if (values >= 8) return 1;
  return 0;
};

const getTotalVisuoperceptionPoints = (values: number) => {
  if (values >= 9) return 3;
  if (values >= 6) return 2;
  if (values >= 4) return 1;
  return 0;
};

const getTotalAttentionPoints = (values: number[]) => {
  if (Number.isNaN(values[0]) || Number.isNaN(values[1])) return 0;
  const circlesPoints = values[0] >= 2 ? 0 : 1;

  let circlesAndSquaresPoints;
  if (values[1] >= 4) circlesAndSquaresPoints = 0;
  else if (values[1] >= 3) circlesAndSquaresPoints = 1;
  else circlesAndSquaresPoints = 2;

  return circlesPoints + circlesAndSquaresPoints;
};

const getMOCAScoreOutOf30 = (values: number[]) =>
  Math.round((values[0] / values[1]) * 30);

const getStandardScore = (values: string[]) => {
  const value = parseFloat(values[0]);
  const mean = parseFloat(values[1]);
  const sd = parseFloat(values[2]);
  const result = Math.round(((mean - value) / sd) * 1000) / 1000;
  return Number.isNaN(result) ? 0 : result;
};

const getPercentileRanking = (values: number[]) => {
  if (!values[0] || Number.isNaN(values[0])) return 0;
  const normDist = new NormalDistribution(0, 1);
  return Math.round(normDist.cdf(values[0]) * 1000) / 10;
};

const getSLUMSClockPoints = (values: any[]) =>
  values.reduce(
    (result, val) => (val === true || val === '1' ? result + 2 : result),
    0
  );

const getTotalScoreVsAnswerKey = (values: string[], answers: string[]) =>
  values.reduce((result, val, idx) => {
    if (val === answers[idx] || (!answers[idx] && !val)) {
      return result + 1;
    }
    return result;
  }, 0);

const getTotalScoreGDS5 = (values: string[]) =>
  getTotalScoreVsAnswerKey(values, GDS5_ANSWERS);
const getTotalScoreGDS15 = (values: string[]) =>
  getTotalScoreVsAnswerKey(values, GDS15_ANSWERS);
const getMVPTScore = (values: string[]) =>
  getTotalScoreVsAnswerKey(values, MVPT_ANSWERS);

const getTotalKmDHQ = (values: string[]) => {
  const numberOfTrips = parseInt(values[0], 10);
  const kmPerTrip = parseInt(values[1], 10);
  return Number.isNaN(numberOfTrips) || Number.isNaN(kmPerTrip)
    ? 0
    : numberOfTrips * kmPerTrip;
};

const getDrivingTableTotalKilometersTotals = (values: string[]) =>
  values.reduce((result, val) => {
    const parsedVal = parseInt(val, 10);
    return Number.isNaN(parsedVal) ? result : result + parsedVal * 2;
  }, 0);

const getTotalCategories = (values: string[], colour: string) =>
  values.reduce(
    (result, val) => (val && val === colour ? result + 1 : result),
    0
  );

const getTotalGreenCategories = (values: string[]) =>
  getTotalCategories(values, 'green');
const getTotalAmberCategories = (values: string[]) =>
  getTotalCategories(values, 'amber');
const getTotalRedCategories = (values: string[]) =>
  getTotalCategories(values, 'red');

const getRookwoodScore = (values: string[]) =>
  values.reduce((result, val) => {
    if (val === 'failTwo') return result + 2;
    if (val === 'failOne' || val === 'borderlineOne') return result + 1;
    return result;
  }, 0);

const getTotalNumberOfTestsFailed = (values: string[]) =>
  values.reduce(
    (result, val) => result + (val === 'failOne' || val === 'failTwo' ? 1 : 0),
    0
  );

const getAverage = (values: string[] | number[]) => {
  let sum = 0;
  let len = 0;
  values.forEach((value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
    if (parsedValue && !Number.isNaN(parsedValue)) {
      sum += parsedValue;
      len += 1;
    }
  });
  return len === 0 ? 0 : Math.round((sum / len + Number.EPSILON) * 100) / 100;
};

const getMax = (values: string[]) => {
  let max = Number.MIN_VALUE;
  let len = 0;
  values.forEach((value) => {
    const parsedValue = parseInt(value, 10);
    if (!Number.isNaN(parsedValue)) {
      max = parsedValue > max ? parsedValue : max;
      len += 1;
    }
  });
  return len === 0 ? 0 : max;
};

const getMin = (values: string[]) => {
  let min = Number.MAX_VALUE;
  let len = 0;
  values.forEach((value) => {
    const parsedValue = parseInt(value, 10);
    if (!Number.isNaN(parsedValue)) {
      min = parsedValue < min ? parsedValue : min;
      len += 1;
    }
  });
  return len === 0 ? 0 : min;
};

const getPercent = (values: string[]) => {
  const score = parseInt(values[0], 10);
  const outOf = parseInt(values[1], 10);
  return Number.isNaN(score) || Number.isNaN(outOf)
    ? 0
    : Math.round(((score / outOf) * 100 + Number.EPSILON) * 100) / 100;
};

const getPercentOutOf20 = (values: string) => getPercent([values[0], '20']);

const getRate = (values: number[]) =>
  !values[0] || !values[1]
    ? 0
    : Math.round((values[0] / values[1] + Number.EPSILON) * 100) / 100;

const getRatePerMin = (values: string) => {
  const numerator = parseInt(values[0], 10) * 60;
  const denominator = parseInt(values[1], 10);
  return Number.isNaN(numerator) || Number.isNaN(denominator)
    ? 0
    : Math.round((numerator / denominator + Number.EPSILON) * 100) / 100;
};

const SARA_MEAN_FIELDS = SARA_CATEGORIES.map((item) => ({
  field: `${convertLabelToKey(item.title)}MeanScore`,
  func: getAverage,
}));

const MOCA_SPECIAL_FUNCTIONS = [
  { field: 'totalSubtractionPoints', func: getMocaSubtractionPoints },
  { field: 'totalFluencyPoints', func: getMocaFluencyPoints },
  { field: 'memoryIndexScore', func: getMemoryIndexScore },
  { field: 'scaledScoreOutOf30', func: getMOCAScoreOutOf30 },
  { field: 'subtractionNo1Result', func: checkMocaSubtraction },
  { field: 'subtractionNo2Result', func: checkMocaSubtraction },
  { field: 'subtractionNo3Result', func: checkMocaSubtraction },
  { field: 'subtractionNo4Result', func: checkMocaSubtraction },
  { field: 'subtractionNo5Result', func: checkMocaSubtraction },
];

const MOCAB_SPECIAL_FUNCTIONS = [
  { field: 'totalFluencyPointsMoCAB', func: getMocaBFluencyPoints },
  { field: 'totalVisuoperceptionPoints', func: getTotalVisuoperceptionPoints },
  { field: 'totalAttentionPoints', func: getTotalAttentionPoints },
  { field: 'scaledScoreOutOf30', func: getMOCAScoreOutOf30 },
];

const TRAILSA_SPECIAL_FUNCTIONS = [
  { field: 'trailAStandardScore', func: getStandardScore },
  { field: 'trailAPercentileRanking', func: getPercentileRanking },
];

const TRAILSB_SPECIAL_FUNCTIONS = [
  { field: 'trailBStandardScore', func: getStandardScore },
  { field: 'trailBPercentileRanking', func: getPercentileRanking },
];

const SLUMS_SPECIAL_FUNCTIONS = [
  { field: 'question9TotalPoints', func: getSLUMSClockPoints },
];

const REACTION_TIME_TESTER_FUNCTIONS = [
  { field: 'averageReactionTime', func: getRate },
  { field: 'testNo1AverageTime', func: getAverage },
  { field: 'testNo2AverageTime', func: getAverage },
  { field: 'testNo3AverageTime', func: getAverage },
  { field: 'testNo4AverageTime', func: getAverage },
  { field: 'overallAverageReactionTime', func: getAverage },
];

const JAMAR_FUNCTIONS = [
  { field: 'leftHandGripIsometricAverage', func: getAverage },
  { field: 'leftLateralPinchAverage', func: getAverage },
  { field: 'leftTripodPinchAverage', func: getAverage },
  { field: 'leftTwoFingerPinchAverage', func: getAverage },
  { field: 'rightHandGripIsometricAverage', func: getAverage },
  { field: 'rightLateralPinchAverage', func: getAverage },
  { field: 'rightTripodPinchAverage', func: getAverage },
  { field: 'rightTwoFingerPinchAverage', func: getAverage },
];

const FINGER_FOOT_TAPS_FUNCTIONS = [{ field: 'averageTime', func: getAverage }];

const SIMPLE_PERCENTAGES = [
  { field: 'trafficSignPercent', func: getPercent },
  { field: 'rulesOfTheRoadPercent', func: getPercent },
  { field: 'gestaltCompletionTestPercent', func: getPercent },
  { field: 'immediateRecallPercent', func: getPercent },
  { field: 'delayedRecallPercent', func: getPercent },
  { field: 'pictureCompletionTestPercent', func: getPercentOutOf20 },
  { field: 'snowyPicturesTestPercent', func: getPercent },
];

const GDS_FUNCTIONS = [
  { field: 'totalScoreGDS5', func: getTotalScoreGDS5 },
  { field: 'totalScoreGDS15', func: getTotalScoreGDS15 },
];

const DHQ_DRIVING_TABLE_FUNCTIONS = [
  ...allButLastRow(DRIVING_TABLE_ROW_TITLES).map((rowTitle) => ({
    field: getKeyWithoutAdditionalParameter('Total Kilometers', rowTitle),
    func: getTotalKmDHQ,
  })),
  {
    field: 'totalKilometersTotals',
    func: getDrivingTableTotalKilometersTotals,
  },
  { field: 'totalNumberOfPlaceTravelledTo', func: getCount },
];

const DHQ_DEP_TABLE_FUNCTIONS = [
  { field: 'dependenceTableTotalNumberOfIndividuals', func: getCount },
  { field: 'totalDependencyScore', func: getAverage },
];

const SPOT_DS_FUNCTIONS = [
  { field: 'totalGreenCategories', func: getTotalGreenCategories },
  { field: 'totalAmberCategories', func: getTotalAmberCategories },
  { field: 'totalRedCategories', func: getTotalRedCategories },
];

const DSC_FUNCTIONS = [
  { field: 'trialNo1Percent', func: getPercentOutOf20 },
  { field: 'trialNo2Percent', func: getPercentOutOf20 },
  { field: 'dSCAverage', func: getAverage },
  { field: 'dSCOverallPercent', func: getPercentOutOf20 },
];

const MVPT_FUNCTIONS = [
  { field: 'mVPTTotalScore', func: getMVPTScore },
  { field: 'averageResponseTime', func: getAverage },
  { field: 'responseTimeRangeLB', func: getMin },
  { field: 'responseTimeRangeUB', func: getMax },
];

const ROOKWOOD_FUNCTIONS = [
  {
    field: 'totalRookwoodNumberOfTestsFailed',
    func: getTotalNumberOfTestsFailed,
  },
  { field: 'totalRookwoodScore', func: getRookwoodScore },
];

const COORDINATION_FUNCTIONS = [
  { field: 'testNo1SpeedBpm', func: getRatePerMin },
  { field: 'testNo2SpeedBpm', func: getRatePerMin },
  { field: 'testNo3SpeedBpm', func: getRatePerMin },
  { field: 'testNo4SpeedBpm', func: getRatePerMin },
];

const SPECIAL_FUNCTIONS: SpecialCalculationFunction[] = [
  ...SIMPLE_PERCENTAGES,
  ...MOCA_SPECIAL_FUNCTIONS,
  ...MOCAB_SPECIAL_FUNCTIONS,
  ...TRAILSA_SPECIAL_FUNCTIONS,
  ...TRAILSB_SPECIAL_FUNCTIONS,
  ...SLUMS_SPECIAL_FUNCTIONS,
  ...REACTION_TIME_TESTER_FUNCTIONS,
  ...JAMAR_FUNCTIONS,
  ...FINGER_FOOT_TAPS_FUNCTIONS,
  ...GDS_FUNCTIONS,
  ...SARA_MEAN_FIELDS,
  ...DHQ_DRIVING_TABLE_FUNCTIONS,
  ...DHQ_DEP_TABLE_FUNCTIONS,
  ...SPOT_DS_FUNCTIONS,
  ...DSC_FUNCTIONS,
  ...MVPT_FUNCTIONS,
  ...ROOKWOOD_FUNCTIONS,
  ...COORDINATION_FUNCTIONS,
];

export default SPECIAL_FUNCTIONS;
