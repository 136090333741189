export interface IClientMetaData {
  clientId: string;
  isConsentGiven: string;
  isConsentFormUploaded?: string;
}

export interface IClientWithoutId {
  username?: string;
  isConsentGiven?: string;
  isConsentFormUploaded?: string;
  batteryFilters?: any;

  accidentInformationSource: string;
  acuityBothEyes: string;
  acuityLeftEye: string;
  acuityRightEye: string;
  afterDiagnosisNumberOfKmPerWeek: number | null;
  afterDiagnosisWhatWasisTheirDrivingFrequency: string;
  ageWhenStartedDriving: number | null;
  age: number | null;
  allergies: string;
  anyAdditionalMedicationsClientNotTakingDetails: string;
  anyAdditionalMedicationsClientNotTaking: string;
  anyPreviousExperience: string;
  anySpecializedEquipment: string;
  attendedAssessmentWithDaughter: string;
  attendedAssessmentWithFather: string;
  attendedAssessmentWithFriend: string;
  attendedAssessmentWithMother: string;
  attendedAssessmentWithNeighbour: string;
  attendedAssessmentWithOtherAttendee: string;
  attendedAssessmentWithPartner: string;
  attendedAssessmentWithSibling: string;
  attendedAssessmentWithSomeone: string;
  attendedAssessmentWithSon: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseFog: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseHighway: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseNight: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRain: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRushHour: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSnow: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset: string;
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseFog: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseHighway: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseNight: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRain: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRushHour: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSnow: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset: string;
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas: string;
  beforeDiagnosisNumberOfKmPerWeek: number | null;
  beforeDiagnosisWhatWasisTheirDrivingFrequency: string;
  clientIDOfPreviousCDE: string;
  clientsPerspectiveAlcoholConsumptionDaysWeek: string;
  clientsPerspectiveCannabisUseDaysWeek: string;
  clientsPerspectiveIllicitDrugUseDaysWeek: string;
  clientsPerspectiveNumberOfAccidentsLast5Years: number | null;
  clientsPerspectiveNumberOfViolationsLast5Years: number | null;
  commentsCategory0: string;
  commentsCategory1: string;
  commentsCategory2: string;
  commentsCategory3: string;
  commentsCategory4: string;
  commentsCategory5: string;
  commentsCategory6: string;
  commentsCategory7: string;
  commentsCategory8: string;
  currentLivingSituation: string;
  currentlyReliesOnFamily: string;
  currentlyReliesOnFriends: string;
  currentlyReliesOnOtherTransportation: string;
  currentlyReliesOnParatransit: string;
  currentlyReliesOnPublicTransit: string;
  currentlyReliesOnSelf: string;
  currentlyReliesOnTaxi: string;
  customRestrictionsDescription: string;
  dateLastDrove: string;
  dateOfAssessment: string;
  dateOfInterruptionsInDrivingPrivileges: string;
  dateOfOnsetOfVisualFieldDefect: string;
  dateOfPreviousCDE: string;
  dateOfPreviousDrivingExperience: string;
  dateOfReferral: string;
  dateOfVisionScreen: string;
  datePreviouslyReported: string;
  detailsOfPreviousDrivingExperience: string;
  disorientationDetails: string;
  dominantFoot: string;
  dominantHand: string;
  drivingGoalsDetails: string;
  drivingGoalsDriveForErrands: string;
  drivingGoalsDriveForWork: string;
  drivingGoalsDriveTheirFamily: string;
  drivingGoalsDriveToLeisureActivities: string;
  drivingGoalsDriveToMedicalAppointments: string;
  drivingGoalsDriveToSchool: string;
  drivingGoalsDriveToWork: string;
  drivingGoalsDriveXKmPerWeek: string;
  drivingGoalsOtherDrivingGoals: string;
  drivingGoalsReturnToThePreviousLevelOfDriving: string;
  drivingGoalsXKmDetails: number | null;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas: string;
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas: string;
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving: string;
  drivingRestrictionsCustomRestrictions: string;
  drivingRestrictionsGlassesOrContactLenses: string;
  drivingRestrictionsNight: string;
  drivingRestrictionsNone: string;
  drivingRestrictionsOutsideCityLimits: string;
  drivingRestrictionsRearViewMirrors: string;
  drivingRestrictionsSpecifyX: string;
  drivingRestrictionsXKmRadiusOfAddress: string;
  endorsements: string;
  equipmentDetails: string;
  everHadLicence: string;
  furtherEyeExamination: string;
  gender: string;
  glassesBifocal: string;
  glassesOtherGlasses: string;
  glassesPrism: string;
  glassesProgressive: string;
  glassesSingleVisionDistance: string;
  glassesSingleVisionReading: string;
  glasses: string;
  hasDriversLicence: string;
  hasDrivingRestrictions: string;
  hasTheMedicalConditionsBeenPreviouslyReported: string;
  hasTheMedicalConditionsBeenReported: string;
  hearingAidsLeftEar: string;
  hearingAidsNone: string;
  hearingAidsRightEar: string;
  highestLevelOfEducation: string;
  historyOfDisorientationOrGettingLost: string;
  howLikelyToPass: string;
  howManyDrivers: number | null;
  interpreterDetails: string;
  interruptionsInDrivingPrivileges: string;
  isCurrentlyDriving: string;
  leftEars: string;
  leftVisualAcuity: string;
  leftVisualFields: string;
  leisureActivities: string;
  lettersFromLicensingBodyFileName: string;
  levelOfIndependence: string;
  licenceClass: string;
  licenceStatus: string;
  medicalConditionNo1DateOfOnset: string;
  medicalConditionNo1DiagnosisBrainInjuryDiagnosis: string;
  medicalConditionNo1DiagnosisCentralNervousSystemDiagnosis: string;
  medicalConditionNo1DiagnosisCognitiveDiagnosis: string;
  medicalConditionNo1DiagnosisDetails: string;
  medicalConditionNo1DiagnosisFirstSeizureDate: string;
  medicalConditionNo1DiagnosisLastSeizureDate: string;
  medicalConditionNo1DiagnosisOptometristEvaluationLetterFileName: string;
  medicalConditionNo1DiagnosisOtherDiagnosis: string;
  medicalConditionNo1DiagnosisPhysicalDiagnosis: string;
  medicalConditionNo1DiagnosisPsychologicalDiagnosis: string;
  medicalConditionNo1Diagnosis: string;
  medicalConditionNo1IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: string;
  medicalConditionNo1IsTheImpairmentExpectedToBeTemporary: string;
  medicalConditionNo2DateOfOnset: string;
  medicalConditionNo2DiagnosisBrainInjuryDiagnosis: string;
  medicalConditionNo2DiagnosisCentralNervousSystemDiagnosis: string;
  medicalConditionNo2DiagnosisCognitiveDiagnosis: string;
  medicalConditionNo2DiagnosisDetails: string;
  medicalConditionNo2DiagnosisFirstSeizureDate: string;
  medicalConditionNo2DiagnosisLastSeizureDate: string;
  medicalConditionNo2DiagnosisOptometristEvaluationLetterFileName: string;
  medicalConditionNo2DiagnosisOtherDiagnosis: string;
  medicalConditionNo2DiagnosisPhysicalDiagnosis: string;
  medicalConditionNo2DiagnosisPsychologicalDiagnosis: string;
  medicalConditionNo2Diagnosis: string;
  medicalConditionNo2IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: string;
  medicalConditionNo2IsTheImpairmentExpectedToBeTemporary: string;
  medicalConditionNo3DateOfOnset: string;
  medicalConditionNo3DiagnosisBrainInjuryDiagnosis: string;
  medicalConditionNo3DiagnosisCentralNervousSystemDiagnosis: string;
  medicalConditionNo3DiagnosisCognitiveDiagnosis: string;
  medicalConditionNo3DiagnosisDetails: string;
  medicalConditionNo3DiagnosisFirstSeizureDate: string;
  medicalConditionNo3DiagnosisLastSeizureDate: string;
  medicalConditionNo3DiagnosisOptometristEvaluationLetterFileName: string;
  medicalConditionNo3DiagnosisOtherDiagnosis: string;
  medicalConditionNo3DiagnosisPhysicalDiagnosis: string;
  medicalConditionNo3DiagnosisPsychologicalDiagnosis: string;
  medicalConditionNo3Diagnosis: string;
  medicalConditionNo3IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: string;
  medicalConditionNo3IsTheImpairmentExpectedToBeTemporary: string;
  medicationEffectOnDriving: string;
  medicationSideEffectsBlurredVision: string;
  medicationSideEffectsDizziness: string;
  medicationSideEffectsDrowsiness: string;
  medicationSideEffectsHeadaches: string;
  medicationSideEffectsNone: string;
  medicationSideEffectsOtherSideEffects: string;
  medicationsList: string;
  meetsLicensingBodyRequirements: string;
  needAnInterpreter: string;
  nonBinaryGender: string;
  officialPerspectiveNumberOfAccidentsLast5Years: number | null;
  officialPerspectiveNumberOfViolationsLast5Years: number | null;
  otherAttendeeSpecify: string;
  otherDriversInHousehold: string;
  otherGlassesSpecify: string;
  otherLanguageSpecify: string;
  otherMedicalConditions: string;
  otherMedicationSideEffectsDetails: string;
  otherTransportationReliesOnDetails: string;
  otherVehicleTransmission: string;
  otherVehicleType: string;
  otherVehicle: string;
  peripheralTestLeft: number | null;
  peripheralTestRight: number | null;
  peripheralTestTotal: number | null;
  previousCDEResultDrivingNotRecommendedFunctionalDeficits: string;
  previousCDEResultDrivingNotRecommendedMedicalDeficits: string;
  previousCDEResultDrivingNotRecommendedVisualDeficits: string;
  previousCDEResultFunctionalDrivingSkillsPoorDrivingHabits: string;
  previousCDEResultFunctionalDrivingSkills: string;
  previousCDEResultNoviceDriverTrainingRequired: string;
  previousCDEResultRehabilitationCandidate: string;
  previousCDE: string;
  primaryLanguage: string;
  primaryVisualFieldDefectHemianopsia: string;
  primaryVisualFieldDefectInferior: string;
  primaryVisualFieldDefectLeft: string;
  primaryVisualFieldDefectNone: string;
  primaryVisualFieldDefectQuadrantanopia: string;
  primaryVisualFieldDefectRight: string;
  primaryVisualFieldDefectScotoma: string;
  primaryVisualFieldDefectSuperior: string;
  reasonWhyInterruptionsInDrivingPrivileges: string;
  recentAccidents: string;
  recentViolations: string;
  referralSourceDetails: string;
  referralSource: string;
  rightEars: string;
  rightVisualAcuity: string;
  rightVisualFields: string;
  sourcesOfHearingInformation: string;
  sourcesOfMedicalInformation: string;
  sourcesOfVisionInformation: string;
  strategiesBeingUsedToCompensateImpactsOnDrivingPerformance: string;
  substanceAbuseDetails: string;
  supportPersonConfirmsDrivingHistory: string;
  supportPersonConfirmsMedications: string;
  supportPersonDoesNotConfirmDrivingHistory: string;
  supportPersonDoesntConfirmsMedicationsDetails: string;
  supportPersonPerceptionOnDrivingComment: string;
  supportPersonPerceptionOnDriving: string;
  supportPersonPresentForClinicalAssessments: string;
  supportPersonPresentForConsent: string;
  supportPersonPresentForDebriefing: string;
  supportPersonPresentForInitialInterview: string;
  supportPersonsPerspectiveAlcoholConsumptionDaysWeek: string;
  supportPersonsPerspectiveCannabisUseDaysWeek: string;
  supportPersonsPerspectiveIllicitDrugUseDaysWeek: string;
  vehicleTransmission: string;
  vehicleType: string;
  visionScreenProvidedWithReferral: string;
  visualConditionAbnormalHeadTilting: string;
  visualConditionCataracts: string;
  visualConditionDetails: string;
  visualConditionDiplopia: string;
  visualConditionGlaucoma: string;
  visualConditionHyperopia: string;
  visualConditionMacularDegeneration: string;
  visualConditionMyopia: string;
  visualConditionNystagmus: string;
  visualConditionStrabismus: string;
  visualConditionVisualConditionNotListed: string;
  vocationalStatusMedicalLeave: string;
  vocationalStatusOther: string;
  vocationalStatusRetired: string;
  vocationalStatusStudent: string;
  vocationalStatusVolunteer: string;
  vocationalStatusWorking: string;
  wherePreviousCDE: string;
  whoReportedTheMedicalCondition: string;
  whyDoesntMeetLicensingBodyRequirements: string;
  whyDrivingRestrictionsUnknown: string;
  whyLicenceStatusUnknown: string;
  whyVehicleStatusUknown: string;
  workHistory: string;
  workingHoursPerWeek: number | null;
  workingOther: string;
  yearsOfDriving: number | null;
}

export interface IClient extends IClientWithoutId {
  clientId: string;
}

export const blankClient: IClientWithoutId = {
  isConsentGiven: 'toBeDetermined',
  isConsentFormUploaded: 'no',
  batteryFilters: {},

  accidentInformationSource: '',
  acuityBothEyes: '',
  acuityLeftEye: '',
  acuityRightEye: '',
  afterDiagnosisNumberOfKmPerWeek: null,
  afterDiagnosisWhatWasisTheirDrivingFrequency: '',
  ageWhenStartedDriving: null,
  age: null,
  allergies: '',
  anyAdditionalMedicationsClientNotTakingDetails: '',
  anyAdditionalMedicationsClientNotTaking: '',
  anyPreviousExperience: '',
  anySpecializedEquipment: '',
  attendedAssessmentWithDaughter: '',
  attendedAssessmentWithFather: '',
  attendedAssessmentWithFriend: '',
  attendedAssessmentWithMother: '',
  attendedAssessmentWithNeighbour: '',
  attendedAssessmentWithOtherAttendee: '',
  attendedAssessmentWithPartner: '',
  attendedAssessmentWithSibling: '',
  attendedAssessmentWithSomeone: '',
  attendedAssessmentWithSon: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseFog: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseHighway: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic:
    '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseNight: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRain: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRushHour: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSnow: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset: '',
  avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseFog: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseHighway: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic:
    '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseNight: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRain: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRushHour: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSnow: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset: '',
  avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas: '',
  beforeDiagnosisNumberOfKmPerWeek: null,
  beforeDiagnosisWhatWasisTheirDrivingFrequency: '',
  clientIDOfPreviousCDE: '',
  clientsPerspectiveAlcoholConsumptionDaysWeek: '',
  clientsPerspectiveCannabisUseDaysWeek: '',
  clientsPerspectiveIllicitDrugUseDaysWeek: '',
  clientsPerspectiveNumberOfAccidentsLast5Years: null,
  clientsPerspectiveNumberOfViolationsLast5Years: null,
  commentsCategory0: '',
  commentsCategory1: '',
  commentsCategory2: '',
  commentsCategory3: '',
  commentsCategory4: '',
  commentsCategory5: '',
  commentsCategory6: '',
  commentsCategory7: '',
  commentsCategory8: '',
  currentLivingSituation: '',
  currentlyReliesOnFamily: '',
  currentlyReliesOnFriends: '',
  currentlyReliesOnOtherTransportation: '',
  currentlyReliesOnParatransit: '',
  currentlyReliesOnPublicTransit: '',
  currentlyReliesOnSelf: '',
  currentlyReliesOnTaxi: '',
  customRestrictionsDescription: '',
  dateLastDrove: '',
  dateOfAssessment: '',
  dateOfInterruptionsInDrivingPrivileges: '',
  dateOfOnsetOfVisualFieldDefect: '',
  dateOfPreviousCDE: '',
  dateOfPreviousDrivingExperience: '',
  dateOfReferral: '',
  dateOfVisionScreen: '',
  datePreviouslyReported: '',
  detailsOfPreviousDrivingExperience: '',
  disorientationDetails: '',
  dominantFoot: '',
  dominantHand: '',
  drivingGoalsDetails: '',
  drivingGoalsDriveForErrands: '',
  drivingGoalsDriveForWork: '',
  drivingGoalsDriveTheirFamily: '',
  drivingGoalsDriveToLeisureActivities: '',
  drivingGoalsDriveToMedicalAppointments: '',
  drivingGoalsDriveToSchool: '',
  drivingGoalsDriveToWork: '',
  drivingGoalsDriveXKmPerWeek: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood:
    '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas: '',
  drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood:
    '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas: '',
  drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving: '',
  drivingGoalsOtherDrivingGoals: '',
  drivingGoalsReturnToThePreviousLevelOfDriving: '',
  drivingGoalsXKmDetails: null,
  drivingRestrictionsCustomRestrictions: '',
  drivingRestrictionsGlassesOrContactLenses: '',
  drivingRestrictionsNight: '',
  drivingRestrictionsNone: '',
  drivingRestrictionsOutsideCityLimits: '',
  drivingRestrictionsRearViewMirrors: '',
  drivingRestrictionsSpecifyX: '',
  drivingRestrictionsXKmRadiusOfAddress: '',
  endorsements: '',
  equipmentDetails: '',
  everHadLicence: '',
  furtherEyeExamination: '',
  gender: '',
  glassesBifocal: '',
  glassesOtherGlasses: '',
  glassesPrism: '',
  glassesProgressive: '',
  glassesSingleVisionDistance: '',
  glassesSingleVisionReading: '',
  glasses: '',
  hasDriversLicence: '',
  hasDrivingRestrictions: '',
  hasTheMedicalConditionsBeenPreviouslyReported: '',
  hasTheMedicalConditionsBeenReported: '',
  hearingAidsLeftEar: '',
  hearingAidsNone: '',
  hearingAidsRightEar: '',
  highestLevelOfEducation: '',
  historyOfDisorientationOrGettingLost: '',
  howLikelyToPass: '',
  howManyDrivers: null,
  interpreterDetails: '',
  interruptionsInDrivingPrivileges: '',
  isCurrentlyDriving: '',
  leftEars: '',
  leftVisualAcuity: '',
  leftVisualFields: '',
  leisureActivities: '',
  lettersFromLicensingBodyFileName: '',
  levelOfIndependence: '',
  licenceClass: '',
  licenceStatus: '',
  medicalConditionNo1DateOfOnset: '',
  medicalConditionNo1DiagnosisBrainInjuryDiagnosis: '',
  medicalConditionNo1DiagnosisCentralNervousSystemDiagnosis: '',
  medicalConditionNo1DiagnosisCognitiveDiagnosis: '',
  medicalConditionNo1DiagnosisDetails: '',
  medicalConditionNo1DiagnosisFirstSeizureDate: '',
  medicalConditionNo1DiagnosisLastSeizureDate: '',
  medicalConditionNo1DiagnosisOptometristEvaluationLetterFileName: '',
  medicalConditionNo1DiagnosisOtherDiagnosis: '',
  medicalConditionNo1DiagnosisPhysicalDiagnosis: '',
  medicalConditionNo1DiagnosisPsychologicalDiagnosis: '',
  medicalConditionNo1Diagnosis: '',
  medicalConditionNo1IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: '',
  medicalConditionNo1IsTheImpairmentExpectedToBeTemporary: '',
  medicalConditionNo2DateOfOnset: '',
  medicalConditionNo2DiagnosisBrainInjuryDiagnosis: '',
  medicalConditionNo2DiagnosisCentralNervousSystemDiagnosis: '',
  medicalConditionNo2DiagnosisCognitiveDiagnosis: '',
  medicalConditionNo2DiagnosisDetails: '',
  medicalConditionNo2DiagnosisFirstSeizureDate: '',
  medicalConditionNo2DiagnosisLastSeizureDate: '',
  medicalConditionNo2DiagnosisOptometristEvaluationLetterFileName: '',
  medicalConditionNo2DiagnosisOtherDiagnosis: '',
  medicalConditionNo2DiagnosisPhysicalDiagnosis: '',
  medicalConditionNo2DiagnosisPsychologicalDiagnosis: '',
  medicalConditionNo2Diagnosis: '',
  medicalConditionNo2IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: '',
  medicalConditionNo2IsTheImpairmentExpectedToBeTemporary: '',
  medicalConditionNo3DateOfOnset: '',
  medicalConditionNo3DiagnosisBrainInjuryDiagnosis: '',
  medicalConditionNo3DiagnosisCentralNervousSystemDiagnosis: '',
  medicalConditionNo3DiagnosisCognitiveDiagnosis: '',
  medicalConditionNo3DiagnosisDetails: '',
  medicalConditionNo3DiagnosisFirstSeizureDate: '',
  medicalConditionNo3DiagnosisLastSeizureDate: '',
  medicalConditionNo3DiagnosisOptometristEvaluationLetterFileName: '',
  medicalConditionNo3DiagnosisOtherDiagnosis: '',
  medicalConditionNo3DiagnosisPhysicalDiagnosis: '',
  medicalConditionNo3DiagnosisPsychologicalDiagnosis: '',
  medicalConditionNo3Diagnosis: '',
  medicalConditionNo3IsTheImpairmentExpectedToBeTemporaryExpectedElapseDate: '',
  medicalConditionNo3IsTheImpairmentExpectedToBeTemporary: '',
  medicationEffectOnDriving: '',
  medicationSideEffectsBlurredVision: '',
  medicationSideEffectsDizziness: '',
  medicationSideEffectsDrowsiness: '',
  medicationSideEffectsHeadaches: '',
  medicationSideEffectsNone: '',
  medicationSideEffectsOtherSideEffects: '',
  medicationsList: '',
  meetsLicensingBodyRequirements: '',
  needAnInterpreter: '',
  nonBinaryGender: '',
  officialPerspectiveNumberOfAccidentsLast5Years: null,
  officialPerspectiveNumberOfViolationsLast5Years: null,
  otherAttendeeSpecify: '',
  otherDriversInHousehold: '',
  otherGlassesSpecify: '',
  otherLanguageSpecify: '',
  otherMedicalConditions: '',
  otherMedicationSideEffectsDetails: '',
  otherTransportationReliesOnDetails: '',
  otherVehicleTransmission: '',
  otherVehicleType: '',
  otherVehicle: '',
  peripheralTestLeft: null,
  peripheralTestRight: null,
  peripheralTestTotal: null,
  previousCDEResultDrivingNotRecommendedFunctionalDeficits: '',
  previousCDEResultDrivingNotRecommendedMedicalDeficits: '',
  previousCDEResultDrivingNotRecommendedVisualDeficits: '',
  previousCDEResultFunctionalDrivingSkillsPoorDrivingHabits: '',
  previousCDEResultFunctionalDrivingSkills: '',
  previousCDEResultNoviceDriverTrainingRequired: '',
  previousCDEResultRehabilitationCandidate: '',
  previousCDE: '',
  primaryLanguage: '',
  primaryVisualFieldDefectHemianopsia: '',
  primaryVisualFieldDefectInferior: '',
  primaryVisualFieldDefectLeft: '',
  primaryVisualFieldDefectNone: '',
  primaryVisualFieldDefectQuadrantanopia: '',
  primaryVisualFieldDefectRight: '',
  primaryVisualFieldDefectScotoma: '',
  primaryVisualFieldDefectSuperior: '',
  reasonWhyInterruptionsInDrivingPrivileges: '',
  recentAccidents: '',
  recentViolations: '',
  referralSourceDetails: '',
  referralSource: '',
  rightEars: '',
  rightVisualAcuity: '',
  rightVisualFields: '',
  sourcesOfHearingInformation: '',
  sourcesOfMedicalInformation: '',
  sourcesOfVisionInformation: '',
  strategiesBeingUsedToCompensateImpactsOnDrivingPerformance: '',
  substanceAbuseDetails: '',
  supportPersonConfirmsDrivingHistory: '',
  supportPersonConfirmsMedications: '',
  supportPersonDoesNotConfirmDrivingHistory: '',
  supportPersonDoesntConfirmsMedicationsDetails: '',
  supportPersonPerceptionOnDrivingComment: '',
  supportPersonPerceptionOnDriving: '',
  supportPersonPresentForClinicalAssessments: '',
  supportPersonPresentForConsent: '',
  supportPersonPresentForDebriefing: '',
  supportPersonPresentForInitialInterview: '',
  supportPersonsPerspectiveAlcoholConsumptionDaysWeek: '',
  supportPersonsPerspectiveCannabisUseDaysWeek: '',
  supportPersonsPerspectiveIllicitDrugUseDaysWeek: '',
  vehicleTransmission: '',
  vehicleType: '',
  visionScreenProvidedWithReferral: '',
  visualConditionAbnormalHeadTilting: '',
  visualConditionCataracts: '',
  visualConditionDetails: '',
  visualConditionDiplopia: '',
  visualConditionGlaucoma: '',
  visualConditionHyperopia: '',
  visualConditionMacularDegeneration: '',
  visualConditionMyopia: '',
  visualConditionNystagmus: '',
  visualConditionStrabismus: '',
  visualConditionVisualConditionNotListed: '',
  vocationalStatusMedicalLeave: '',
  vocationalStatusOther: '',
  vocationalStatusRetired: '',
  vocationalStatusStudent: '',
  vocationalStatusVolunteer: '',
  vocationalStatusWorking: '',
  wherePreviousCDE: '',
  whoReportedTheMedicalCondition: '',
  whyDoesntMeetLicensingBodyRequirements: '',
  whyDrivingRestrictionsUnknown: '',
  whyLicenceStatusUnknown: '',
  whyVehicleStatusUknown: '',
  workHistory: '',
  workingHoursPerWeek: null,
  workingOther: '',
  yearsOfDriving: null,
};
