import React, { FC } from 'react';

import { Grid } from '@mui/material';

import { IClient, IClientMetaData } from '../../models/client';
import { Conditional, FieldsLayout } from '../../models/forms';
import ClearConditional from './ClearConditional';
import FieldContainer from './FieldContainer/FieldContainer';

type FormSectionProps = {
  fields: FieldsLayout[];
  clientId?: string;
  values: IClient | IClientMetaData | any; // any is for clinical assessments, which would be a lot to declare types for
  commentsPosition?: 'bottom' | 'right' | 'none';
  commentsName?: string;
  sectionKey: string;
  namespace: string;
};

// TODO: make conditional faster (less computation and maybe less changes on state change)
// Renders a form section with comments
const FormSection: FC<FormSectionProps> = ({
  fields,
  values,
  clientId,
  commentsPosition = 'right',
  commentsName,
  sectionKey,
  namespace,
}) => {
  const getCommentsName = () =>
    commentsName ||
    (fields && fields[0]
      ? `comments${sectionKey.charAt(0).toUpperCase()}${sectionKey.slice(1)}`
      : 'comments');

  return (
    <Grid item container direction="row">
      <Grid item container direction="column" xs={12}>
        {fields.map((field) => (
          <React.Fragment key={field.key}>
            <Grid item>
              <FieldContainer
                label={field.label}
                name={field.key}
                namespace={field.namespace ? field.namespace : namespace}
                type={field.fieldType}
                options={field.options}
                defaultValue={field.defaultValue}
                units={field.units}
                clientId={clientId}
                selectAll={field.selectAll}
                cells={field.cells}
                columnHeadings={field.columnHeadings}
                valueDependsOn={field.valueDependsOn}
              />
            </Grid>
            {/* Display conditional fields if applicable */}
            {field.conditionals &&
              values &&
              field.conditionals.map((conditional: Conditional) =>
                conditional.condition(values, conditional.conditionalKeys) ? (
                  <Grid item key={conditional.key}>
                    <FieldContainer
                      label={conditional.label}
                      name={conditional.key}
                      namespace={field.namespace ? field.namespace : namespace}
                      type={conditional.fieldType}
                      options={conditional.options}
                      units={conditional.units}
                      defaultValue={conditional.defaultValue}
                      clientId={clientId}
                      selectAll={field.selectAll}
                      cells={conditional.cells}
                      columnHeadings={conditional.columnHeadings}
                      valueDependsOn={field.valueDependsOn}
                    />
                  </Grid>
                ) : (
                  <ClearConditional
                    key={conditional.key}
                    name={conditional.key}
                    type={conditional.fieldType}
                    options={conditional.options}
                  />
                )
              )}
          </React.Fragment>
        ))}
        {commentsPosition === 'bottom' && (
          <Grid item>
            <FieldContainer
              label="Comments"
              name={getCommentsName()}
              namespace="generalNs"
              type="TEXT-AREA"
            />
          </Grid>
        )}
      </Grid>
      {commentsPosition === 'right' && (
        <Grid item>
          <FieldContainer
            label="Comments"
            name={getCommentsName()}
            namespace="generalNs"
            type="TEXT-AREA"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FormSection;
