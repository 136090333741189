import React, { FC, useState } from 'react';

import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/Help';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import allNamespaces from '../../allNamespaces';
import i18n from '../../i18n';
import { getTranslationLabel, languageAbbr } from '../../utils/utils';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  open: boolean;
}

const ListItemLink: FC<ListItemLinkProps> = ({ icon, primary, to, open }) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <Tooltip title={primary}>
          <RouterLink to={to} ref={ref} {...itemProps} />
        </Tooltip>
      )),
    [to, primary]
  );

  return (
    <li>
      <ListItemButton component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText
          primary={primary}
          sx={{ display: { xs: 'none', lg: open ? 'block' : 'none' } }}
        />
      </ListItemButton>
    </li>
  );
};

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({ postLogoutRedirectUri: '/' });
};

const SideBar = () => {
  const { t } = useTranslation(allNamespaces);
  const { instance } = useMsal();
  const theme = useTheme();
  const isLargeScreenSize = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);

  type NavItem = {
    key: string;
    text: string;
    icon?: any;
    to: string;
  };
  const itemsList: NavItem[] = [
    {
      key: 'clients',
      text: getTranslationLabel('navBar', 'clients', t),
      icon: <PeopleIcon />,
      to: '/clients',
    },
    {
      key: 'settings',
      text: getTranslationLabel('navBar', 'settings', t),
      icon: <SettingsIcon />,
      to: '/user/settings/account',
    },
    {
      key: 'help',
      text: getTranslationLabel('navBar', 'help', t),
      icon: <HelpIcon />,
      to: '/help',
    },
  ];

  const flagToShow = () => (
    <ReactCountryFlag
      countryCode={i18n.resolvedLanguage === 'en' ? 'FR' : 'GB'}
      svg
      style={{
        width: '2em',
        height: '2em',
      }}
    />
  );

  const drawer = (
    <>
      <List>
        <Grid item container direction="column" justifyContent="space-between">
          {isLargeScreenSize && (
            <Grid item>
              <ListItem button onClick={() => setOpen(!open)}>
                <ListItemIcon>
                  <Tooltip title={open ? 'Collpase' : 'Expand'}>
                    {open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                  </Tooltip>
                </ListItemIcon>
                <Divider />
              </ListItem>
            </Grid>
          )}
          <Grid item>
            {itemsList.map((item: NavItem) => (
              <ListItemLink
                key={item.key}
                primary={item.text}
                icon={item.icon}
                to={item.to}
                open={open}
              />
            ))}
          </Grid>

          <Grid item>
            <ListItem
              button
              onClick={() => i18n.changeLanguage(languageAbbr())}
            >
              <ListItemIcon>{flagToShow()}</ListItemIcon>
            </ListItem>
          </Grid>
          <Grid item>
            <Divider />
            <ListItem button onClick={() => handleLogout(instance)}>
              <ListItemIcon>
                <Tooltip title="Sign Out">
                  <ExitToAppIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={getTranslationLabel('navBar', 'signOut', t)}
                sx={{
                  display: {
                    xs: 'none',
                    lg: open ? 'block' : 'none',
                  },
                }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </>
  );

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: '5%',
        [theme.breakpoints.down('md')]: {
          width: '10%',
        },
        [theme.breakpoints.up('lg')]: {
          width: open ? '15%' : '5%',
        },
        flexShrink: 0,
      }}
      PaperProps={{
        sx: {
          width: '5%',
          [theme.breakpoints.down('md')]: {
            width: '10%',
          },
          [theme.breakpoints.up('lg')]: {
            width: open ? '15%' : '5%',
          },
          backgroundColor: theme.palette.secondary.light,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default SideBar;
