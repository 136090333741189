import React from 'react';

import { Typography } from '@mui/material';

import {
  FieldTypes,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../models/forms';
import {
  Results,
  PASS_FAIL,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { VISION_QUADRANTS } from '../Visual/Visual-Utils';

const getSubtest1or2or3 = (testNo: number): SubHeadingItem => ({
  key: `subtestNo${testNo}`,
  label: `Subtest No. ${testNo}`,
  fields: [
    {
      key: `subtestNo${testNo}Score`,
      label: 'Score',
      fieldType: 'INPUT-NUM',
    },
    {
      key: `subtestNo${testNo}ReactionTime`,
      label: 'Average reaction time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Results:',
      key: `subtestNo${testNo}Results`,
      fieldType: 'SCALE',
      options: PASS_FAIL,
    },
  ],
});

const dynavisionD2: MainHeadingItem = {
  key: 'dynavisionD2',
  label: 'Dynavision D2',
  subheadings: [
    getSubtest1or2or3(1),
    getSubtest1or2or3(2),
    getSubtest1or2or3(3),
    {
      key: `subtestNo4`,
      label: `Subtest No. 4`,
      fields: [
        {
          key: 'subtestNo4Score',
          label: 'Score',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'subtestNo4ReactionTime',
          label: 'Average reaction time',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          key: 'scoresByVisionAreaLabel',
          label: 'Scores by vision area',
          fieldType: 'LABEL',
        },
        ...VISION_QUADRANTS.map((item) => ({
          key: `subtestNo4${capitalizeFirstLetter(
            convertLabelToKey(item)
          )}QuadrantResult`,
          label: `${item} quadrant score`,
          fieldType: 'INPUT-NUM' as FieldTypes,
        })),
        {
          key: 'subtestNo4CentralTargetsScore',
          label: 'Central targets score',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Results:',
          key: 'subtestNo4Results',
          fieldType: 'SCALE',
          options: PASS_FAIL,
        },
      ],
    },
    {
      key: 'overallObservations',
      label: 'Overall Observations',
      fields: [
        {
          key: 'scanningPatternObserved',
          label: 'Scanning pattern observed',
          fieldType: 'RADIO',
          options: [
            { key: 'sufficient', val: 'sufficient', label: 'Sufficient' },
            { key: 'deficient', val: 'deficient', label: 'Deficient' },
          ],
        },
        {
          key: 'levelOfAttention',
          label: 'Level of attention',
          fieldType: 'RADIO',
          options: [
            { key: 'sustained', val: 'sustained', label: 'Sustained' },
            {
              key: 'easilyDistracted',
              val: 'easilyDistracted',
              label: 'Easily distracted',
            },
            { key: 'lossOfFocus', val: 'lossOfFocus', label: 'Loss of focus' },
          ],
        },
        {
          key: 'observedUseOfReripheralVision',
          label: 'Observed use of peripheral vision',
          fieldType: 'RADIO',
          options: [
            { key: 'adequate', val: 'adequate', label: 'Adequate' },
            { key: 'inadequate', val: 'inadequate', label: 'Inadequate' },
            { key: 'absent', val: 'absent', label: 'Absent' },
          ],
        },
        ...Results,
      ],
    },
  ],
};

export default dynavisionD2;
