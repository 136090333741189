import { createTheme, responsiveFontSizes } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    itemBackground: Palette['primary'];
  }
  interface PaletteOptions {
    itemBackground: PaletteOptions['primary'];
  }
}

let theme = createTheme({
  palette: {
    // grey
    primary: {
      main: '#aeaeae',
      light: '#f5f5f5',
      dark: '#424242',
    },
    // blue
    secondary: {
      main: '#1f628e',
      light: '#b9d2e6',
      dark: '#003860',
    },
    // red
    error: {
      main: '#b71c1c',
      light: '#ffdfdf',
      dark: '#870d07',
    },
    // orange
    warning: {
      main: '#ff9e80',
      light: '#ffdccb',
      dark: '#903400',
    },
    // white
    info: {
      main: '#aeaeae',
      light: '#fafafa',
      dark: '#424242',
    },
    // green
    success: {
      main: '#7cb342',
      light: '#d1e3b1',
      dark: '#102705',
    },
    background: {
      default: '#f5f5f5',
    },
    text: {
      disabled: '#000000',
    },
    itemBackground: {
      main: '#c1dbf5',
      contrastText: 'black',
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    h1: {
      fontWeight: 400,
      fontSize: '1.75rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h2: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.0rem',
      lineHeight: 1.3,
      letterSpacing: '0.0075em',
    },
    h4: {
      fontWeight: 350,
      fontSize: '0.9rem',
      lineHeight: 1,
      letterSpacing: '0.005em',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.0rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.9rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#1f628e',
    },
    body1: {
      fontSize: 'x-small',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.0rem',
      lineHeight: 1.3,
      letterSpacing: '0.0075em',
      color: '#424242',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1.0rem',
      lineHeight: 1.3,
      letterSpacing: '0.0075em',
      color: '#1f628e',
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'secondary',
        variant: 'contained',
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
        color: 'secondary',
      },
    },
    MuiTableCell: {
      defaultProps: {
        align: 'center',
      },
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.info,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          '&.Mui-active': {
            color: theme.palette.secondary.main,
          },
        },
        text: {
          fill: theme.palette.primary.light,
        },
        active: {},
        completed: {},
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '5%',
          [theme.breakpoints.down('md')]: {
            width: '10%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '15%',
          },
          flexShrink: 0,
        },
        paper: {
          width: '5%',
          [theme.breakpoints.down('md')]: {
            width: '10%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '15%',
          },
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.info.light,
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
          textTransform: 'none',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.dark,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          minWidth: 275,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.dark,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 'medium',
          color: theme.palette.secondary.dark,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.info.light,
          elevation: 0,
        },
        indicator: {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.Mui-hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.light,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: '10px',
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: 'green',
        },
        colorSecondary: {
          color: 'red',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
