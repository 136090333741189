import React, { FC, useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { IOption } from '../../models/forms';
import { getTranslationLabel } from '../../utils/utils';

interface CheckboxSectionProps {
  sectionKey: string;
  sectionLabel?: string;
  loPossibleItems: IOption[];
  loCheckedItems: string[];
  onChangeItem: (
    event: React.ChangeEvent<HTMLInputElement>,
    label?: string
  ) => void;
  onChangeSection?: (
    event: React.ChangeEvent<HTMLInputElement>,
    sectionContents: IOption[]
  ) => void;
}

const CheckboxSection: FC<CheckboxSectionProps> = ({
  sectionKey,
  sectionLabel,
  loPossibleItems,
  loCheckedItems,
  onChangeItem,
  onChangeSection,
}) => {
  const { t } = useTranslation(allNamespaces);
  const [isAllSelected, setIsAllSelected] = useState(
    loPossibleItems.length === loCheckedItems.length
  );

  useEffect(() => {
    const checkIsAllSelected = loPossibleItems
      ? loPossibleItems.reduce(
          (result, item) => result && loCheckedItems.includes(item.key),
          true
        )
      : false;
    setIsAllSelected(checkIsAllSelected);
  }, [loCheckedItems, loPossibleItems]);

  return (
    <>
      {onChangeSection && <Divider />}
      {sectionLabel && (
        <FormLabel
          component="legend"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          {getTranslationLabel(
            'assessmentsGeneral/categoryTitles',
            sectionKey,
            t
          )}
        </FormLabel>
      )}

      <FormGroup style={{ paddingBottom: '15px' }}>
        {onChangeSection && isAllSelected !== undefined && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllSelected}
                onChange={(event) => onChangeSection(event, loPossibleItems)}
                name={sectionKey}
                checkedIcon={<AddBoxIcon />}
                icon={<AddBoxOutlinedIcon />}
              />
            }
            label={getTranslationLabel('generalNs', 'selectAll', t)}
            style={{ paddingBottom: '10px' }}
          />
        )}

        {loPossibleItems.map((option) => (
          <FormControlLabel
            key={option.key}
            control={
              <Checkbox
                checked={loCheckedItems.includes(option.key)}
                onChange={(event) => onChangeItem(event, option.label)}
                name={option.key}
              />
            }
            label={
              sectionKey !== 'customGroups'
                ? getTranslationLabel(
                    'assessmentsGeneral/assessmentTitles',
                    option.key,
                    t
                  )
                : option.label
            }
          />
        ))}
      </FormGroup>
    </>
  );
};

export default CheckboxSection;
