import { FieldsLayout, MainHeadingItem } from '../../../models/forms';
import {
  PhysicalResults,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { capitalizeFirstLetter } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const getWhisperedVoiceTestFields = (whichEar: string): FieldsLayout[] => [
  {
    label: `Can patient hear with the ${whichEar} ear?`,
    key: `canPatientHear${capitalizeFirstLetter(whichEar)}Ear`,
    fieldType: 'RADIO',
    options: YES_NO,
  },
  {
    label: 'Number of letters whispered',
    key: `numberOfLettersWhispered${capitalizeFirstLetter(whichEar)}Ear`,
    fieldType: 'INPUT-NUM',
  },
  {
    label: 'Number of letters repeated correctly',
    key: `numberOfLettersRepeated${capitalizeFirstLetter(whichEar)}Ear`,
    fieldType: 'INPUT-NUM',
  },
];

const whisperedVoiceTest: MainHeadingItem = {
  label: 'Whispered Voice Test',
  key: 'whisperedVoiceTest',
  subheadings: [
    {
      key: 'betterEar',
      label: 'Better ear',
      fields: [...getWhisperedVoiceTestFields('better')],
    },
    {
      key: 'impairedEar',
      label: 'Impaired ear',
      fields: [...getWhisperedVoiceTestFields('impaired')],
    },
    {
      key: 'results',
      label: 'Results:',
      fields: PhysicalResults,
    },
  ],
};

export default whisperedVoiceTest;
