import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getIntersectionOptions } from './utils';

const ADJUSTMENT_TO_STIMULI_SIGNS_UNCONTROLLED_INTRSC = [
  'Failed to notice the intersection',
  'Failed to yield the right of way',
  'Stopped at incorrect time',
];
const ADJUSTMENT_TO_STIMULI_SIGNS_UNCONTROLLED_INTRSC_OPTIONS =
  getOptionsFromLabels(ADJUSTMENT_TO_STIMULI_SIGNS_UNCONTROLLED_INTRSC);

const UNCONTROLLED_INTERSECTION: SubHeadingItem = {
  key: 'uncontrolledIntersection',
  label: 'Uncontrolled Intersection',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'uncontrolledIntersectionAdjustmentToStimuliTrafficSigns',
      fieldType: 'CHECKBOX',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_UNCONTROLLED_INTRSC_OPTIONS,
    },
    ...getIntersectionOptions('uncontrolledIntersection'),
  ],
};

export default UNCONTROLLED_INTERSECTION;
