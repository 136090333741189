import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, IOption, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
  getNumericOptionsXToY,
  getOptionsFromLabelsEnumaratedKeys,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const CASE_SCORES_01_OPTIONS: IOption[] = getNumericOptionsXToY(0, 1);
const CASE_SCORES_012_OPTIONS: IOption[] = getNumericOptionsXToY(0, 2);

const CASE_SCORES_SELECTION_LABEL: string =
  'Select the responses answered correctly:';

const TEMPORAL_ORIENTATION_QUESTION_LABELS: string[] = [
  'Approximate time',
  'Time elapsed since beginning of interview',
  'Date of birth',
  'Age',
  'Year of birth',
];

const TEMPORAL_ORIENTATION_QUESTION_OPTIONS =
  TEMPORAL_ORIENTATION_QUESTION_LABELS.map((item) => ({
    key: `knows${capitalizeFirstLetter(convertLabelToKey(item))}`,
    val: `knows${capitalizeFirstLetter(convertLabelToKey(item))}`,
    label: item,
  }));

const SPATIAL_ORIENTATION_QUESTION_LABELS: string[] = [
  'Place of interview',
  'Place of birth',
  'Description to grocery store',
  'Canada relative location',
];

const SPATIAL_ORIENTATION_QUESTION_OPTIONS =
  SPATIAL_ORIENTATION_QUESTION_LABELS.map((item) => ({
    key: `knows${capitalizeFirstLetter(convertLabelToKey(item))}`,
    val: `knows${capitalizeFirstLetter(convertLabelToKey(item))}`,
    label: item,
  }));

const CALCULATION_LABELS: string[] = [
  '2 + 9',
  '27 - 5',
  '21 x 4',
  '14 + 7',
  '32 - 13',
  '43 x 12',
];

const STORY_OF_MRS_WEST_PLOT_POINTS: string[] = [
  'Mrs West',
  'found',
  'a little dog',
  ' on the street.',
  'It felt sick',
  'so she took it to the veterinarians',
  'The medication',
  'was expensive.',
  'Her children told her',
  'she should get rid of that dog',
  'but she kept it anyhow',
  'One night,',
  'there was a fire.',
  'The firemen range the doorbell',
  `but Mrs. West didn't hear it`,
  'It was the dog that woke her up',
  'by licking her face.',
  'The dog saved her life.',
  'To reward the dog,',
  'she gave it a big bone.',
];

const CUED_RECALL_QUESTION_LABELS: string[] = [
  'What is the womans name?',
  'What did she find on the street?',
  'What did she do with it?',
  'What did her children say?',
  'What event happened one night?',
  'What did the dog do?',
  'What reward did she give the dog?',
];

const getRecallFields = (typeOfRecall: string): FieldsLayout[] => [
  {
    key: `${typeOfRecall}MemoryForDesigns`,
    label: 'Memory for designs',
    fieldType: 'CHECKBOX',
    options: [
      {
        key: `${typeOfRecall}MemoryForDesignsDesignOne`,
        val: `${typeOfRecall}MemoryForDesignsDesignOne`,
        label: 'Design 1',
      },
      {
        key: `${typeOfRecall}MemoryForDesignsDesignTwo`,
        val: `${typeOfRecall}MemoryForDesignsDesignTwo`,
        label: 'Design 2',
      },
      {
        key: `${typeOfRecall}MemoryForDesignsOthers`,
        val: `${typeOfRecall}MemoryForDesignsOthers`,
        label: 'Others',
      },
    ],
    conditionals: [
      {
        label: 'Specify',
        key: `${typeOfRecall}OthersSpecify`,
        fieldType: 'INPUT',
        condition: (values: any) =>
          values[`${typeOfRecall}MemoryForDesigns`] &&
          values[`${typeOfRecall}MemoryForDesigns`].includes('others'),
      },
    ],
  },
  {
    key: `${typeOfRecall}StoryOfMrsWest`,
    label: 'Story of Mrs West',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabelsEnumaratedKeys(
      STORY_OF_MRS_WEST_PLOT_POINTS,
      `${typeOfRecall}StoryOfMrsWest`
    ),
  },
  {
    key: `${typeOfRecall}SpontaneousRecallText`,
    label: 'Spontaneous recall',
    fieldType: 'TEXT-AREA',
  },
  {
    key: `${typeOfRecall}SpontaneousRecallRawScore`,
    label: 'Spontaneous recall raw score',
    fieldType: 'CHOICE_CHIPS',
    options: [
      {
        key: 'zeroToTwo',
        val: '0',
        label: '0: 0 to 2',
      },
      {
        key: 'threeToFive',
        val: '1',
        label: '1: 3 to 5',
      },
      {
        key: 'sixOrSeven',
        val: '2',
        label: '2: 6 or 7',
      },
      {
        key: 'eightPlus',
        val: '3',
        label: ' 3: 8 and +',
      },
    ],
  },
  {
    key: `${typeOfRecall}cuedRecallAnswers`,
    label: CASE_SCORES_SELECTION_LABEL,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabelsEnumaratedKeys(
      CUED_RECALL_QUESTION_LABELS,
      `${typeOfRecall}CuedRecall`
    ),
  },
];

const VERBAL_FLUENCY_RAW_SCORE_OPTIONS: IOption[] = [
  {
    key: 'zeroToThirteen',
    val: '0',
    label: '0 - 13',
  },
  {
    key: 'fourteenToTwenty',
    val: '1',
    label: '14 - 20',
  },
  {
    key: 'twentyOneToTwentySeven',
    val: '2',
    label: '21 - 27',
  },
  {
    key: 'TwentyEightPlus',
    val: '3',
    label: ' 28 and above',
  },
];

const NAMING_QUESTIONS: string[] = [
  'Paint brush',
  'Pot',
  'Mushroom',
  'Wheel barrow',
  'Stepladder',
  'Sword',
  'Skate',
  'Flag',
  'Anchor',
  'Kite',
  'Whistle',
];

const REMOTE_MEMORY_QUESTION_LABELS: string[] = [
  'Mothers name',
  'Fathers occupation',
  'Parents cause of death',
  'One school attended',
  'Occupation',
  'Country the first atomic bomb was dropped on',
  'Who was Ed Sullivan',
  'Two former American presidents / Prime-Ministers of Canada',
  'Name of the Americal president killed in 1963',
  'German leader during World War II',
];

const VISUAL_QUESTION_LABELS: string[] = [
  'Picture of two bulls',
  'Horn',
  'Eye',
];

const FRAGMENTED_PICTURES_LABELS: string[] = [
  'Scissors',
  'Magnifying glass',
  'Rocking chair',
  'Light bulb',
  'Trumpet or cornet',
  'Shoe',
];

const FRUITS: string[] = [
  'Apple',
  'Banana',
  'Pear',
  'Grapes',
  'Grapefruit or other citrus fruit',
];

const AUDITORY_LABELS: string[] = [`Knocking on the table`, `Hand clapping`];

const APRAXIA_II: string[] = [
  'Pretend to cut paper',
  'Pretend to brush your teeth',
  'Military salute',
  'Applaude',
];

const CONSTRUCTIONAL: string[] = ['Stair design', 'Cube design'];

// TODO: Autocalculate scores (see 'Assessment Papers'>CASE.pdf in our shared folder)
const cognitiveAssessmentScaleForTheElderly: MainHeadingItem = {
  label: 'Cognitive Assessment Scale for the Elderly (CASE)',
  key: 'cognitiveAssessmentScaleForTheElderly',
  // TODO: calculate score automatically for each section
  subheadings: [
    {
      key: 'temporalOrientation',
      label: '1. Temporal Orientation',
      fields: [
        {
          key: 'temporalOrientationAnswers',
          label: CASE_SCORES_SELECTION_LABEL,
          fieldType: 'CHECKBOX',
          options: TEMPORAL_ORIENTATION_QUESTION_OPTIONS,
        },
      ],
    },
    {
      key: 'spatialOrientation',
      label: '2. Spatial Orientation',
      fields: [
        {
          key: 'spatialOrientationAnswers',
          label: CASE_SCORES_SELECTION_LABEL,
          fieldType: 'CHECKBOX',
          options: SPATIAL_ORIENTATION_QUESTION_OPTIONS,
        },
      ],
    },
    {
      key: 'attentionConcentrationAndCalculaton',
      label: '3. Attention-Concentration and Calculaton',
      fields: [
        {
          key: 'calculationQuestions',
          label: CASE_SCORES_SELECTION_LABEL,
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            CALCULATION_LABELS,
            'Caculation'
          ),
        },
        {
          key: 'groceryChange',
          label: `If you go to the grocery store, buy a loaf of bread that costs $ 1.35 and you pay 
            with a $5 bill, how much change would you get?`,
          fieldType: 'CHECKBOX',
          options: [
            {
              key: 'groceryChangePartialPoints',
              val: 'groceryChangePartialPoints',
              label: '$3.00 to $3.99',
            },
            {
              key: 'groceryChangeFullPoints',
              val: 'groceryChangeFullPoints',
              label: '$3.65',
            },
            {
              key: 'groceryChangeZeroPoints',
              val: 'groceryChangeZeroPoints',
              label: 'Other',
            },
          ],
        },
      ],
    },
    {
      key: 'immediateRecall',
      label: '4. Immediate Recall',
      fields: [...getRecallFields('immediateRecall')],
    },
    {
      key: 'language',
      label: '5. Language',
      fields: [
        {
          key: 'verbalFluencyColorsText',
          label: 'Verbal fluency - colors (1 minute)',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'verbalFluencyAnimalsText',
          label: 'Verbal fluency - animals (1 minute)',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'verbalFluencyAnimalsRawScore',
          label: 'Raw score - colors + animals',
          fieldType: 'CHOICE_CHIPS',
          options: VERBAL_FLUENCY_RAW_SCORE_OPTIONS,
        },
        {
          key: 'naming',
          label: 'Naming - select correct answers',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            NAMING_QUESTIONS,
            'Naming'
          ),
        },
      ],
    },
    {
      key: 'remoteMemory',
      label: '6. Remote Memory',
      fields: [
        {
          key: 'remoteMemoryAnswers',
          label: CASE_SCORES_SELECTION_LABEL,
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            REMOTE_MEMORY_QUESTION_LABELS,
            'RemoteMemory'
          ),
        },
      ],
    },
    {
      key: 'judgementAndAbstraction',
      label: '7. Judgement and Abstraction',
      fields: [
        {
          key: 'judgementLabel',
          label: 'Judgement',
          fieldType: 'LABEL',
        },
        {
          key: 'judgementScore',
          label: `If there is a fire at home and you discover it first, 
          what would you do if you can't extinguish it ?`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_012_OPTIONS,
        },
        {
          key: 'anticipationLabel',
          label: 'Anticipation',
          fieldType: 'LABEL',
        },
        {
          key: 'anticipationScore',
          label: `Picture of "Woman walking on the sidewalk"`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_01_OPTIONS,
        },
        {
          key: 'similaritesLabel',
          label: 'Similarities',
          fieldType: 'LABEL',
        },
        {
          key: 'similaritiesScore',
          label: `If an apple and a banana are both fruits, 
          in which way are a trumpet and a drum alike?`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_01_OPTIONS,
        },
        {
          key: 'conceptualShiftingLabel',
          label: 'Conceptual shifting',
          fieldType: 'LABEL',
        },
        {
          key: 'conceptualShiftingComment',
          label: `Fruits - animals (1 minute)`,
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'conceptualShiftingRawScore',
          label: 'Fruits - animals (1 minute) raw score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 7</Typography>,
        },
        {
          key: 'abstractionProverbsLabel',
          label: 'Abstraction - proverbs',
          fieldType: 'LABEL',
        },
        {
          key: 'proverbYouCanNotTellABookByItsCover',
          label: `"You can't tell a book by it's cover"`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_01_OPTIONS,
        },
        {
          key: 'proverbStrikeWhileTheIronIsHot',
          label: `"Strike while the iron is hot"`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_01_OPTIONS,
        },
        {
          key: 'insightLabel',
          label: 'Insight',
          fieldType: 'LABEL',
        },
        {
          key: 'memoryInsightAsGoodAsTenYearsAgo',
          label: 'Is your memory as good as it was ten years ago?',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'memoryInsightScore',
          label: `Memory insight score`,
          fieldType: 'CHOICE_CHIPS',
          options: CASE_SCORES_012_OPTIONS,
        },
      ],
    },

    {
      key: 'agnosia',
      label: '8. Agnosia',
      fields: [
        {
          key: 'visualQuestions',
          label: 'Visual',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            VISUAL_QUESTION_LABELS,
            'VisualQuestions'
          ),
        },
        {
          key: 'fragmentedPicturesCheckbox',
          label: 'Fragmented Pictures',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            FRAGMENTED_PICTURES_LABELS,
            'FragmentedPictures'
          ),
        },
        {
          key: 'fruitsCheckbox',
          label: 'Fruits',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(FRUITS, 'FruitsOptions'),
        },

        {
          key: 'auditoryQuestions',
          label: 'Auditory',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            AUDITORY_LABELS,
            'AuditoryQuestions'
          ),
        },
      ],
    },
    {
      key: 'apraxia',
      label: '9. Apraxia',
      fields: [
        {
          key: 'ideationalIdeomotorCheckbox',
          label: 'Ideational-Ideomotor',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(APRAXIA_II, 'ApraxiaII'),
        },
        {
          key: 'constructionalCheckbox',
          label: 'Constructional',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabelsEnumaratedKeys(
            CONSTRUCTIONAL,
            'Constructional'
          ),
        },
        {
          key: 'clockDrawScore',
          label: 'Clock draw score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 3</Typography>,
        },
        {
          key: 'cASEClockDrawingFile',
          label: 'clock drawing',
          fieldType: 'UPLOAD',
        },
      ],
    },
    {
      key: 'recentMemory',
      label: '10. Recent Memory',
      fields: [...getRecallFields('recentMemory')],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          key: 'totalScore',
          label: 'Total score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 100</Typography>,
        },
        ...Results,
      ],
    },
  ],
};

export default cognitiveAssessmentScaleForTheElderly;
