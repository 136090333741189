import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import theme from '../../../theme';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../BasicDialog';
import AssessmentOptionsList from './AssessmentOptionsList';

const CustomizeMyList = () => {
  const { t } = useTranslation(allNamespaces);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenCustomizeMyList = () => {
    setIsModalOpen(true);
  };
  const handleCloseCustomizeMyList = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid>
      <Button
        aria-label="customize"
        onClick={handleOpenCustomizeMyList}
        variant="outlined"
        size="small"
      >
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'customizeMyList',
          t
        )}
      </Button>

      <BasicDialog
        open={isModalOpen}
        handleClose={handleCloseCustomizeMyList}
        label={
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Typography variant="h1" component="span">
                {getTranslationLabel(
                  'assessmentsGeneral/assessClient',
                  'customizeMyAssessmentTools',
                  t
                )}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleCloseCustomizeMyList}
              >
                <ClearIcon color="primary" fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        }
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'selectAssessments',
            t
          )}
        </Typography>
        <Grid item container direction="row">
          <AssessmentOptionsList colSize={4} />
        </Grid>
      </BasicDialog>
    </Grid>
  );
};

export default CustomizeMyList;
