export interface IResult {
  clientId?: string;
  clinicalAssessmentOverallResult: string;
  commentsResults: string;
  commentsClinicalAssessmentResults: string;
  discussedWithPatient: string;
  drivingNotRecommendedFunctionalDeficits: string;
  drivingNotRecommendedMedicalDeficits: string;
  drivingNotRecommendedVisualDeficits: string;
  functionalDrivingSkills: string;
  functionalDrivingSkillsPoorDrivingHabits: string;
  noviceDriverTrainingRequired: string;
  rehabilitationCandidate: string;
  temproraryDiversionLicence: string;
}

export const blankResult: IResult = {
  clinicalAssessmentOverallResult: '',
  commentsResults: '',
  commentsClinicalAssessmentResults: '',
  discussedWithPatient: '',
  drivingNotRecommendedFunctionalDeficits: '',
  drivingNotRecommendedMedicalDeficits: '',
  drivingNotRecommendedVisualDeficits: '',
  functionalDrivingSkills: '',
  functionalDrivingSkillsPoorDrivingHabits: '',
  noviceDriverTrainingRequired: '',
  rehabilitationCandidate: '',
  temproraryDiversionLicence: '',
};
