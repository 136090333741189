import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOfPercent } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const snowyPicturesTest: SubHeadingItem = {
  key: 'snowyPicturesTest',
  label: 'Snowy Pictures Test (SPT)',
  fields: [...getScoreAndOutOfPercent('Snowy pictures test'), ...Results],
};

export default snowyPicturesTest;
