import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, getSpeedFields } from './utils';

const GAP_ACCEPTANCE = getOptionsFromLabels([
  'Narrow traffic gap',
  'Insufficient traffic gap - other road user affected',
]);
const VISUAL_OPTIONS = getOptionsFromLabels([
  'Missed blindspot check',
  'Failed to check mirrors',
]);

const EXIT_RAMP_ERRORS: SubHeadingItem = {
  key: 'exitRamp',
  label: 'Exit Ramp',
  fields: [
    ...getSpeedFields('exitRamp'),
    {
      label: 'Gap Acceptance',
      key: 'exitRampGapAcceptance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: GAP_ACCEPTANCE,
    },
    {
      label: 'Lane Maintenance',
      key: 'exitRampLaneMaintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Sharp turn into exit ramp',
        'Late entry',
      ]),
    },
    {
      label: 'Visual Scanning',
      key: 'exitRampVisualScanning',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: VISUAL_OPTIONS,
    },
    ...getCommonSignallingFields('exitRamp'),
  ],
};

export default EXIT_RAMP_ERRORS;
