import React from 'react';

import { Typography } from '@mui/material';

import {
  FieldsLayout,
  IOption,
  OptionOrFieldsLayout,
} from '../../../models/forms';

export const allButFirstColumn = (arr: any[]) => arr.slice(1, arr.length);
export const allButLastRow = (arr: any[]) => arr.slice(0, arr.length - 1);

export const capitalizeFirstLetter = (s: string) =>
  s ? `${s[0].toUpperCase()}${s.slice(1)}` : '';

export const lowercaseFirstLetter = (s: string) =>
  s ? `${s[0].toLowerCase()}${s.slice(1)}` : '';

export const getNumbersXToY = (x: number, y: number): number[] =>
  Array.from({ length: y - x + 1 }, (_, i) => i + x);

export const getNumericOptionsXToY = (x: number, y: number): IOption[] =>
  getNumbersXToY(x, y).map((key) => ({
    key: key.toString(),
    val: key.toString(),
    label: key.toString(),
  }));

export const convertLabelToKey = (label: string): string => {
  if (!label) return '';
  let arr = label.split(' ');
  arr = arr.map((s) => {
    const cleanedS = s.replace(/[-().,'?:&/]/g, '');
    return cleanedS.length > 0
      ? `${cleanedS[0].toUpperCase()}${cleanedS.slice(1)}`
      : '';
  });
  let key = arr.join('');
  key = `${key[0].toLowerCase()}${key.slice(1)}`;
  return key;
};

export const getKeysFromOptions = (options: OptionOrFieldsLayout[]): string[] =>
  options.map((item) => item.key);

const getOptionsFromLabelsHelper = (
  label: string,
  labelKey: string,
  prefixToAppendToKey?: string,
  suffixToAppendToKey?: string
): IOption => ({
  key: `${prefixToAppendToKey}${labelKey}${suffixToAppendToKey}`,
  val: `${prefixToAppendToKey}${labelKey}${suffixToAppendToKey}`,
  label: label.toString(),
});

export const getKeyFromLabel = (label: string, prefix?: string) =>
  prefix === ''
    ? convertLabelToKey(label)
    : capitalizeFirstLetter(convertLabelToKey(label));

export const getOptionsFromLabels = (
  labels: string[],
  suffixToAppendToKey?: string,
  prefixToAppendToKey?: string
): IOption[] => {
  const prefix = prefixToAppendToKey || '';
  const suffix = suffixToAppendToKey || '';
  return labels.map((item) => {
    const labelKey = getKeyFromLabel(item, prefix);
    return getOptionsFromLabelsHelper(item, labelKey, prefix, suffix);
  });
};

export const getOptionsFromLabelsEnumaratedKeys = (
  labels: string[],
  title: string
): IOption[] =>
  labels.map((label, i) => ({
    key: `${convertLabelToKey(title)}Option${i + 1}`,
    val: `${convertLabelToKey(title)}Option${i + 1}`,
    label: label.toString(),
  }));

export const getScoreAndOutOf = (label: string): FieldsLayout[] => [
  {
    label: `${label} score`,
    key: `${convertLabelToKey(label)}Score`,
    fieldType: 'INPUT-NUM',
  },
  {
    label: `${label} score out of`,
    key: `${convertLabelToKey(label)}ScoreOutOf`,
    fieldType: 'INPUT-NUM',
  },
];

export const getScoreAndOutOfPercent = (label: string): FieldsLayout[] => [
  ...getScoreAndOutOf(label),
  {
    label: `${label} score percent`,
    key: `${convertLabelToKey(label)}Percent`,
    fieldType: 'INPUT-NUM',
    units: <Typography>% </Typography>,
    valueDependsOn: [
      `${convertLabelToKey(label)}Score`,
      `${convertLabelToKey(label)}ScoreOutOf`,
    ],
  },
];

export const getLanguageField = (): FieldsLayout => ({
  label: 'Language assessment completed in',
  key: 'languageAssessmentCompletedIn',
  fieldType: 'RADIO',
  options: [
    {
      key: 'english',
      val: 'english',
      label: 'English',
    },
    {
      key: 'french',
      val: 'french',
      label: 'French',
    },
    {
      key: 'other',
      val: 'other',
      label: 'Other (please specify)',
    },
  ],
});

export const getRef = (text: string) => (
  <Typography variant="h4">{text}</Typography>
);

export const isThereAnyUnsavedData = (currentData: any, savedData: any) => {
  // We don't have anything to compare to, but if this is indeed a blank assessment, we need to not set the model open
  if (!savedData) {
    return false;
  }

  // remove file and switch fields from current data
  const fileSwitchFields = Object.keys(currentData).filter(
    (key) => key.slice(-4) === 'File' || key.slice(-6) === 'Switch'
  );
  const currentDataFiltered = currentData;
  fileSwitchFields.forEach((key) => delete currentDataFiltered[key]);

  // remove switch fields from saved data
  const switchFields = Object.keys(savedData).filter(
    (key) => key.slice(-6) === 'Switch'
  );
  const savedDataFiltered = savedData;
  switchFields.forEach((key) => delete savedDataFiltered[key]);

  return (
    JSON.stringify(currentDataFiltered) !== JSON.stringify(savedDataFiltered)
  );
};
