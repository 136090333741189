import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const digitSpanTest: SubHeadingItem = {
  key: 'digitSpanTest',
  label: 'Digit Span Test',
  fields: [
    {
      label: 'Number of digits recalled (forward)',
      key: 'numberOfDigitsRecalledForward',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of digits recalled (backward)',
      key: 'numberOfDigitsRecalledBackward',
      fieldType: 'INPUT-NUM',
    },
    ...Results,
  ],
};

export default digitSpanTest;
