import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const functionalIndependenceMeasure: SubHeadingItem = {
  key: 'functionalIndependenceMeasure',
  label: 'Functional Independence Measure',
  fields: [
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 126 points</Typography>,
    },
    ...Results,
  ],
};

export default functionalIndependenceMeasure;
