import React, { FC, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import AsyncErrorWrapper from '../../../../errors/AsyncErrorWrapper';
import { useDeleteCustomGroup } from '../../../../hooks/database/settings/customGroups';
import useModal from '../../../../hooks/useModal';
import { ICustomGroup } from '../../../../models/assessments/assessments';
import theme from '../../../../theme';
import { getTranslationLabel, useUsername } from '../../../../utils/utils';
import BasicDialog from '../../../common/BasicDialog';
import LoadingButton from '../../../common/LoadingButton';
import EditCustomGroupForm from './EditCustomGroupForm';

export interface AssessmentGroupRowProps {
  group: ICustomGroup;
}

export const AssessmentGroupRow: FC<AssessmentGroupRowProps> = ({ group }) => {
  const { t } = useTranslation(allNamespaces);
  const username = useUsername();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const confirmationModal = useModal();
  const deleteAsmtGroupMutation = useDeleteCustomGroup(username);

  const handleCloseEditGroup = () => {
    setIsModalOpen(false);
  };
  const handleOpenEditGroup = () => {
    setIsModalOpen(true);
  };

  const handleCloseDeleteGroup = () => {
    confirmationModal.close();
  };

  const confirmDeleteGroup = () => {
    confirmationModal.show();
  };

  const handleDeleteGroup = () => {
    deleteAsmtGroupMutation.mutate(group.groupKey);
  };

  return (
    <AsyncErrorWrapper
      mutation={deleteAsmtGroupMutation}
      loadingComp={<tr />}
      errorMessage="The assessment group could not be deleted"
      successMessage="Assessment group successfully deleted!"
      onSuccess={handleCloseDeleteGroup}
    >
      <TableRow key={group.groupKey}>
        <TableCell key={group.groupKey}>{group.groupLabel}</TableCell>

        <TableCell>
          <Grid item>
            <Button variant="text" size="small" onClick={handleOpenEditGroup}>
              {getTranslationLabel('generalNs', 'edit', t)}
            </Button>
            <Button variant="text" size="small" onClick={confirmDeleteGroup}>
              {getTranslationLabel('generalNs', 'delete', t)}
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
      <Dialog open={confirmationModal.isShowing} fullWidth={false}>
        <DialogTitle>
          <Typography variant="h2" component="span">
            {getTranslationLabel('settings', 'deleteCustomGroup1', t)}{' '}
            {group.groupLabel}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {getTranslationLabel('settings', 'deleteCustomGroup2', t)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={deleteAsmtGroupMutation.isLoading}>
            <Button onClick={handleCloseDeleteGroup}>
              {getTranslationLabel('generalNs', 'cancel', t)}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleDeleteGroup}
            >
              {getTranslationLabel('generalNs', 'delete', t)}
            </Button>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <BasicDialog
        open={isModalOpen}
        handleClose={handleCloseEditGroup}
        label={
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Typography variant="h1" component="span">
                {getTranslationLabel('settings', 'editCustomGroup1', t)}{' '}
                {group.groupLabel}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleCloseEditGroup}
              >
                <ClearIcon color="primary" fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        }
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      >
        <DialogContent>
          <EditCustomGroupForm
            handleClose={handleCloseEditGroup}
            group={group}
          />
        </DialogContent>
      </BasicDialog>
    </AsyncErrorWrapper>
  );
};
