import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getIntersectionOptions } from './utils';

const ADJUSTMENT_TO_STIMULI_SIGNS_PEDESTRIAN_CROSSWALK = [
  'Failed to notice the crossing',
  'Failed to yield the right of way',
  'Stopped at incorrect time',
];

const ADJUSTMENT_TO_STIMULI_SIGNS_PEDESTRIAN_CROSSWALK_OPTIONS =
  getOptionsFromLabels(ADJUSTMENT_TO_STIMULI_SIGNS_PEDESTRIAN_CROSSWALK);

const TYPE_OF_PEDESTRIAN_CROSSWALK = ['With signals', 'Without signals'];

export const PEDESTRIAN_CROSSWALK_META: SubHeadingItem = {
  key: 'pedestrianCrosswalk',
  label: 'Pedestrian Crosswalk',
  fields: [
    {
      label: 'Type of Pedestrian Crosswalk',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(TYPE_OF_PEDESTRIAN_CROSSWALK),
    },
  ],
};

const PEDESTRIAN_CROSSWALK: SubHeadingItem = {
  key: 'pedestrianCrosswalk',
  label: 'Pedestrian Crosswalk',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'pedestrianCrossingAdjustmentToStimuliTrafficSigns',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_PEDESTRIAN_CROSSWALK_OPTIONS,
    },
    ...getIntersectionOptions('pedestrianCrosswalk'),
  ],
};

export default PEDESTRIAN_CROSSWALK;
