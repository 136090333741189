import React from 'react';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { styled, Typography } from '@mui/material';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import i18next from 'i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';

import allNamespaces from './allNamespaces';
import ClientsView from './components/clients/ClientsView';
import SideBar from './components/common/SideBar';
import Help from './components/help/Help';
import ClientRoutes from './components/routers/ClientRoutes';
import SettingsRoutes from './components/routers/SettingsRoutes';
import GenericClientsFallback from './errors/fallbacks/GenericClientsFallback';
import NoMatch from './errors/NoMatch';
import { ErrorComponent, LoadingComponent } from './errors/QueryUtils';
import { getTranslationLabel } from './utils/utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const Root = styled('div')({
  display: 'flex',
});
const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const Protected = () => {
  i18next.loadNamespaces(allNamespaces);
  const { t } = useTranslation(allNamespaces);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <Root>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <SideBar />
          <Main>
            <Switch>
              <Route exact path="/">
                <Redirect to="/clients" />
              </Route>
              <Route exact path="/clients">
                <ErrorBoundary FallbackComponent={GenericClientsFallback}>
                  <ClientsView />
                </ErrorBoundary>
              </Route>
              <Route path="/clients">
                <ErrorBoundary FallbackComponent={GenericClientsFallback}>
                  <ClientRoutes />
                </ErrorBoundary>
              </Route>
              <Route path="/help">
                <Help />
              </Route>
              <Route path="/allocateerror">
                <Typography>
                  {getTranslationLabel('errors', 'allocate', t)}
                </Typography>
              </Route>
              <Route path="/user/settings">
                <SettingsRoutes />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Main>
          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
      </Root>
    </MsalAuthenticationTemplate>
  );
};

export default Protected;
