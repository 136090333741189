import { Conditional } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const otherLanguage = (values: any) => values.primaryLanguage === 'other';
const nonBinaryGender = (values: any) => values.gender === 'nonbinary';
const attendedAssessmentWithSomeone = (values: any) =>
  values.attendedAssessmentWithSomeone === 'yes';
const otherAttendee = (values: any) =>
  values.attendedAssessmentWithSomeone === 'yes' &&
  (values.attendedAssessmentWithOtherAttendee === true ||
    values.attendedAssessmentWithOtherAttendee === '1');
const itDepends = (values: any) =>
  values.attendedAssessmentWithSomeone === 'yes' &&
  (values.supportPersonPerceptionOnDriving === 'itDepends' ||
    values.supportPersonPerceptionOnDriving === 'no');
const needAnInterpreter = (values: any) => values.needAnInterpreter === 'yes';

export const ATTEND_WITH_ANYONE_CONDITIONAL: Conditional[] = [
  {
    label: 'Who did they attend the assessment with?',
    key: 'whoAttendAssessmentWith',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(
      [
        'Partner',
        'Son',
        'Daughter',
        'Father',
        'Mother',
        'Sibling',
        'Friend',
        'Neighbour',
        'Other attendee',
      ],
      undefined,
      'attendedAssessmentWith'
    ),
    condition: attendedAssessmentWithSomeone,
  },
  {
    key: 'otherAttendeeSpecify',
    label: "Support person's relationship",
    fieldType: 'INPUT',
    condition: otherAttendee,
  },
  {
    label: 'The support person was present for',
    key: 'supportPersonPresentFor',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(
      ['Consent', 'Initial interview', 'Clinical assessments', 'Debriefing'],
      undefined,
      'supportPersonPresentFor'
    ),
    condition: attendedAssessmentWithSomeone,
  },
  {
    label: 'Does the support person believe the client should be driving?',
    key: 'supportPersonPerceptionOnDriving',
    fieldType: 'RADIO',
    options: getOptionsFromLabels(['Yes', 'No', 'It depends']),
    condition: attendedAssessmentWithSomeone,
  },
  {
    key: 'supportPersonPerceptionOnDrivingComment',
    label: 'Please provide details',
    fieldType: 'TEXT-AREA',
    condition: itDepends,
  },
];

export const GENDER_CONDITIONAL: Conditional[] = [
  {
    key: 'nonBinaryGender',
    label: `Gender identity (if provided)`,
    fieldType: 'INPUT',
    condition: nonBinaryGender,
  },
];

export const LANGUAGE_CONDITIONAL: Conditional[] = [
  {
    key: 'otherLanguageSpecify',
    label: 'Please specify other language',
    fieldType: 'INPUT',
    condition: otherLanguage,
  },
];

export const INTERPRETER_CONDITIONAL: Conditional[] = [
  {
    key: 'interpreterDetails',
    label: 'Interpreter details',
    fieldType: 'TEXT-AREA',
    condition: needAnInterpreter,
  },
];
