import React from 'react';

import { Typography } from '@mui/material';

import { NameScore } from '../../../models/assessments/assessments';
import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getNumbersXToY } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const MODIFIED_TOKEN_TEST_CATEGORIES: NameScore[] = [
  { name: 'Part 1 (All tokens)', score: 7 },
  { name: 'Part 2 (Only large tokens)', score: 4 },
  { name: 'Part 3 (All tokens)', score: 4 },
  { name: 'Part 4 (Only large tokens)', score: 4 },
  { name: 'Part 5 (All tokens)', score: 4 },
  { name: 'Part 6 (Only large tokens)', score: 13 },
];

const MODIFIED_TOKEN_TEST_FIELDS = MODIFIED_TOKEN_TEST_CATEGORIES.map(
  (item, i) => ({
    label: item.name,
    key: `part${i + 1}Score`,
    fieldType: 'INPUT-NUM' as FieldTypes,
    units: <Typography>/ {item.score} points</Typography>,
  })
);

const modifiedTokenTest: SubHeadingItem = {
  key: 'modifiedTokenTest',
  label: 'Modified Token Test (Shortened Version)',
  fields: [
    ...MODIFIED_TOKEN_TEST_FIELDS,
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 36 points</Typography>,
      valueDependsOn: getNumbersXToY(1, 6).map((no) => `part${no}Score`),
    },
    ...Results,
  ],
};

export default modifiedTokenTest;
