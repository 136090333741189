import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const doubleLetterCancellation: SubHeadingItem = {
  key: 'doubleLetterCancellation',
  label: 'Double Letter Cancellation Task (DLCT)',
  fields: [
    {
      label: 'Number of Cs missed on the left',
      key: 'numberOfCsMissedLeft',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of Es missed on the left',
      key: 'numberOfEsMissedLeft',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of Cs missed on the right',
      key: 'numberOfCsMissedRight',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of Es missed on the right',
      key: 'numberOfEsMissedRight',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Total number of letters missed',
      key: 'totalNumberOfLettersMissed',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 105 max</Typography>,
      valueDependsOn: [
        'numberOfCsMissedLeft',
        'numberOfEsMissedLeft',
        'numberOfCsMissedRight',
        'numberOfEsMissedRight',
      ],
    },
    {
      label: 'Time taken',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    ...Results,
  ],
};

export default doubleLetterCancellation;
