import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getIntersectionOptions } from './utils';

const TYPE_OF_TRAFFIC_LIGHT = [
  'Green',
  'Yellow',
  'Red',
  'Flashing green',
  'Flashing yellow',
  'Flashing red',
];

const ADJUSTMENT_TO_STIMULI_SIGNS_TRAFFIC_LIGHT = [
  'Failed to notice the traffic light',
  'Green: stopped',
  'Green: unnecessary slowing',
  'Yellow: sped up to get through',
  'Yellow: unnecessary abrupt braking',
  'Red: did not stop',
  'Red: abrupt last minute braking',
];
const ADJUSTMENT_TO_STIMULI_SIGNS_TRAFFIC_LIGHT_OPTIONS = getOptionsFromLabels(
  ADJUSTMENT_TO_STIMULI_SIGNS_TRAFFIC_LIGHT
);

export const TRAFFIC_LIGHT_META: SubHeadingItem = {
  key: 'trafficLight',
  label: 'Traffic Light',
  fields: [
    {
      label: 'Type of Traffic Light',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(TYPE_OF_TRAFFIC_LIGHT),
    },
  ],
};

const TRAFFIC_LIGHT: SubHeadingItem = {
  key: 'trafficLight',
  label: 'Traffic Light',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'trafficLightAdjustmentToStimuliTrafficSigns',
      fieldType: 'CHECKBOX',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_TRAFFIC_LIGHT_OPTIONS,
    },
    ...getIntersectionOptions('trafficLight'),
  ],
};

export default TRAFFIC_LIGHT;
