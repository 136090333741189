import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

const UNEXPECTED_EVENT_TYPES_LABELS = [
  'Pedestrian',
  'Cyclist',
  'Construction',
  'School bus',
  'Emergency vehicle',
  'Maintenance vehicle',
  'Stopped transit vehicle',
  'Road hazard',
  'Sudden weather event',
  'Vehicle malfunction',
];

export const UNEXPECTED_EVENT_ERRORS: SubHeadingItem = {
  key: 'unexpectedEvent',
  label: 'Unexpected Event',
  fields: [],
};

export const UNEXPECTED_EVENT_META: SubHeadingItem = {
  key: 'unexpectedEvent',
  label: 'Unexpected Event',
  fields: [
    {
      label: 'Type of unexpected event',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(UNEXPECTED_EVENT_TYPES_LABELS),
    },
    {
      label: 'What happened?',
      key: 'eventWhatHappened',
      fieldType: 'TEXT-AREA',
    },
  ],
};
