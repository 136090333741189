import { SubHeadingItem } from '../../../models/forms';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  ATTEND_WITH_ANYONE_CONDITIONAL,
  INTERPRETER_CONDITIONAL,
  GENDER_CONDITIONAL,
  LANGUAGE_CONDITIONAL,
} from './PersonalDetailsConditionals';

const PERSONAL_DETAILS: SubHeadingItem = {
  label: 'Personal Details',
  key: 'category0',
  fields: [
    {
      label: 'Gender',
      key: 'gender',
      fieldType: 'RADIO',
      options: getOptionsFromLabels(['Male', 'Female', 'Non-binary']),
      conditionals: GENDER_CONDITIONAL,
    },
    {
      label: 'Age',
      key: 'age',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Primary language',
      key: 'primaryLanguage',
      fieldType: 'RADIO',
      options: getOptionsFromLabels(['English', 'French', 'Other']),
      conditionals: LANGUAGE_CONDITIONAL,
    },
    {
      label: 'Did they attend the assessment with anyone?',
      key: 'attendedAssessmentWithSomeone',
      fieldType: 'RADIO',
      options: YES_NO,
      conditionals: ATTEND_WITH_ANYONE_CONDITIONAL,
    },
    {
      label: 'Do they need an interpreter?',
      key: 'needAnInterpreter',
      fieldType: 'RADIO',
      options: YES_NO,
      conditionals: INTERPRETER_CONDITIONAL,
    },
  ],
};

export default PERSONAL_DETAILS;
