import React from 'react';

import { Typography, Grid, TableBody, Table } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import { useCustomGroups } from '../../../hooks/database/settings/customGroups';
import { getTranslationLabel, useUsername } from '../../../utils/utils';
import CustomizeMyList from '../../common/Assessments/CustomizeMyList';
import { AssessmentGroupRow } from './CustomGroups/AssessmentGroupRow';
import NewCustomGroup from './CustomGroups/NewCustomGroup';
import { isDefaultGroup } from './CustomGroups/utils';

const AssessmentSettings = () => {
  const { t } = useTranslation(allNamespaces);
  const username = useUsername();
  const {
    isLoading,
    isError,
    error,
    data: asmtGroups,
  } = useCustomGroups(username);

  if (isLoading) <LoadingComponent />;

  if (isError)
    <ErrorComponent
      error={error}
      errorMessage={getTranslationLabel(
        'settings',
        'customGroupCannotBeFound',
        t
      )}
    />;

  return (
    <Grid item container direction="column" spacing={3} alignItems="baseline">
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel('settings', 'clinicalAssessmentSettings', t)}
        </Typography>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h3">
            {getTranslationLabel('settings', 'allClinicalAssessments', t)}
          </Typography>
        </Grid>
        <Grid item>
          <CustomizeMyList />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h3">
            {getTranslationLabel('settings', 'myCustomAssessmentGroups', t)}
          </Typography>
        </Grid>
        <Grid item>
          <NewCustomGroup />
        </Grid>
      </Grid>
      <Table aria-label="simple table" style={{ width: 400 }}>
        <TableBody>
          {asmtGroups?.reduce((result, group) => {
            if (!isDefaultGroup(group.groupKey)) {
              result.push(
                <AssessmentGroupRow
                  key={`${group.groupKey}Row`}
                  group={group}
                />
              );
            }
            return result;
          }, [] as JSX.Element[])}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default AssessmentSettings;
