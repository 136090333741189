import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getNumbersXToY,
  getOptionsFromLabels,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

export const WNL_IMPAIRED_OTIONS = getOptionsFromLabels([
  'Within normal limits',
  'Impaired',
]);

// https://www.sralab.org/rehabilitation-measures/motor-free-visual-perception-test
// https://strokengine.ca/en/assessments/motor-free-visual-perception-test-mvpt/
// Note: In the future, this battery could be expanded to have fields that record results
// for each of the 36 items and to have automatic caclculation of the score, average time and range
// based on the values entered into those fields.
const MVPT_CATEGORIES = [
  {
    name: 'Visual Discrimination / Spatial Relationship',
    startNo: 1,
    endNo: 3,
  },
  { name: 'Figure-Ground', startNo: 4, endNo: 8 },
  { name: 'Form Constancy', startNo: 9, endNo: 13 },
  { name: 'Visual Memory', startNo: 14, endNo: 21 },
  { name: 'Visual analysis and Synthesis', startNo: 22, endNo: 32 },
  {
    name: 'Spatial Relationship and Visual Discrimination',
    startNo: 33,
    endNo: 36,
  },
];

const MVPT_COLUMN_HEADINGS = ['Question Number', 'Question', 'Time'];

const getValueDependsOn = (columnHeading: string) =>
  getNumbersXToY(1, 36).map((rowTitle) =>
    getKeyWithAdditionalParameter(
      'Response',
      columnHeading,
      rowTitle.toString()
    )
  );

const getMVPTRowSpec = (key: string) => [
  {
    rowTitle: 'Question',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: getOptionsFromLabels(['A', 'B', 'C', 'D']),
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const motorFreeVisualPerceptionTest: MainHeadingItem = {
  key: 'motorFreeVisualPerceptionTest',
  label: 'Motor-Free Visual Perception Test (MVPT)',
  subheadings: [
    ...MVPT_CATEGORIES.map((item) => ({
      key: `${convertLabelToKey(item.name)}Section`,
      label: item.name,
      fields: [
        {
          key: `${item.name}Table`,
          label: '',
          fieldType: 'TABLE' as FieldTypes,
          columnHeadings: MVPT_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            MVPT_COLUMN_HEADINGS,
            getNumbersXToY(item.startNo, item.endNo).map((no) => no.toString()),
            getMVPTRowSpec,
            'Response',
            true
          ),
        },
      ],
    })),
    {
      key: 'results',
      label: 'Results',
      fields: [
        {
          key: 'mVPTTotalScore',
          label: 'TOTAL SCORE',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 36</Typography>,
          valueDependsOn: getValueDependsOn('Question'),
        },
        {
          key: 'mVPTTotalScoreNorm',
          label: 'Total score norm given age',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'mVPTTotalScoreNormInterpretation',
          label: 'Total score interpretation',
          fieldType: 'RADIO',
          options: WNL_IMPAIRED_OTIONS,
        },
        {
          key: 'mVPTTotalScorePredictiveOfFailure',
          label:
            'Is the total score predictive of failure of the on-road evaluation?',
          fieldType: 'RADIO',
          options: YES_NO,
          valueDependsOn: ['mVPTTotalScore'],
          units: getRef('Ref.: Mazer 2000'),
        },
        {
          key: 'averageResponseTime',
          label: 'AVERAGE PROCESSING TIME',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>seconds</Typography>,
          valueDependsOn: getValueDependsOn('Time'),
        },
        {
          key: 'mVPTProcessingTimeNorm',
          label: 'Processing time norm given age',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'mVPTProcessingTimeInterpretation',
          label: 'Processing time interpretation',
          fieldType: 'RADIO',
          options: WNL_IMPAIRED_OTIONS,
        },
        {
          key: 'responseTimeRangeLB',
          label: 'PROCESSING TIME LOWER BOUND',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>seconds</Typography>,
          valueDependsOn: getValueDependsOn('Time'),
        },
        {
          key: 'responseTimeRangeUB',
          label: 'PROCESSING TIME UPPER BOUND',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>seconds</Typography>,
          valueDependsOn: getValueDependsOn('Time'),
        },
        ...Results,
      ],
    },
  ],
};

export default motorFreeVisualPerceptionTest;
