import React, { FC, useCallback, useEffect, useState } from 'react';

import { Card, CardContent, Grid, useMediaQuery } from '@mui/material';

import {
  IManeuverSummary,
  IOrderedManeuverSummary,
} from '../../../../models/on-road/route';
import theme from '../../../../theme';
import StyledListCard from '../../../common/StyledListCard';
import TablePaginationComp from '../../../common/TablePaginationComp';
import AddedManeuverCard from './AddedManeuverCard';

interface AddedManeuverColumnProps {
  sortedManueverSummary: IOrderedManeuverSummary[];
  handleOpenTask: (task: IOrderedManeuverSummary) => void;
  openTask?: IOrderedManeuverSummary;
}

const AddedManeuverColumn: FC<AddedManeuverColumnProps> = ({
  sortedManueverSummary,
  handleOpenTask,
  openTask,
}) => {
  const largeScreen = useMediaQuery('(min-width:1200px)');
  const [paginationProps, setPaginationProps] = useState({
    rowsPerPage: 5,
    currentPage: 0,
  });

  const getCurrentPage = useCallback(
    (rowsPerPage: number) =>
      openTask ? Math.floor((openTask.idx - 1) / rowsPerPage) : 0,
    [openTask]
  );

  useEffect(() => {
    if (largeScreen) {
      setPaginationProps({
        rowsPerPage: 10,
        currentPage: getCurrentPage(10),
      });
    } else {
      setPaginationProps({
        rowsPerPage: 5,
        currentPage: getCurrentPage(5),
      });
    }
  }, [getCurrentPage, largeScreen]);

  const isSelected = (maneuver: IManeuverSummary) =>
    maneuver.maneuverId !== undefined &&
    maneuver.maneuverId !== null &&
    openTask?.maneuverId !== undefined &&
    maneuver.maneuverId === openTask.maneuverId;

  const emptyRows = sortedManueverSummary
    ? paginationProps.rowsPerPage -
      Math.min(
        paginationProps.rowsPerPage,
        sortedManueverSummary.length -
          paginationProps.currentPage * paginationProps.rowsPerPage
      )
    : paginationProps.rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPaginationProps({
      rowsPerPage: paginationProps.rowsPerPage,
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const no = parseInt(event.target.value, 10);
    setPaginationProps({
      rowsPerPage: no,
      currentPage: getCurrentPage(no),
    });
  };

  return (
    <Grid item container direction="column" sx={{ paddingTop: '20px' }}>
      <Grid item container direction="column" spacing={1}>
        {!!sortedManueverSummary &&
          sortedManueverSummary
            .slice(
              paginationProps.currentPage * paginationProps.rowsPerPage,
              paginationProps.currentPage * paginationProps.rowsPerPage +
                paginationProps.rowsPerPage
            )
            .map(
              (task: IOrderedManeuverSummary) =>
                task.maneuverType && (
                  <Grid item key={task.maneuverId}>
                    <StyledListCard
                      sx={{
                        backgroundColor: isSelected(task)
                          ? theme.palette.secondary.light
                          : task.recordType === 'error'
                          ? '#fff5f5'
                          : '#f6fbf7',
                      }}
                    >
                      <CardContent>
                        <AddedManeuverCard
                          maneuverSummaries={sortedManueverSummary}
                          maneuver={task}
                          handleOpenTask={handleOpenTask}
                        />
                      </CardContent>
                    </StyledListCard>
                  </Grid>
                )
            )}
        {emptyRows > 0 && (
          <Card
            sx={{
              height: 53 * emptyRows,
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px',
              justifyContent: 'space-between',
              color: 'primary.contrastText',
              border: 'none',
              boxShadow: 'none',
            }}
          />
        )}
      </Grid>
      <TablePaginationComp
        currentPage={paginationProps.currentPage}
        rowsPerPage={paginationProps.rowsPerPage}
        rowsCount={sortedManueverSummary ? sortedManueverSummary.length : 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default AddedManeuverColumn;
