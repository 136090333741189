import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import theme from '../../../theme';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';
import { getAssessmentLabel } from '../common';

export interface DeleteAssessmentDialogProps {
  clientId: string;
  assessmentId: string;
  isDeleteDialogOpen: boolean;
  handleConfirmDelete: (assessmentKey: string) => void;
  handleCancelDelete: (assessmentKey: string) => void;
}

export const DeleteAssessmentDialog: FC<DeleteAssessmentDialogProps> = ({
  clientId,
  assessmentId,
  isDeleteDialogOpen,
  handleConfirmDelete,
  handleCancelDelete,
}) => {
  const { t } = useTranslation(allNamespaces);
  const assessmentLabel = getAssessmentLabel(assessmentId);

  return (
    <BasicDialog
      open={isDeleteDialogOpen}
      handleClose={() => handleCancelDelete(assessmentId)}
      label={
        <Typography variant="h2" component="span">
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'deleteAssessmentTitle',
            t
          )}{' '}
          {assessmentLabel}?
        </Typography>
      }
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.light,
        },
      }}
      actions={
        <>
          <Button onClick={() => handleCancelDelete(assessmentId)}>
            {getTranslationLabel('generalNs', 'cancel', t)}
          </Button>
          <Button
            onClick={() => handleConfirmDelete(assessmentId)}
            variant="outlined"
          >
            {getTranslationLabel('generalNs', 'delete', t)}
          </Button>
        </>
      }
      fullWidth
      maxWidth="md"
    >
      <Typography>
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'deleteAssessmentText1',
          t
        )}{' '}
        {assessmentLabel}{' '}
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'deleteAssessmentText2',
          t
        )}{' '}
        {clientId}.
      </Typography>
      <Typography style={{ fontWeight: 'bold' }}>
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'deleteAssessmentText3',
          t
        )}
      </Typography>
    </BasicDialog>
  );
};
