import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const d2TestOfAttention: SubHeadingItem = {
  label: 'D2 Test of Attention',
  key: 'd2TestOfAttention',
  fields: [
    {
      label: 'Number of D2s correctly identified',
      key: 'numberOfD2sIdentified',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of commissions',
      key: 'numberOfCommissions',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Number of omissions',
      key: 'numberOfOmissions',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Percentage of errors',
      key: 'percentageOfErrors',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Standard score',
      key: 'standardScore',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Percentile rank',
      key: 'percentileRank',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'record form',
      key: 'd2RecordFormFile',
      fieldType: 'UPLOAD',
    },
    {
      label: 'Concentration performance',
      key: 'concentrationPerformance',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Superior',
        'Above average',
        'Average',
        'Below average',
        'Borderline deficient',
      ]),
    },
    ...Results,
  ],
};

export default d2TestOfAttention;
