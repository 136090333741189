import { FieldTypes } from '../../../models/forms';
import {
  allButFirstColumn,
  convertLabelToKey,
  capitalizeFirstLetter,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

export const getKeyWithAdditionalParameter = (
  additionalParameter: string,
  columnHeading: string,
  rowTitle: string
) =>
  `${convertLabelToKey(additionalParameter)}${capitalizeFirstLetter(
    convertLabelToKey(columnHeading)
  )}${capitalizeFirstLetter(convertLabelToKey(rowTitle))}`;

export const getKeyWithoutAdditionalParameter = (
  columnHeading: string,
  rowTitle: string
) =>
  `${convertLabelToKey(columnHeading)}${capitalizeFirstLetter(
    convertLabelToKey(rowTitle)
  )}`;

const generateCellsForTableWithUniformCells = (
  columnHeadings: string[],
  rowTitles: string[],
  rowSpec: Function,
  additionalParameter?: string,
  columns?: boolean
) =>
  rowTitles.map((rowTitle) => [
    [
      {
        key: convertLabelToKey(rowTitle),
        label: rowTitle,
        fieldType: 'LABEL' as FieldTypes,
      },
    ],
    ...allButFirstColumn(columnHeadings).map((columnHeading) => {
      const key = additionalParameter
        ? getKeyWithAdditionalParameter(
            additionalParameter,
            columnHeading,
            rowTitle
          )
        : getKeyWithoutAdditionalParameter(columnHeading, rowTitle);

      const rows: any[] = rowSpec(key, columnHeading, additionalParameter);
      const ret = columns
        ? rows.find((element) => element.rowTitle === columnHeading)
        : rows.find((element) => element.rowTitle === rowTitle);
      return ret
        ? [ret.element]
        : [rows.find((element) => element.rowTitle === 'default').element];
    }),
  ]);

export default generateCellsForTableWithUniformCells;
