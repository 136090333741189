import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import theme from '../../theme';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface FullScreenDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactElement;
  children: React.ReactElement;
}

const FullScreenDialog: FC<FullScreenDialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => (
  <Dialog
    fullScreen
    open={isOpen}
    onClose={onClose}
    TransitionComponent={Transition}
    scroll="body"
    PaperProps={{ sx: { backgroundColor: theme.palette.primary.light } }}
  >
    <AppBar
      sx={{
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Toolbar>
        {title}
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            marginLeft: 'auto',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <DialogContent sx={{ backgroundColor: theme.palette.primary.light, p: 5 }}>
      {children}
    </DialogContent>
  </Dialog>
);

export default FullScreenDialog;
