import React, { FC } from 'react';

import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';

const PREFIX = 'NonLinearStepper';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  instructions: `${PREFIX}-instructions`,
};

interface NonLinearStepperProps {
  getStepLabels: () => string[];
  getStepContents: (step: number) => JSX.Element;
  onNextClick?: () => void;
  onBackClick?: () => void;
  onSave: () => void;
  alternativeLabel?: boolean;
  vertical?: boolean;
}

const NonLinearStepper: FC<NonLinearStepperProps> = ({
  getStepLabels,
  getStepContents,
  onNextClick,
  onBackClick,
  onSave,
  alternativeLabel,
  vertical,
}) => {
  const { t } = useTranslation(allNamespaces);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getStepLabels();

  const totalSteps = () => getStepLabels().length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    const newActiveStep = isLastStep()
      ? // It's the last step, just stay here
        activeStep
      : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Grid
      item
      container
      direction={vertical ? 'row' : 'column'}
      spacing={4}
      justifyContent="flex-end"
    >
      <Grid item>
        <Stepper
          alternativeLabel={!!alternativeLabel}
          nonLinear
          orientation={vertical ? 'vertical' : 'horizontal'}
          activeStep={activeStep}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={handleStep(index)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item>{getStepContents(activeStep)}</Grid>
      <Grid item container spacing={1} justifyContent="flex-end">
        {onBackClick && (
          <Grid item>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              {getTranslationLabel('generalNs', 'back', t)}
            </Button>
          </Grid>
        )}
        {onNextClick &&
          (activeStep === steps.length - 1 ? (
            <Grid item>
              <Button variant="contained" color="primary">
                {getTranslationLabel('generalNs', 'finish', t)}
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {getTranslationLabel('generalNs', 'next', t)}
              </Button>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default NonLinearStepper;
