import { FieldsLayout, SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields } from './utils';

const getLaneChangeFields = (side: string): FieldsLayout[] => [
  {
    label: 'Speed Regulation',
    key: `${side}LaneChangeSpeedRegulation`,
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Unnecessary acceleration',
      'Excessive slowing',
    ]),
  },
  {
    label: 'Adjustment to stimuli / traffic signs',
    key: `${side}adjustmentToStimuliTrafficSigns`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      "Performed in another driver's blind spot",
      'Performed in intersection or crosswalk',
      'Performed under a bridge or in a tunnel',
    ]),
  },
  {
    label: 'Lane Maintenance',
    key: `${side}LaneChangeLaneMaintenance`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Sharp turn into lane',
      'Drifting in lane while scanning',
    ]),
  },
  {
    label: 'Gap Acceptance',
    key: `${side}LaneChangeGapAcceptance`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Narrow traffic gap',
      'Insufficient traffic gap - other road user affected',
    ]),
  },
  {
    label: 'Visual Scanning',
    key: `${side}LaneChangeVisualScanning`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Missed blindspot check',
      'Failed to check mirrors',
    ]),
  },
  ...getCommonSignallingFields(`${side}LaneChangeSignalling`),
];

export const LEFT_LANE_CHANGE: SubHeadingItem = {
  key: 'leftLaneChange',
  label: 'Left Lane Change',
  fields: getLaneChangeFields('left'),
};

export const RIGHT_LANE_CHANGE: SubHeadingItem = {
  key: 'rightLaneChange',
  label: 'Right Lane Change',
  fields: getLaneChangeFields('right'),
};
