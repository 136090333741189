import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3006470/
const DBS_QUESTIONS_LIST: string[] = [
  '1. I lose track of where I am going.',
  '2. I yell at the driver/drivers who make me nervous.',
  '3. I slow down when approaching intersections, even when the light is green.',
  '4. I have trouble staying in the correct lane.',
  '5. I drift into other lanes.',
  '6. I forget to make appropriate adjustments in speed.',
  '7. I let the driver who made me nervous know that I am upset.',
  '8. I maintain a large distance between myself and the driver in front of me.',
  '9. I forget where I am driving to.',
  '10. I make gestures at the driver/drivers who made me nervous.',
  '11. I try to put distance between myself and other cars.',
  '12. I maintain my speed in order to calm myself down.',
  '13. I try to stay away from other cars.',
  '14. I have trouble finding the correct lane.',
  `15. I pound on the steering wheel when I'm nervous.`,
  '16. I decrease my speed until I feel comfortable.',
  '17. I honk my horn at the driver who made me nervous.',
  '18. I try to find ways to let other drivers know that they are making me nervous.',
  '19. During bad weather, I drive more cautiously than other vehicles on the road.',
  '20. I swear/use profanity while I am driving.',
  '21. I have difficulty merging into traffic.',
];

const DBS_SCORE_LIST: string[] = [
  'Anxiety score',
  'Exaggerated safety / Caution score',
  'Hostile / Aggressive behaviour score',
];

const getDBSValueDependsOn = (category: string) => {
  if (category === 'Anxiety score') {
    return [1, 4, 5, 6, 9, 14, 21].map((no) => `question${no}`);
  }
  if (category === 'Exaggerated safety / Caution score') {
    return [3, 8, 11, 12, 13, 16, 19].map((no) => `question${no}`);
  }
  return [2, 7, 10, 15, 17, 18, 20].map((no) => `question${no}`);
};

const DBS_QUESION_FIELDS: FieldsLayout[] = DBS_QUESTIONS_LIST.map(
  (item, i) => ({
    key: `question${i + 1}`,
    label: item,
    fieldType: 'CHOICE_CHIPS',
    options: getNumericOptionsXToY(1, 7),
  })
);

const DBS_SCORE_FIELDS: FieldsLayout[] = DBS_SCORE_LIST.map((item) => ({
  key: convertLabelToKey(item),
  label: item,
  fieldType: 'INPUT-NUM',
  units: <Typography> / 49 </Typography>,
  valueDependsOn: getDBSValueDependsOn(item),
}));

const drivingBehaviorSurvey: SubHeadingItem = {
  key: 'drivingBehaviorSurvey',
  label: 'Driving Behavior Survey (DBS)',
  fields: [
    {
      key: 'likertScaleScoreLabel',
      label: `1 = Never, 2 = Very Infrequently, 3 = Infrequently, 4 = Sometimes, 
      5 = Frequently, 6 = Very Frequently, 7 = Always`,
      fieldType: 'LABEL',
    },
    ...DBS_QUESION_FIELDS,
    ...DBS_SCORE_FIELDS,
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography> / 147 </Typography>,
      valueDependsOn: getKeysFromOptions(DBS_SCORE_FIELDS),
    },
    ...Results,
  ],
};

export default drivingBehaviorSurvey;
