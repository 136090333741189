import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOfPercent } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const gestaltCompletionTest: SubHeadingItem = {
  key: 'gestaltCompletionTest',
  label: 'Gestalt Completion Test (GCT)',
  fields: [...getScoreAndOutOfPercent('Gestalt completion test'), ...Results],
};

export default gestaltCompletionTest;
