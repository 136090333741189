import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';

const monthsInReverseOrder: SubHeadingItem = {
  key: 'monthsInReverseOrder',
  label: 'Months in Reverse Order',
  fields: [
    {
      label: `Is the client able to say the months of the year from start to finish in their usual
        order, starting with January?`,
      key: 'monthsCorrectForward',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      label: `Is the client able to say the months again, but this time in reverse order starting 
        with December?`,
      key: 'monthsCorrectBackward',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      label: 'Time taken',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    ...Results,
  ],
};

export default monthsInReverseOrder;
