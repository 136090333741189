import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, getSpeedFields } from './utils';

const VISUAL_OPTIONS = getOptionsFromLabels([
  'Missed blindspot check',
  'Failed to check mirrors',
]);
const LANE_MAINTENANCE = getOptionsFromLabels(['Sharp turn into lane']);
const GAP_ACCEPTANCE = getOptionsFromLabels([
  'Narrow traffic gap',
  'Insufficient traffic gap - other road user affected',
]);
const TYPES_OF_MERGE = getOptionsFromLabels([
  'From roadside stop',
  'Onto freeway',
  'Traffic island',
  'End of lane',
]);

export const MERGE_META: SubHeadingItem = {
  key: 'mergeManeuver',
  label: 'Merge Meta',
  fields: [
    {
      label: 'Type of Merge',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: TYPES_OF_MERGE,
    },
  ],
};

export const MERGE_ERRORS: SubHeadingItem = {
  key: 'mergeManeuver',
  label: 'Merge',
  fields: [
    ...getSpeedFields('merge'),
    {
      label: 'Lane Maintenance',
      key: 'mergeLaneMaintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: LANE_MAINTENANCE,
    },
    {
      label: 'Gap Acceptance',
      key: 'mergeGapAcceptance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: GAP_ACCEPTANCE,
    },
    {
      label: 'Visual Scanning',
      key: 'mergeVisual',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: VISUAL_OPTIONS,
    },
    ...getCommonSignallingFields('merge'),
  ],
};
