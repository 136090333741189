import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, TASK_SPEED_OPTIONS } from './utils';

const TYPE_OF_PULLOVER = getOptionsFromLabels([
  'Emergency stop',
  'To give feedback',
  'Pulling away from the curb',
  'Other',
]);

const VISUAL_LABELS = ['Missed blindspot check', 'Failed to check mirrors'];

const PULLOVER_OPERATIONAL = getOptionsFromLabels([
  'Wrong pedal selection',
  'Incorrect position of gear selector',
  'Failed to apply parking brake when required',
  'Failed to engage 4-way signals',
]);

export const PULLOVER_META: SubHeadingItem = {
  key: 'pullOver',
  label: 'Pull Over',
  fields: [
    {
      label: 'Type of Pullover',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: TYPE_OF_PULLOVER,
    },
  ],
};
export const PULLOVER_ERRORS: SubHeadingItem = {
  key: 'pullOver',
  label: 'Pull Over',
  fields: [
    {
      label: 'Speed Regulation',
      key: 'pullOverSpeed',
      fieldType: 'CHOICE_CHIPS',
      options: TASK_SPEED_OPTIONS,
    },
    {
      label: 'Gap Acceptance',
      key: 'pullOverGapAcceptance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels(['Poor traffic gap judgement']),
    },
    {
      label: 'Visual Scanning',
      key: 'pullOverVisual',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels(VISUAL_LABELS),
    },
    ...getCommonSignallingFields('pullOver'),
    {
      label: 'Operational',
      key: 'pullOverOperational',
      fieldType: 'CHECKBOX',
      options: PULLOVER_OPERATIONAL,
    },
  ],
};
