import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const singleLetterCancellation: SubHeadingItem = {
  key: 'singleLetterCancellation',
  label: 'Single Letter Cancellation Task (SLCT)',
  fields: [
    {
      label: 'Number of letters missed on the left',
      key: 'numberOfLettersMissedOnTheleftSide',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 53 max</Typography>,
    },
    {
      label: 'Number of letters missed on the right',
      key: 'numberOfLettersMissedOnTherightSide',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 51 max</Typography>,
    },
    {
      label: 'Total number of letters missed',
      key: 'totalNumberOfLetterMissed',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 104 max</Typography>,
      valueDependsOn: [
        'numberOfLettersMissedOnTheleftSide',
        'numberOfLettersMissedOnTherightSide',
      ],
    },
    {
      label: 'Time taken',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    ...Results,
  ],
};

export default singleLetterCancellation;
