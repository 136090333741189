import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  PhysicalResults,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getNumbersXToY,
  getNumericOptionsXToY,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

const JAMAR_COLUMN_HEADINGS = [
  '',
  'Hand grip - isometric',
  'Two finger pinch',
  'Tripod pinch',
  'Lateral pinch',
];

const JAMAR_ROW_TITLES = [
  'Units',
  'Grip setting',
  ...getNumbersXToY(1, 3).map((no) => `Trial no. ${no}`),
  'Average',
  'Result within norms?',
];

const getValueDependsOn = (columnHeading: string, hand: string) =>
  JAMAR_ROW_TITLES.slice(2, 5).map(
    (rowTitle) =>
      `${getKeyWithAdditionalParameter(hand, columnHeading, rowTitle)}DataPoint`
  );

const getJamarRowSpec = (key: string, columnHeading: string, hand: string) => [
  {
    rowTitle: 'Units',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      size: 200,
      options: getOptionsFromLabels(['Kilograms (kg)', 'Pounds (lbs)']),
    },
  },
  {
    rowTitle: 'Grip setting',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      size: 200,
      defaultValue: 2,
      options: getNumericOptionsXToY(1, 5),
    },
  },
  {
    rowTitle: 'Average',
    element: {
      key,
      label: 'Average',
      fieldType: 'INPUT-SWITCH' as FieldTypes,
      size: 200,
      valueDependsOn: getValueDependsOn(columnHeading, hand),
    },
  },
  {
    rowTitle: 'Result within norms?',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}DataPoint`,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const getJamarTable = (hand: string) => ({
  key: `${hand}HandSection`,
  label: `${hand} Hand`,
  fields: [
    {
      label: '',
      key: `${hand}HandResultsTable`,
      fieldType: 'TABLE' as FieldTypes,
      columnHeadings: JAMAR_COLUMN_HEADINGS,
      cells: generateCellsForTableWithUniformCells(
        JAMAR_COLUMN_HEADINGS,
        JAMAR_ROW_TITLES,
        getJamarRowSpec,
        hand
      ),
    },
  ],
});

const jamarGripDynamometerTest: MainHeadingItem = {
  key: 'jamarGripDynamometerTest',
  label: 'Jamar Grip Dynamometer Test',
  subheadings: [
    getJamarTable('Left'),
    getJamarTable('Right'),
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: PhysicalResults,
    },
  ],
};

export default jamarGripDynamometerTest;
