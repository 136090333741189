import { SpecialCalculationFunction } from '../../../models/assessments/assessments';

const classifyTotalScoreCTMT = (values: number) => {
  if (values >= 71) return 'verySuperior';
  if (values >= 65) return 'superior';
  if (values >= 58) return 'highAverage';
  if (values >= 43) return 'average';
  if (values >= 36) return 'belowAverage';
  if (values >= 30) return 'mildlyImpaired';
  return 'severelyImpaired';
};

const classifyTotalScoreBAI = (values: number) => {
  if (values >= 36) return 'concerningAnxiety';
  if (values >= 22) return 'moderateAnxiety';
  return 'lowAnxiety';
};

const classifyTotalScoreBDI = (values: number) => {
  if (values >= 41) return 'extreme';
  if (values >= 31) return 'severe';
  if (values >= 21) return 'moderate';
  if (values >= 17) return 'borderline';
  if (values >= 11) return 'mild';
  return 'normal';
};

const classifyTotalScoreESS = (values: number) => {
  if (values >= 16) return 'severeExcessive';
  if (values >= 13) return 'moderateExcessive';
  if (values >= 11) return 'mildExcessive';
  if (values >= 6) return 'higherThanNormal';
  return 'lowerThanNormal';
};

const classifyTotalScoreSII = (values: number) => {
  if (values >= 22) return 'clinicalInsomniaII';
  if (values >= 15) return 'clinicalInsomniaI';
  if (values >= 8) return 'subthresholdInsomnia';
  return 'noInsomnia';
};

const classifyMoCAScore = (values: number) => {
  if (values >= 26) return 'normal';
  if (values >= 18) return 'mildCognitiveImpairment';
  if (values >= 10) return 'moderateCognitiveImpairment';
  return 'severeCognitiveImpairment';
};

const classifyMMSEScore = (values: number) => {
  if (values >= 26) return 'normal';
  if (values >= 20) return 'mildCognitiveImpairment';
  if (values >= 10) return 'moderateCognitiveImpairment';
  return 'severeCognitiveImpairment';
};

const classifyBBSScore = (values: number) => {
  if (values >= 41) return 'lowFallRisk';
  if (values >= 21) return 'mediumFallRisk';
  return 'highFallRisk';
};

const classifyBITCScore = (values: number) =>
  values >= 129 ? 'bITCsatisfactory' : 'bITCvisualNeglect';
const classifyBITBScore = (values: number) =>
  values >= 67 ? 'bITBsatisfactory' : 'bITBvisualNeglect';
const classifyBITScore = (values: number) =>
  values >= 196 ? 'bITsatisfactory' : 'bITvisualNeglect';

const classifyMVPT = (values: number) => (values < 30 ? 'yes' : 'no');

const classifySnellgroveMazeTest = (values: string[]) => {
  const completed = values[0];
  if (completed === undefined || completed === 'no') return 'fail';
  const numberOfErrors = parseInt(values[1], 10);
  const time = parseInt(values[2], 10);
  if (
    !Number.isNaN(numberOfErrors) &&
    numberOfErrors <= 1 &&
    !Number.isNaN(time) &&
    time <= 60
  )
    return 'pass';
  return 'fail';
};

const classifyBellsTest = (values: string[]) => {
  const completed = values[0];
  if (!completed) return 'undetermined';
  const score = parseInt(values[1], 10);
  const time = parseInt(values[2], 10);
  const left = parseInt(values[3], 10);
  const right = parseInt(values[4], 10);
  if (
    (!Number.isNaN(left) && left >= 6) ||
    (!Number.isNaN(right) && right >= 6)
  )
    return 'failUnilateralSpatialNeglect';
  if (
    (!Number.isNaN(score) && score < 32) ||
    (!Number.isNaN(time) && time > 182) ||
    completed === 'no'
  )
    return 'failAttentionalDeficit';
  if (
    !Number.isNaN(score) &&
    score >= 32 &&
    !Number.isNaN(time) &&
    time <= 182 &&
    completed === 'yes'
  )
    return 'pass';
  return 'undetermined';
};

const classificyTrailsX = (values: number) => {
  if (values >= 71) return 'significantlyAboveAverage';
  if (values >= 65) return 'moderatelyAboveAverage';
  if (values >= 58) return 'aboveAverage';
  if (values >= 43) return 'average';
  if (values >= 36) return 'belowAverage';
  if (values >= 30) return 'moderatelyBelowAverage';
  return 'significantlyBelowAverage';
};

const classifyStandardizedRoadMapTest = (values: number) => {
  if (values >= 4) return 'yes';
  return 'no';
};

const classifyBBSBergScore = (values: number) => {
  if (values >= 45) return 'minimalConcernsBerg';
  return 'greaterFallRiskBerg';
};

const classifyBBSShumwayCookScore = (values: string[]) => {
  const historyOfFalls = values[0];
  const score = parseInt(values[1], 10);
  if (!Number.isNaN(score) && score >= 51) return 'minimalConcernsCook';
  if (!Number.isNaN(score) && score >= 42 && historyOfFalls === 'no')
    return 'minimalConcernsCook';
  return 'greaterFallRiskCook';
};

const classifyClock1Score = (values: number) => {
  if (values >= 10) return 'normalExecutiveControl';
  return 'impairedExecutiveControl';
};

const classifyClock2Score = (values: number) => {
  if (values >= 12) return 'normalVisualSpatialCopying';
  return 'impairedVisualSpatialCopying';
};

const classifyTotalScoreRookwood = (values: number) => {
  if (values >= 11) return 'interpretationFail';
  if (values >= 6) return 'difficultiesWithDriving';
  return 'normal';
};

const classifyCognistatOrientation = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 10) return 'orientationAverage';
  if (score === 8) return 'orientationMild';
  if (score === 6) return 'orientationModerate';
  if (score === 4) return 'orientationSevere';
  return 'other';
};

const classifyCognistatAttention = (values: string[]) => {
  if (values[1] === 'pass') return 'attentionAverage';
  const score = parseInt(values[0], 10);
  if (score >= 6) return 'attentionAverage';
  if (score === 5) return 'attentionMild';
  if (score === 3) return 'attentionModerate';
  if (score === 1) return 'attentionSevere';
  return 'other';
};

const classifyCognistatMemoryRegistration = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score === 2 || score === 3) return 'memoryRegistrationAverage';
  if (score === 4) return 'memoryRegistrationMild';
  if (score === 5) return 'memoryRegistrationModerate';
  if (score === 6) return 'memoryRegistrationSevere';
  return 'other';
};

const classifyCognistatLanguageComprehension = (values: string[]) => {
  if (values[1] === 'pass') return 'languageComprehensionAverage';
  const score = parseInt(values[0], 10);
  if (score >= 5) return 'languageComprehensionAverage';
  if (score === 4) return 'languageComprehensionMild';
  if (score === 3) return 'languageComprehensionModerate';
  if (score === 2) return 'languageComprehensionSevere';
  return 'other';
};

const classifyCognistatLanguageRepitition = (values: string[]) => {
  if (values[1] === 'pass') return 'languageRepetitionAverage';
  const score = parseInt(values[0], 10);
  if (score >= 11) return 'languageRepetitionAverage';
  if (score === 9) return 'languageRepetitionMild';
  if (score === 7) return 'languageRepetitionModerate';
  if (score === 5) return 'languageRepetitionSevere';
  return 'other';
};

const classifyCognistatLanguageNaming = (values: string[]) => {
  if (values[1] === 'pass') return 'languageNamingAverage';
  const score = parseInt(values[0], 10);
  if (score >= 7) return 'languageNamingAverage';
  if (score === 5) return 'languageNamingMild';
  if (score === 3) return 'languageNamingModerate';
  if (score === 2) return 'languageNamingSevere';
  return 'other';
};

const classifyCognistatConstructionalAbility = (values: string[]) => {
  if (values[1] === 'pass') return 'constructionalAbilityAverage';
  const score = parseInt(values[0], 10);
  if (score >= 4) return 'constructionalAbilityAverage';
  if (score === 3) return 'constructionalAbilityMild';
  if (score === 2) return 'constructionalAbilityModerate';
  if (score === 0) return 'constructionalAbilitySevere';
  return 'other';
};

const classifyCognistatMemory = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 10) return 'memoryAverage';
  if (score === 8) return 'memoryMild';
  if (score === 6) return 'memoryModerate';
  if (score === 4) return 'memorySevere';
  return 'other';
};

const classifyCognistatCalculations = (values: string[]) => {
  if (values[1] === 'pass') return 'calculationsAverage';
  const score = parseInt(values[0], 10);
  if (score >= 3) return 'calculationsAverage';
  if (score === 2) return 'calculationsMild';
  if (score === 1) return 'calculationsModerate';
  if (score === 0) return 'calculationsSevere';
  return 'other';
};

const classifyCognistatReasonExecSimilarities = (values: string[]) => {
  if (values[1] === 'pass') return 'reasonExecSimilaritiesAverage';
  const score = parseInt(values[0], 10);
  if (score >= 5) return 'reasonExecSimilaritiesAverage';
  if (score === 4) return 'reasonExecSimilaritiesMild';
  if (score === 3) return 'reasonExecSimilaritiesModerate';
  if (score === 2) return 'reasonExecSimilaritiesSevere';
  return 'other';
};

const classifyCognistatReasonExecJudgment = (values: string[]) => {
  if (values[1] === 'pass') return 'reasonExecJudgmentAverage';
  const score = parseInt(values[0], 10);
  if (score >= 4) return 'reasonExecJudgmentAverage';
  if (score === 3) return 'reasonExecJudgmentMild';
  if (score === 2) return 'reasonExecJudgmentModerate';
  if (score === 1) return 'reasonExecJudgmentSevere';
  return 'other';
};

const classifyDSC = (values: number[]) => {
  if (!values[0]) return 'fail';
  if (values[0] >= 80) return 'pass';
  return 'fail';
};

const classifyIntermediateADL = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 78) return 'intermediateADLGood';
  return 'intermediateADLWarningZone';
};

const classifyPhysicalFunctionADL = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 88) return 'physicalFunctionADLGood';
  return 'physicalFunctionADLWarningZone';
};

const classifyPsychologicalFunction = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 71) return 'psychologicalFunctionGood';
  return 'psychologicalFunctionWarningZone';
};

const classifyQualityOfSocialInteraction = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 70) return 'qualityOfSocialInteractionGood';
  return 'qualityOfSocialInteractionWarningZone';
};

const classifySocialActivity = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 79) return 'socialActivityGood';
  return 'socialActivityWarningZone';
};

const classifySocialRoleFunction = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 79) return 'socialRoleFunctionGood';
  return 'socialRoleFunctionWarningZone';
};

const classifyUFOVSubtest1 = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 350) return 'severeSubtest1';
  if (score > 60) return 'centralVisionLossSubtest1';
  if (score > 30) return 'slowProcessingSubtest1';
  return 'normalSubtest1';
};

const classifyUFOVSubtest2 = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 350) return 'severeDifficultySubtest2';
  if (score >= 100) return 'someDifficultySubtest2';
  return 'normalSubtest2';
};

const classifyUFOVSubtest3 = (values: string[]) => {
  const score = parseInt(values[0], 10);
  if (score >= 500) return 'severeDifficultySubtest3';
  if (score >= 350) return 'someDifficultySubtest3';
  return 'normalSubtest3';
};

const CLASSIFICATION_FUNCTIONS: SpecialCalculationFunction[] = [
  { field: 'interpretationBITC', func: classifyBITCScore },
  { field: 'interpretationBITB', func: classifyBITBScore },
  { field: 'interpretationBIT', func: classifyBITScore },
  { field: 'analysisOfThePercentileCTMT', func: classifyTotalScoreCTMT },
  { field: 'interpretationMoCA', func: classifyMoCAScore },
  { field: 'interpretationMMSE', func: classifyMMSEScore },
  { field: 'analysisOfRawScoreBellsTest', func: classifyBellsTest },
  { field: 'analysisOfClock1RawScore', func: classifyClock1Score },
  { field: 'analysisOfClock2RawScore', func: classifyClock2Score },
  { field: 'mVPTTotalScorePredictiveOfFailure', func: classifyMVPT },
  { field: 'extrapolatedScorePredictiveOfFailure', func: classifyMVPT },
  {
    field: 'interpretationSnellgroveMazeTest',
    func: classifySnellgroveMazeTest,
  },
  { field: 'analysisOfTotalMatrixPercentile', func: classificyTrailsX },
  { field: 'increasedRiskOfFailing', func: classifyStandardizedRoadMapTest },
  { field: 'interpretationBBS', func: classifyBBSScore },
  { field: 'interpretationBBSBerg', func: classifyBBSBergScore },
  { field: 'interpretationBBSShumwayCook', func: classifyBBSShumwayCookScore },
  { field: 'interpretationBAI', func: classifyTotalScoreBAI },
  { field: 'interpretationBDI', func: classifyTotalScoreBDI },
  { field: 'interpretationESS', func: classifyTotalScoreESS },
  { field: 'interpretationSII', func: classifyTotalScoreSII },
  { field: 'interpretationRookwood', func: classifyTotalScoreRookwood },

  { field: 'orientationInterpretation', func: classifyCognistatOrientation },
  { field: 'attentionInterpretation', func: classifyCognistatAttention },
  {
    field: 'memoryRegistrationInterpretation',
    func: classifyCognistatMemoryRegistration,
  },
  {
    field: 'languageComprehensionInterpretation',
    func: classifyCognistatLanguageComprehension,
  },
  {
    field: 'languageRepetitionInterpretation',
    func: classifyCognistatLanguageRepitition,
  },
  {
    field: 'languageNamingInterpretation',
    func: classifyCognistatLanguageNaming,
  },
  {
    field: 'constructionalAbilityInterpretation',
    func: classifyCognistatConstructionalAbility,
  },
  { field: 'memoryInterpretation', func: classifyCognistatMemory },
  { field: 'calculationsInterpretation', func: classifyCognistatCalculations },
  {
    field: 'reasonExecSimilaritiesInterpretation',
    func: classifyCognistatReasonExecSimilarities,
  },
  {
    field: 'reasonExecJudgmentInterpretation',
    func: classifyCognistatReasonExecJudgment,
  },
  {
    field: 'trialNo1Interpretation',
    func: classifyDSC,
  },
  {
    field: 'trialNo2Interpretation',
    func: classifyDSC,
  },
  {
    field: 'dSCOverallInterpretation',
    func: classifyDSC,
  },
  {
    field: 'intermediateADLInterpretation',
    func: classifyIntermediateADL,
  },
  {
    field: 'physicalFunctionADLInterpretation',
    func: classifyPhysicalFunctionADL,
  },
  {
    field: 'psychologicalFunctionInterpretation',
    func: classifyPsychologicalFunction,
  },
  {
    field: 'qualityOfSocialInteractionInterpretation',
    func: classifyQualityOfSocialInteraction,
  },
  {
    field: 'socialActivityInterpretation',
    func: classifySocialActivity,
  },
  {
    field: 'socialRoleFunctionInterpretation',
    func: classifySocialRoleFunction,
  },
  {
    field: 'processingSpeedSubtest1Interpretation',
    func: classifyUFOVSubtest1,
  },
  {
    field: 'dividedAttentionSubtest2Interpretation',
    func: classifyUFOVSubtest2,
  },
  {
    field: 'selectiveAttentionSubtest3Interpretation',
    func: classifyUFOVSubtest3,
  },
];

export default CLASSIFICATION_FUNCTIONS;
