import { axiosGetAllCall, useQueryWithToken } from '../utils';

const assessmentOptionsKey = ['assessmentOptions'] as const;

const assessmentOptionsUrl = '/api/assessments/assessment-options';

const useAssessmentOptions = () =>
  useQueryWithToken(assessmentOptionsKey, (token: string) =>
    axiosGetAllCall(assessmentOptionsUrl, token)
  );

export default useAssessmentOptions;
