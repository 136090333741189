import React, { FC } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import { styled, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

interface HelpTooltipProps {
  title: JSX.Element;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const HelpTooltip: FC<HelpTooltipProps> = ({ title }) => (
  <LightTooltip
    disableFocusListener
    title={title}
    sx={{
      root: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    }}
  >
    <HelpIcon style={{ position: 'relative', top: '5px' }} fontSize="small" />
  </LightTooltip>
);

export default HelpTooltip;
