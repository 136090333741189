import { Conditional } from '../../../models/forms';
import { TRUE_FALSE_OPTIONS } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

export const TYPE_OF_DOCTORS = getOptionsFromLabels([
  'Family',
  'Police',
  'Physician',
  'Optometrist',
  'Ophthalmologist',
  'Occupational therapist',
  'Unknown',
]);

const hasBeenReportedToLicensingBody = (values: any) =>
  values.hasTheMedicalConditionsBeenReported === 'yes';
const hasBeenPreviouslyReportedToLicensingBody = (values: any) =>
  values.hasTheMedicalConditionsBeenPreviouslyReported === 'yes';

const hasSeizure = (values: any, keys?: string[]) =>
  keys
    ? values[keys[0]] === 'centralNervousSystemDiagnosis' &&
      values[keys[1]] === 'seizure'
    : false;

const hasVisionCondition = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'physical' && values[keys[1]] === 'vision' : false;

const hasBrainInjury = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'brainInjuryDiagnosis' : false;
const hasCentralNervousSystemCondition = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'centralNervousSystemDiagnosis' : false;
const hasCognitiveCondition = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'cognitiveDiagnosis' : false;
const hasOtherReasonForReferral = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'otherReasonForReferralDiagnosis' : false;
const hasPhysicalCondition = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'physicalDiagnosis' : false;
const hasPsychologicalCondition = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'psychologicalDiagnosis' : false;

const impairmentIsTemporary = (values: any, keys?: string[]) =>
  keys ? values[keys[0]] === 'yes' : false;

const getVisionConditionals = (key: string, parentKey: string) => [
  {
    label: 'Optometrist Evaluation Letter',
    key: `${key}OptometristEvaluationLetter`,
    fieldType: 'UPLOAD',
    condition: hasVisionCondition,
    conditionalKeys: [key, parentKey],
  },
];
const getSeizureConditionals = (key: string, parentKey: string) => [
  {
    label: 'First seizure date',
    key: `${key}FirstSeizureDate`,
    fieldType: 'DATE',
    condition: hasSeizure,
    conditionalKeys: [key, parentKey],
  },
  {
    label: 'Last seizure date',
    key: `${key}LastSeizureDate`,
    fieldType: 'DATE',
    condition: hasSeizure,
    conditionalKeys: [key, parentKey],
  },
];

export const getDiagnosticConditionals = (key: string) => [
  {
    label: 'Type of brain injury',
    key: `${key}BrainInjuryDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels(['ABI', 'Stroke', 'TBI']),
    condition: hasBrainInjury,
    conditionalKeys: [key],
  },
  {
    label: 'Type of CNS condition',
    key: `${key}CentralNervousSystemDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels([
      'Huntingtons',
      'Neuological disorders',
      'SCI',
      'Spina bifida',
      'Seizure',
      'Parkinsons',
      'Ataxia',
      'ALS Lou Gehrig',
    ]),
    condition: hasCentralNervousSystemCondition,
    conditionalKeys: [key],
  },
  {
    label: 'Type of cognitive condition',
    key: `${key}CognitiveDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels([
      'Cognitive impairment',
      'Dementia',
      "Korsakoff's",
    ]),
    condition: hasCognitiveCondition,
    conditionalKeys: [key],
  },
  {
    label: 'Type of other condition',
    key: `${key}OtherDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels([
      'Unknown',
      'Various medical conditions',
      'Work place injuries',
    ]),
    condition: hasOtherReasonForReferral,
    conditionalKeys: [key],
  },
  {
    label: 'Type of physical condition',
    key: `${key}PhysicalDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels([
      'Amputee',
      'Arthritis',
      'Balance',
      'Cancer',
      'Cardiac issues',
      'Chronic pain',
      'Diabetes',
      'MS',
      'Muscular dystrophy',
      'Musculoskeletal injury',
      'Myasthenia gravis',
      'Myopathy',
      'Neuromuscular degenerative',
      'Ortho',
      'Paraplegia',
      'Polio',
      'Sleep apnea',
      'Syncope',
      'Vertigo',
      'Vision',
    ]),
    condition: hasPhysicalCondition,
    conditionalKeys: [key],
  },
  {
    label: 'Type of psychological injury',
    key: `${key}PsychologicalDiagnosis`,
    fieldType: 'SELECT',
    options: getOptionsFromLabels([
      'Autism',
      'Depression',
      'Driving anxiety',
      'Drug abuse',
      'Learning disorder',
      'Psychiatric',
    ]),
    condition: hasPsychologicalCondition,
    conditionalKeys: [key],
  },
  ...getVisionConditionals(key, `${key}PhysicalDiagnosis`),
  ...getSeizureConditionals(key, `${key}CentralNervousSystemDiagnosis`),
];

export const getImpairmentIsTemproraryConditional = (key: string) => [
  {
    label: 'Expected elapse date',
    key: `${key}ExpectedElapseDate`,
    fieldType: 'DATE_SHORT',
    condition: impairmentIsTemporary,
    conditionalKeys: [key],
  },
];

export const REPORTED_TO_LICENSING_BODY_COND: Conditional[] = [
  {
    label: 'Who reported the condition(s) to the licensing body?',
    key: 'whoReportedTheMedicalConditionLabel',
    fieldType: 'LABEL',
    condition: hasBeenReportedToLicensingBody,
  },
  {
    label: 'Please select',
    key: 'whoReportedTheMedicalCondition',
    fieldType: 'SELECT',
    options: TYPE_OF_DOCTORS,
    condition: hasBeenReportedToLicensingBody,
  },
  {
    label:
      'Has/have the condition(s) been previously reported to the licensing body?',
    key: 'hasTheMedicalConditionsBeenPreviouslyReported',
    fieldType: 'RADIO',
    options: TRUE_FALSE_OPTIONS,
    condition: hasBeenReportedToLicensingBody,
  },
  {
    label: 'Date',
    key: 'datePreviouslyReported',
    fieldType: 'DATE',
    condition: hasBeenPreviouslyReportedToLicensingBody,
  },
];

export default getDiagnosticConditionals;
