import {
  FieldsLayout,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../../models/forms';
import { YES_NO } from '../../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

export const POST_ROUTE: FieldsLayout[] = [
  {
    label: 'Did you change the route during the test?',
    key: 'changeRouteDuringTest',
    fieldType: 'CHOICE_CHIPS',
    options: YES_NO,
    conditionals: [
      {
        label: 'Please provide details',
        key: 'whyRouteChanged',
        fieldType: 'INPUT',
        condition: (values: any) => values.changeRouteDuringTest === 'yes',
      },
    ],
  },
  {
    label: 'Was the test ended early?',
    key: 'endedEarly',
    fieldType: 'CHOICE_CHIPS',
    options: YES_NO,
    conditionals: [
      {
        label: 'Please provide details',
        key: 'whyTestEndedEarly',
        fieldType: 'INPUT',
        condition: (values: any) => values.endedEarly === 'yes',
      },
    ],
  },
];

export const DURATION: FieldsLayout[] = [
  {
    label: 'Duration',
    key: 'duration',
    fieldType: 'INPUT',
  },
  {
    label: 'Start Time',
    key: 'startTime',
    fieldType: 'TIME',
  },
  {
    label: 'End Time',
    key: 'endTime',
    fieldType: 'TIME',
  },
];

export const CLIENTS_INSIGHT: FieldsLayout[] = [
  {
    label: "Client's perception of performance",
    key: 'clientsPerception',
    fieldType: 'TEXT-AREA',
  },
  {
    label: "Client's perception agrees with OT's perception?",
    key: 'clientsPerceptionAgreesWithOT',
    fieldType: 'CHOICE_CHIPS',
    options: YES_NO,
  },
];

const DI_OVERALL_RESULTS_LABELS = [
  'Meets provincial road test expectations',
  'Does not meet provincial road test expectations',
  'Inconclusive',
];

const DI_RESULTS: FieldsLayout[] = [
  {
    label: 'DI Score',
    key: 'dIScore',
    fieldType: 'INPUT-NUM',
  },
  {
    label: 'Score out of',
    key: 'scoreOutOf',
    fieldType: 'INPUT-NUM',
  },
  {
    label: 'Overall DI Impressions',
    key: 'overallDIImpressions',
    fieldType: 'RADIO',
    options: getOptionsFromLabels(DI_OVERALL_RESULTS_LABELS),
  },
];

export const POST_TEST: MainHeadingItem = {
  label: 'Post Road Test',
  key: 'postRoadTest',
  subheadings: [
    {
      key: 'onRoadRoute',
      label: 'Route',
      fields: POST_ROUTE,
    },
    {
      key: 'onRoadDuration',
      label: 'Duration',
      fields: DURATION,
    },
    {
      key: 'dIImpressions',
      label: 'DI Impressions',
      fields: DI_RESULTS,
    },
    {
      key: 'onRoadClientInsight',
      label: 'Insight',
      fields: CLIENTS_INSIGHT,
    },
  ],
};

// ----RESULTS----
const IMPAIRMENT_AREAS_LABELS = [
  'Attention',
  'Fatigue',
  'Judgement',
  'Perception',
  'Physical',
  'Planning / Problem Solving',
  'Processing Speed',
  'Scanning',
  'Other Area of Impairment',
];
const IMPAIRMENT_AREAS_OPTIONS = getOptionsFromLabels(
  IMPAIRMENT_AREAS_LABELS,
  'Impairment'
);
const OVERALL_RESULTS = [
  'Demonstrates functional driving skills',
  'Demonstrates errors consistent with cognitive-perceptual impairment',
  'Demonstrates errors consistent with physical impairment',
  'Demonstrates errors consistent with visual impairment',
  'Demonstrates errors consistent with poor driving habits',
  'Demonstrates aggressive driving behaviour',
];
const ON_ROAD_OVERALL_RESULTS: FieldsLayout[] = [
  {
    label: 'Select applicable results',
    key: 'results',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(OVERALL_RESULTS),
  },
  {
    label: 'Select areas in which client is consistently impaired',
    key: 'consistentImpairment',
    fieldType: 'CHECKBOX',
    options: IMPAIRMENT_AREAS_OPTIONS,
  },
];

export const ROAD_RESULTS_FORM: SubHeadingItem[] = [
  {
    label: 'OT Impressions',
    key: 'oTImpressions',
    fields: ON_ROAD_OVERALL_RESULTS,
  },
  {
    label: 'DI Impressions',
    key: 'dIImpressions',
    fields: DI_RESULTS,
  },
];

export const ROAD_RESULTS_SECTION: MainHeadingItem = {
  key: 'roadResults',
  label: 'Road Results',
  subheadings: ROAD_RESULTS_FORM,
};
