import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem, SubHeadingItem } from '../../../models/forms';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const QNST_CATEGORIES: string[] = [
  'Hand skill',
  'Figure recognition and production',
  'Palm form recognition',
  'Eye tracking',
  'Sound patterns',
  'Finger to nose',
  'Thumb and finger circle',
  'Double simultaneous stimulation of hand and cheek',
  'Rapidly reversing repetitive hand movements',
  'Arm and leg extension',
  'Tandem walk',
  'Stand on one leg',
  'Skipping',
  'Left-right discrimination',
  'Behavioral irregularities',
];

const QNST_SUBHEADINGS: SubHeadingItem[] = QNST_CATEGORIES.map((item) => ({
  key: `${convertLabelToKey(item)}`,
  label: item,
  fields: [
    {
      key: `${convertLabelToKey(item)}Score`,
      label: `Score`,
      fieldType: 'INPUT-NUM',
    },
  ],
}));

const quickNeurologicalScreeningTest: MainHeadingItem = {
  key: 'quickNeurologicalScreeningTest',
  label: 'Quick Neurological Screening Test (QNST)',
  subheadings: [
    ...QNST_SUBHEADINGS,
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: [
        {
          key: 'totalScore',
          label: 'Total score',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 149</Typography>,
          valueDependsOn: QNST_CATEGORIES.map(
            (item) => `${convertLabelToKey(item)}Score`
          ),
        },
        {
          label: 'Interpretation',
          key: 'overallInterpretation',
          fieldType: 'RADIO',
          options: [
            { key: 'normalRange', val: 'normalRange', label: 'Normal range' },
            {
              key: 'moderateDiscrepancy',
              val: 'moderateDiscrepancy',
              label: 'Moderate discrepancy',
            },
            {
              key: 'severeDiscrepancy',
              val: 'severeDiscrepancy',
              label: 'Severe discrepancy',
            },
          ],
        },
      ],
    },
  ],
};

export default quickNeurologicalScreeningTest;
