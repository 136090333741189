import { MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getTrailMakingSubheadings,
  TRAIL_MAKING_OPTIONS,
} from './trailMakingA';

const trailMakingB: MainHeadingItem = {
  label: 'Trail Making B',
  key: 'trailMakingB',
  subheadings: [
    ...getTrailMakingSubheadings('B'),
    {
      key: 'resultsSection',
      label: 'Results',
      fields: [
        {
          key: 'overallScanningRating',
          label: 'Overall scanning',
          fieldType: 'RADIO',
          options: TRAIL_MAKING_OPTIONS,
        },
        {
          key: 'processingSpeedRating',
          label: 'Processing speed',
          fieldType: 'RADIO',
          options: TRAIL_MAKING_OPTIONS,
        },
        {
          key: 'abilityToAlternateAttentionRating',
          label: 'Ability to alternate attention',
          fieldType: 'RADIO',
          options: TRAIL_MAKING_OPTIONS,
        },
        ...Results,
      ],
    },
  ],
};

export default trailMakingB;
