import { Conditional } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

export const ROAD_CONDITIONS = [
  'Night',
  'Rain',
  'Fog',
  'Snow',
  'Sunrise / sunset',
  'Unfamiliar areas',
  'Rush hour',
  'Highway',
  'Left turns across oncoming traffic',
];

export const DRIVING_FREQUENCY = [
  'Almost daily',
  'Three days / week',
  'One day / week',
  'Three or fewer days / month',
];

export const ROAD_TYPES = [
  'Clients Neighborhood',
  'Downtown',

  'Commercial',
  'Industrial',
  'Residential',
  'Rural',
  'One-Way',
  'Parking Lot',

  'Undivided Highway',
  'Divided Highway',
  'Freeway',

  'Light Traffic',
  'Moderate Traffic',
  'Busy Traffic',
  'Night Driving',
  'Winter Driving',
  'Unfamiliar Areas',
];

export const TYPE_OF_VECHILE = getOptionsFromLabels([
  'Car',
  'Van',
  'Truck',
  'Unknown',
]);

export const TYPE_OF_TRANSMISSION = getOptionsFromLabels([
  'Manual',
  'Automatic',
  'Unknown',
]);

const otherVechile = (values: any) => values.otherVehicle === 'yes';
const unknownVehicle = (values: any) =>
  values.vehicleType === 'unknown' ||
  values.vehicleTransmission === 'unknown' ||
  values.otherVehicle === 'unknown' ||
  values.otherVehicleType === 'unknown' ||
  values.otherVehicleTransmission === 'unknown';

const anySpecializedEquipment = (values: any) =>
  values.anySpecializedEquipment === 'yes' ||
  values.anySpecializedEquipment === 'unknown';
const historyOfDisorientation = (values: any) =>
  values.historyOfDisorientationOrGettingLost === 'yes' ||
  values.historyOfDisorientationOrGettingLost === 'unknown';
const drivingGoalsSpecifyX = (values: any) =>
  values.drivingGoalsDriveXKmPerWeek === true ||
  values.drivingGoalsDriveXKmPerWeek === '1';
const otherDrivingGoals = (values: any) =>
  values.drivingGoalsOtherDrivingGoals === true ||
  values.drivingGoalsOtherDrivingGoals === '1';
const reliesOnOtherTransportation = (values: any) =>
  values.currentlyReliesOnOtherTransportation === true ||
  values.currentlyReliesOnOtherTransportation === '1';
const supportPersonDoesNotConfirmDrivingHistory = (values: any) =>
  values.supportPersonConfirmsDrivingHistory === 'no';

export const OTHER_VECHILE_CONDITIONAL: Conditional[] = [
  {
    label: 'What type of an other vehicle do they use?',
    key: 'otherVehicleType',
    fieldType: 'RADIO',
    options: TYPE_OF_VECHILE,
    condition: otherVechile,
  },
  {
    label: 'What transmission does the other vehicle have?',
    key: 'otherVehicleTransmission',
    fieldType: 'RADIO',
    options: TYPE_OF_TRANSMISSION,
    condition: otherVechile,
  },
  {
    label: 'Why is their vehicle status unknown?',
    key: 'whyVehicleStatusUknown',
    fieldType: 'TEXT-AREA',
    condition: unknownVehicle,
  },
];

export const DISORIENTATION_CONDITIONAL: Conditional[] = [
  {
    label: 'Please provide details',
    key: 'disorientationDetails',
    fieldType: 'TEXT-AREA',
    condition: historyOfDisorientation,
  },
];

export const EQUIPMENT_CONDITIONAL: Conditional[] = [
  {
    label: 'Please provide details',
    key: 'equipmentDetails',
    fieldType: 'TEXT-AREA',
    condition: anySpecializedEquipment,
  },
];

export const DRIVING_GOALS_CONDITIONAL: Conditional[] = [
  {
    label: 'Please specify X',
    key: 'drivingGoalsXKmDetails',
    fieldType: 'INPUT-NUM',
    condition: drivingGoalsSpecifyX,
  },
  {
    label: 'Other driving goals',
    key: 'drivingGoalsDetails',
    fieldType: 'TEXT-AREA',
    condition: otherDrivingGoals,
  },
];

export const OTHER_TRANSPORTATION_CONDITIONAL: Conditional[] = [
  {
    label: 'Please specify other transportation',
    key: 'otherTransportationReliesOnDetails',
    fieldType: 'TEXT-AREA',
    condition: reliesOnOtherTransportation,
  },
];

export const SUPPORT_PERSON_CONDITIONAL: Conditional[] = [
  {
    label: 'Please explain',
    key: 'supportPersonDoesNotConfirmDrivingHistory',
    fieldType: 'TEXT-AREA',
    condition: supportPersonDoesNotConfirmDrivingHistory,
  },
];
