import React, { FC, useContext, useEffect, useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  RadioGroup,
  Radio,
  Select,
  TextField,
  Grid,
  Checkbox,
  Switch,
  Box,
  Button,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { SpecialCalculationFunction } from '../../../models/assessments/assessments';
import { FieldTypes, IOption } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import CLASSIFICATION_FUNCTIONS from '../../assessment-definitions/_SpecialFunctions/CLASSIFICATION_FUNCTIONS';
import SPECIAL_FUNCTIONS from '../../assessment-definitions/_SpecialFunctions/SPECIAL_FUNCTIONS';
import ChoiceChips from '../ChoiceChips';
import ConditionalWrapper from '../ConditionalWrapper';
import DisabledTooltip from '../DisabledTooltip';
import HelpTooltip from '../HelpTooltip';
import MultiSelectChips from '../MultiSelectChips';
import { TEXT_AREA_WIDTH, TEXT_FIELD_WIDTH } from './PrimitiveField-constants';

const lookUpSpecialFunction = (field: string) =>
  SPECIAL_FUNCTIONS.find(
    (aSpecialFuncEntry: SpecialCalculationFunction) =>
      aSpecialFuncEntry.field === field
  );

const lookUpClassificationFunction = (field: string) =>
  CLASSIFICATION_FUNCTIONS.find(
    (aSpecialFuncEntry: SpecialCalculationFunction) =>
      aSpecialFuncEntry.field === field
  );

interface MySelectProps {
  name: string;
  namespace: string;
  label: string;
  size?: number;
  defaultValue?: any;
}

export const MySelect: FC<MySelectProps> = ({
  children,
  name,
  namespace,
  label,
  size,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation(allNamespaces);
  const { register } = useContext(FormContext);

  return (
    <FormControl style={{ minWidth: size || 190 }}>
      <InputLabel htmlFor={name}>
        {getTranslationLabel(namespace, name, t, label)}
      </InputLabel>
      <Select
        native
        id={name}
        autoWidth
        {...props}
        {...register(name)}
        defaultValue={defaultValue || null}
        style={{ width: size || TEXT_FIELD_WIDTH }}
      >
        <option aria-label="None" value="" />
        {children}
      </Select>
    </FormControl>
  );
};

interface MyChoiceChipsProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
}

export const MyChoiceChips: FC<MyChoiceChipsProps> = ({
  label,
  name,
  namespace,
  options,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control } = useContext(FormContext);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={() => (
        <FormControl variant="outlined">
          <FormLabel htmlFor={name} color="secondary">
            {getTranslationLabel(namespace, name, t, label)}
          </FormLabel>
          <ChoiceChips
            chipData={options}
            fieldName={name}
            namespace={namespace}
          />
        </FormControl>
      )}
    />
  );
};

interface MyMultiSelectChipsProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
}

export const MyMultiSelectChips: FC<MyMultiSelectChipsProps> = ({
  label,
  name,
  namespace,
  options,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <>
      <FormLabel htmlFor={name} color="secondary">
        {getTranslationLabel(namespace, name, t, label)}
      </FormLabel>
      <MultiSelectChips chipData={options} namespace={namespace} />
    </>
  );
};

interface MyRadioProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
  units?: JSX.Element;
  valueDependsOn?: string[];
}

export const MyRadio: FC<MyRadioProps> = ({
  children,
  name,
  namespace,
  label,
  units,
  options,
  valueDependsOn,
  ...props
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control, getValues, setValue, watch } = useContext(FormContext);

  const handleClick = (event: any) => {
    if (event.target.value === getValues(name)) {
      setValue(name, '');
    } else {
      setValue(name, event.target.value);
    }
  };

  const computeValue = () => {
    if (valueDependsOn) {
      const entireValueSpecialFunc = lookUpClassificationFunction(name);
      if (entireValueSpecialFunc) {
        return entireValueSpecialFunc.func(
          valueDependsOn.map((item) => getValues(item))
        );
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (valueDependsOn) {
      setValue(name, computeValue());
    }
  }, watch(valueDependsOn || []));

  const getRadioControl = (option: IOption) => {
    const disabled = valueDependsOn !== undefined;

    return (
      <ConditionalWrapper
        key={option.key}
        condition={disabled}
        wrapper={(wrapperChildren: any) => (
          <DisabledTooltip>{wrapperChildren}</DisabledTooltip>
        )}
      >
        <FormControlLabel
          key={option.key}
          value={option.val}
          control={<Radio onClick={handleClick} />}
          label={getTranslationLabel(namespace, option.key, t, option.label)}
          disabled={disabled}
        />
      </ConditionalWrapper>
    );
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={[]}
        render={() => (
          <FormControl>
            <FormLabel htmlFor={name} color="secondary">
              <Box>
                {getTranslationLabel(namespace, name, t, label)}
                {'  '}
                {units && <HelpTooltip title={units} />}
              </Box>
            </FormLabel>
            <RadioGroup
              aria-label={name}
              color="secondary"
              value={getValues(name) || ''}
              {...props}
            >
              {options.map((option) => getRadioControl(option))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
};

interface MyScaleProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
}

export const MyScale: FC<MyScaleProps> = ({
  children,
  name,
  namespace,
  label,
  options,
  ...props
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control, getValues, setValue } = useContext(FormContext);

  const handleClick = (event: any) => {
    if (event.target.value === getValues(name)) {
      setValue(name, '');
    } else {
      setValue(name, event.target.value);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <>
          <FormLabel htmlFor={name} color="secondary">
            {getTranslationLabel(namespace, name, t, label)}
          </FormLabel>
          <RadioGroup
            aria-label={name}
            color="secondary"
            row
            value={value || ''}
            {...props}
          >
            {options.map((option) => (
              <Grid item key={option.key}>
                <FormControlLabel
                  key={option.key}
                  value={option.val}
                  control={<Radio onClick={handleClick} />}
                  label={getTranslationLabel(
                    namespace,
                    option.key,
                    t,
                    option.label
                  )}
                  labelPlacement="bottom"
                  style={{ color: 'grey' }}
                />
              </Grid>
            ))}
          </RadioGroup>
        </>
      )}
    />
  );
};

interface MyCheckboxProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
}

export const MyCheckbox: FC<MyCheckboxProps> = ({
  label,
  name,
  namespace,
  options,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control, getValues } = useContext(FormContext);

  return (
    <>
      <FormLabel
        htmlFor={name}
        style={{ padding: ' 10px 0' }}
        color="secondary"
      >
        {getTranslationLabel(namespace, name, t, label)}
      </FormLabel>
      {options.map((option) => (
        <Grid item key={option.key}>
          <Controller
            name={option.key}
            control={control}
            defaultValue="0"
            render={({ field }) => (
              <FormControlLabel
                key={option.key}
                control={
                  <Checkbox
                    {...field}
                    checked={
                      getValues(option.key) === '1' ||
                      getValues(option.key) === true
                    }
                  />
                }
                label={getTranslationLabel(
                  namespace,
                  option.key,
                  t,
                  option.label
                )}
              />
            )}
          />
        </Grid>
      ))}
    </>
  );
};

interface MyCheckboxWithButtonProps {
  label: string;
  name: string;
  namespace: string;
  options: IOption[];
  onClick?: any;
  clearSelection: any;
}

export const MyCheckBoxWithButton: FC<MyCheckboxWithButtonProps> = ({
  name,
  namespace,
  label,
  options,
  onClick,
  clearSelection,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { getValues, setValue } = useContext(FormContext);

  return (
    <>
      <MyCheckbox
        label={label}
        name={name}
        options={options}
        namespace={namespace}
      />
      <Button
        onClick={() => onClick(getValues, setValue)}
        variant="outlined"
        sx={{
          marginTop: '10px',
        }}
      >
        {getTranslationLabel(namespace, name, t)}
      </Button>
      {clearSelection && (
        <Button
          onClick={() => clearSelection(setValue)}
          variant="outlined"
          sx={{
            marginTop: '10px',
            marginLeft: '10px',
          }}
        >
          {getTranslationLabel('generalNs', 'clearSelection', t)}
        </Button>
      )}
    </>
  );
};

interface MyLabelProps {
  label: string;
  name: string;
  namespace: string;
  valueDependsOn?: string[];
}

export const MyLabel: FC<MyLabelProps> = ({
  name,
  namespace,
  label,
  valueDependsOn,
}) => {
  const { t, i18n } = useTranslation(allNamespaces);
  const { getValues, watch } = useContext(FormContext);
  const [labelToShow, setLabelToShow] = useState<string>(
    getTranslationLabel(namespace, name, t, label, i18n.resolvedLanguage)
  );

  const computeValue = () => {
    if (valueDependsOn) {
      const entireValueSpecialFunc = lookUpSpecialFunction(name);
      if (entireValueSpecialFunc) {
        return entireValueSpecialFunc.func(
          valueDependsOn.map((item) => getValues(item))
        );
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (valueDependsOn) {
      setLabelToShow(computeValue());
    }
  }, watch(valueDependsOn || []));

  return (
    <FormLabel htmlFor={name} color="secondary">
      {labelToShow}
    </FormLabel>
  );
};

interface MyDatePickerProps {
  label: string;
  name: string;
  namespace: string;
  type: FieldTypes;
}

// Note: date has not been adapted to go inside a table
export const MyDatePicker: FC<MyDatePickerProps> = ({
  label,
  name,
  namespace,
  type,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control } = useContext(FormContext);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            label={getTranslationLabel(namespace, name, t, label)}
            value={value || null}
            onChange={(newValue: any) => {
              if (newValue === null) {
                onChange(null);
              } else if (newValue !== null && type === 'DATE_SHORT') {
                onChange(newValue.format('MMM-YYYY'));
              } else {
                onChange(newValue.format('YYYY-MM-DD'));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            views={
              type === 'DATE_SHORT'
                ? ['month', 'year']
                : ['year', 'month', 'day']
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

interface MyTimePickerProps {
  label: string;
  name: string;
  namespace: string;
}

// Note: time picker has not been adapted to go inside a table
export const MyTimePicker: FC<MyTimePickerProps> = ({
  label,
  name,
  namespace,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control } = useContext(FormContext);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field: { onChange, value } }) => (
          <TimePicker
            label={getTranslationLabel(namespace, name, t)}
            value={value || null}
            onChange={(newValue) =>
              onChange(newValue === null ? null : newValue)
            }
            renderInput={(params) => <TextField {...params} />}
            clearable
          />
        )}
      />
    </LocalizationProvider>
  );
};

interface MyTextFieldProps {
  label: string;
  name: string;
  namespace: string;
  type: string;
  units?: JSX.Element;
  defaultValue?: any;
  valueDependsOn?: string[];
  placeholder?: string;
  shrinkLabel?: boolean;
  size?: number;
  insideTable?: boolean;
  required?: boolean;
}

export const MyTextField: FC<MyTextFieldProps> = ({
  label,
  name,
  namespace,
  type,
  units,
  defaultValue,
  shrinkLabel,
  size,
  insideTable,
  valueDependsOn,
  required = false,
  ...rest
}) => {
  const { t } = useTranslation(allNamespaces);
  const { register, watch, getValues, setValue } = useContext(FormContext);

  const computeValue = () => {
    let thereIsAtLeastOneInput = false;
    if (valueDependsOn) {
      // there is a special function to compute the entire value, apply it
      const entireValueSpecialFunc = lookUpSpecialFunction(name);
      if (entireValueSpecialFunc) {
        const computedValue = entireValueSpecialFunc.func(
          valueDependsOn.map((item) => getValues(item))
        );
        if (
          computedValue <= 0 &&
          name !== 'trailAStandardScore' &&
          name !== 'trailBStandardScore'
        )
          return 0;
        return computedValue;
      }
      // otherwise, process dependency array element-by-element
      // NOTE: We currently don't have any relevant "SCALE" or "SELECT" anywhere in any
      //    assessments added thus far. If we do get some, it shouldn't be too hard to incoporate
      //    them under "INPUT-NUM or CHOICE_CHIPS". All other types of fields
      //    (i.e. "INPUT", "LABEL", "TEXT-AREA", "TIME", "UPLOAD") wouldn't be expected to be listed as
      //    a dependency
      const computedValue = valueDependsOn.reduce((result, field) => {
        const currentValue = getValues(field);

        if (currentValue === 'zero') {
          return result;
        }
        if (currentValue === 'one') {
          return result + 1;
        }
        if (currentValue === 'two') {
          return result + 2;
        }
        if (currentValue === 'three') {
          return result + 3;
        }

        if (
          currentValue === '' ||
          currentValue === undefined ||
          currentValue === null ||
          currentValue === 0 ||
          currentValue === '0' ||
          Number.isNaN(currentValue)
        ) {
          return result + 0;
        }

        thereIsAtLeastOneInput = true;

        // CHECKBOXES
        if (currentValue === true || currentValue === '1') {
          return result + 1;
        }

        // RADIO
        if (currentValue === 'yes') {
          return result + 1;
        }

        // INPUT-NUM or CHOICE_CHIPS
        if (typeof currentValue === 'number') {
          return result + currentValue;
        }
        if (
          typeof currentValue === 'string' &&
          !Number.isNaN(parseInt(currentValue, 10))
        ) {
          return result + parseInt(currentValue, 10);
        }

        // else
        return result;
      }, 0);

      if (!thereIsAtLeastOneInput) return '';
      if (computedValue <= 0) return 0;
      return computedValue;
    }
    return undefined;
  };

  const switchName = `${name}Switch`;
  const isAutoMode = () =>
    type === 'INPUT-SWITCH'
      ? watch(switchName) || watch(switchName) === undefined
      : true;

  const arrayToWatch = valueDependsOn ? [...valueDependsOn, switchName] : [];
  useEffect(() => {
    if (valueDependsOn && isAutoMode()) {
      setValue(name, computeValue());
    }
  }, watch(arrayToWatch));

  const getAutoSwitch = () => (
    <>
      <Grid item style={{ padding: '10px 0px 0px 0px' }}>
        <MyLabel label={label} name={`${name}Label`} namespace={namespace} />
      </Grid>
      <Grid item style={{ padding: '10px 0px 10px 0px' }}>
        <MyLabel
          label="Use auto computed result"
          name={`${name}AutoToggle`}
          namespace={namespace}
        />
        <Switch
          color="secondary"
          checked={isAutoMode()}
          {...register(switchName)}
        />
      </Grid>
    </>
  );

  const getTextField = () => {
    const disabled = valueDependsOn !== undefined && isAutoMode();
    const labelProps = disabled
      ? {
          shrink: true,
          style: { color: 'grey' },
        }
      : {
          shrink: shrinkLabel,
        };
    const convertedType =
      type === 'INPUT-SWITCH' || type === 'TIME' ? 'number' : type;

    return (
      <ConditionalWrapper
        condition={disabled}
        wrapper={(children: any) => (
          <DisabledTooltip>{children}</DisabledTooltip>
        )}
      >
        <TextField
          label={getTranslationLabel(namespace, name, t, label)}
          className="input"
          color="secondary"
          InputLabelProps={labelProps}
          type={convertedType}
          style={{ width: size || TEXT_FIELD_WIDTH }}
          autoComplete="off"
          disabled={disabled}
          defaultValue={defaultValue || null}
          onWheel={() => (document.activeElement as HTMLElement).blur()}
          {...register(name, {
            setValueAs: (val) =>
              convertedType === 'number' && val !== null
                ? parseFloat(val)
                : val,
            required,
          })}
          {...rest}
        />
      </ConditionalWrapper>
    );
  };

  return insideTable ? (
    <>
      {type === 'INPUT-SWITCH' && getAutoSwitch()}
      {getTextField()}
      {units && <Grid item>{units}</Grid>}
    </>
  ) : (
    <>
      {type === 'INPUT-SWITCH' && getAutoSwitch()}
      <Grid container direction="row" alignItems="center">
        <Grid item>{getTextField()}</Grid>
        {units && <Grid item>{units}</Grid>}
      </Grid>
    </>
  );
};

interface MyTextAreaProps {
  label: string;
  name: string;
  namespace: string;
  placeholder?: string;
  size?: number;
}

export const MyTextArea: FC<MyTextAreaProps> = ({
  name,
  namespace,
  label,
  size,
  ...props
}) => {
  const { t, i18n } = useTranslation(allNamespaces);
  const { register } = useContext(FormContext);

  return (
    <>
      <TextField
        label={getTranslationLabel(
          namespace,
          name,
          t,
          label,
          i18n.resolvedLanguage
        )}
        className="input"
        color="secondary"
        multiline
        rows={3}
        style={{ width: size || TEXT_AREA_WIDTH }}
        autoComplete="off"
        {...props}
        {...register(name)}
      />
    </>
  );
};
