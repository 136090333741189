import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getNumbersXToY } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const steerClear: SubHeadingItem = {
  label: 'Steer Clear',
  key: 'steerClear',
  fields: [
    ...getNumbersXToY(1, 6).map((item) => ({
      label: `Number of errors period no. ${item}`,
      key: `numberOfErrorsForPeriodNo${item}`,
      fieldType: 'INPUT-NUM' as FieldTypes,
      units: <Typography>/ 130</Typography>,
    })),
    {
      label: 'Total errors',
      key: 'totalErrors',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 780</Typography>,
      valueDependsOn: getNumbersXToY(1, 6).map(
        (item) => `numberOfErrorsForPeriodNo${item}`
      ),
    },
    ...Results,
  ],
};

export default steerClear;
