import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { VisualResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import VISION_SCREENING_DEVICE_FIELD from './Visual-Utils';

const CONTRAST_SENSITIVITY_MEASUREMENT_TYPES = [
  'Traditional contrast sensitivity (1-300)',
  'Percent contrast (0-100%)',
  'Pelli-Robson contrast sensitivity (0.0-2.5)',
];

const contrastSensitivity: SubHeadingItem = {
  key: 'contrastSensitivity',
  label: 'Contrast Sensitivity',
  fields: [
    VISION_SCREENING_DEVICE_FIELD,
    {
      key: `typeOfUnits`,
      label: 'Select measurement units',
      fieldType: 'SELECT',
      options: getOptionsFromLabels(CONTRAST_SENSITIVITY_MEASUREMENT_TYPES),
    },
    ...[
      'Both eyes measurement',
      'Left eye measurement',
      'Right eye measurement',
    ].map((rowTitle) => ({
      key: `contrastSensitivity${capitalizeFirstLetter(
        convertLabelToKey(rowTitle)
      )}`,
      label: rowTitle,
      fieldType: 'INPUT-NUM' as FieldTypes,
    })),
    ...VisualResults,
  ],
};

export default contrastSensitivity;
