import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';

import allNamespaces from '../../allNamespaces';
import NoMatch from '../../errors/NoMatch';
import { getTranslationLabel } from '../../utils/utils';
import TabsComp, { MenuItemProp } from '../common/Tabs';
import AccountSettings from '../settings/AccountSettings/AccountSettings';
import AssessmentSettings from '../settings/AssessmentSettings/AssessmentsSettings';
import OnRoadSettings from '../settings/OnRoadSettings/OnRoadSettings';

const SettingsRoutes = () => {
  const { t } = useTranslation(allNamespaces);
  const match = useRouteMatch();

  const tabsMenuItems: MenuItemProp[] = [
    {
      key: 'myAccount',
      label: 'My Account',
      path: `${match.path}/account`,
    },
    {
      key: 'assessments',
      label: 'Assessments',
      path: `${match.path}/assessments`,
    },
    {
      key: 'myRoutes',
      label: 'My Routes',
      path: `${match.path}/on-road`,
    },
  ];

  return (
    <>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h1">
            {getTranslationLabel('settings', 'settings', t)}
          </Typography>
        </Grid>
        <Grid item>
          <TabsComp menuItems={tabsMenuItems} orientation="horizontal" />
        </Grid>
        <Grid item>
          <div className="site-layout-content">
            <Switch>
              <Route exact path="/user/settings">
                <Redirect to="/user/settings/account" />
              </Route>
              <Route path={`${match.path}/account`}>
                <AccountSettings />
              </Route>
              <Route path={`${match.path}/assessments`}>
                <AssessmentSettings />
              </Route>
              <Route path={`${match.path}/on-road`}>
                <OnRoadSettings />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsRoutes;
