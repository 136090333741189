import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const getGeneralOptions = (question: string) =>
  [
    'No errors (excellent)',
    '1-2 errors (good)',
    '3-5 errors (fair)',
    '5+ errors (poor)',
  ].map((item, idx) => ({
    key: `${question}Option${idx}`,
    val: `${question}Option${idx}`,
    label: item,
  }));

const getExcellentToPoorOptions = (question: string) =>
  ['Excellent', 'Good', 'Fair', 'Poor'].map((item, idx) => ({
    key: `${question}Option${idx}`,
    val: `${question}Option${idx}`,
    label: item,
  }));

const getCompensatoryOptions = (question: string) =>
  [
    'continuously moving head and eyes in an efficient manner seeing all traffic information; no improvement required (excellent)',
    'moving head and eyes most times in an efficient manner seeing all relevant traffic stimuli; no improvement required 1-2 errors (good)',
    'some head and/or eye movement noted but it is inconsistent; improvement required (fair)',
    'minimal to no head and eye movements noted; much improvement required (poor)',
  ].map((item, idx) => ({
    key: `${question}Option${idx}`,
    val: `${question}Option${idx}`,
    label: item,
  }));

const P3Q6_COLUMN_HEADINGS = ['Question', 'Answer'];

const P3Q6_ROW_TITLES = [
  'Understanding of feedback',
  'Receptive to feedback',
  'Accurate awareness of skills',
];

const getP3Q6TableRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key: `table${key}`,
      label: '',
      fieldType: 'SCALE' as FieldTypes,
      options: getExcellentToPoorOptions(`table${key}`),
    },
  },
];
const metacognitionOnRoadInterview: MainHeadingItem = {
  key: 'metacognitionOnRoadInterview',
  label: 'Metacognition Interview (On-Road)',
  subheadings: [
    {
      key: 'part2',
      label: 'Prior to Starting Road Test #1',
      fields: [
        {
          key: 'p2Q1',
          label: `How well will you be able to follow the rules of the road, for example, making complete stops at stop signs, taking your right of way,
          making proper lane changes, etc?`,
          fieldType: 'RADIO',
          options: getGeneralOptions('p2Q1'),
        },
        {
          key: 'p2Q2',
          label:
            'How well will you be able to check all blind spots around the vehicle as you drive?',
          fieldType: 'RADIO',
          options: getGeneralOptions('p2Q2'),
        },
        {
          key: 'p2Q3',
          label:
            'How well will you be able to move your head and eyes to see all that you need to see and to help you to compensate for your vision loss?',
          fieldType: 'RADIO',
          options: getCompensatoryOptions('p2Q3'),
        },
      ],
    },
    {
      key: 'part3',
      label: 'After Completing On-Road Test #1',
      fields: [
        {
          key: 'p3Q1Label',
          label:
            'Did you do anything that you would consider a big error (for example, miss a stop sign, miss seeing a pedestrian, pulling out too soon, etc)?',
          fieldType: 'LABEL',
        },
        {
          key: 'p3Q1',
          label: '',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p3Q2',
          label: `How well were you able to follow the rules of the road, for example, making complete stops at stop signs, taking your right of way,
          making proper lane changes, etc?`,
          fieldType: 'RADIO',
          options: getGeneralOptions('p3Q2'),
        },
        {
          key: 'p3Q3',
          label:
            'How well were you able to check all of the blind spots around the vehicle as you drove?',
          fieldType: 'RADIO',
          options: getGeneralOptions('p3Q3'),
        },
        {
          key: 'p3Q4',
          label:
            'How well were you able to move your head and eyes to see all that you need to see and to help you to compensate for your vision loss?',
          fieldType: 'RADIO',
          options: getCompensatoryOptions('p3Q4'),
        },
        {
          key: 'p3Q5Label',
          label:
            'What will you try to improve upon during the next road test? (List all areas that driver will attempt to make improvements in):',
          fieldType: 'LABEL',
        },
        {
          key: 'p3Q5',
          label: '',
          fieldType: 'TEXT-AREA',
        },
        {
          label: 'Occupational Therapist rated the drivers:',
          key: 'p3Q6Table',
          fieldType: 'TABLE',
          columnHeadings: P3Q6_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            P3Q6_COLUMN_HEADINGS,
            P3Q6_ROW_TITLES,
            getP3Q6TableRowSpec,
            '1'
          ),
        },
      ],
    },
    {
      key: 'part4',
      label: 'After Completing On-Road Test #2:',
      fields: [
        {
          key: 'p4Q1',
          label:
            'How well did you accomplish the goals set after your first road test?',
          fieldType: 'RADIO',
          options: [
            'Excellent - accomplished with 90-100% accuracy and consistency',
            'Good - accomplished with 75% accuracy and consistency',
            'Fair - used better skills 50% of the time but need to improve further',
            'Poor - was only able to improve on some things some of the time',
          ].map((item, idx) => ({
            key: `p4Q1Option${idx}`,
            val: `p4Q1Option${idx}`,
            label: item,
          })),
        },
        {
          key: 'p4Q2Label',
          label:
            'Did you do anything that you would consider a big error (for example, miss a stop sign, miss seeing a pedestrian, pulling out too soon, etc?)',
          fieldType: 'LABEL',
        },
        {
          key: 'p4Q2',
          label: '',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p4Q3',
          label: `How well were you able to follow the rules of the road, for example, making complete stops at stop signs, taking your right of
          way, making proper lane changes, etc?`,
          fieldType: 'RADIO',
          options: getGeneralOptions('p4Q3'),
        },
        {
          key: 'p4Q4',
          label:
            'How well were you be able to check all blind spots around the vehicle as you drove?',
          fieldType: 'RADIO',
          options: getGeneralOptions('p4Q4'),
        },
        {
          key: 'p4Q5',
          label:
            'How well were you be able to move your head and eyes to see all that you need to see and to help you to compensate for your vision loss?',
          fieldType: 'RADIO',
          options: getCompensatoryOptions('p4Q5'),
        },
        {
          key: 'p4Q6',
          label:
            'What, if anything do you feel would improve your driving skills?',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels([
            'take lessons',
            'practice driving again',
            'help from family',
            'use of strategies as discussed today',
            'other (specify in comments)',
          ]),
        },
        {
          label: 'Occupational Therapist rated the drivers:',
          key: 'p4Q7Table',
          fieldType: 'TABLE',
          columnHeadings: P3Q6_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            P3Q6_COLUMN_HEADINGS,
            P3Q6_ROW_TITLES,
            getP3Q6TableRowSpec,
            '2'
          ),
        },
      ],
    },
    {
      key: 'part5',
      label: 'Scoring - Metacognition Only',
      fields: [
        {
          key: 'p5Q1',
          label: 'Functional - Pass',
          fieldType: 'CHECKBOX',
          options: [
            'able to identify target and bad habit errors',
            'able to modify driving performance in Test #2',
            `recognizes skills and areas that require improvement - rating by Occupational Therapist of at least "fair" on
            understanding of feedback, receptiveness and awareness after road test #2`,
            `demonstrates enough awareness that carry over of learning will be possible or enough awareness that driver
            will continue to use strategies competently`,
          ].map((item, idx) => ({
            key: `p5Q1Option${idx}`,
            val: `p5Q1Option${idx}`,
            label: item,
          })),
        },
        {
          key: 'p5Q2',
          label: 'Skill Deficit - Fail',
          fieldType: 'CHECKBOX',
          options: [
            'unable to recognize target and bad habit errors',
            'unable to modify driving performance in Test #2',
            `poor awareness of skills defi cits even with feedback - rating by Occupational Therapist of "poor" on
            understanding of feedback, receptiveness and awareness after road test #2`,
            'cognitive deficits evident that are not compatible with training',
          ].map((item, idx) => ({
            key: `p5Q2Option${idx}`,
            val: `p5Q2Option${idx}`,
            label: item,
          })),
        },
      ],
    },
  ],
};

export default metacognitionOnRoadInterview;
