import React, { FC } from 'react';

import { DoNotDisturb } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface DisabledTooltipProps {
  children: JSX.Element;
}

const DisabledTooltip: FC<DisabledTooltipProps> = ({ children }) => (
  <Tooltip title={<DoNotDisturb />} enterDelay={300} followCursor>
    {children}
  </Tooltip>
);

export default DisabledTooltip;
