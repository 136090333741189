import React from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import { gray } from '../../constants';

const Icons = ({ iconData, namespace }: any) => {
  const { t } = useTranslation(websiteNamespaces);
  const largeScreen = useMediaQuery('(min-width:900px)');

  return (
    <Grid container paddingTop="1vw" paddingBottom="1vw">
      <Grid
        container
        xs={11}
        spacing={largeScreen ? 1 : 0}
        alignItems="flex-start"
      >
        {iconData.map((icon: any) => (
          <Grid container item key={icon.id} xs={12} md={6} alignItems="center">
            <Grid item xs={3} md={4} padding={largeScreen ? '7.5%' : '3%'}>
              <Box
                component="img"
                sx={{
                  width: '8vw',
                }}
                src={icon.icon}
              />
            </Grid>
            <Grid item xs={9} md={8}>
              <Typography variant="h4" color={gray}>
                {getTranslationLabel(namespace, `iconTitle${icon.id}`, t)}
              </Typography>
              <Typography variant="h5" color={gray}>
                {getTranslationLabel(namespace, `iconText${icon.id}`, t)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Icons;
