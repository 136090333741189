import { SubHeadingItem } from '../../../models/forms';
import {
  RawScoreAnalysis,
  Results,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOf } from '../../assessments/AssessmentCard/AssessmentCard-utils';

/* Multiple approaches to scoring:
https://www.sciencedirect.com/science/article/pii/S2210833514000057 (/10)
https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4346244/ (/13)
https://www.researchgate.net/publication/339582388_Automatic_Qualitative_Scoring_of_the_Interlocking_Pentagon_Drawing_Test_PDT_Based_on_U-Net_and_Mobile_Sensor_Data
(/6, /10, /13)
*/
const intersectingPentagonsTest: SubHeadingItem = {
  key: 'intersectingPentagonsTest',
  label: 'Intersecting Pentagons Test',
  fields: [
    ...getScoreAndOutOf('Pentagons test'),
    {
      label: 'pentagons drawing',
      key: 'pentagonsDrawingFile',
      fieldType: 'UPLOAD',
    },
    RawScoreAnalysis,
    ...Results,
  ],
};

export default intersectingPentagonsTest;
