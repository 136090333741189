import React, { FC } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { ReactQueryAutoSyncSaveStatus } from '../../hooks/auto-sync/utils/ReactQueryAutoSyncStatus';
import { getTranslationLabel } from '../../utils/utils';

interface AutoSaveStatusProps {
  saveStatus: ReactQueryAutoSyncSaveStatus;
}

const AutoSaveStatus: FC<AutoSaveStatusProps> = ({ saveStatus }) => {
  const { t } = useTranslation(allNamespaces);

  switch (saveStatus) {
    case 'unsaved':
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <ErrorIcon color="error" />
          <Typography variant="h4">
            {getTranslationLabel('generalNs', 'unsavedChanges', t)}
          </Typography>
        </Stack>
      );
    case 'saving':
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <AutorenewIcon color="warning" />
          <Typography variant="h4">
            {getTranslationLabel('generalNs', 'saving', t)}
          </Typography>
        </Stack>
      );
    case 'saved':
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <CheckCircleIcon color="success" />
          <Typography variant="h4">
            {getTranslationLabel('generalNs', 'saved', t)}
          </Typography>
        </Stack>
      );
    case 'loading':
      return (
        <Typography variant="h4">
          {getTranslationLabel('generalNs', 'loading', t)}
        </Typography>
      );
    case 'error':
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <ErrorIcon color="error" />
          <Typography variant="h4">
            {getTranslationLabel('generalNs', 'errorSaving', t)}
          </Typography>
        </Stack>
      );
    default:
      return <Typography variant="h4">{saveStatus}</Typography>;
  }
};

export default AutoSaveStatus;
