import {
  IAssessmentSummary,
  ICategories,
} from '../../models/assessments/assessment-summaries';
import { AssessmentCategoryKeys } from '../../models/assessments/assessments';
import { IOption } from '../../models/forms';
import {
  ASSESSMENT_DEFINITIONS,
  ASSESSMENT_IOPTION,
} from '../assessment-definitions/ASSESSMENT_DEFINITIONS';

export const getAssessmentKeys = (
  assessmentSummaryData: IAssessmentSummary[]
) => assessmentSummaryData.map((summary) => summary.assessmentKey);

export const getAssessmentLabel = (assessmentKey: string) => {
  const assessmentIOption = ASSESSMENT_IOPTION.find(
    (item) => item.key === assessmentKey
  );
  return assessmentIOption?.label || '';
};

export const getIOptionFromAssessmentList = (
  assessmentList: IAssessmentSummary[]
): IOption[] =>
  assessmentList.map((assessment) => ({
    key: assessment.assessmentKey,
    val: assessment.assessmentKey,
    label: assessment.assessmentLabel,
  }));

export const getAssessmentDefinition = (assessmentKey: string) =>
  ASSESSMENT_DEFINITIONS.find(
    (assessmentDefinition) => assessmentDefinition.key === assessmentKey
  );

export const getAssessmentsFromCustomGroup = (
  customGroups: any,
  group: string
) => {
  if (!group) return [];
  const groupObj = customGroups.find((item: any) => item.groupKey === group);
  const assessments = groupObj?.assessments;
  return assessments || [];
};

// Categorize assessments
export const categorizeAssessments = (
  assessments: IAssessmentSummary[]
): ICategories =>
  assessments.reduce(
    (categories: ICategories, assessment: IAssessmentSummary) => ({
      ...categories,
      [assessment.categoryKey]: {
        categoryKey: assessment.categoryKey,
        assessments: [
          ...(categories[assessment.categoryKey]?.assessments || []),
          assessment,
        ],
      },
    }),
    {} as ICategories
  );

// Sort assessment groups by order
export const compareAssessmentGroupOrder = (group1: string, group2: string) =>
  AssessmentCategoryKeys.indexOf(group1) -
  AssessmentCategoryKeys.indexOf(group2);

// Sort assessments
const compareAssessmentLabels = (
  summary1: IAssessmentSummary,
  summary2: IAssessmentSummary
) => {
  const label1 = summary1.assessmentLabel.toUpperCase();
  const label2 = summary2.assessmentLabel.toUpperCase();

  if (label1 === label2) {
    return 0;
  }

  return label1 < label2 ? -1 : 1;
};

export const sortAssessmentSummaries = (
  assessmentSummaries: IAssessmentSummary[]
) =>
  assessmentSummaries.sort(
    (summary1: IAssessmentSummary, summary2: IAssessmentSummary) =>
      compareAssessmentLabels(summary1, summary2)
  );
