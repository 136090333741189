import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

// 1. Speed regulation
const TASK_SPEED = ['Too slow', 'Too fast', 'Variable'];
export const TASK_SPEED_OPTIONS = getOptionsFromLabels(TASK_SPEED);
const SPEED_ROAD_CONDITIONS = ['Wet', 'Covered in snow', 'Potholes', 'Gravel'];
export const SPEED_ROAD_CONDITIONS_OPTIONS = getOptionsFromLabels(
  SPEED_ROAD_CONDITIONS
);

export const getSpeedFields = (key: string): FieldsLayout[] => [
  {
    label: 'Speed',
    key: `${key}Speed`,
    fieldType: 'CHOICE_CHIPS',
    options: TASK_SPEED_OPTIONS,
  },
  {
    label: 'Speed error compared to',
    key: `${key}SpeedComparedTo`,
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels(['Traffic', 'Speed limit', 'Road condition']),
    conditionals: [
      {
        label: 'Road condition',
        key: 'roadCondition',
        fieldType: 'CHOICE_CHIPS',
        options: SPEED_ROAD_CONDITIONS_OPTIONS,
        condition: (values: any) =>
          values[`${key}SpeedComparedTo`] === 'roadConditions',
      },
    ],
  },
  {
    label: 'Speed off by',
    key: `${key}SpeedOffBy`,
    fieldType: 'INPUT',
    units: <Typography>km / h</Typography>,
  },
];

// 2. Lane maintenance
const LANE_MAINTENANCE = [
  'Too far left',
  'Too far right',
  'Drifts to the left',
  'Drifts to the right',
  'Too close to a pedestrian',
  'Too close to a vehicle or object',
];
export const LANE_MAINTENANCE_OPTIONS = getOptionsFromLabels(LANE_MAINTENANCE);

// 3. Vehicle positioning (for a stop)
const getVehiclePositioningOptions = (key: string) =>
  getOptionsFromLabels([
    'Stops on stop line',
    'Stops past stop line',
    key !== 'railwayCrossing'
      ? 'Stops on pedestrian crossing'
      : 'Stops on railway crossing',
    'Too far behind the stop line',
    'Stops too close to another vehicle',
    'Creeps forward while waiting',
  ]);

// 4. Signaling
const SIGNALING = [
  'Not used',
  'Used too early',
  'Used too late',
  'Not cancelled',
  'Wrong signal',
];
export const SIGNALING_OPTIONS = getOptionsFromLabels(SIGNALING);

export const getCommonSignallingFields = (key: string): FieldsLayout[] => [
  {
    label: 'Signalling',
    key: `${key}Signalling`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: SIGNALING_OPTIONS,
  },
];

const OPERATIONAL_ERRORS_STOP = [
  'Abrupt stop',
  'Slow acceleration',
  'Heavy acceleration',
  'Wrong pedal selection',
];
export const OPERATIONAL_ERRORS_STOP_OPTIONS = getOptionsFromLabels(
  OPERATIONAL_ERRORS_STOP
);

const VISUAL_LABELS = [
  'Failed to check rear-view mirror',
  'Failed to scan for traffic and/or pedestrians',
];
export const VISUAL_LABELS_OPTIONS = getOptionsFromLabels(VISUAL_LABELS);

export const getIntersectionOptions = (key: string): FieldsLayout[] => [
  {
    label: 'Speed Regulation',
    key: `${key}Speed`,
    fieldType: 'CHOICE_CHIPS',
    options: [...TASK_SPEED_OPTIONS, ...OPERATIONAL_ERRORS_STOP_OPTIONS],
  },
  {
    label: 'Vehicle Positioning',
    key: `${key}VehiclePositionPositioning`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getVehiclePositioningOptions(key),
  },
  {
    label: 'Visual Scanning',
    key: `${key}VisualScanning`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: VISUAL_LABELS_OPTIONS,
  },
];
