import React, { FC, useContext, useState } from 'react';

import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { FieldsLayout } from '../../../models/forms';
import theme from '../../../theme';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../BasicDialog';

const removeCapitalFirstLetter = (s: string) =>
  s ? `${s[0].toLowerCase()}${s.slice(1)}` : '';

export interface SectionHeadingProps {
  title: string;
  selectAll?: string | string[];
  cells?: FieldsLayout[][][];
  fields?: FieldsLayout[];
}

export const SectionHeading: FC<SectionHeadingProps> = ({
  title,
  selectAll,
  cells,
  fields,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { setValue } = useContext(FormContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const translatedSelectAll =
    typeof selectAll === 'string'
      ? getTranslationLabel('generalNs', selectAll, t)
      : selectAll;

  const getChoiceChipFieldKeys = (flattenedFields?: FieldsLayout[]) =>
    flattenedFields?.reduce((result, field) => {
      if (field.fieldType === 'CHOICE_CHIPS') {
        result.push(field.key);
      }
      return result;
    }, [] as string[]);

  const getAllFieldKeys = (flattenedFields?: FieldsLayout[]) =>
    flattenedFields?.map((item) => item.key);

  const choiceChipsFieldKeys = cells
    ? getChoiceChipFieldKeys(cells.flat(2))
    : getChoiceChipFieldKeys(fields);
  const allFieldKeys = cells
    ? getAllFieldKeys(cells.flat(2))
    : getAllFieldKeys(fields);

  const handleSelectAll = () => {
    if (selectAll) {
      if (typeof selectAll === 'string') {
        choiceChipsFieldKeys?.forEach((key) => {
          setValue(key, removeCapitalFirstLetter(selectAll));
        });
      } else {
        fields?.forEach((field) => {
          const correctFieldOption = field.options?.find((option) =>
            selectAll.includes(option.key)
          );
          const correctFieldName = correctFieldOption
            ? correctFieldOption.key
            : '';
          setValue(field.key, removeCapitalFirstLetter(correctFieldName));
        });
      }
    }
  };

  const handleConfirmClear = () => {
    if (selectAll) {
      allFieldKeys?.forEach((key) => {
        setValue(key, '');
      });
    }
    setIsDialogOpen(false);
  };

  const handleClearSelection = () => {
    setIsDialogOpen(true);
  };

  const handleCancelClear = () => {
    setIsDialogOpen(false);
  };

  const getTitle = () =>
    title
      ? `${getTranslationLabel('generalNs', 'section', t)} ${title}`
      : getTranslationLabel('generalNs', 'thisAssessment', t);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        style={
          title || !fields
            ? { padding: '0px 20px 0px' }
            : { padding: '0px 0px 10px' }
        }
      >
        {(title || !fields) && <Typography variant="h2">{title}</Typography>}
        {selectAll && (
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={handleSelectAll}
            >
              {getTranslationLabel('generalNs', 'selectAll', t)} &quot;
              {typeof selectAll === 'string' ? translatedSelectAll : 'Okay'}
              &quot;
            </Button>
            &nbsp;
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={handleClearSelection}
            >
              {getTranslationLabel('generalNs', 'clearSelection', t)}
            </Button>
          </Grid>
        )}
      </Grid>
      <BasicDialog
        open={isDialogOpen}
        handleClose={handleCancelClear}
        label={
          <Typography variant="h2" component="span">
            {getTranslationLabel('generalNs', 'areYouSure', t)} {getTitle()}?
          </Typography>
        }
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.light,
          },
        }}
        actions={
          <>
            <Button onClick={handleCancelClear}>
              {getTranslationLabel('generalNs', 'cancel', t)}
            </Button>
            <Button onClick={handleConfirmClear} variant="outlined">
              {getTranslationLabel('generalNs', 'clear', t)}
            </Button>
          </>
        }
        fullWidth
        maxWidth="md"
      >
        <Typography>
          {getTranslationLabel('generalNs', 'clearAllMessage1', t)} {getTitle()}
          . {getTranslationLabel('generalNs', 'clearAllMessage2', t)}
        </Typography>
      </BasicDialog>
    </>
  );
};
