import React from 'react';

import { Grid, Box, Typography } from '@mui/material';

const Card = ({ text1, text2, text3, image, rightSide }: any) => {
  const imageWidth = 'min(100%, calc(100vw * 0.51515 + 290.909px))';
  const translationVal = `-0.5 * (${imageWidth} + 30vw)`;

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '2vw',
      }}
    >
      <Grid container justifyContent={rightSide ? 'flex-end' : 'flex-start'}>
        <Box
          component="img"
          sx={{
            width: imageWidth,
            height: 'auto',
          }}
          src={image}
        />
      </Grid>
      <Grid container justifyContent={rightSide ? 'flex-start' : 'flex-end'}>
        <div
          style={{
            backgroundColor: rightSide ? '#1d577d' : '#4f8dbf',
            opacity: 0.89,
            height: '30vw',
            width: 'min(100%, calc(100vw * 0.34848 + 390.909px))',
            padding: '2.5%',
            position: 'absolute',
            transform: `translateY(calc(${translationVal}))`,
          }}
        >
          <Typography variant="h2" color="white" padding="1.5%">
            {text1}
          </Typography>
          <Typography variant="h4" padding="1.5%">
            {text2}
          </Typography>
          <Typography variant="h5" padding="1.5%">
            {text3}
          </Typography>
        </div>
      </Grid>
    </div>
  );
};

export default Card;
