import React from 'react';

import { IClientMetaData } from '../../../models/client';
import {
  RevokeConsentButton,
  ConsentActionRequiredButton,
  ConsentRevokedCell,
} from './ConsentButtons';

// helpers
const isConsentGivenAndFormUploaded = (clientMetaData: IClientMetaData) =>
  clientMetaData.isConsentGiven === 'yes' &&
  clientMetaData.isConsentFormUploaded === 'yes';

const isConsentTBDOrNotStarted = (clientMetaData: IClientMetaData) =>
  clientMetaData.isConsentGiven === '' ||
  clientMetaData.isConsentGiven === undefined ||
  clientMetaData.isConsentGiven === 'toBeDetermined';

const isConsentGivenButFormNotUploaded = (clientMetaData: IClientMetaData) =>
  clientMetaData.isConsentGiven === 'yes' &&
  (clientMetaData.isConsentFormUploaded === '' ||
    clientMetaData.isConsentFormUploaded === undefined ||
    clientMetaData.isConsentFormUploaded === 'no');

// exports
export const getConsentButton = (
  clientMetaData: IClientMetaData,
  setIsRevokeModalOpen: (newState: boolean) => void,
  setIsConsentDialogOpen: (newState: boolean) => void
) => {
  if (isConsentGivenAndFormUploaded(clientMetaData)) {
    return <RevokeConsentButton setIsRevokeModalOpen={setIsRevokeModalOpen} />;
  }
  if (isConsentGivenButFormNotUploaded(clientMetaData)) {
    return (
      <ConsentActionRequiredButton
        setIsConsentDialogOpen={setIsConsentDialogOpen}
        colorVariant="main"
      />
    );
  }
  if (isConsentTBDOrNotStarted(clientMetaData)) {
    return (
      <ConsentActionRequiredButton
        setIsConsentDialogOpen={setIsConsentDialogOpen}
        colorVariant="dark"
      />
    );
  }
  return <ConsentRevokedCell />;
};

export const showConsentPrompt = (clientMetaData: IClientMetaData) =>
  isConsentTBDOrNotStarted(clientMetaData) ||
  isConsentGivenButFormNotUploaded(clientMetaData);
