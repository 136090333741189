import { useState } from 'react';

const useModal = (initialState = false) => {
  const [isShowing, setIsShowing] = useState(initialState);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  const show = () => {
    setIsShowing(true);
  };

  const close = () => {
    setIsShowing(false);
  };

  return {
    isShowing,
    toggle,
    close,
    show,
  };
};

export default useModal;
