import React, { FC } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogActions,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';

interface BasicDialogProps extends DialogProps {
  label: JSX.Element;
  open: boolean;
  actions?: JSX.Element;
  handleClose: () => void;
}

const BasicDialog: FC<BasicDialogProps> = ({
  label,
  open,
  actions = <></>,
  handleClose,
  children,
  ...rest
}) => (
  <Dialog onClose={handleClose} maxWidth="lg" fullWidth {...rest} open={open}>
    <DialogTitle>{label}</DialogTitle>
    {/* Bug in MUI padding of dialog title, using style as temporary fix: https://github.com/mui/material-ui/issues/27851 */}
    <DialogContent style={{ paddingTop: '5px' }}>{children}</DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

interface SpinningDialogProps {
  isSpinning: boolean;
  messageKey: string;
}

export const SpinningDialog: FC<SpinningDialogProps> = ({
  isSpinning,
  messageKey,
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <BasicDialog
      label={
        <Typography variant="h2">
          {getTranslationLabel('generalNs', messageKey, t)}
        </Typography>
      }
      maxWidth="sm"
      open={isSpinning}
      handleClose={() => {}}
    >
      {getTranslationLabel('generalNs', 'pleaseWait', t)}
    </BasicDialog>
  );
};

export default BasicDialog;
