import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';

interface StyledCardProps extends CardProps {}

const StyledListCard = styled(Card)<StyledCardProps>(({ theme }) => ({
  maxHeight: 60,
  width: '100%',
  color: theme.palette.primary.contrastText,
}));

export default StyledListCard;
