import React, { FC } from 'react';

import { useMsal } from '@azure/msal-react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import { useCustomGroups } from '../../../hooks/database/settings/customGroups';
import { ICustomGroup } from '../../../models/assessments/assessments';
import { getTranslationLabel } from '../../../utils/utils';
import CheckboxSection from '../../common/CheckboxSection';
import NewCustomGroup from '../../settings/AssessmentSettings/CustomGroups/NewCustomGroup';
import { isDefaultGroup } from '../../settings/AssessmentSettings/CustomGroups/utils';
import { getAssessmentsFromCustomGroup } from '../common';

interface CustomGroupsProps {
  assignedAssessmentKeys: string[];
  onChangeSelectAssessment: (
    assessmentKey: string,
    isSelecting: boolean
  ) => void;
}
const CustomGroups: FC<CustomGroupsProps> = ({
  assignedAssessmentKeys,
  onChangeSelectAssessment,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { accounts } = useMsal();
  const { username } = accounts[0];
  const {
    isLoading,
    isError,
    error,
    data: customGroups,
  } = useCustomGroups(username);

  if (isLoading) <LoadingComponent />;

  if (isError)
    <ErrorComponent
      errorMessage={getTranslationLabel(
        'assessmentsGeneral/assessClient',
        'problemGettingCustomGroups',
        t
      )}
      error={error}
    />;

  const onChangeSelectGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    getAssessmentsFromCustomGroup(customGroups, event.target.name).map(
      (assessment: any) =>
        onChangeSelectAssessment(assessment, event.target.checked)
    );
  };

  // Checks if a group of clinical assessments created by the OT is selected or not.
  const isGroupChecked = (group: ICustomGroup) =>
    group.assessments
      ? group.assessments.reduce(
          (result, assessment) =>
            result && assignedAssessmentKeys.includes(assessment),
          true
        )
      : false;

  // Returns the key,val,label for each of the users custom groups
  const getIOptionFromGroup = () =>
    customGroups
      ? customGroups
          .filter((group) => !isDefaultGroup(group.groupKey))
          .map((group) => ({
            key: group.groupKey,
            val: group.groupKey,
            label: group.groupLabel,
          }))
      : [];

  // Returns the custom groups that are checked (meaning, all the assessments for that group have been added)
  const getCheckedCustomGroups = () =>
    customGroups
      ? customGroups
          .filter((group) => isGroupChecked(group))
          .map((group) => group.groupKey)
      : [];

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'myCustomGroups',
            t
          )}
        </Typography>
      </Grid>
      {customGroups && customGroups.length === 0 ? (
        <>
          <Grid item>
            <Typography>
              {getTranslationLabel(
                'assessmentsGeneral/assessClient',
                'noCustomGroupsCreated',
                t
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {getTranslationLabel(
                'assessmentsGeneral/assessClient',
                'startCreatingCustomGroups',
                t
              )}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <CheckboxSection
              sectionKey="customGroups"
              loPossibleItems={getIOptionFromGroup()}
              loCheckedItems={getCheckedCustomGroups()}
              onChangeItem={onChangeSelectGroup}
            />
          </Grid>
        </>
      )}
      <Grid item>
        <NewCustomGroup />
      </Grid>
    </Grid>
  );
};

export default CustomGroups;
