import React, { FC } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Paper, PaperProps, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface PaperHeaderProps extends PaperProps {
  title: string;
  clientId: string;
  backLink: string | false;
  topRightComponent?: JSX.Element;
}

export const PaperHeader: FC<PaperHeaderProps> = ({
  title,
  clientId,
  backLink,
  topRightComponent,
  ...rest
}) => (
  <Paper square style={{ padding: '10px' }} elevation={1} {...rest}>
    <Grid item container justifyContent="space-between" alignItems="flex-end">
      <Grid item>
        {backLink !== false && (
          <IconButton
            color="secondary"
            aria-label="back"
            component={Link}
            to={backLink}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h2">{title}</Typography>
          <Typography>{clientId}</Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ paddingRight: 3 }}>
        {topRightComponent || <></>}
      </Grid>
    </Grid>
  </Paper>
);
