import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const travelAnxietyQuestionnaire: SubHeadingItem = {
  key: 'travelAnxietyQuestionnaire',
  label: 'Travel Anxiety Questionnaire (TAQ)',
  fields: [
    {
      label: 'Driving avoidance score',
      key: 'drivingAvoidnaceScore',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 32</Typography>,
    },
    {
      label: 'Driving anxiety score',
      key: 'drivingAnxietyScore',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 32</Typography>,
    },
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 64</Typography>,
      valueDependsOn: ['drivingAvoidnaceScore', 'drivingAnxietyScore'],
    },
    ...Results,
  ],
};

export default travelAnxietyQuestionnaire;
