import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, IOption, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getKeysFromOptions } from '../../assessments/AssessmentCard/AssessmentCard-utils';

// source: research paper saved onto one drive
const CESD_RESPONSES: string[] = [
  '0: Rarely or none of the time (less than 1 day)',
  '1: Some or a little of the time (1-2 days)',
  '2: Occasionally or a moderate amount (3-4 days)',
  '3: Most or all of the time (5-7 days)',
];

const CESD_OPTIONS: IOption[] = CESD_RESPONSES.map((response, i) => ({
  key: `r${i}`,
  val: i.toString(),
  label: response,
}));

const CESD_QUESTIONS: string[] = [
  "I was bothered by things that usually don't bother me.",
  'I did not feel like eating; my appetite was poor.',
  'I felt that I could not shake off the blues even with help from my family or friends.',
  'I felt that I was just as good as other people.',
  'I had trouble keeping my mind on what I was doing.',
  'I felt depressed.',
  'I felt that everything I did was an effort.',
  'I felt hopeful about the future.',
  'I thought my life had been a failure.',
  'I felt fearful.',
  'My sleep was restless.',
  'I was happy.',
  'I talked less than usual.',
  'I felt lonely.',
  'People were unfriendly.',
  'I enjoyed life.',
  'I had crying spells.',
  'I felt sad.',
  'I felt that people dislike me.',
  "I could not get 'going'.",
];

const CESD_FIELDS: FieldsLayout[] = CESD_QUESTIONS.map((question, i) => ({
  key: `question${i + 1}`,
  label: question,
  fieldType: 'RADIO',
  options: CESD_OPTIONS,
}));

const centerForEpidemiologicStudiesDepression: SubHeadingItem = {
  key: 'centerForEpidemiologicStudiesDepression',
  label: 'Center for Epidemiologic Studies - Depression (CES-D)',
  fields: [
    {
      label: 'During the past week:',
      key: 'prompt',
      fieldType: 'LABEL',
    },
    ...CESD_FIELDS,
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 60</Typography>,
      valueDependsOn: getKeysFromOptions(CESD_FIELDS),
    },
    ...Results,
  ],
};

export default centerForEpidemiologicStudiesDepression;
