import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import {
  PASS_FAIL,
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getRef } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const snellgroveMazeTest: SubHeadingItem = {
  key: 'snellgroveMazeTest',
  label: 'Snellgrove Maze Test',
  fields: [
    {
      label: 'Task completed?',
      key: 'isTaskCompleted',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      label: 'Time to complete task',
      key: 'time',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Number of errors',
      key: 'numberOfErrors',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'completed maze',
      key: 'snellgroveCompletedMazeFile',
      fieldType: 'UPLOAD',
    },
    {
      key: 'interpretationSnellgroveMazeTest',
      label: 'Analysis of the raw score',
      fieldType: 'RADIO',
      options: PASS_FAIL,
      valueDependsOn: ['isTaskCompleted', 'numberOfErrors', 'time'],
      units: getRef(
        'Must be completed in under 60 seconds with at most 1 error'
      ),
    },
    ...Results,
  ],
};

export default snellgroveMazeTest;
