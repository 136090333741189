import React, { FC } from 'react';

import { FieldsLayout, IOption } from '../../../models/forms';
import MyTable from './MyTable';
import PrimitiveField from './PrimitiveField';

interface FieldContainerProps {
  name: string;
  namespace: string;
  label: string;
  type: string;
  options?: IOption[];
  units?: JSX.Element;
  defaultValue?: any;
  clientId?: string;
  selectAll?: string;
  valueDependsOn?: string[];
  columnHeadings?: string[];
  cells?: FieldsLayout[][][];
}

// Gets the primitive field component for placing in a react-hook-form
const FieldContainer: FC<FieldContainerProps> = ({
  name,
  namespace,
  label,
  type,
  selectAll,
  columnHeadings,
  cells,
  ...rest
}) => {
  switch (type) {
    case 'TABLE': {
      return (
        <MyTable
          name={name}
          namespace={namespace}
          title={label}
          selectAll={selectAll}
          columnHeadings={columnHeadings}
          cells={cells}
        />
      );
    }
    default:
      return (
        <PrimitiveField
          name={name}
          namespace={namespace}
          label={label}
          type={type}
          {...rest}
        />
      );
  }
};

export default FieldContainer;
