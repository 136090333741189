import { FieldsLayout } from '../../models/forms';
import { YES_NO } from '../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../assessments/AssessmentCard/AssessmentCard-utils';

const RESULTS_FIELDS: FieldsLayout[] = [
  {
    key: 'clinicalAssessmentOverallResult',
    label: 'Clinical Assessment Overall Result',
    fieldType: 'RADIO',
    options: getOptionsFromLabels([
      'Proceed with the road test',
      'Do not proceed with the road test',
      'Undetermined',
    ]),
  },
  {
    label: 'Comments',
    key: 'commentsClinicalAssessmentResults',
    fieldType: 'TEXT-AREA',
  },
  {
    key: 'overallAssessmentRecommendations',
    label: 'Overall Assessment Recommendations',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Functional driving skills',
      'Functional driving skills (poor driving habits)',
      'Novice driver training required',
      'Rehabilitation candidate',
      'Driving not recommended (functional deficits)',
      'Driving not recommended (medical deficits)',
      'Driving not recommended (visual deficits)',
    ]),
    conditionals: [
      {
        label: 'Temporary Diversion Licence',
        key: 'temproraryDiversionLicence',
        fieldType: 'RADIO',
        options: getOptionsFromLabels([
          'TDL Required for 60 days',
          'TDL Required for 90 days',
        ]),
        condition: (values: any) =>
          values.rehabilitationCandidate &&
          (values.rehabilitationCandidate === true ||
            values.rehabilitationCandidate === '1'),
      },
    ],
  },
  {
    key: 'discussedWithPatient',
    label: 'Have the results been discussed with the patient?',
    fieldType: 'RADIO',
    options: YES_NO,
  },
];

export default RESULTS_FIELDS;
