import React, { FC, useEffect, useContext } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import {
  uploadFileToStorage,
  deleteFileFromStorage,
  getFileFromStorage,
} from '../../../auth/StorageAPICall';
import useAlert from '../../../hooks/useAlert';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import BasicAlert from '../BasicAlert';
import { TEXT_FIELD_WIDTH } from '../FieldContainer/PrimitiveField-constants';
import { ConfirmFileOpModal } from './ConfirmFileOpModal';
import { FileOpInProgress } from './FileOpInProgressModal';
import { FILE_SIZE_IN_MB } from './FileUpload-constants';
import { verifyFileExtension, verifyFileName } from './FileUpload-utils';

interface FileUploadProps {
  label: string;
  name: string;
  namespace: string;
  placeholder?: string;
  shrinkLabel?: boolean;
  clientId?: string;
}

const FileUpload: FC<FileUploadProps> = ({
  shrinkLabel,
  label,
  clientId,
  name,
  namespace,
  ...rest
}) => {
  const { t } = useTranslation(allNamespaces);
  const MAX_FILE_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;

  const { setValue, getValues, watch, register } = useContext(FormContext);
  const { instance, accounts } = useMsal();
  const { alertState, handleCloseAlert, showAlert } = useAlert();

  const fileNameFieldName = `${name}FileName`;
  useEffect(() => {
    if (getValues(fileNameFieldName) === undefined) {
      setValue(fileNameFieldName, '');
    }
  }, [fileNameFieldName, getValues, setValue]);

  // Spot to store the file between user selecting it in input box and the start of the actual upload
  const [fileObjOnTheState, setFileObjOnTheState] = React.useState<File>();
  // URI to image once it been downloaded from Azure
  const [imageURI, setImageURI] = React.useState<string>('');
  const [isImageOpen, setIsImageOpen] = React.useState<boolean>(false);
  const [isFileUploadInProgress, setIsFileUploadInProgress] =
    React.useState<boolean>(false);
  const [isFileDownloadInProgress, setIsFileDownloadInProgress] =
    React.useState<boolean>(false);
  const [isConfirmUploadOpen, setIsConfirmUploadOpen] =
    React.useState<boolean>(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] =
    React.useState<boolean>(false);

  // 1. Direct API Calls (PUT, GET, DELETE)
  const putAPICall = (file: File, fileName: string) =>
    new Promise<Response>((fulfill, reject) => {
      instance
        .acquireTokenSilent({
          scopes: ['https://storage.azure.com/.default'],
          account: accounts[0] as AccountInfo,
        })
        .then((response) => {
          uploadFileToStorage(
            response.accessToken,
            accounts[0].username,
            file,
            fileName,
            file.size
          ).then((uploadResponse: Response | Promise<Response>) =>
            fulfill(uploadResponse)
          );
        })
        .catch((err) => reject(err));
    });

  const getAPICall = () =>
    new Promise<Blob>((fulfill, reject) => {
      instance
        .acquireTokenSilent({
          scopes: ['https://storage.azure.com/.default'],
          account: accounts[0] as AccountInfo,
        })
        .then((response) => {
          getFileFromStorage(
            response.accessToken,
            accounts[0].username,
            getValues(fileNameFieldName)
          ).then((getResponse) => fulfill(getResponse.blob()));
        })
        .catch((err) => reject(err));
    });

  const deleteAPICall = () =>
    new Promise<Response>((fulfill, reject) => {
      instance
        .acquireTokenSilent({
          scopes: ['https://storage.azure.com/.default'],
          account: accounts[0] as AccountInfo,
        })
        .then((response) => {
          deleteFileFromStorage(
            response.accessToken,
            accounts[0].username,
            getValues(fileNameFieldName)
          ).then((deleteResponse: Response | Promise<Response>) =>
            fulfill(deleteResponse)
          );
        })
        .catch((err) => reject(err));
    });

  // 2. Confirm Upload Dialog Box
  const openConfirmUpload = () => {
    setIsConfirmUploadOpen(true);
  };
  const handleConfirmUploadProceed = () => {
    setIsConfirmUploadOpen(false);
    if (!fileObjOnTheState) {
      showAlert('error', 'No file to upload found, try again please.');
      return;
    }
    const extension = fileObjOnTheState.name.split('.').pop();
    const fileName = `${clientId}_${name}.${extension}`;

    if (imageURI) {
      window.URL.revokeObjectURL(imageURI);
      setImageURI('');
    }
    setIsFileUploadInProgress(true);

    putAPICall(fileObjOnTheState, fileName)
      .then((response) => {
        setIsFileUploadInProgress(false);
        setFileObjOnTheState(undefined);
        setValue(name, '');
        if (response.status === 200) {
          setValue(fileNameFieldName, fileName);
          showAlert('success', 'File uploaded successfully!');
        } else {
          showAlert('error', `ERROR: ${response.status}`);
        }
      })
      .catch((err) => {
        setIsFileUploadInProgress(false);
        setFileObjOnTheState(undefined);
        setValue(name, '');
        showAlert('error', `ERROR: ${err}`);
      });
  };
  const handleConfirmUploadCancel = () => {
    setIsConfirmUploadOpen(false);
    setFileObjOnTheState(undefined);
    setValue(name, '');
  };

  // 3. Confirm Delete Dialog Box
  const openConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };
  const handleConfirmDeleteProceed = () => {
    setIsConfirmDeleteOpen(false);
    deleteAPICall()
      .then((response) => {
        if (response.status === 200) {
          window.URL.revokeObjectURL(imageURI);
          setImageURI('');
          setValue(fileNameFieldName, '');
          setValue(name, '');
          showAlert('success', 'File deleted successfully!');
        } else {
          showAlert('error', `ERROR: ${response.status}`);
        }
      })
      .catch((err) => showAlert('error', `ERROR: ${err}`));
  };
  const handleConfirmDeleteCancel = () => {
    setIsConfirmDeleteOpen(false);
  };

  // 4. Handle Initial Apperance of a File
  const handleOnChange = (event: any) => {
    const file: File = event.currentTarget.files[0];

    if (!verifyFileName(file.name)) {
      showAlert(
        'error',
        `Sorry, the name of the file must consist of only alpha-numeric characters, spaces,
        underscores, dashes or periods and must be less than 255 characters long.`
      );
      setValue(name, '');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      showAlert(
        'error',
        'Sorry, we are only able to accept files that are no greater than 10 MB.'
      );
      setValue(name, '');
      return;
    }

    verifyFileExtension(file).then((result) => {
      if (!result) {
        showAlert(
          'error',
          'Sorry, we are only able to accept image or PDF files.'
        );
        setValue(name, '');
      } else {
        setFileObjOnTheState(file);
        openConfirmUpload();
      }
    });
  };

  // 5. Handlers Tied to Link "Buttons" (Download, Show, Delete) - one per button
  const handleDownloadFile = () => {
    getAPICall()
      .then((file) => {
        const fileUrl = window.URL.createObjectURL(file);

        const linkElement = document.createElement('a');
        linkElement.href = fileUrl;
        linkElement.setAttribute('download', `${getValues(fileNameFieldName)}`);
        document.body.appendChild(linkElement);
        linkElement.click();
        if (linkElement.parentNode) {
          linkElement.parentNode.removeChild(linkElement);
        }

        window.URL.revokeObjectURL(fileUrl);
      })
      .catch((err) => showAlert('error', `ERROR: ${err}`));
  };

  const handleShowImage = () => {
    if (!isImageOpen) {
      if (!imageURI) {
        setIsFileDownloadInProgress(true);
        getAPICall()
          .then((file) => {
            setImageURI(window.URL.createObjectURL(file));
            setIsFileDownloadInProgress(false);
          })
          .catch((err) => showAlert('error', `ERROR: ${err}`));
      }
      setIsImageOpen(true);
    } else {
      setIsImageOpen(false);
    }
  };

  const handleDeleteFile = () => {
    openConfirmDelete();
  };

  const isNotPDF = () =>
    getValues(fileNameFieldName).split('.').pop() !== 'pdf';

  const getLabel = () =>
    getValues(fileNameFieldName)
      ? t(`${namespace}:${name}`, `Overwrite existing ${label}`)
      : t(`${namespace}:${name}`, `Upload ${label}`);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <TextField
            className="input"
            color="secondary"
            InputLabelProps={{
              shrink: shrinkLabel,
            }}
            type="file"
            style={{ width: TEXT_FIELD_WIDTH }}
            label={getLabel()}
            autoComplete="off"
            data-testid={name}
            {...rest}
            {...register(name)}
            onChange={handleOnChange}
          />
        </Grid>
        {watch(fileNameFieldName) && watch(fileNameFieldName) !== '' && (
          <Grid item container>
            <Grid item>
              <CheckCircleIcon color="secondary" />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {`Uploaded ${label}`}:
                <Button
                  variant="text"
                  size="small"
                  onClick={handleDownloadFile}
                >
                  {isNotPDF()
                    ? getTranslationLabel('fileUpload', 'downloadImage', t)
                    : getTranslationLabel('fileUpload', 'downloadFile', t)}
                </Button>
                {isNotPDF() && (
                  <Button variant="text" size="small" onClick={handleShowImage}>
                    {isImageOpen
                      ? getTranslationLabel('fileUpload', 'hideImage', t)
                      : getTranslationLabel('fileUpload', 'showImage', t)}
                  </Button>
                )}
                <Button variant="text" size="small" onClick={handleDeleteFile}>
                  {isNotPDF()
                    ? getTranslationLabel('fileUpload', 'deleteImage', t)
                    : getTranslationLabel('fileUpload', 'deleteFile', t)}
                </Button>
              </Typography>
              <Typography>
                {isImageOpen && (
                  <img alt="drawing" width="50%" height="auto" src={imageURI} />
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <FileOpInProgress
        show={isFileUploadInProgress}
        title={getTranslationLabel('fileUpload', 'uploadingTitle', t)}
        text={getTranslationLabel('fileUpload', 'uploadingText', t)}
      />
      <FileOpInProgress
        show={isFileDownloadInProgress}
        title={getTranslationLabel('fileUpload', 'downloadingTitle', t)}
        text={getTranslationLabel('fileUpload', 'downloadingText', t)}
      />
      <ConfirmFileOpModal
        show={isConfirmUploadOpen}
        title={getTranslationLabel('fileUpload', 'confirmUploadTitle', t)}
        text={getTranslationLabel('fileUpload', 'confirmUploadText', t)}
        handleCancel={handleConfirmUploadCancel}
        handleConfirm={handleConfirmUploadProceed}
      />
      <ConfirmFileOpModal
        show={isConfirmDeleteOpen}
        title={getTranslationLabel('fileUpload', 'confirmDeleteTitle', t)}
        text={getTranslationLabel('fileUpload', 'confirmDeleteText', t)}
        handleCancel={handleConfirmDeleteCancel}
        handleConfirm={handleConfirmDeleteProceed}
      />
      {alertState.open && (
        <BasicAlert
          alertKey={name}
          open={alertState.open}
          handleClose={handleCloseAlert}
          variant={alertState.variant}
          message={alertState.message}
        />
      )}
    </>
  );
};

export default FileUpload;
