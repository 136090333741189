import React, { FC } from 'react';

import {
  CardActionArea,
  CardActionAreaProps,
  CardContent,
} from '@mui/material';

import StyledListCard from './StyledListCard';

interface ClickableCardProps extends CardActionAreaProps {
  id: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  clicked: boolean;
}

const ClickableCard: FC<ClickableCardProps> = ({
  id,
  onClick,
  clicked,
  children,
  ...rest
}) => (
  <StyledListCard
    sx={{
      backgroundColor: clicked ? 'secondary.light' : 'primary.light',
    }}
  >
    <CardActionArea onClick={onClick} key={id} value={id} {...rest}>
      {children && <CardContent>{children}</CardContent>}
    </CardActionArea>
  </StyledListCard>
);

export default ClickableCard;
