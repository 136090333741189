import React from 'react';

import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';

const EmptyMainPane = () => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Stack>
      <Typography variant="h3">
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'noAssessmentsSelected',
          t
        )}
      </Typography>
    </Stack>
  );
};

export default EmptyMainPane;
