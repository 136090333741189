import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { IListRefs, IManeuverSummary } from '../../../models/on-road/route';
import { useReactQueryAutoSync } from '../../auto-sync/useReactQueryAutoSync';
import useToken from '../../useToken';
import {
  MAX_WAIT,
  SHORT_WAIT,
  axiosDeleteCall,
  axiosGetAllCall,
  axiosGetCall,
  axiosPostCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

export const allTab4TacticalKeys = ['tab4'] as const;

const maneuverSummariesKeys = (roadTestId: string) =>
  [...allTab4TacticalKeys, 'maneuverSummaries', roadTestId] as const;

const listRefKeys = (roadTestId: string) =>
  [...allTab4TacticalKeys, 'listRefs', roadTestId] as const;

const maneuverSummaryKeys = (maneuverId: string) =>
  [...allTab4TacticalKeys, 'maneuverSummary', maneuverId] as const;

const maneuverSummariesUrl = (roadTestId: string) =>
  `/api/on-road/${roadTestId}/maneuvers-summaries`;

const maneuverSummariesCopyUrl = (roadTestId: string) =>
  `/api/on-road/${roadTestId}/maneuvers-summaries/copy`;

const maneuverSummaryUrl = (roadTestId: string, maneuverId: string) =>
  `/api/on-road/${roadTestId}/maneuvers-summaries/${maneuverId}`;

const listRefsUrl = (roadTestId: string) =>
  `/api/on-road/${roadTestId}/list-refs`;

const deleteAfterUrl = (roadTestId: string, refManeuverId: string) =>
  `/api/on-road/${roadTestId}/maneuvers-summaries/${refManeuverId}/after`;

// Gets
export const useManeuverSummary = (roadTestId: string, maneuverId: string) => {
  const getSilentToken = useToken();
  const queryClient = useQueryClient();

  return useReactQueryAutoSync({
    queryOptions: {
      queryKey: maneuverSummaryKeys(maneuverId),
      queryFn: () =>
        getSilentToken
          .then((response) =>
            axiosGetCall(
              maneuverSummaryUrl(roadTestId, maneuverId),
              response.accessToken
            )
          )
          .catch((err) => {
            throw new Error(err);
          }),
    },
    mutationOptions: {
      mutationFn: (maneuver: IManeuverSummary) =>
        getSilentToken
          .then((response) =>
            axiosPutCall(
              maneuverSummaryUrl(roadTestId, maneuver.maneuverId),
              maneuver,
              response.accessToken
            )
          )
          .catch((err) => {
            throw new Error(err);
          }),
      onSuccess: () =>
        queryClient.invalidateQueries(maneuverSummaryKeys(roadTestId)),
    },
    autoSaveOptions: {
      wait: SHORT_WAIT,
      maxWait: MAX_WAIT,
    },
    merge: (remote, local) => ({
      ...remote,
      ...local,
    }),
  });
};

export const useManeuverSummaries = (roadTestId: string) =>
  useQueryWithToken(maneuverSummariesKeys(roadTestId), (token: string) =>
    axiosGetAllCall(maneuverSummariesUrl(roadTestId), token)
  );

export const useListRefs = (roadTestId: string) =>
  useQueryWithToken(listRefKeys(roadTestId), (token: string) =>
    axiosGetCall(listRefsUrl(roadTestId), token)
  );

// Adds
export const useCreateManeuverSummary = (roadTestId: string) =>
  useMutationWithToken(
    allTab4TacticalKeys,
    (maneuverSummary: IManeuverSummary, token: string) =>
      axiosPostCall(
        maneuverSummariesUrl(maneuverSummary.roadTestId),
        maneuverSummary,
        token
      )
  );

const createManeuverSummaries = async (
  maneuverSummaries: IManeuverSummary[],
  token: string
) => {
  const addManeuverSummariesCalls = maneuverSummaries.map((maneuverSummary) =>
    axiosPostCall(
      maneuverSummariesCopyUrl(maneuverSummary.roadTestId),
      maneuverSummary,
      token
    )
  );

  return axios
    .all(addManeuverSummariesCalls)
    .then(axios.spread((...responses) => responses));
};

export const useCreateManeuverSummaries = (roadTestId: string) =>
  useMutationWithToken(
    allTab4TacticalKeys,
    (maneuverSummaries: IManeuverSummary[], token: string) =>
      createManeuverSummaries(maneuverSummaries, token)
  );

// Updates
export const useUpdateListRefs = (roadTestId: string) =>
  useMutationWithToken(
    listRefKeys(roadTestId),
    (listRefs: IListRefs, token: string) =>
      axiosPutCall(listRefsUrl(listRefs.roadTestId), listRefs, token)
  );

export const useUpdateManeuverSummary = (roadTestId: string) =>
  useMutationWithToken(
    allTab4TacticalKeys,
    (maneuverSummary: IManeuverSummary, token: string) =>
      axiosPutCall(
        maneuverSummaryUrl(
          maneuverSummary.roadTestId,
          maneuverSummary.maneuverId
        ),
        maneuverSummary,
        token
      )
  );

// Deletes
export const useDeleteManeuverSummary = (roadTestId: string) =>
  useMutationWithToken(
    allTab4TacticalKeys,
    (summary: IManeuverSummary, token: string) =>
      axiosDeleteCall(
        maneuverSummaryUrl(summary.roadTestId, summary.maneuverId),
        token
      )
  );

export const useDeleteManeuverSummaries = (roadTestId: string) =>
  useMutationWithToken(allTab4TacticalKeys, (dummy: string, token: string) =>
    axiosDeleteCall(maneuverSummariesUrl(roadTestId), token)
  );

export const useDeleteManeuverSummariesAfterThisOne = (roadTestId: string) =>
  useMutationWithToken(
    allTab4TacticalKeys,
    (summary: IManeuverSummary, token: string) =>
      axiosDeleteCall(
        deleteAfterUrl(summary.roadTestId, summary.refManeuverId),
        token
      )
  );
