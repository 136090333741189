import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import FormSection from '../../../common/FormSection';
import { getTaskMetaFields } from '../../Constants/utils';

interface ManeuverMetaProps {
  maneuverType: string;
  subType: string;
}

const ManeuverMeta: FC<ManeuverMetaProps> = ({ maneuverType, subType }) => {
  const { t } = useTranslation(allNamespaces);
  const taskMetaFields = getTaskMetaFields(maneuverType);

  return (
    <Grid item container direction="column">
      {taskMetaFields && taskMetaFields.length > 0 ? (
        <>
          <Grid item>
            <Typography variant="h3">
              {getTranslationLabel(
                'maneuvers/maneuversGeneral',
                'maneuverDetails',
                t
              )}
            </Typography>
          </Grid>
          <FormSection
            fields={taskMetaFields}
            values={subType}
            namespace={`maneuvers/${maneuverType}`}
            sectionKey="onRoadManeuverDetails"
            commentsName="maneuverSummaryComments"
          />
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ManeuverMeta;
