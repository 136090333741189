import { SubHeadingItem } from '../../../models/forms';
import { VisualResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import VISION_SCREENING_DEVICE_FIELD from './Visual-Utils';

const verticalAndLateralPhoria: SubHeadingItem = {
  key: 'verticalAndLateralPhoria',
  label: 'Vertical and Lateral Phoria',
  fields: [
    VISION_SCREENING_DEVICE_FIELD,
    {
      key: 'verticalPhoria',
      label: 'Vertical phoria',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Normal',
        'Left hyperphoria',
        'Right hyperphoria',
      ]),
    },
    {
      key: 'verticalPhoriaMeasurement',
      label: 'Vertical phoria prism diopter',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels([
        '1.5 Left',
        '1 Left',
        '0.5 Left',
        '0',
        '0.5 Right',
        '1 Right',
        '1.5 Right',
      ]),
    },
    {
      key: 'lateralPhoria',
      label: 'Lateral phoria',
      fieldType: 'RADIO',
      options: getOptionsFromLabels(['Normal', 'Esophoria', 'Exophoria']),
    },
    {
      key: 'lateralPhoriaMeasurement',
      label: 'Lateral phoria prism diopter',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels([
        '7 Eso',
        '6 Eso',
        '5 Eso',
        '4 Eso',
        '3 Eso',
        '2 Eso',
        '1 Eso',
        '0',
        '1 Exo',
        '2 Exo',
        '3 Exo',
        '4 Exo',
        '5 Exo',
        '6 Exo',
        '7 Exo',
      ]),
    },
    ...VisualResults,
  ],
};

export default verticalAndLateralPhoria;
