import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { IRoadTest } from '../../../models/on-road/on-road';
import TacticalManeuversMain from './TacticalManeuversMain';

interface Tab4TacticalManeuversProps {
  roadTest: IRoadTest;
}
const Tab4TacticalManeuvers: FC<Tab4TacticalManeuversProps> = ({
  roadTest,
}) => {
  if (roadTest.roadTestId === undefined) {
    return (
      <Typography>
        The road test you are trying to edit does not exist. Try going back to
        your clients page and opening the road test again.
      </Typography>
    );
  }

  return <TacticalManeuversMain roadTestId={roadTest.roadTestId} />;
};

export default Tab4TacticalManeuvers;
