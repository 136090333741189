import React, { FC, useState } from 'react';

import { FormControl, Grid, Paper } from '@mui/material';
import { nanoid } from 'nanoid';

import { useCreateAssessmentSummary } from '../../hooks/database/assessments/assessmentSummaries';
import { IAssessmentSummary } from '../../models/assessments/assessment-summaries';
import AssessmentPicker from './AssessmentsSideBar/AssessmentPicker';
import CustomGroups from './AssessmentsSideBar/CustomGroups';
import { getAssessmentKeys } from './common';
import { DeleteAssessments } from './DeleteAssessment/DeleteAssessments';

interface AssessmentsSideBarProps {
  clientId: string;
  assessmentSummaries: IAssessmentSummary[];
}

const AssessmentsSideBar: FC<AssessmentsSideBarProps> = ({
  clientId,
  assessmentSummaries,
}) => {
  const createMutation = useCreateAssessmentSummary(clientId);
  const [keysOfAssessmentsToDelete, setKeysOfAssessmentToDelete] = useState<
    string[]
  >([]);

  const assignedAssessmentKeys = getAssessmentKeys(assessmentSummaries);

  const createAssessmentSummaryIfDNE = (assessmentKey: string) => {
    if (assignedAssessmentKeys.includes(assessmentKey)) {
      return;
    }
    createMutation.mutate({
      clientId,
      assessmentId: nanoid(),
      assessmentKey,
      resultSummary: 'undetermined',
    });
  };

  const onChangeSelectAssessment = (
    assessmentKey: string,
    isSelecting: boolean
  ) => {
    if (isSelecting) {
      createAssessmentSummaryIfDNE(assessmentKey);
    } else {
      setKeysOfAssessmentToDelete((prevAssessments) => [
        ...prevAssessments,
        assessmentKey,
      ]);
    }
  };

  return (
    <Grid item xs={12} lg={3}>
      <Paper square style={{ padding: '20px' }}>
        <FormControl component="fieldset">
          <Grid item container direction="column" spacing={3}>
            <CustomGroups
              assignedAssessmentKeys={assignedAssessmentKeys}
              onChangeSelectAssessment={onChangeSelectAssessment}
            />
            <AssessmentPicker
              assignedAssessmentKeys={assignedAssessmentKeys}
              onChangeSelectAssessment={onChangeSelectAssessment}
            />
          </Grid>
        </FormControl>
      </Paper>
      <DeleteAssessments
        clientId={clientId}
        keysOfAssessmentsToDelete={keysOfAssessmentsToDelete}
        setKeysOfAssessmentToDelete={setKeysOfAssessmentToDelete}
      />
    </Grid>
  );
};

export default AssessmentsSideBar;
