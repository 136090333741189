import { SubHeadingItem } from '../../../../models/forms';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getSpeedFields, LANE_MAINTENANCE_OPTIONS } from './utils';

const CURVED_ROAD_ERRORS: SubHeadingItem = {
  key: 'curvedRoad',
  label: 'Curved Road',
  fields: [
    ...getSpeedFields('curvedRoad'),
    {
      label: 'Lane Maintenance',
      key: 'curvedRoadLaneMaintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: LANE_MAINTENANCE_OPTIONS,
    },
    {
      label: 'Vehicle Positioning',
      key: convertLabelToKey('Vehicle Positioning'),
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Follows too closely',
        'Drives in blindspot of another vehicle',
      ]),
    },
    {
      label: 'Visual Scanning',
      key: convertLabelToKey('Visual Scanning'),
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels(['Insufficient visual scanning']),
    },
  ],
};

export default CURVED_ROAD_ERRORS;
