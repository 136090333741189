export const darkBlue = "#1d577d";
export const lightBlue = "#4f8dbf";
export const gray = "#707070";

export const paddingTopVal = "1.75vw";
export const cardSpacingVal = 2.5;

export const veryBold = 700;
export const bold = 600;
export const normal = 500;
export const light = 400;

export const large = "4vw";
export const medium = "max(10px, 2vw)";
export const small = "max(10px, 1.5vw)";

export const containerWidth = 95;
