import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2170069/
// typically out of 15 points
const getClockFields = (no: number): SubHeadingItem => ({
  label: `Clock ${no}`,
  key: `clock${no}`,
  fields: [
    {
      label: `Clock ${no} score`,
      key: `clock${no}Score`,
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 15 </Typography>,
    },
    {
      label: `clock ${no} drawing`,
      key: `cLOXClock${no}DrawingFile`,
      fieldType: 'UPLOAD',
    },
    {
      label: 'Analysis of the raw score',
      key: `analysisOfClock${no}RawScore`,
      fieldType: 'RADIO',
      options:
        no === 1
          ? getOptionsFromLabels([
              'Normal executive control',
              'Impaired executive control',
            ])
          : getOptionsFromLabels([
              'Normal visual spatial copying',
              'Impaired visual spatial copying',
            ]),
      valueDependsOn: [`clock${no}Score`],
    },
  ],
});

const cloxTest: MainHeadingItem = {
  key: 'cloxTest',
  label: 'CLOX Test',
  subheadings: [
    getClockFields(1),
    getClockFields(2),
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: Results,
    },
  ],
};

export default cloxTest;
