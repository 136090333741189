import { MainHeadingItem } from '../../../models/forms';
import { PhysicalResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { processROMSTSubheading, ROM_ST_LABEL } from './ROM-ST-Utils';

const rangeOfMotion: MainHeadingItem = {
  label: 'Range of Motion',
  key: 'rangeOfMotion',
  subheadings: [
    {
      label: '',
      key: 'strengthTestingUnits',
      fields: [ROM_ST_LABEL],
    },
    processROMSTSubheading('ROM', 'Trunk', ['Rotation']),
    processROMSTSubheading('ROM', 'Neck', [
      'Left rotation',
      'Right rotation',
      'Extension',
      'Flexion',
    ]),
    processROMSTSubheading('ROM', 'Shoulder', [
      'Flexion',
      'Extension',
      'Abduction',
      'Adduction',
      'Internal rotation',
      'External rotation',
    ]),
    processROMSTSubheading('ROM', 'Elbow', ['Flexion', 'Extension']),
    processROMSTSubheading('ROM', 'Forearm', ['Pronation', 'Supination']),
    processROMSTSubheading('ROM', 'Wrist', [
      'Flexion',
      'Extension',
      'Ulnar deviation',
      'Radial deviation',
    ]),
    processROMSTSubheading('ROM', 'Grasp', [
      'General grasp and release',
      'Cylindrical',
      'Spherical',
      'Pincer',
      'Tenodesis',
    ]),
    processROMSTSubheading('ROM', 'Hip', [
      'Flexion',
      'Extension',
      'Abduction',
      'Adduction',
    ]),
    processROMSTSubheading('ROM', 'Knee', ['Flexion', 'Extension']),
    processROMSTSubheading('ROM', 'Ankle', [
      'Plantar flexion',
      'Dorsal flexion',
    ]),
    {
      key: 'overallResult',
      label: 'Overall result',
      fields: PhysicalResults,
    },
  ],
};

export default rangeOfMotion;
