import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

const ADJUSTMENT_TO_STIMULI_SIGNS_YIELD = [
  'Failed to recognize the yield sign',
  'Failed to yield',
  'Stopped when no traffic',
];

const YIELD_TYPES_LABELS = ['Facing', 'Perpendicular'];

export const YIELD_META: SubHeadingItem = {
  key: 'yield',
  label: 'Yield',
  fields: [
    {
      label: 'Type of yield',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(YIELD_TYPES_LABELS),
    },
  ],
};

export const YIELD_ERRORS: SubHeadingItem = {
  key: 'yield',
  label: 'Yield',
  fields: [
    {
      key: 'yieldAdjustmentToStimuliAndSigns',
      label: 'Adjustment to stimuli / traffic signs',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(ADJUSTMENT_TO_STIMULI_SIGNS_YIELD),
    },
    {
      key: 'yieldSpeedRegulation',
      label: 'Speed Regulation',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Too fast',
        'Too slow',
        'Abrupt stop',
        'Heavy acceleration',
        'Slow acceleration',
        'Wrong pedal selection',
      ]),
    },
    {
      key: 'yieldVehiclePositioning',
      label: 'Vehicle Positioning',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Vehicle position partially obstructing through traffic lane',
        'Too far behind',
        'Stops too close to another vehicle',
        'Creeps forward while waiting',
      ]),
    },
    {
      key: 'yieldVisualScanning',
      label: 'Visual Scanning',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Failed to check rear-view mirror',
        'Failed to sufficiently scan for traffic and/or pedestrians',
        'Failed to check blind spots',
      ]),
    },
    {
      key: 'yieldLaneMaintenance',
      label: 'Lane Maintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels(['Sharp turn into lane']),
    },
  ],
};
