import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
  ICustomGroup,
  ICustomGroupSQL,
} from '../../../models/assessments/assessments';
import useToken from '../../useToken';
import {
  axiosDeleteCall,
  axiosGetAllCall,
  axiosPostCall,
  useMutationWithToken,
} from '../utils';

const customGroupKey = ['customGroups'] as const;

const customGroupsUrl = (username: string) =>
  `/api/settings/${username}/custom-groups`;

const customGroupUrl = (username: string, groupKey: string) =>
  `/api/settings/${username}/custom-groups/${groupKey}`;

const customGroupAssessmentsUrl = (username: string, groupKey: string) =>
  `/api/settings/${username}/custom-groups/${groupKey}/assessments`;

const customGroupAssessmentUrl = (
  username: string,
  groupKey: string,
  assessmentName: string
) =>
  `/api/settings/${username}/custom-groups/${groupKey}/assessments/${assessmentName}`;

export const useCustomGroups = (username: string) => {
  const getSilentToken = useToken();
  return useQuery(
    customGroupKey,
    () =>
      getSilentToken
        .then(
          (response) =>
            axiosGetAllCall(
              customGroupsUrl(username),
              response.accessToken
            ) as Promise<ICustomGroupSQL[]>
        )
        .then((groups) =>
          groups.map((group) => ({
            ...group,
            assessments: group.assessments ? group.assessments.split(',') : [],
          }))
        ),
    { useErrorBoundary: true }
  );
};

// Adds
export const useCreateCustomGroupAssessment = (
  username: string,
  groupKey: string
) =>
  useMutationWithToken(customGroupKey, (assessmentKey: string, token: string) =>
    axiosPostCall(
      customGroupAssessmentsUrl(username, groupKey),
      { assessmentKey },
      token
    )
  );

const createCustomGroupAssessments = async (
  customGroupAssessments: string[],
  username: string,
  groupKey: string,
  token: string
) => {
  const addAssessmentRequests = customGroupAssessments.map((assessmentKey) =>
    axiosPostCall(
      customGroupAssessmentsUrl(username, groupKey),
      { assessmentKey },
      token
    )
  );

  return axios.all(addAssessmentRequests).then(() => username);
};

export const useCreateCustomGroup = (username: string) => {
  const getSilentToken = useToken();
  const queryClient = useQueryClient();

  return useMutation(
    (customGroup: ICustomGroup) =>
      getSilentToken
        .then((response) =>
          axiosPostCall(
            customGroupsUrl(username),
            customGroup,
            response.accessToken
          )
        )
        .then(() => getSilentToken)
        .then((response) =>
          createCustomGroupAssessments(
            customGroup.assessments || [],
            username,
            customGroup.groupKey,
            response.accessToken
          )
        ),
    {
      onSuccess: () => queryClient.invalidateQueries(customGroupKey),
    }
  );
};

// Deletes
export const useDeleteCustomGroup = (username: string) =>
  useMutationWithToken(customGroupKey, (groupKey: string, token: string) =>
    axiosDeleteCall(customGroupUrl(username, groupKey), token)
  );

export const useDeleteCustomGroupAssessment = (
  username: string,
  groupKey: string
) =>
  useMutationWithToken(customGroupKey, (assessmentKey: string, token: string) =>
    axiosDeleteCall(
      customGroupAssessmentUrl(username, groupKey, assessmentKey),
      token
    )
  );
