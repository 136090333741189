import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { useReactQueryAutoSync } from '../../auto-sync/useReactQueryAutoSync';
import useToken from '../../useToken';
import {
  MAX_WAIT,
  SHORT_WAIT,
  axiosGetCall,
  axiosPutCall,
  generateAuthorizationHeader,
  useQueryWithToken,
} from '../utils';
import { allTab4TacticalKeys } from './maneuverSummaries';

const maneuverKeys = {
  maneuverId: (maneuverId: string) =>
    [...allTab4TacticalKeys, 'maneuvers', maneuverId] as const,
  maneuverTypes: (roadTestId: string, maneuverTypes: string[]) =>
    ['maneuvers', maneuverTypes, roadTestId] as const,
};

const maneuversUrl = (roadTestId: string, maneuverType: string) =>
  `/api/on-road/${roadTestId}/maneuvers/${maneuverType}`;

const maneuverUrl = (
  roadTestId: string,
  maneuverType: string,
  maneuverId: string
) => `/api/on-road/${roadTestId}/maneuvers/${maneuverType}/${maneuverId}`;

// Maneuvers
const getManeuversHelper = async (
  roadTestId: string,
  maneuverType: string,
  token: string
) =>
  axios
    .get<any[]>(
      maneuversUrl(roadTestId, maneuverType),
      generateAuthorizationHeader(token)
    )
    .then((response) => ({ maneuverType, results: response.data }));

const getManeuvers = async (
  roadTestId: string,
  maneuverTypes: string[],
  token: string
) => {
  const getManeuversRequest = maneuverTypes.map((type) =>
    getManeuversHelper(roadTestId, type, token)
  );

  return axios
    .all(getManeuversRequest)
    .then(axios.spread((...responses) => responses));
};

export const useManeuvers = (roadTestId: string, maneuverTypes: string[]) =>
  useQueryWithToken(
    maneuverKeys.maneuverTypes(roadTestId, maneuverTypes),
    (token: string) => getManeuvers(roadTestId, maneuverTypes, token)
  );

// Maneuver
export const useManeuver = (
  roadTestId: string,
  maneuverType: string,
  maneuverId: string
) => {
  const getSilentToken = useToken();
  const queryClient = useQueryClient();

  return useReactQueryAutoSync({
    queryOptions: {
      queryKey: maneuverKeys.maneuverId(maneuverId),
      queryFn: () =>
        getSilentToken
          .then((response) =>
            axiosGetCall(
              maneuverUrl(roadTestId, maneuverType, maneuverId),
              response.accessToken
            )
          )
          .catch((err) => {
            throw new Error(err);
          }),
    },
    mutationOptions: {
      mutationFn: (maneuver: any) =>
        getSilentToken
          .then((response) =>
            axiosPutCall(
              maneuverUrl(roadTestId, maneuverType, maneuverId),
              maneuver,
              response.accessToken
            )
          )
          .catch((err) => {
            throw new Error(err);
          }),
      onSuccess: () =>
        queryClient.invalidateQueries(maneuverKeys.maneuverId(maneuverId)),
    },
    autoSaveOptions: {
      wait: SHORT_WAIT,
      maxWait: MAX_WAIT,
    },
    merge: (remote, local) => ({
      ...remote,
      ...local,
    }),
  });
};
