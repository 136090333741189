import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';
import {
  VISION_HEARING_CONDITIONALS,
  VISION_SCREEN_CONDITIONALS,
} from './VisionHearingHistoryConditionals';

const getVisionHearingRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  },
];

const VISION_HEARING_HISTORY: SubHeadingItem = {
  label: 'Vision and Hearing',
  key: 'category6',
  fields: [
    {
      label: 'Sources of vision information',
      key: 'sourcesOfVisionInformation',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Sources of hearing information',
      key: 'sourcesOfHearingInformation',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Any vision or hearing impairments?',
      key: 'visionOrHearingImpairmentsLabel',
      fieldType: 'LABEL',
    },
    {
      label: '',
      key: 'visionImpairmentTable',
      fieldType: 'TABLE',
      columnHeadings: ['', 'Left', 'Right'],
      cells: generateCellsForTableWithUniformCells(
        ['', 'Left', 'Right'],
        ['Visual acuity', 'Visual fields', 'Ears'],
        getVisionHearingRowSpec,
        undefined
      ),
      selectAll: 'No',
      conditionals: VISION_HEARING_CONDITIONALS,
    },
    {
      label: 'Was a vision screen provided with the referral?',
      key: 'visionScreenProvidedWithReferral',
      fieldType: 'RADIO',
      options: YES_NO,
      conditionals: VISION_SCREEN_CONDITIONALS,
    },
    {
      key: 'furtherEyeExamination',
      label: 'Is further eye examination recommended?',
      fieldType: 'RADIO',
      options: YES_NO,
    },
  ],
};

export default VISION_HEARING_HISTORY;
