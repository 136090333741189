import React from 'react';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import GridGoldenratioIcon from '@mui/icons-material/GridGoldenratio';
import HexagonIcon from '@mui/icons-material/Hexagon';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MergeIcon from '@mui/icons-material/Merge';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RampRightIcon from '@mui/icons-material/RampRight';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import RouteIcon from '@mui/icons-material/Route';
import SchoolIcon from '@mui/icons-material/School';
import StraightIcon from '@mui/icons-material/Straight';
import TrafficIcon from '@mui/icons-material/Traffic';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import { Stack } from '@mui/material';

import { FieldsLayout, IOption, SubHeadingItem } from '../../../models/forms';
import { ROAD_TYPE_OPTIONS } from './ON-ROAD-CONSTANTS';
import { TASK_ERRORS, TASK_META } from './ON-ROAD-FIELDS/TACTICAL';

export const getOption = (options: IOption[], key: string) =>
  options.find((option) => option.key === key);

export const getOptionsFromSubheadings = (subheadings: SubHeadingItem[]) =>
  subheadings.map((subheading) => {
    const option: IOption = {
      key: subheading.key,
      val: subheading.key,
      label: subheading.label,
    };
    return option;
  });

export const getFieldsFromSubheading = (
  subheadings: SubHeadingItem[],
  subheadingKey: string
) => subheadings.find((subheading) => subheading.key === subheadingKey)?.fields;

// TODO: Create an object that has the tasks, instead of getting this from TASK_ERRORS and then require all of those tasks in TASK_ERRORS
export const TASK_OPTIONS = getOptionsFromSubheadings(TASK_ERRORS);
export const DEFAULT_TASK_OPTIONS = TASK_OPTIONS.filter(
  (option) =>
    option.key === 'drivingStraight' ||
    option.key === 'leftTurn' ||
    option.key === 'rightTurn' ||
    option.key === 'stopSign' ||
    option.key === 'trafficLight' ||
    option.key === 'event'
);

export const getRoadTypeLabel = (roadTypeKey: string) =>
  getOption(ROAD_TYPE_OPTIONS, roadTypeKey)?.label;

export const getTaskLabel = (taskKey: string) =>
  getOption(getOptionsFromSubheadings(TASK_ERRORS), taskKey)?.label;

export const getTaskOption = (taskKey: string) =>
  getOption(TASK_OPTIONS, taskKey);

export const getTaskErrorFields = (
  taskKey: string
): FieldsLayout[] | undefined => getFieldsFromSubheading(TASK_ERRORS, taskKey);

export const getTaskMetaFields = (
  taskKey: string
): FieldsLayout[] | undefined => getFieldsFromSubheading(TASK_META, taskKey);

export type TaskKeys =
  | 'curvedRoad'
  | 'drivingStraight'
  | 'event'
  | 'exitRamp'
  | 'leftLaneChange'
  | 'leftTurn'
  | 'mergeManeuver'
  | 'parking'
  | 'passing'
  | 'railwayCrossing'
  | 'rightTurn'
  | 'rightLaneChange'
  | 'schoolZone'
  | 'stopSign'
  | 'trafficCircle'
  | 'trafficLight'
  | 'uncontrolledIntersection'
  | 'yield';

export const getTaskIcon = (
  taskKey: string,
  color?: 'error' | 'success' | 'primary' | 'secondary'
) => {
  switch (taskKey) {
    case 'curvedRoad':
      return <RouteIcon color={color} />;
    case 'drivingStraight':
      return <StraightIcon color={color} />;
    case 'event':
      return <PriorityHighIcon color={color} />;
    case 'exitRamp':
      return <RampRightIcon color={color} />;
    case 'leftLaneChange':
      return <TurnSlightLeftIcon color={color} />;
    case 'leftTurn':
      return <TurnLeftIcon color={color} />;
    case 'mergeManeuver':
      return <MergeIcon color={color} />;
    case 'parking':
      return <LocalParkingIcon color={color} />;
    case 'passing':
      return (
        <Stack direction="row">
          <DirectionsCarIcon color={color} />
          <DirectionsCarIcon color={color} />
        </Stack>
      );
    case 'railwayCrossing':
      return <DirectionsRailwayIcon color={color} />;
    case 'rightTurn':
      return <TurnRightIcon color={color} />;
    case 'rightLaneChange':
      return <TurnSlightRightIcon color={color} />;
    case 'schoolZone':
      return <SchoolIcon color={color} />;
    case 'stopSign':
      return <HexagonIcon color={color} />;
    case 'trafficCircle':
      return <RoundaboutLeftIcon color={color} />;
    case 'trafficLight':
      return <TrafficIcon color={color} />;
    case 'uncontrolledIntersection':
      return <GridGoldenratioIcon color={color} />;
    case 'yield':
      return <ChangeHistoryIcon color={color} />;

    default:
      return <DriveEtaIcon color={color} />;
  }
};

export const getTaskSuccessFields = (taskFields: any) => [];
