import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { WNL_IMPAIRED_OTIONS } from './motorFreeVisualPerceptionTest';

// https://nursekey.com/motor-free-visual-perception-test-third-and-fourth-editions-mvpt-3-and-4/
const motorFreeVisualPerceptionTest4: SubHeadingItem = {
  key: 'motorFreeVisualPerceptionTest4',
  label: 'Motor-Free Visual Perception Test (MVPT-4)',
  fields: [
    {
      key: 'rawScore',
      label: 'Raw score',
      fieldType: 'INPUT-NUM',
      units: <Typography>/45</Typography>,
    },
    {
      key: 'standardScore',
      label: 'Standard score',
      fieldType: 'INPUT-NUM',
    },
    {
      key: 'percentileRanking',
      label: 'Percentile ranking',
      fieldType: 'INPUT-NUM',
    },
    {
      key: 'percentileRankingInterpretation',
      label: 'Percentile ranking interpretation',
      fieldType: 'RADIO',
      options: WNL_IMPAIRED_OTIONS,
    },
    ...Results,
  ],
};

export default motorFreeVisualPerceptionTest4;
