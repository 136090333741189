import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import useDefaultManeuvers from '../../../hooks/database/settings/onRoadSettings';
import {
  getAllSelectedAsObj,
  getAllSelectedAsString,
  getTranslationLabel,
} from '../../../utils/utils';
import DefaultManeuverTypes from './DefaultManeuverTypes';
import EditStandardRoutes from './EditStandardRoutes';

const OnRoadSettings = () => {
  const { t } = useTranslation(allNamespaces);
  const { setDraft, queryResult, saveStatus } = useDefaultManeuvers();

  if (queryResult.isLoading) {
    return <LoadingComponent />;
  }

  if (queryResult.isError) {
    return <ErrorComponent error={queryResult.error} />;
  }

  const handleSave = (defaultManeuvers: any) => {
    const selectedAsString = getAllSelectedAsString(defaultManeuvers);
    setDraft({ defaultTacticalManeuvers: selectedAsString });
  };

  const defaultManeuverTypes = queryResult.data.defaultTacticalManeuvers
    ? getAllSelectedAsObj(queryResult.data.defaultTacticalManeuvers.split(','))
    : [];

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel('settings', 'defaultManeueversTitle', t)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {getTranslationLabel('settings', 'defaultManeuversPrompt', t)}
        </Typography>
        <DefaultManeuverTypes
          handleSave={handleSave}
          defaultManeuverTypes={defaultManeuverTypes}
          saveStatus={saveStatus}
        />
      </Grid>
      <Grid item>
        <EditStandardRoutes />
      </Grid>
    </Grid>
  );
};

export default OnRoadSettings;
