import React from 'react';

import { Typography } from '@mui/material';

import {
  FieldTypes,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  allButLastRow,
  capitalizeFirstLetter,
  convertLabelToKey,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

const DSC_COLUMN_HEADINGS = ['', 'Left', 'Right'];
const DSC_ROW_TITLES = ['Head level', 'Waist level', 'Knee level', 'Total'];

const getValueDependsOn = (columnHeading: string, typeOfTest: string) =>
  allButLastRow(DSC_ROW_TITLES).map(
    (rowTitle) =>
      `${getKeyWithAdditionalParameter(
        typeOfTest,
        columnHeading,
        rowTitle
      )}DataPoint`
  );

const getDSCRowSpec = (
  key: string,
  columnHeading: string,
  typeOfTest: string
) => [
  {
    rowTitle: 'Total',
    element: {
      key,
      label: 'Total',
      fieldType: 'INPUT-SWITCH' as FieldTypes,
      size: 200,
      valueDependsOn: getValueDependsOn(columnHeading, typeOfTest),
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}DataPoint`,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const DSC_INTEPRETATION_OPTIONS = [
  {
    key: 'pass',
    val: 'pass',
    label: 'Pass',
  },
  {
    key: 'fail',
    val: 'fail',
    label: 'Fail',
  },
];

const getDynamicScoreSubheading = (typeOfTest: string): SubHeadingItem => ({
  key: convertLabelToKey(typeOfTest),
  label: typeOfTest,
  fields: [
    {
      label: '',
      key: `${convertLabelToKey(typeOfTest)}Table`,
      fieldType: 'TABLE',
      columnHeadings: DSC_COLUMN_HEADINGS,
      cells: generateCellsForTableWithUniformCells(
        DSC_COLUMN_HEADINGS,
        DSC_ROW_TITLES,
        getDSCRowSpec,
        typeOfTest
      ),
    },
    {
      label: 'Total Targets Hit',
      key: `${convertLabelToKey(typeOfTest)}TotalTargetsHit`,
      fieldType: 'INPUT-SWITCH',
      units: <Typography> / 20 targets</Typography>,
      valueDependsOn: [
        `${convertLabelToKey(typeOfTest)}LeftTotal`,
        `${convertLabelToKey(typeOfTest)}RightTotal`,
      ],
    },
    {
      label: `${typeOfTest} percent`,
      key: `${convertLabelToKey(typeOfTest)}Percent`,
      fieldType: 'INPUT-NUM',
      units: <Typography> % </Typography>,
      valueDependsOn: [`${convertLabelToKey(typeOfTest)}TotalTargetsHit`],
    },
    {
      label: 'OBSERVATIONS',
      key: `${convertLabelToKey(typeOfTest)}Observations`,
      fieldType: 'CHECKBOX' as FieldTypes,
      options: [
        'Misses items on the left side of the hall',
        'Misses items on the right side of the hall',
        'Turns head to scan',
        'Scans ahead',
        `Doesn't maintain an even walking speed`,
        'Stops walking',
        'Walks centered in the hall',
      ].map((option) => ({
        key: `${convertLabelToKey(typeOfTest)}${capitalizeFirstLetter(
          convertLabelToKey(option)
        )}`,
        val: `${convertLabelToKey(typeOfTest)}${capitalizeFirstLetter(
          convertLabelToKey(option)
        )}`,
        label: option,
      })),
    },
    {
      label: `${typeOfTest} interpretation`,
      key: `${convertLabelToKey(typeOfTest)}Interpretation`,
      fieldType: 'RADIO',
      options: DSC_INTEPRETATION_OPTIONS,
    },
  ],
});

const dynamicScanCourse: MainHeadingItem = {
  key: 'dynamicScanCourse',
  label: 'Dynamic Scan Course',
  subheadings: [
    getDynamicScoreSubheading('Trial No. 1'),
    getDynamicScoreSubheading('Trial No. 2'),
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          key: 'dSCAverage',
          label: 'Average',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 20 targets</Typography>,
          valueDependsOn: [
            'trialNo1TotalTargetsHit',
            'trialNo2TotalTargetsHit',
          ],
        },
        {
          key: 'dSCOverallPercent',
          label: 'Overall percent',
          fieldType: 'INPUT-NUM',
          units: <Typography> % </Typography>,
          valueDependsOn: ['dSCAverage'],
        },
        {
          label: 'Overall interpretation',
          key: 'dSCOverallInterpretation',
          fieldType: 'RADIO',
          options: DSC_INTEPRETATION_OPTIONS,
        },
        ...Results,
      ],
    },
  ],
};

export default dynamicScanCourse;
