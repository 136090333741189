import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import AsyncErrorWrapper from '../../../errors/AsyncErrorWrapper';
import { useUpdateConsent } from '../../../hooks/database/clients/clients';
import { IClientMetaData } from '../../../models/client';
import { IForm } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { ChangeConsentDialog } from './ChangeConsentDialog';
import { getConsentButton } from './consent-utils';
import RevokeConsentDialog from './RevokeConsentDialog';

interface ConsentTableCellProps {
  clientMetaData: IClientMetaData;
}

const ConsentTableCell: FC<ConsentTableCellProps> = ({ clientMetaData }) => {
  const { clientId, isConsentGiven, isConsentFormUploaded } = clientMetaData;
  const updateConsentMutation = useUpdateConsent(clientId);

  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false);
  const [isConsentDialogOpen, setIsConsentDialogOpen] =
    useState<boolean>(false);

  const { register, control, setValue, watch, getValues } = useForm({
    defaultValues: {
      isConsentGiven,
      isConsentFormUploaded,
    },
  });
  const consentForm: IForm = { register, control, setValue, getValues, watch };

  const handleChangeConsent = (newConsent: IClientMetaData) => {
    updateConsentMutation.mutate(newConsent);
  };

  return (
    <AsyncErrorWrapper mutation={updateConsentMutation}>
      <FormContext.Provider value={consentForm}>
        <form>
          {getConsentButton(
            clientMetaData,
            setIsRevokeModalOpen,
            setIsConsentDialogOpen
          )}
          {isRevokeModalOpen && (
            <RevokeConsentDialog
              clientMetaData={clientMetaData}
              setIsRevokeModalOpen={setIsRevokeModalOpen}
              handleChangeConsent={handleChangeConsent}
            />
          )}
          {isConsentDialogOpen && (
            <ChangeConsentDialog
              clientId={clientId}
              setIsConsentDialogOpen={setIsConsentDialogOpen}
              handleChangeConsent={handleChangeConsent}
            />
          )}
        </form>
      </FormContext.Provider>
    </AsyncErrorWrapper>
  );
};

export default ConsentTableCell;
