import React from 'react';

import { Typography } from '@mui/material';

import { darkBlue } from '../../constants';

const Banner = ({
  text,
  variant,
  padding,
  textAlign,
  textColor = darkBlue,
}: any) => (
  <Typography
    paddingTop={padding}
    paddingBottom={padding}
    variant={variant}
    textAlign={textAlign}
    style={{ color: textColor }}
  >
    {text}
  </Typography>
);

export default Banner;
