import React, { FC, useContext, useEffect } from 'react';

import { FieldTypes, IOption } from '../../models/forms';
import FormContext from '../../utils/formContext';

interface ClearConditionalProps {
  name: string;
  type: FieldTypes;
  options?: IOption[];
}

const ClearConditional: FC<ClearConditionalProps> = ({
  name,
  type,
  options,
}) => {
  const { setValue } = useContext(FormContext);
  const clearConditional = () => {
    if (type === 'INPUT-NUM') {
      setValue(name, null);
    } else if (
      (type === 'CHECKBOX' || type === 'MULTISELECT_CHOICE_CHIPS') &&
      options
    ) {
      options.forEach((option) => {
        setValue(option.key, '0');
      });
    } else {
      setValue(name, '');
    }
  };

  useEffect(() => {
    clearConditional();
  }, []);

  return <div />;
};

export default ClearConditional;
