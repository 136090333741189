import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getNumbersXToY,
  getOptionsFromLabels,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithoutAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

const TRAILSX_COLUMN_HEADINGS = [
  '',
  ...getNumbersXToY(1, 9).map((no) => `Trail ${no}`),
];

const TRAILSX_ROW_TITLES = [
  'Time to discontinue',
  'Connected circles',
  'Matrix score',
];

const getValueDependsOn = (rowTitle: string) =>
  TRAILSX_COLUMN_HEADINGS.map((columnHeading) =>
    getKeyWithoutAdditionalParameter(columnHeading, rowTitle)
  );

const getTrailsXRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 75,
    },
  },
];

const trailsX: MainHeadingItem = {
  key: 'trailsX',
  label: 'Trails X',
  subheadings: [
    {
      key: 'trailsXTableSection',
      label: 'Trail X Table',
      fields: [
        {
          key: 'trailsXTable',
          label: '',
          fieldType: 'TABLE' as FieldTypes,
          columnHeadings: TRAILSX_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            TRAILSX_COLUMN_HEADINGS,
            TRAILSX_ROW_TITLES,
            getTrailsXRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Results',
      fields: [
        {
          label: 'trail drawings',
          key: 'trailsXFile',
          fieldType: 'UPLOAD',
        },
        {
          key: 'qualitativeFeatures',
          label: 'Qualitative features',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels([
            'Sacrificed speed for accuracy',
            'Sacrificed accuracy for speed',
            'Long latency before beginning trail',
            'Impulsive/quick start',
            'Self-corrections',
          ]),
        },
        {
          key: 'totalTimeToDiscontinue',
          label: 'TOTAL TIME TO DISCONTINUE',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: getValueDependsOn('Time to discontinue'),
        },
        {
          key: 'totalConnectedCircles',
          label: 'TOTAL CONNECTED CIRCLES',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: getValueDependsOn('Connected circles'),
        },
        {
          key: 'totalMatrixScore',
          label: 'TOTAL MATRIX SCORE',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: getValueDependsOn('Matrix score'),
        },
        {
          key: 'totalMatrixTScore',
          label: 'Total matrix t-score',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'totalMatrixPercentile',
          label: 'Total matrix percentile',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'analysisOfTotalMatrixPercentile',
          label: 'Analysis of total matrix percentile',
          fieldType: 'RADIO',
          valueDependsOn: ['totalMatrixPercentile'],
          options: [
            {
              key: 'significantlyAboveAverage',
              val: 'significantlyAboveAverage',
              label: '71+: Significantly above average',
            },
            {
              key: 'moderatelyAboveAverage',
              val: 'moderatelyAboveAverage',
              label: '65-70: Moderately above average',
            },
            {
              key: 'aboveAverage',
              val: 'aboveAverage',
              label: '58-64: Above average',
            },
            { key: 'average', val: 'average', label: '43-57: Average' },
            {
              key: 'belowAverage',
              val: 'belowAverage',
              label: '36-42: Below average',
            },
            {
              key: 'moderatelyBelowAverage',
              val: 'moderatelyBelowAverage',
              label: '30-35: Moderately below average',
            },
            {
              key: 'significantlyBelowAverage',
              val: 'significantlyBelowAverage',
              label: '0-29: Significantly below average',
            },
          ],
          units: getRef('Ref.: Trails X record form, 2019'),
        },
        ...Results,
      ],
    },
  ],
};

export default trailsX;
