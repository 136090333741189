import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { IForm } from '../../models/forms';
import { IRoadTest } from '../../models/on-road/on-road';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import AutoSave from '../common/AutoSave';
import { SectionHeading } from '../common/FieldContainer/SectionHeading';
import FormSection from '../common/FormSection';
import STRATEGIC from './Constants/ON-ROAD-FIELDS/STRATEGIC';

interface Tab5StrategicAbilitiesProps {
  roadTest: IRoadTest;
  handleSave: (roadTest: IRoadTest) => void;
}

const Tab5StrategicAbilities: FC<Tab5StrategicAbilitiesProps> = ({
  roadTest,
  handleSave,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({
      defaultValues: roadTest,
    });
  const onRoadForm: IForm = { register, control, setValue, getValues, watch };

  return (
    <Grid item container xs={12} direction="column" alignItems="center">
      <FormContext.Provider value={onRoadForm}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid item container spacing={5} sx={{ p: 4 }}>
            {STRATEGIC.map((subheading) => (
              <Grid
                item
                container
                direction="column"
                xs={12}
                lg={6}
                key={subheading.key}
              >
                <SectionHeading
                  title={getTranslationLabel(
                    'onroad/strategicAbilities',
                    subheading.key,
                    t
                  )}
                  selectAll={['sufficient', 'satisfactory', 'appropriate']}
                  fields={subheading.fields}
                />
                <FormSection
                  fields={subheading.fields}
                  namespace="onroad/strategicAbilities"
                  values={watch()}
                  commentsPosition="bottom"
                  sectionKey={subheading.key}
                />
              </Grid>
            ))}
            <Grid item>
              <AutoSave watch={watch} handleSave={handleSave} />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Grid>
  );
};

export default Tab5StrategicAbilities;
