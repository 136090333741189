import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const beckAnxietyInventory: SubHeadingItem = {
  key: 'beckAnxietyInventory',
  label: 'Beck Anxiety Inventory (BAI)',
  fields: [
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 63</Typography>,
    },
    {
      key: 'interpretationBAI',
      label: 'Interpretation of the score',
      fieldType: 'RADIO',
      options: [
        {
          key: 'lowAnxiety',
          val: 'lowAnxiety',
          label: '0-21: Low anxiety',
        },
        {
          key: 'moderateAnxiety',
          val: 'moderateAnxiety',
          label: '22-35: Moderate anxiety',
        },
        {
          key: 'concerningAnxiety',
          val: 'concerningAnxiety',
          label: '36+: Potentially concerning levels of anxiety',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    ...Results,
  ],
};

export default beckAnxietyInventory;
