import React, { FC, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IClientMetaData } from '../../../models/client';
import { IOption } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import BasicDialog from '../../common/BasicDialog';
import PrimitiveField from '../../common/FieldContainer/PrimitiveField';

const YES_NO_TBD: IOption[] = [
  ...YES_NO,
  {
    key: 'toBeDetermined',
    label: 'To be determined',
    val: 'toBeDetermined',
  },
];

export interface ConsentDialogProps {
  clientId: string;
  setIsConsentDialogOpen: (newState: boolean) => void;
  handleChangeConsent: (consent: IClientMetaData) => void;
}

export const ChangeConsentDialog: FC<ConsentDialogProps> = ({
  clientId,
  setIsConsentDialogOpen,
  handleChangeConsent,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { getValues, watch } = useContext(FormContext);

  const handleSubmitModal = () => {
    const consent: IClientMetaData = {
      clientId,
      isConsentGiven: getValues('isConsentGiven'),
      isConsentFormUploaded: getValues('isConsentFormUploaded'),
    };
    handleChangeConsent(consent);
    setIsConsentDialogOpen(false);
  };

  return (
    <BasicDialog
      open
      handleClose={() => {}}
      label={
        <Typography variant="h2" component="span">
          {getTranslationLabel('consent', 'consentTitle', t)}
        </Typography>
      }
      maxWidth="sm"
      actions={
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={handleSubmitModal}
        >
          {getTranslationLabel('consent', 'continueButton', t)}
        </Button>
      }
    >
      <PrimitiveField
        label="Does the client consent to have their unidentifiable data shared with researchers at the University of Saskatchewan, as per terms outlined in the consent form?"
        name="isConsentGiven"
        namespace="consent"
        type="RADIO"
        options={YES_NO_TBD}
      />
      {watch('isConsentGiven') === 'yes' && (
        <PrimitiveField
          label="Have you uploaded the consent form?"
          name="isConsentFormUploaded"
          namespace="consent"
          type="RADIO"
          options={YES_NO}
        />
      )}
    </BasicDialog>
  );
};
