import React, { FC } from 'react';

import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';

export type UnsavedDataWarningModalProps = {
  handleGoBack: () => void;
  handleIgnoreData: () => void;
};

export const UnsavedDataWarningModal: FC<UnsavedDataWarningModalProps> = ({
  handleGoBack,
  handleIgnoreData,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <BasicDialog
      open
      handleClose={handleGoBack}
      label={
        <Typography variant="subtitle1" component="span">
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'warningUnsavedChanges',
            t
          )}
        </Typography>
      }
      maxWidth="sm"
      actions={
        <>
          <Button
            onClick={handleIgnoreData}
            color="secondary"
            variant="outlined"
          >
            {getTranslationLabel(
              'assessmentsGeneral/assessClient',
              'ignoreUnsavedData',
              t
            )}
          </Button>
          <Button onClick={handleGoBack}>
            {getTranslationLabel(
              'assessmentsGeneral/assessClient',
              'goBackAndSave',
              t
            )}
          </Button>
        </>
      }
    >
      <Typography>
        {getTranslationLabel(
          'assessmentsGeneral/assessClient',
          'youMayHaveUnsavedData',
          t
        )}
      </Typography>
    </BasicDialog>
  );
};
