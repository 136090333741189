import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';

const colourTrailsTest: MainHeadingItem = {
  key: 'colourTrailsTest',
  label: 'Colour Trails Test (CTT)',
  subheadings: [
    {
      key: 'colourTrailsTestPart1',
      label: 'Part 1: Ascending Numbered Sequence',
      fields: [
        {
          label: 'Task completed?',
          key: 'isPart1TaskCompleted',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          label: 'Number of errors',
          key: 'part1NumberOfErrors',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Number of near misses',
          key: 'part1NumberOfNearMisses',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Number of prompts required',
          key: 'part1NumberOfPrompts',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Time taken',
          key: 'part1Time',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'record form',
          key: 'cTTPart1RecordFormFile',
          fieldType: 'UPLOAD',
        },
      ],
    },
    {
      key: 'colourTrailsTestPart2',
      label:
        'Part 2: Ascending Numbered Sequence, Alternating Between Pink and Yellow Colours',
      fields: [
        {
          label: 'Task completed?',
          key: 'isPart2TaskCompleted',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          label: 'Number of errors for numbers',
          key: 'part2NumberOfErrorsForNumbers',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Number of errors for colours',
          key: 'part2NumberOfErrorsForColours',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Number of near misses',
          key: 'part2NumberOfNearMisses',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Number of prompts required',
          key: 'part2NumberOfPrompts',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Time taken',
          key: 'part2Time',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'record form',
          key: 'cTTPart2RecordFormFile',
          fieldType: 'UPLOAD',
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: Results,
    },
  ],
};

export default colourTrailsTest;
