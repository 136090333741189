import React, { FC } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { IForm, SubHeadingItem } from '../../models/forms';
import { IRoadTest } from '../../models/on-road/on-road';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import AutoSave from '../common/AutoSave';
import BasicAccordion from '../common/BasicAccordion';
import { SectionHeading } from '../common/FieldContainer/SectionHeading';
import FormSection from '../common/FormSection';
import OPERATIONAL_ABILITIES from './Constants/ON-ROAD-FIELDS/OPERATIONAL';

interface Tab2OperationalOnRoadProps {
  roadTest: IRoadTest;
  handleSave: (roadTest: IRoadTest) => void;
}

const Tab2OperationalOnRoad: FC<Tab2OperationalOnRoadProps> = ({
  roadTest,
  handleSave,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({
      defaultValues: roadTest,
    });
  const onRoadForm: IForm = { register, control, setValue, getValues, watch };

  return (
    <Grid item container direction="column">
      <FormContext.Provider value={onRoadForm}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h2">
                {getTranslationLabel(
                  'onroad/operationalOnRoad',
                  'operationalLabel',
                  t
                )}
              </Typography>
            </Grid>
            <BasicAccordion
              namespace="onroad/operationalOnRoad"
              subheadings={OPERATIONAL_ABILITIES}
            >
              {(subheading: SubHeadingItem) => (
                <Stack direction="column" spacing={1} key={subheading.key}>
                  {subheading.selectAll && (
                    <SectionHeading
                      title=""
                      selectAll={subheading.selectAll}
                      fields={subheading.fields}
                    />
                  )}
                  <FormSection
                    fields={subheading.fields}
                    namespace="onroad/operationalOnRoad"
                    values={watch()}
                    sectionKey={subheading.key}
                  />
                </Stack>
              )}
            </BasicAccordion>
            <Grid item>
              <AutoSave watch={watch} handleSave={handleSave} />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Grid>
  );
};

export default Tab2OperationalOnRoad;
