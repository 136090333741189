import React, { FC } from 'react';

import { Paper, Grid, Typography, Button } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import EditClientFallback from '../../errors/fallbacks/EditClientFallback';
import { IClient } from '../../models/client';
import { SubHeadingItem } from '../../models/forms';
import { getTranslationLabel } from '../../utils/utils';
import FormSection from '../common/FormSection';

export interface IntakeFormPageProps {
  subheading: SubHeadingItem;
  watch: UseFormWatch<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  justSave: (data: IClient) => void;
  saveAndContinue: (data: IClient) => void;
}

export const IntakeFormPage: FC<IntakeFormPageProps> = ({
  subheading,
  watch,
  handleSubmit,
  justSave,
  saveAndContinue,
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <Paper
      style={{
        backgroundColor: 'primary',
        padding: '30px',
      }}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h2">
            {getTranslationLabel('navBar', subheading.key, t)}
          </Typography>
        </Grid>
        <ErrorBoundary FallbackComponent={EditClientFallback}>
          <FormSection
            fields={subheading.fields}
            namespace={`intake/${subheading.key}`}
            values={watch()}
            sectionKey={subheading.key}
          />
        </ErrorBoundary>
        <Grid item>
          <Button
            onClick={handleSubmit(saveAndContinue)}
            variant="contained"
            color="secondary"
            style={{ margin: '0px 15px 0px 0px' }}
          >
            {getTranslationLabel('generalNs', 'saveAndContinue', t)}
          </Button>
          <Button
            onClick={handleSubmit(justSave)}
            variant="contained"
            color="secondary"
          >
            {getTranslationLabel('generalNs', 'save', t)}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
