import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IOption } from '../../../models/forms';
import { getTranslationLabel } from '../../../utils/utils';
import CustomizeMyList from '../../common/Assessments/CustomizeMyList';
import { SelectedAssessments } from '../../common/Assessments/SelectedAssessments';

interface AssessmentPickerProps {
  assignedAssessmentKeys: string[];
  onChangeSelectAssessment: (
    assessmentKey: string,
    isSelecting: boolean
  ) => void;
}

const AssessmentPicker: FC<AssessmentPickerProps> = ({
  assignedAssessmentKeys,
  onChangeSelectAssessment,
}) => {
  const { t } = useTranslation(allNamespaces);

  const onChangeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSelectAssessment(e.target.name, e.target.checked);
  };

  const onChangeSection = (
    event: React.ChangeEvent<HTMLInputElement>,
    assessmentsInSection: IOption[]
  ) => {
    assessmentsInSection.forEach((assessment) => {
      onChangeSelectAssessment(assessment.key, event.target.checked);
    });
  };

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'myClinicalAssessments',
            t
          )}
        </Typography>
      </Grid>
      <Grid item container direction="column">
        <Grid item xs={12}>
          <CustomizeMyList />
        </Grid>

        <SelectedAssessments
          loCheckedItems={assignedAssessmentKeys}
          onChangeItem={onChangeItem}
          onChangeSection={onChangeSection}
        />
      </Grid>
    </Grid>
  );
};

export default AssessmentPicker;
