import React, { FC } from 'react';

import { AppBar, Tab, Tabs, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';

const a11yProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export interface TabsProps {
  menuItems: MenuItemProp[];
  orientation?: 'vertical' | 'horizontal';
  mappings?: any;
}

export interface MenuItemProp {
  key: string;
  label: string;
  path: string;
  icon?: any;
}

const TabsComp: FC<TabsProps> = ({ menuItems, orientation = 'vertical' }) => {
  const { t } = useTranslation(allNamespaces);
  const location = useLocation();

  return (
    <AppBar position="static" enableColorOnDark>
      <Paper
        sx={{
          flexGrow: 1,
        }}
      >
        <Tabs
          value={location.pathname}
          orientation={orientation}
          aria-label="tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {menuItems.map((tab, index) => (
            <Tab
              label={getTranslationLabel('navBar', tab.key, t)}
              value={`${tab.path}`}
              key={tab.key}
              component={Link}
              to={`${tab.path}`}
              {...a11yProps({ index })}
              sx={{ color: 'primary.dark' }}
            />
          ))}
        </Tabs>
      </Paper>
    </AppBar>
  );
};

export default TabsComp;
