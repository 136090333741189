import { FieldsLayout, IOption, SubHeadingItem } from '../../../models/forms';
import {
  convertLabelToKey,
  getKeysFromOptions,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://www.saskatoonhealthregion.ca/locations_services/Services/Occupational-Therapy/Documents/SPOT-DS_2018.pdf
const SPOT_DS_RATINGS_LIST: string[] = ['Green', 'Amber', 'Red'];

const SPOT_DS_RATINGS: IOption[] = SPOT_DS_RATINGS_LIST.map((item) => ({
  key: convertLabelToKey(item),
  val: convertLabelToKey(item),
  label: item,
}));

const SPOT_DS_CATEGORIES_LIST: string[] = [
  'Cognition / Perception',
  'Physical / Sensation',
  'Psychosocial',
  'Medications',
  'Other',
];

const SPOT_DS_CATEGORIES: FieldsLayout[] = SPOT_DS_CATEGORIES_LIST.map(
  (item) => ({
    key: `${convertLabelToKey(item)}CategoryScore`,
    label: item,
    fieldType: 'SCALE',
    options: SPOT_DS_RATINGS,
  })
);

const SPOT_DS_TOTALS: FieldsLayout[] = SPOT_DS_RATINGS_LIST.map((item) => ({
  key: `total${item}Categories`,
  label: `Total ${item.toLowerCase()} categories`,
  fieldType: 'INPUT-NUM',
  valueDependsOn: getKeysFromOptions(SPOT_DS_CATEGORIES),
}));

const SPOT_DS_RESULTS_LIST: string[] = [
  'No concern present',
  'Mild concern present',
  'Moderate concern present',
  'Significant concern present',
];

const SPOT_DS_RESULTS: IOption[] = SPOT_DS_RESULTS_LIST.map((item) => ({
  key: convertLabelToKey(item),
  val: convertLabelToKey(item),
  label: item,
}));

const SPOT_DS_RECOMMENDATION_LIST: string[] = [
  'Continue driving',
  'Re-screen after further stabilization',
  'Refer for specialized driving assessment',
  'Unsafe to drive',
  'Other',
];

const SPOT_DS_RECOMMENDATION: IOption[] = SPOT_DS_RECOMMENDATION_LIST.map(
  (item) => ({
    key: convertLabelToKey(item),
    val: convertLabelToKey(item),
    label: item,
  })
);

const SaskatchewanPsychiatricOTDrivingScreen: SubHeadingItem = {
  key: 'SaskatchewanPsychiatricOTDrivingScreen',
  label: 'Saskatchewan Psychiatric OT Driving Screen (SPOT-DS)',
  fields: [
    ...SPOT_DS_CATEGORIES,
    ...SPOT_DS_TOTALS,
    {
      key: 'results',
      label: 'Results',
      fieldType: 'SCALE',
      options: SPOT_DS_RESULTS,
    },
    {
      key: 'recomendation',
      label: 'Recomendation:',
      fieldType: 'RADIO',
      options: SPOT_DS_RECOMMENDATION,
    },
  ],
};

export default SaskatchewanPsychiatricOTDrivingScreen;
