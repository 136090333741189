import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const computerizedMazeTasks: SubHeadingItem = {
  key: 'computerizedMazeTasks',
  label: 'Computerized Maze Tasks',
  fields: [
    {
      label: 'Total time for all five mazes',
      key: 'totalTime',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Total score for all five mazes',
      key: 'totalScore',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'completed mazes',
      key: 'computerizedMazeTasksFile',
      fieldType: 'UPLOAD',
    },
    ...Results,
  ],
};

export default computerizedMazeTasks;
