import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { containerWidth, gray, medium } from '../constants';
import Banner from './Helpers/Banner';

const FAQPage = () => {
  const { t } = useTranslation(websiteNamespaces);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <Banner
        text={getTranslationLabel('website/faq', 'faqPageTitle', t)}
        variant="h3"
        textColor={gray}
        padding="1%"
      />
      <Box sx={{ width: '100%' }}>
        {[1, 2, 3].map((idx) => (
          <Accordion key={idx} sx={{ marginBottom: '2%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: medium }} />}
              id={`faq-${idx}-header`}
            >
              <Typography variant="h5" color={gray} sx={{ fontWeight: 600 }}>
                {getTranslationLabel('website/faq', `question${idx}`, t)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h5" color={gray}>
                {getTranslationLabel('website/faq', `answer${idx}`, t)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQPage;
