import { SubHeadingItem } from '../../../models/forms';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  CURRENTLY_WORKING_CONDITIONAL,
  OTHER_DRIVERS_CONDITIONAL,
} from './FunctionForDailyLivingTasksConditionals';

const FUNCTION_FOR_DAILY_LIVING_TASKS: SubHeadingItem = {
  label: 'Function for Daily Living Tasks (FDL)',
  key: 'category7',
  fields: [
    {
      label: 'Current living situation',
      key: 'currentLivingSituation',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Any other drivers in the household?',
      key: 'otherDriversInHousehold',
      fieldType: 'RADIO',
      options: YES_NO,
      conditionals: OTHER_DRIVERS_CONDITIONAL,
    },
    {
      label: 'Highest level of education',
      key: 'highestLevelOfEducation',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Primary school',
        'High school',
        'College',
        'Bachelors degree',
        'Masters degree',
        'Doctorate degree',
      ]),
    },
    {
      label: 'Current vocational status',
      key: 'currentVocationalStatus',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(
        [
          'Working',
          'Medical leave',
          'Retired',
          'Volunteer',
          'Student',
          'Other',
        ],
        undefined,
        'vocationalStatus'
      ),
      conditionals: CURRENTLY_WORKING_CONDITIONAL,
    },
    {
      label: 'Work history',
      key: 'workHistory',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Level of independence',
      key: 'levelOfIndependence',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Below ADLs',
        'ADLs',
        'Between ADLs and IADLs',
        'IADLs',
      ]),
    },
    {
      label: 'Leisure activities',
      key: 'leisureActivities',
      fieldType: 'TEXT-AREA',
    },
  ],
};

export default FUNCTION_FOR_DAILY_LIVING_TASKS;
