import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const sleepImpairmentIndex: SubHeadingItem = {
  label: 'Sleep Impairment Index',
  key: 'sleepImpairmentIndex',
  fields: [
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 28</Typography>,
    },
    {
      key: 'interpretationSII',
      label: 'Interpretation of the score',
      fieldType: 'RADIO',
      options: [
        {
          key: 'noInsomnia',
          label: '0-7: No clinically significant insomnia',
          val: 'noInsomnia',
        },
        {
          key: 'subthresholdInsomnia',
          label: '8-14: Subthreshold insomnia',
          val: 'subthresholdInsomnia',
        },
        {
          key: 'clinicalInsomniaI',
          label: '15-21: Clinical insomnia (moderate severity)',
          val: 'clinicalInsomniaI',
        },
        {
          key: 'clinicalInsomniaII',
          label: '22-28: Clinical insomnia (severe)',
          val: 'clinicalInsomniaII',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    ...Results,
  ],
};

export default sleepImpairmentIndex;
