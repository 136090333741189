import React from 'react';

import { Typography } from '@mui/material';

import { NameScore } from '../../../models/assessments/assessments';
import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const SPORT_CATEGORIES: NameScore[] = [
  { name: 'Observing', score: 15 },
  { name: 'Communicating', score: 15 },
  { name: 'Speed adjustment', score: 15 },
  { name: 'Vehicle positioning', score: 18 },
  { name: 'Time and space management', score: 18 },
  { name: 'Hazard perception', score: 18 },
];

const safePerformanceOnRoadTest: SubHeadingItem = {
  key: 'safePerformanceOnRoadTest',
  label: 'Safe Performance On-Road Test (SPORT)',
  fields: [
    ...SPORT_CATEGORIES.map((item) => ({
      key: `${convertLabelToKey(item.name)}Score`,
      label: item.name,
      fieldType: 'INPUT-NUM' as FieldTypes,
      units: <Typography>/ {item.score}</Typography>,
    })),
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 99</Typography>,
      valueDependsOn: SPORT_CATEGORIES.map(
        (item) => `${convertLabelToKey(item.name)}Score`
      ),
    },
    {
      key: 'averageNumberOfCollisions',
      label: 'Average number of collisions',
      fieldType: 'INPUT-NUM',
      units: <Typography>per million miles of driving</Typography>,
    },
    {
      key: 'crashProbability',
      label: 'Crash probability',
      fieldType: 'INPUT-NUM',
    },
    ...Results,
  ],
};

export default safePerformanceOnRoadTest;
