import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import NewClientButton from '../NewClientButton';

const EmptyTable = () => {
  const { t } = useTranslation(allNamespaces);

  return (
    <caption>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="subtitle1">
            {getTranslationLabel('clientsGeneral', 'noClients', t)}
          </Typography>
        </Grid>
        <Grid item>
          <NewClientButton
            text={getTranslationLabel('clientsGeneral', 'createOne', t)}
            variant="text"
            size="small"
            icon={<></>}
          />
        </Grid>
      </Grid>
    </caption>
  );
};

export default EmptyTable;
