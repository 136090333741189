import React, { FC, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import {
  ErrorComponent,
  LoadingComponent,
} from '../../../../errors/QueryUtils';
import { ReactQueryAutoSyncSaveStatus } from '../../../../hooks/auto-sync/utils/ReactQueryAutoSyncStatus';
import { useManeuver } from '../../../../hooks/database/on-road/maneuvers';
import { useManeuverSummary } from '../../../../hooks/database/on-road/maneuverSummaries';
import { IOrderedManeuverSummary } from '../../../../models/on-road/route';
import OpenManeuver from './OpenManeuver';

interface OpenManeuverColumnProps {
  openManeuver: IOrderedManeuverSummary;
  maneuverSummaries: IOrderedManeuverSummary[];
  handleOpenTask: (task: IOrderedManeuverSummary) => void;
}

const OpenManeuverColumn: FC<OpenManeuverColumnProps> = ({
  openManeuver,
  maneuverSummaries,
  handleOpenTask,
}) => {
  const [combinedSaveStatus, setCombinedSaveStatus] =
    useState<ReactQueryAutoSyncSaveStatus>('saved');

  const {
    draft: summaryDraft,
    setDraft: setSummaryDraft,
    queryResult: summaryQueryResult,
    saveStatus: summarySaveStatus,
  } = useManeuverSummary(openManeuver.roadTestId, openManeuver.maneuverId);

  const { draft, setDraft, queryResult, saveStatus } = useManeuver(
    openManeuver.roadTestId,
    openManeuver.maneuverType,
    openManeuver.maneuverId
  );

  useEffect(
    () =>
      saveStatus === 'saved' && summarySaveStatus === 'saved'
        ? setCombinedSaveStatus('saved')
        : saveStatus === 'loading' || summarySaveStatus === 'loading'
        ? setCombinedSaveStatus('loading')
        : saveStatus === 'error' || summarySaveStatus === 'error'
        ? setCombinedSaveStatus('error')
        : saveStatus === 'saving' || summarySaveStatus === 'saving'
        ? setCombinedSaveStatus('saving')
        : setCombinedSaveStatus('unsaved'),
    [saveStatus, summarySaveStatus]
  );

  if (queryResult.isLoading || summaryQueryResult.isLoading) {
    return <LoadingComponent />;
  }
  if (queryResult.isError) {
    return <ErrorComponent error={queryResult.error} />;
  }
  if (summaryQueryResult.isError) {
    return <ErrorComponent error={summaryQueryResult.error} />;
  }
  if (summaryQueryResult.data === undefined) {
    return <ErrorComponent error="That maneuver doesn't exist" />;
  }

  const handleSaveSummary = (summary: any) => {
    setSummaryDraft({
      ...summaryQueryResult.data,
      ...summary,
    });
  };

  const handleSaveErrors = (errors: any) => {
    setDraft({
      roadTestId: summaryQueryResult.data.roadTestId,
      maneuverType: summaryQueryResult.data.maneuverType,
      subManeuverType: summaryQueryResult.data.subManeuverType,
      ...errors,
    });
  };

  return (
    <Grid item>
      <OpenManeuver
        maneuver={{
          idx: openManeuver.idx,
          ...draft,
          ...summaryDraft,
        }}
        maneuverSummaries={maneuverSummaries}
        handleOpenTask={handleOpenTask}
        handleSaveSummary={handleSaveSummary}
        handleSaveErrors={handleSaveErrors}
        maneuverType={summaryQueryResult.data.maneuverType}
        recordType={summaryQueryResult.data.recordType}
        saveStatus={combinedSaveStatus}
      />
    </Grid>
  );
};

export default OpenManeuverColumn;
