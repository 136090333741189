import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://strokengine.ca/en/assessments/behavioral-inattention-test-bit/
// paper "Validity of the Behavioral Inattention Test (BIT): relationships with functional tasks"
const BITC_CONSTANTS = [
  { title: '1. Line crossing', units: '/ 36' },
  { title: '2. Letter cancellation', units: '/ 40' },
  { title: '3. Star cancellation', units: '/ 54' },
  { title: '4. Figure and shape copying', units: '/ 4' },
  { title: '5. Line bisection', units: '/ 9' },
  { title: '6. Representational drawing', units: '/ 3' },
];

const BITC_FIELDS = BITC_CONSTANTS.map((item) => ({
  label: item.title,
  key: `bITC${convertLabelToKey(item.title)}Score`,
  fieldType: 'INPUT-NUM' as FieldTypes,
  units: <Typography>{item.units}</Typography>,
}));

const BITB_CONSTANTS = [
  { title: '1. Picture scanning', units: '/ 9' },
  { title: '2. Telephone dialing', units: '/ 9' },
  { title: '3. Menu reading', units: '/ 9' },
  { title: '4. Article reading', units: '/ 9' },
  { title: '5. Telling and setting the time', units: '/ 9' },
  { title: '6. Coin sorting', units: '/ 9' },
  { title: '7. Address and sentence copying', units: '/ 9' },
  { title: '8. Map navigation', units: ' / 9' },
  { title: '9. Card sorting', units: '/ 9' },
];

const BITB_FIELDS = BITB_CONSTANTS.map((item) => ({
  label: item.title,
  key: `bITB${convertLabelToKey(item.title)}Score`,
  fieldType: 'INPUT-NUM' as FieldTypes,
  units: <Typography>{item.units}</Typography>,
}));

const BIT_REF = getRef(
  'Ref.: Halligan et al., 1991; Menon and Korner-Bitensky, 2004'
);

const behavioralInattentionTest: MainHeadingItem = {
  label: 'Behavioral Inattention Test (BIT)',
  key: 'behavioralInattentionTest',
  subheadings: [
    {
      label: 'BIT Conventional subtest (BITC)',
      key: 'BITC',
      fields: [
        ...BITC_FIELDS,
        {
          label: 'BITC total score',
          key: 'bITCTotalScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 146</Typography>,
          valueDependsOn: getKeysFromOptions(BITC_FIELDS),
        },
        {
          key: 'interpretationBITC',
          label: 'Interpretation of the score',
          fieldType: 'RADIO',
          options: [
            {
              key: 'bITCsatisfactory',
              val: 'bITCsatisfactory',
              label: '129+: Satisfactory',
            },
            {
              key: 'bITCvisualNeglect',
              val: 'bITCvisualNeglect',
              label: '0-128: Visual neglect',
            },
          ],
          units: BIT_REF,
          valueDependsOn: ['bITCTotalScore'],
        },
      ],
    },
    {
      label: 'BIT Behavioral subtest (BITB)',
      key: 'BITB',
      fields: [
        ...BITB_FIELDS,
        {
          label: 'BITB total score',
          key: 'bITBTotalScore',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 81</Typography>,
          valueDependsOn: getKeysFromOptions(BITB_FIELDS),
        },
        {
          key: 'interpretationBITB',
          label: 'Interpretation of the score',
          fieldType: 'RADIO',
          options: [
            {
              key: 'bITBsatisfactory',
              val: 'bITBsatisfactory',
              label: '67+: Satisfactory',
            },
            {
              key: 'bITBvisualNeglect',
              val: 'bITBvisualNeglect',
              label: '0-66: Visual neglect',
            },
          ],
          units: BIT_REF,
          valueDependsOn: ['bITBTotalScore'],
        },
      ],
    },
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: [
        {
          label: 'BIT total score',
          key: 'bITTotalScore',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 227</Typography>,
          valueDependsOn: ['bITCTotalScore', 'bITBTotalScore'],
        },
        {
          key: 'interpretationBIT',
          label: 'Interpretation of the score',
          fieldType: 'RADIO',
          options: [
            {
              key: 'bITsatisfactory',
              val: 'bITsatisfactory',
              label: '196+: Satisfactory',
            },
            {
              key: 'bITvisualNeglect',
              val: 'bITvisualNeglect',
              label: '0-195: Visual neglect',
            },
          ],
          units: BIT_REF,
          valueDependsOn: ['bITTotalScore'],
        },
        ...Results,
      ],
    },
  ],
};

export default behavioralInattentionTest;
