import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
  getKeysFromOptions,
  getNumericOptionsXToY,
  getRef,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const MMSE_ORIENTATION_OPTIONS = [
  'Year',
  'Season',
  'Month',
  'Date',
  'Day of the week',
  'Country',
  'Province',
  'City',
  'Building',
  'Floor',
].map((option) => ({
  key: `knows${capitalizeFirstLetter(convertLabelToKey(option))}`,
  val: `knows${capitalizeFirstLetter(convertLabelToKey(option))}`,
  label: option,
}));

const getMMSEObjectOptions = (recallOrRepeatOrNamed: string) => {
  const objects =
    recallOrRepeatOrNamed === 'Named'
      ? ['first', 'second']
      : ['first', 'second', 'third'];
  return objects.map((item) => ({
    key: `${item}Object${recallOrRepeatOrNamed}Correctly`,
    val: `${item}Object${recallOrRepeatOrNamed}Correctly`,
    label: `${capitalizeFirstLetter(item)} object`,
  }));
};

const MMSE_TASKS = [
  'Repeat a phrase',
  'Read sentence and close eyes',
  'Write a sentence',
  'Copy design',
].map((option) => ({
  key: `${convertLabelToKey(option)}TaskPerformedCorrectly`,
  val: `${convertLabelToKey(option)}TaskPerformedCorrectly`,
  label: option,
}));

const miniMentalStatusExamination: MainHeadingItem = {
  key: 'miniMentalStatusExamination',
  label: 'Mini-mental Status Examination (MMSE)',
  subheadings: [
    {
      key: 'orientationSection',
      label: 'Orientation',
      fields: [
        {
          key: 'orientationCheckboxes',
          label: 'Check correct answers',
          fieldType: 'CHECKBOX',
          options: MMSE_ORIENTATION_OPTIONS,
        },
        {
          key: 'orientationTotalScore',
          label: 'Orientation total score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 10</Typography>,
          valueDependsOn: getKeysFromOptions(MMSE_ORIENTATION_OPTIONS),
        },
      ],
    },
    {
      key: 'tasksSection',
      label: 'Tasks',
      fields: [
        {
          key: 'objectsRepeatedCorrectly',
          label: 'Check-off objects repeated correctly',
          fieldType: 'CHECKBOX',
          options: getMMSEObjectOptions('Repeat'),
        },
        {
          key: 'spellingWORLDScore',
          label: 'Spelling WORLD Score',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(1, 5),
        },
        {
          key: 'objectsRecalledCorrectly',
          label: 'Check-off objects recalled correctly',
          fieldType: 'CHECKBOX',
          options: getMMSEObjectOptions('Recall'),
        },
        {
          key: 'objectsNamedCorrectly',
          label: 'Check-off objects named correctly',
          fieldType: 'CHECKBOX',
          options: getMMSEObjectOptions('Named'),
        },
        {
          key: 'tasksPerformedCorrectly',
          label: 'Check-off tasks performed correctly',
          fieldType: 'CHECKBOX',
          options: MMSE_TASKS,
        },
        {
          key: 'foldingPaperScore',
          label: 'Folding paper score',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(1, 3),
        },
        {
          key: 'tasksTotalScore',
          label: 'Tasks total score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 20</Typography>,
          valueDependsOn: [
            ...getKeysFromOptions(getMMSEObjectOptions('Repeat')),
            'spellingWORLDScore',
            ...getKeysFromOptions(getMMSEObjectOptions('Recall')),
            ...getKeysFromOptions(getMMSEObjectOptions('Named')),
            ...getKeysFromOptions(MMSE_TASKS),
            'foldingPaperScore',
          ],
        },
      ],
    },
    {
      key: 'results',
      label: 'Results',
      fields: [
        {
          key: 'factorsPotentiallyAffectingMentalStatusTestingLabel',
          label: 'Factors potentially affecting mental status testing',
          fieldType: 'LABEL',
        },
        {
          key: 'factorsPotentiallyAffectingMentalStatusTesting',
          label: '',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 10</Typography>,
        },
        {
          key: 'totalScore',
          label: 'Total score',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 30</Typography>,
          valueDependsOn: ['tasksTotalScore', 'orientationTotalScore'],
        },
        {
          label: 'Interpretation of the score',
          key: 'interpretationMMSE',
          fieldType: 'RADIO',
          options: [
            { key: 'normal', val: 'normal', label: '26+: Normal' },
            {
              key: 'mildCognitiveImpairment',
              val: 'mildCognitiveImpairment',
              label: '20-25: Mild cognitive impairment',
            },
            {
              key: 'moderateCognitiveImpairment',
              val: 'moderateCognitiveImpairment',
              label: '10-19: Moderate cognitive impairment',
            },
            {
              key: 'severeCognitiveImpairment',
              val: 'severeCognitiveImpairment',
              label: '0-9: Severe cognitive impairment',
            },
          ],
          units: getRef(
            'Ref.: Davey RJ, Jamieson S. The validity of using the mini mental state examination in NICE dementia guidelines. J Neurol Neurosurg Psychiatry. 2004'
          ),
          valueDependsOn: ['totalScore'],
        },
        ...Results,
      ],
    },
  ],
};

export default miniMentalStatusExamination;
