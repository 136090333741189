import React, { FC } from 'react';

import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import useAlert from '../../../hooks/useAlert';
import { IClientMetaData } from '../../../models/client';
import { getTranslationLabel } from '../../../utils/utils';
import BasicAlert from '../../common/BasicAlert';
import BasicDialog from '../../common/BasicDialog';

interface RevokeConsentDialogProps {
  clientMetaData: IClientMetaData;
  setIsRevokeModalOpen: (newState: boolean) => void;
  handleChangeConsent: (consent: IClientMetaData) => void;
}

const RevokeConsentDialog: FC<RevokeConsentDialogProps> = ({
  clientMetaData,
  setIsRevokeModalOpen,
  handleChangeConsent,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { clientId, isConsentFormUploaded } = clientMetaData;
  const { alertState, handleCloseAlert, showAlert } = useAlert();

  const handleRevokeConsent = () => {
    if (clientId) {
      handleChangeConsent({
        clientId,
        isConsentFormUploaded,
        isConsentGiven: 'no',
      });
      showAlert(
        'success',
        `${getTranslationLabel(
          'Consent for ',
          'consentRevoked1',
          t
        )} ${clientId} ${getTranslationLabel('consent', 'consentRevoked2', t)}`
      );
      setIsRevokeModalOpen(false);
    }
  };

  return (
    <>
      <BasicDialog
        open
        handleClose={() => setIsRevokeModalOpen(false)}
        label={
          <Typography variant="subtitle1" component="span">
            {getTranslationLabel('consent', 'areYouSure', t)} {clientId}?
          </Typography>
        }
        actions={
          <>
            <Button onClick={() => setIsRevokeModalOpen(false)}>
              {getTranslationLabel('generalNs', 'cancel', t)}
            </Button>
            <Button type="submit" onClick={handleRevokeConsent}>
              {getTranslationLabel('consent', 'revoke', t)}
            </Button>
          </>
        }
        fullWidth={false}
      >
        <Typography>
          {getTranslationLabel('consent', 'thisActionCannotBeUndone', t)}
        </Typography>
      </BasicDialog>
      {alertState.open && (
        <BasicAlert
          alertKey={clientId || 'alertKey'}
          open={alertState.open}
          handleClose={handleCloseAlert}
          variant={alertState.variant}
          message={alertState.message}
        />
      )}
    </>
  );
};

export default RevokeConsentDialog;
