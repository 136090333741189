import { FieldTypes, IOption, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithoutAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

const COORDINATION_COLUMN_HEADINGS = [
  '',
  'Test No. 1',
  'Test No. 2',
  'Test No. 3',
  'Test No. 4',
];

const COORDINATION_ROW_TITLES = [
  'Type of test',
  'Movement achieved?',
  'Speed',
  'Accuracy',
  'Consistency',
  'Number of movements',
  'Elpased time (s)',
  'Speed (bpm)',
  'Comments',
];

const NORMAL_IMPAIRED: IOption[] = getOptionsFromLabels(['Normal', 'Impaired']);

const getValueDependsOn = (columnHeading: string) =>
  COORDINATION_ROW_TITLES.slice(5, 7).map(
    (rowTitle) => `${getKeyWithoutAdditionalParameter(columnHeading, rowTitle)}`
  );

const getCoordinationRowSpec = (key: string, columnHeading: string) => [
  {
    rowTitle: 'Type of test',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: getOptionsFromLabels([
        'Rapid alternating movements',
        'Rapid foot taps',
        'Finger nose finger',
        'Finger tapping test',
        'Heel to shin',
        'Sequential finger thumb opposition',
        'Other (specify in comments)',
      ]),
      size: 200,
    },
  },
  {
    rowTitle: 'Movement achieved?',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  },
  {
    rowTitle: 'Speed',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: NORMAL_IMPAIRED,
    },
  },
  {
    rowTitle: 'Accuracy',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: NORMAL_IMPAIRED,
    },
  },
  {
    rowTitle: 'Consistency',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: NORMAL_IMPAIRED,
    },
  },
  {
    rowTitle: 'Speed (bpm)',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-SWITCH' as FieldTypes,
      size: 200,
      valueDependsOn: getValueDependsOn(columnHeading),
    },
  },
  {
    rowTitle: 'Comments',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const coordination: SubHeadingItem = {
  key: 'coordination',
  label: 'Coordination',
  fields: [
    {
      key: 'coordinationTable',
      label: '',
      fieldType: 'TABLE',
      columnHeadings: COORDINATION_COLUMN_HEADINGS,
      cells: generateCellsForTableWithUniformCells(
        COORDINATION_COLUMN_HEADINGS,
        COORDINATION_ROW_TITLES,
        getCoordinationRowSpec,
        undefined
      ),
    },
    ...Results,
  ],
};

export default coordination;
