import React from 'react';

import { Typography } from '@mui/material';

import { Conditional } from '../../../models/forms';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const VISION_CONDITIONS = [
  'Myopia',
  'Hyperopia',
  'Cataracts',
  'Glaucoma',
  'Diplopia',
  'Nystagmus',
  'Strabismus',
  'Macular degeneration',
  'Abnormal head tilting',
  'Visual condition not listed',
];

const hasImpairedVision = (values: any) =>
  values.leftVisualAcuity === 'yes' ||
  values.rightVisualAcuity === 'yes' ||
  values.leftVisualFields === 'yes' ||
  values.rightVisualFields === 'yes';
const hasImpairedAcuity = (values: any) =>
  values.leftVisualAcuity === 'yes' || values.rightVisualAcuity === 'yes';
const hasImpairedHearing = (values: any) =>
  values.leftEars === 'yes' || values.rightEars === 'yes';
const hasImpairedVisiualFields = (values: any) =>
  values.leftVisualFields === 'yes' || values.rightVisualFields === 'yes';

const hasGlasses = (values: any) =>
  hasImpairedVision(values) && values.glasses === 'yes';
const hasOtherTypeOfGlasses = (values: any) =>
  hasGlasses(values) &&
  (values.glassesOtherGlasses === true || values.glassesOtherGlasses === '1');
const otherVisualCondition = (values: any) =>
  hasImpairedAcuity(values) &&
  (values.visualConditionVisualConditionNotListed === true ||
    values.visualConditionVisualConditionNotListed === '1' ||
    values.primaryVisualCondition === 'visualConditionNotListed ');

const visionScreenProvidedWithReferral = (values: any) =>
  values.visionScreenProvidedWithReferral === 'yes';
const doesntMeetLicensingBodyRequirements = (values: any) =>
  visionScreenProvidedWithReferral(values) &&
  values.meetsLicensingBodyRequirements === 'no';

export const VISION_HEARING_CONDITIONALS: Conditional[] = [
  {
    key: 'hearingAids',
    label: 'Hearing aids',
    fieldType: 'CHECKBOX',
    condition: hasImpairedHearing,
    options: getOptionsFromLabels(
      ['None', 'Left ear', 'Right ear'],
      undefined,
      'hearingAids'
    ),
  },
  {
    label: 'Glasses',
    key: 'glasses',
    fieldType: 'RADIO',
    options: YES_NO,
    condition: hasImpairedVision,
  },
  {
    key: 'typeOfGlasses',
    label: 'Type of glasses',
    fieldType: 'CHECKBOX',
    condition: hasGlasses,
    options: getOptionsFromLabels(
      [
        'Single vision distance',
        'Single vision reading',
        'Bifocal',
        'Progressive',
        'Prism',
        'Other glasses',
      ],
      undefined,
      'glasses'
    ),
  },
  {
    key: 'otherGlassesSpecify',
    label: 'Type of glasses',
    fieldType: 'INPUT',
    condition: hasOtherTypeOfGlasses,
  },
  {
    key: 'visualConditions',
    label: 'Visual condition(s)',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(
      VISION_CONDITIONS,
      undefined,
      'visualCondition'
    ),
    condition: hasImpairedAcuity,
  },
  {
    key: 'visualConditionDetails',
    label: 'Details of visual condition',
    fieldType: 'TEXT-AREA',
    condition: otherVisualCondition,
  },
  {
    key: 'primaryVisualFieldDefect',
    label: 'Primary visual field defect',
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels(
      [
        'Hemianopsia',
        'Quadrantanopia',
        'Scotoma',
        'Right',
        'Left',
        'Inferior',
        'Superior',
        'None',
      ],
      undefined,
      'primaryVisualFieldDefect'
    ),
    condition: hasImpairedVisiualFields,
  },
  {
    key: 'dateOfOnsetOfVisualFieldDefect',
    label: 'Date of onset of the above',
    fieldType: 'DATE',
    condition: hasImpairedVisiualFields,
  },
];

export const VISION_SCREEN_CONDITIONALS: Conditional[] = [
  {
    key: 'dateOfVisionScreen',
    label: 'Date of vision screen',
    fieldType: 'DATE',
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'acuityBothEyes',
    label: 'Acuity (both eyes)',
    fieldType: 'INPUT',
    units: <Typography>20/x</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'acuityLeftEye',
    label: 'Acuity (left eye)',
    fieldType: 'INPUT',
    units: <Typography>20/x</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'acuityRightEye',
    label: 'Acuity (right eye)',
    fieldType: 'INPUT',
    units: <Typography>20/x</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'peripheralTestLeft',
    label: 'Peripheral test (left)',
    fieldType: 'INPUT-NUM',
    units: <Typography>degrees</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'peripheralTestRight',
    label: 'Peripheral test (right)',
    fieldType: 'INPUT-NUM',
    units: <Typography>degrees</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'peripheralTestTotal',
    label: 'Peripheral test (total)',
    fieldType: 'INPUT-NUM',
    units: <Typography>degrees</Typography>,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'meetsLicensingBodyRequirements',
    label: `Does the client's vision meet the licensing body's requirements?`,
    fieldType: 'RADIO',
    options: YES_NO,
    condition: visionScreenProvidedWithReferral,
  },
  {
    key: 'whyDoesntMeetLicensingBodyRequirements',
    label: 'Please provide details',
    fieldType: 'TEXT-AREA',
    condition: doesntMeetLicensingBodyRequirements,
  },
];
