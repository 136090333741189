import { SubHeadingItem } from '../../../models/forms';
import DRIVING_HISTORY from './DrivingHistory';
import FUNCTION_FOR_DAILY_LIVING_TASKS from './FunctionForDailyLivingTasks';
import LICENSING_INFORMATION from './LicensingInformation';
import MEDICAL_HISTORY from './MedicalHistory';
import PERSONAL_DETAILS from './PersonalDetails';
import PREVIOUS_EVALUATIONS from './PreviousEvaluations';
import REFERRAL_INFORMATION from './ReferralInformation';
import SUMMARY from './Summary';
import VISION_HEARING_HISTORY from './VisionHearingHistory';

const INTAKE_FORM_FIELDS: SubHeadingItem[] = [
  PERSONAL_DETAILS,
  LICENSING_INFORMATION,
  PREVIOUS_EVALUATIONS,
  DRIVING_HISTORY,
  REFERRAL_INFORMATION,
  MEDICAL_HISTORY,
  VISION_HEARING_HISTORY,
  FUNCTION_FOR_DAILY_LIVING_TASKS,
  SUMMARY,
];

export default INTAKE_FORM_FIELDS;
