import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getIntersectionOptions } from './utils';

const TYPE_OF_STOPS_LABELS = [
  '2-way (facing)',
  '2-way (perpendicular)',
  '3-way (in a 4-way intersection)',
  'All-way',
];

const ADJUSTMENT_TO_STIMULI_SIGNS_STOP_SIGN = [
  'Failed to respond to the stop sign',
  'Failed to execute the right of way',
  'Rolling stop (less than 5kph)',
  'Rolling stop (5 to 10kph)',
  'Rolling stop (greater than 10kph)',
];
const ADJUSTMENT_TO_STIMULI_SIGNS_STOP_SIGN_OPTIONS = getOptionsFromLabels(
  ADJUSTMENT_TO_STIMULI_SIGNS_STOP_SIGN
);

export const STOP_SIGN_META: SubHeadingItem = {
  key: 'stopSign',
  label: 'Stop Sign',
  fields: [
    {
      label: 'Type of Stop',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels(TYPE_OF_STOPS_LABELS),
    },
  ],
};
export const STOP_SIGN_ERRORS: SubHeadingItem = {
  key: 'stopSign',
  label: 'Stop Sign',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'stopSignAdjustmentToStimuliTrafficSigns',
      fieldType: 'CHECKBOX',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_STOP_SIGN_OPTIONS,
    },
    ...getIntersectionOptions('stopSign'),
  ],
};
