import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../BasicDialog';

export interface ConfirmFileOpModalProps {
  show: boolean;
  title: string;
  text: string;
  handleCancel: any;
  handleConfirm: any;
}

export const ConfirmFileOpModal: FC<ConfirmFileOpModalProps> = ({
  show,
  title,
  text,
  handleCancel,
  handleConfirm,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <BasicDialog
      open={show}
      handleClose={handleCancel}
      label={
        <Typography variant="subtitle1" component="span">
          {title}
        </Typography>
      }
      maxWidth="sm"
      actions={
        <>
          <Button onClick={handleCancel}>
            {getTranslationLabel('fileUpload', 'cancelButton', t)}
          </Button>
          <Button onClick={handleConfirm} color="secondary" variant="outlined">
            {getTranslationLabel('fileUpload', 'confirmButton', t)}
          </Button>
        </>
      }
    >
      <Typography>{text}</Typography>
    </BasicDialog>
  );
};
