import {
  Conditional,
  FieldsLayout,
  IOption,
  MainHeadingItem,
  SubHeadingItem,
} from '../../../../models/forms';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../../assessments/AssessmentCard/AssessmentCard-utils';

const SATISFACTORY_RATING_OPTIONS = getOptionsFromLabels([
  'Satisfactory',
  'Unsatisfactory',
]);
const APPROPRIATE_RATING_OPTIONS = getOptionsFromLabels([
  'Appropriate',
  'Inappropriate',
]);

const VERBAL_HELP_OPTION = getOptionsFromLabels(['Verbal help']);
const DI_INTERVENTION_OPTION = getOptionsFromLabels(['DI Intervention']);
const NA_OPTION = getOptionsFromLabels(['N/A']);

const isShowComment = (selected: string) =>
  selected === 'inappropriate' ||
  selected === 'insufficient' ||
  selected === 'concerns';

const getStrategicConditionals = (fieldKey: string): Conditional[] => [
  {
    key: `verbalHelpComments${fieldKey}`,
    label: `Comments`,
    fieldType: 'TEXT-AREA',
    condition: (values: any) =>
      values[`${fieldKey}`] && values[`${fieldKey}`] === 'verbalHelp',
  },
  {
    key: `dIInterventionComments${fieldKey}`,
    label: `Comments`,
    fieldType: 'TEXT-AREA',
    condition: (values: any) =>
      values[`${fieldKey}`] && values[`${fieldKey}`] === 'dIIntervention',
  },
  {
    key: `comments${fieldKey}`,
    label: `Comments`,
    fieldType: 'TEXT-AREA',
    condition: (values: any) =>
      values[`${fieldKey}`] && isShowComment(values[`${fieldKey}`]),
  },
];

const getChoiceChipsField = (
  label: string,
  options: IOption[]
): FieldsLayout => {
  const fieldKey = convertLabelToKey(label);

  return {
    label,
    key: fieldKey,
    fieldType: 'CHOICE_CHIPS',
    options: [...options, ...NA_OPTION],
    conditionals: getStrategicConditionals(fieldKey),
  };
};

const getChoiceChipsFieldSection = (
  labels: string[],
  options: IOption[]
): FieldsLayout[] =>
  labels.map((label) => ({
    label,
    key: convertLabelToKey(label),
    fieldType: 'CHOICE_CHIPS',
    options: [...options, ...NA_OPTION],
    conditionals: getStrategicConditionals(convertLabelToKey(label)),
  }));

const ROAD_KNOWLEDGE_LABELS = [
  'Road sign detection and adherence',
  'Rules of the road understanding and execution',
];

const ROAD_KNOWLEDGE: FieldsLayout[] = getChoiceChipsFieldSection(
  ROAD_KNOWLEDGE_LABELS,
  [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
    ...DI_INTERVENTION_OPTION,
  ]
);
const EXECUTIVE_FUNCTION_SKILLS: FieldsLayout[] = [
  getChoiceChipsField('Awareness of Driving Environment', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Initiation', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Judgment', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Problem solving', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Hazard recognition', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Hazard response', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
    ...DI_INTERVENTION_OPTION,
  ]),
  getChoiceChipsField('Response to adverse conditions', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
    ...DI_INTERVENTION_OPTION,
  ]),
  getChoiceChipsField('Response to unusual situations', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
    ...DI_INTERVENTION_OPTION,
  ]),
  getChoiceChipsField('Landmark recognition', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Route finding', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Topographical orientation', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
];

const BEHAVIOUR: FieldsLayout[] = [
  getChoiceChipsField('Affect', [...APPROPRIATE_RATING_OPTIONS]),
  getChoiceChipsField('Anxiety management', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
  ]),
  getChoiceChipsField('Focus', [
    ...SATISFACTORY_RATING_OPTIONS,
    ...DI_INTERVENTION_OPTION,
  ]),
  getChoiceChipsField('Frustration tolerance', [
    ...SATISFACTORY_RATING_OPTIONS,
  ]),
  getChoiceChipsField('Impulsivity tolerance', [
    // TODO: Maybe there is a better word for this question (opposite of impulsivity)
    ...SATISFACTORY_RATING_OPTIONS,
    ...DI_INTERVENTION_OPTION,
  ]),
  getChoiceChipsField('Risk taking behaviour', [
    ...APPROPRIATE_RATING_OPTIONS,
    ...VERBAL_HELP_OPTION,
    ...DI_INTERVENTION_OPTION,
  ]),
];

const REHABILITATION_POTENTIAL: FieldsLayout[] = [
  getChoiceChipsField('Recognizing errors and accepting responsibility', [
    ...SATISFACTORY_RATING_OPTIONS,
  ]),
  getChoiceChipsField('Response to feedback', [...SATISFACTORY_RATING_OPTIONS]),
  getChoiceChipsField('Understanding of feedback', [
    ...SATISFACTORY_RATING_OPTIONS,
  ]),
  getChoiceChipsField('Use of compensatory techniques', [
    ...APPROPRIATE_RATING_OPTIONS,
  ]),
];

const STRATEGIC: SubHeadingItem[] = [
  {
    key: 'roadKnowledge',
    label: 'Road Knowledge',
    fields: ROAD_KNOWLEDGE,
  },
  {
    key: 'rehabilitationPotential',
    label: 'Rehabilitation Potential',
    fields: REHABILITATION_POTENTIAL,
  },
  {
    key: 'behaviour',
    label: 'Behaviour',
    fields: BEHAVIOUR,
  },
  {
    key: 'executiveFunctionSkills',
    label: 'Executive Function Skills',
    fields: EXECUTIVE_FUNCTION_SKILLS,
  },
];

export const STRATEGIC_SECTION: MainHeadingItem = {
  key: 'strategic',
  label: 'Strategic',
  subheadings: STRATEGIC,
};

export default STRATEGIC;
