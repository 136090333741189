import React from 'react';

import { Typography } from '@mui/material';

import { IOption, MainHeadingItem } from '../../../models/forms';
import {
  YES_NO,
  PASS_FAIL,
  VisualResults,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getNumericOptionsXToY } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const TITMUS_STEREOPSIS_OPTIONS: IOption[] = [
  '400',
  '200',
  '100',
  '70',
  '50',
  '40',
  '30',
  '25',
  '20',
].map((item) => ({
  key: `stereopsisOption${item}`,
  label: item,
  val: item,
}));

const SHEPARD_FRY_PERCENTAGES: IOption[] = [
  '15',
  '30',
  '50',
  '60',
  '70',
  '80',
  '85',
  '90',
  '95',
].map((item) => ({
  key: `shepardFryOption${item}`,
  label: item,
  val: item,
}));

const NUMBERS_CLIENT_FAILED_TO_SEE: IOption[] = [
  'A:1',
  'A:2',
  'B:5',
  'C:2',
  'C:6',
  'D:6',
  'E:1',
  'E:6',
  'F:None',
].map((item) => ({
  key: `shepardFryOption${item.replace(':', '')}`,
  label: item,
  val: item,
}));

const PERIPHERAL_VISION_OPTIONS: IOption[] = ['50', '75', '85'].map((item) => ({
  key: `peripheralVisionOption${item}`,
  label: item,
  val: item,
}));

const titmusVisionScreener: MainHeadingItem = {
  label: 'Titmus Vision Screener',
  key: 'titmusVisionScreener',
  subheadings: [
    {
      key: 'binocularVision',
      label: 'Binocular vision',
      fields: [
        {
          label: 'How many cubes does the client see?',
          key: 'howManyCubesDoesTheClientSee',
          fieldType: 'RADIO',
          options: [
            {
              key: 'three',
              label: 'Three',
              val: 'three',
            },
            {
              key: 'fourThenThree',
              label: 'Four, then three',
              val: 'fourThenThree',
            },
            {
              key: 'four',
              label: 'Four',
              val: 'four',
            },
            {
              key: 'two',
              label: 'Two',
              val: 'two',
            },
          ],
        },
        {
          label: 'Colour of the cubes correctly identified?',
          key: 'isColourOfCubesCorrectlyIdentified',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          key: 'binocularVisionOverallResult',
          label: 'Binocular vision overall result',
          fieldType: 'RADIO',
          options: [
            {
              key: 'pass',
              val: 'pass',
              label: 'Pass',
            },
            {
              key: 'failNoFusion',
              val: 'failNoFusion',
              label: 'Fail, no fusion',
            },
            {
              key: 'failVisionInOnlyOneEye',
              val: 'failVisionInOnlyOneEye',
              label: 'Fail, vision in only one eye',
            },
          ],
        },
      ],
    },
    {
      key: 'acuity',
      label: 'Acuity',
      fields: [
        {
          label: 'Record last acuity level seen correctly',
          key: 'acuityLabel',
          fieldType: 'LABEL',
        },
        {
          label: 'Acuity (both eyes)',
          key: 'acuityBothEyes',
          fieldType: 'INPUT-NUM',
          units: <Typography>20 / x</Typography>,
        },
        {
          label: 'Acuity (right eye)',
          key: 'acuityRightEye',
          fieldType: 'INPUT-NUM',
          units: <Typography>20 / x</Typography>,
        },
        {
          label: 'Acuity (left eye)',
          key: 'acuityLeftEye',
          fieldType: 'INPUT-NUM',
          units: <Typography>20 / x</Typography>,
        },
        {
          key: 'acuityOverallResult',
          label: 'Acuity overall result',
          fieldType: 'RADIO',
          options: PASS_FAIL,
        },
      ],
    },
    {
      key: 'depthPerception',
      label: 'Depth Perception',
      fields: [
        {
          label: 'Last correct answered target',
          key: 'lastCorrectAnswerDepthPerception',
          fieldType: 'CHOICE_CHIPS',
          options: [
            {
              key: 'none',
              val: 'none',
              label: 'None',
            },
            ...getNumericOptionsXToY(1, 9),
          ],
        },
        {
          label: 'Stereo depth test measurement',
          key: 'stereoDepthTestMeasurement',
          fieldType: 'CHOICE_CHIPS',
          options: TITMUS_STEREOPSIS_OPTIONS,
        },
        {
          label: 'Shepard-Fry percentage',
          key: 'shepardFryPercentage',
          fieldType: 'CHOICE_CHIPS',
          options: SHEPARD_FRY_PERCENTAGES,
        },
        {
          key: 'depthPerceptionOverallResult',
          label: 'Depth perception overall result',
          fieldType: 'RADIO',
          options: PASS_FAIL,
        },
      ],
    },
    {
      key: 'colourPerception',
      label: 'Colour Perception',
      fields: [
        {
          label: 'What numbers client failed to see',
          key: 'whatColourNumbersClientFailedToSee',
          fieldType: 'CHOICE_CHIPS',
          options: NUMBERS_CLIENT_FAILED_TO_SEE,
        },
        {
          key: 'colourVisionOverallResult',
          label: 'Colour vision overall result',
          fieldType: 'RADIO',
          options: [
            {
              label: 'Normal: Correct reading of ALL 8 numerals',
              key: 'normal',
              val: 'normal',
            },
            {
              label: 'Mild Deficiency: Correct reading of at least 5 numerals',
              key: 'mildDeficiency',
              val: 'mildDeficiency',
            },
            {
              label: 'Fail: Inability to read at least 5 numerals',
              key: 'fail',
              val: 'fail',
            },
          ],
        },
      ],
    },
    {
      key: 'verticalPhoria',
      label: 'Vertical Phoria',
      fields: [
        {
          label: 'Vertical phoria measurement',
          key: 'verticalPhoriaMeasurement',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(1, 7),
        },
        {
          key: 'verticalPhoriaOverallResult',
          label: 'Vertical phoria overall result',
          fieldType: 'RADIO',
          options: PASS_FAIL,
        },
      ],
    },
    {
      key: 'lateralPhoria',
      label: 'Lateral Phoria',
      fields: [
        {
          label: 'Lateral phoria measurement',
          key: 'lateralPhoriaMeasurement',
          fieldType: 'CHOICE_CHIPS',
          options: getNumericOptionsXToY(1, 15),
        },
        {
          key: 'lateralPhoriaOverallResult',
          label: 'Lateral phoria overall result',
          fieldType: 'RADIO',
          options: PASS_FAIL,
        },
      ],
    },
    {
      key: 'peripheralVision',
      label: 'Peripheral Vision Test',
      fields: [
        {
          label: 'Peripheral vision (left eye)',
          key: 'peripheralVisionLeftEye',
          fieldType: 'CHOICE_CHIPS',
          options: PERIPHERAL_VISION_OPTIONS,
        },
        {
          label: 'Peripheral vision (right eye)',
          key: 'peripheralVisionRightEye',
          fieldType: 'CHOICE_CHIPS',
          options: PERIPHERAL_VISION_OPTIONS,
        },
        {
          label: 'Peripheral vision (left eye - nasal)',
          key: 'peripheralVisionLeftEyeNasal',
          fieldType: 'CHOICE_CHIPS',
          options: PASS_FAIL,
        },
        {
          label: 'Peripheral vision (right eye - nasal)',
          key: 'peripheralVisionRightEyeNasal',
          fieldType: 'CHOICE_CHIPS',
          options: PASS_FAIL,
        },
        {
          label: 'Total peripheral angle (left eye)',
          key: 'peripheralVisionLeftTotalAngleLeftEye',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Total peripheral angle (right eye)',
          key: 'peripheralVisionRightTotalAngleRightEye',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'peripheralVisionOverallResult',
          label: 'Peripheral vision overall result',
          fieldType: 'RADIO',
          options: [
            {
              label: 'Normal peripheral vision',
              key: 'normal',
              val: 'normal',
            },
            {
              label: 'Some restriction',
              key: 'someRestriction',
              val: 'someRestriction',
            },
            {
              label: 'Severe restriction',
              key: 'severeRestriction',
              val: 'severeRestriction',
            },
            {
              label: 'Nasal restriction',
              key: 'nasalRestriction',
              val: 'nasalRestriction',
            },
          ],
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: VisualResults,
    },
  ],
};

export default titmusVisionScreener;
