import { SubHeadingItem } from '../../../models/forms';
import { VisualResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const stereoDepthPerception: SubHeadingItem = {
  label: 'Stereo Depth Perception',
  key: 'stereoDepthPerception',
  fields: [
    {
      label: 'Device used for measurement',
      key: 'deviceUsedForMeasurement',
      fieldType: 'SELECT',
      options: getOptionsFromLabels([
        'OPTEC',
        'Titmus vision screener',
        'Stereo Fly',
        'Other (please specify in comments)',
      ]),
    },
    {
      key: `typeOfUnits`,
      label: 'Select measurement units',
      fieldType: 'SELECT',
      options: getOptionsFromLabels([
        'Minimum angle detectable',
        'Shepard-Fry percentage',
      ]),
    },
    {
      label: 'Measurement',
      key: 'measurement',
      fieldType: 'INPUT-NUM',
    },
    ...VisualResults,
  ],
};

export default stereoDepthPerception;
