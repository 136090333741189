import React, { FC } from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

interface LoadingButtonProps {
  isLoading: boolean;
  onClose?: () => void;
  buttonLabel?: string;
}
const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  onClose,
  buttonLabel,
  children,
}) =>
  isLoading ? (
    <Button>
      <CircularProgress size={24} />
    </Button>
  ) : children ? (
    <>{children}</>
  ) : (
    <>
      <Button onClick={onClose} color="secondary">
        Cancel
      </Button>
      <Box>
        <Button type="submit">{buttonLabel}</Button>
      </Box>
    </>
  );

export default LoadingButton;
