import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { PhysicalResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getNumbersXToY,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells, {
  getKeyWithoutAdditionalParameter,
} from '../../common/FieldContainer/MyTable-Utils';

const REACTION_TIME_COLUMN_HEADINGS = [
  'Trial No.',
  'Test No. 1',
  'Test No. 2',
  'Test No. 3',
  'Test No. 4',
];

const REACTION_TIME_ROW_TITLES = [
  'Type of test',
  'Left / right extremity',
  ...getNumbersXToY(1, 10).map((no) => `Trial No. ${no}`),
  'Number of trials recorded',
  'Average time',
];

const getValueDependsOn = (columnHeading: string) =>
  getNumbersXToY(1, 10).map(
    (no) =>
      `${getKeyWithoutAdditionalParameter(
        columnHeading,
        convertLabelToKey(`Trial No. ${no}`)
      )}DataPoint`
  );

const TYPE_OF_TEST_OPTIONS = getOptionsFromLabels([
  'Foot brake',
  'Hand brake',
  'Steering left',
  'Steering right',
]);

const EXTTREMITY_OPTIONS = getOptionsFromLabels([
  'Left',
  'Right',
  'Both (steering)',
]);

const getReactionTimeRowSpec = (key: string, columnHeading: string) => [
  {
    rowTitle: 'Type of test',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: TYPE_OF_TEST_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'Left / right extremity',
    element: {
      key: `${convertLabelToKey(columnHeading)}Extremity`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: EXTTREMITY_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'Number of trials recorded',
    element: {
      key,
      label: 'Number of trials',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
  {
    rowTitle: 'Average time',
    element: {
      key,
      label: 'Average (ms)',
      fieldType: 'INPUT-SWITCH' as FieldTypes,
      size: 200,
      valueDependsOn: getValueDependsOn(columnHeading),
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}DataPoint`,
      label: 'Time (ms)',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const reactionTimeTester: SubHeadingItem = {
  label: 'Reaction Time Tester',
  key: 'reactionTimeTester',
  fields: [
    {
      key: 'reactionTimeTesterPerTrialTable',
      label: '',
      fieldType: 'TABLE',
      columnHeadings: REACTION_TIME_COLUMN_HEADINGS,
      cells: generateCellsForTableWithUniformCells(
        REACTION_TIME_COLUMN_HEADINGS,
        REACTION_TIME_ROW_TITLES,
        getReactionTimeRowSpec,
        undefined
      ),
    },
    {
      label: 'Overall average reaction time',
      key: 'overallAverageReactionTime',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>ms</Typography>,
      valueDependsOn: [
        'testNo1AverageTime',
        'testNo2AverageTime',
        'testNo3AverageTime',
        'testNo4AverageTime',
      ],
    },
    {
      label: 'Type of tester',
      key: 'typeOfTester',
      fieldType: 'SELECT',
      options: getOptionsFromLabels([
        'RT-2S',
        'AAA',
        'Other (specify in comments)',
      ]),
    },
    ...PhysicalResults,
  ],
};

export default reactionTimeTester;
