import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const cognitiveBehaviouralDriversInventory: MainHeadingItem = {
  key: 'cognitiveBehaviouralDriversInventory',
  label: "Cognitive Behavioural Driver's Inventory (CBDI)",
  subheadings: [
    {
      label: 'Task 1 - Visual Reaction Differential Response',
      key: 'cBDITask1',
      fields: [
        {
          label: 'Overall reaction time',
          key: 'task1ReactionTime',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Variance',
          key: 'task1Variance',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 50 max</Typography>,
        },
        {
          label: 'Number of errors',
          key: 'task1NumberOfErrors',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 36 max</Typography>,
        },
        {
          label: 'Average reaction time for the left',
          key: 'task1AverageLeft',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Average reaction time for the right',
          key: 'task1AverageRight',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
      ],
    },
    {
      label: 'Task 2 - Visual Reaction Differential Response Reversed',
      key: 'cBDITask2',
      fields: [
        {
          label: 'Overall reaction time',
          key: 'task2ReactionTime',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Variance',
          key: 'task2Variance',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 50 max</Typography>,
        },
        {
          label: 'Number of errors',
          key: 'task2NumberOfErrors',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 36 max</Typography>,
        },
        {
          label: 'Average reaction time for the left',
          key: 'task2AverageLeft',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Average reaction time for the right',
          key: 'task2AverageRight',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
      ],
    },
    {
      label: 'Task 3 - Visual Discrimination Differential Response',
      key: 'cBDITask3',
      fields: [
        {
          label: 'Average number of correct responses',
          key: 'task3AverageNoOfCorrectResponses',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 20 max</Typography>,
        },
      ],
    },
    {
      label: 'Task 4 - Visual Scanning',
      key: 'cBDITask4',
      fields: [
        {
          label: 'Overall reaction time (left to right)',
          key: 'task4ReactionTimeLR',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Number of errors (left to right)',
          key: 'task4NumberOfErrorsLR',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 20 max</Typography>,
        },
        {
          label: 'Overall reaction time (right to left)',
          key: 'task4ReactionTimeRL',
          fieldType: 'INPUT-NUM',
          units: <Typography>seconds</Typography>,
        },
        {
          label: 'Number of errors (right to left)',
          key: 'task4NumberOfErrorsRL',
          fieldType: 'INPUT-NUM',
          units: <Typography>/ 20 max</Typography>,
        },
      ],
    },
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: Results,
    },
  ],
};

export default cognitiveBehaviouralDriversInventory;
