import { IOption, SubHeadingItem, MainHeadingItem } from '../../models/forms';
import accidentFearQuestionnaire from './Behavpsych/accidentFearQuestionnaire';
import beckAnxietyInventory from './Behavpsych/beckAnxietyInventory';
import beckDepressionInventory from './Behavpsych/beckDepressionInventory';
import centerForEpidemiologicStudiesDepression from './Behavpsych/centerForEpidemiologicStudiesDepression';
import epworthSleepinessScale from './Behavpsych/epworthSleepinessScale';
import functionalAbilitiesQuestionnaire from './Behavpsych/functionalAbilitiesQuestionnaire';
import generalizedAnxietyDisorderScale from './Behavpsych/generalizedAnxietyDisorderScale';
import geriatricDepressionScale15 from './Behavpsych/geriatricDepressionScale15';
import geriatricDepressionScale5 from './Behavpsych/geriatricDepressionScale5';
import mcGillPainQuestionnaire from './Behavpsych/mcGillPainQuestionnaire';
import sleepImpairmentIndex from './Behavpsych/sleepImpairmentIndex';
import travelAnxietyQuestionnaire from './Behavpsych/travelAnxietyQuestionnaire';
import behavioralInattentionTest from './Cognitive/behavioralInattentionTest';
import bellsTest from './Cognitive/bellsTest';
import charronTest from './Cognitive/charronTest';
import clockDrawing from './Cognitive/clockDrawing';
import cloxTest from './Cognitive/cloxTest';
import cognistat from './Cognitive/cognistat';
import cognitiveAssessmentScaleForTheElderly from './Cognitive/cognitiveAssessmentScaleForTheElderly';
import cognitiveBehaviouralDriversInventory from './Cognitive/cognitiveBehaviouralDriversInventory';
import colourTrailsTest from './Cognitive/colourTrailsTest';
import comprehensiveTrailMaking from './Cognitive/comprehensiveTrailMaking';
import computerizedMazeTasks from './Cognitive/computerizedMazeTasks';
import d2TestOfAttention from './Cognitive/d2TestOfAttention';
import developmentTestOfVisualPerception from './Cognitive/developmentTestOfVisualPerception';
import digitSpanTest from './Cognitive/digitSpanTest';
import doubleLetterCancellation from './Cognitive/doubleLetterCancellation';
import dynamicScanCourse from './Cognitive/dynamicScanCourse';
import dynavisionD2 from './Cognitive/dynavisionD2';
import gestaltCompletionTest from './Cognitive/gestaltCompletionTest';
import intersectingPentagonsTest from './Cognitive/intersectingPentagonsTest';
import logicalMemoryTest from './Cognitive/logicalMemoryTest';
import metacognitionInClinicInterview from './Cognitive/metacognitionInClinicInterview';
import metacognitionOnRoadInterview from './Cognitive/metacognitionOnRoadInterview';
import miniMentalStatusExamination from './Cognitive/miniMentalStatusExamination';
import moca from './Cognitive/moca';
import mocab from './Cognitive/moca-b';
import modifiedTokenTest from './Cognitive/modifiedTokenTest';
import monthsInReverseOrder from './Cognitive/monthsInReverseOrder';
import motorFreeVisualPerceptionTest from './Cognitive/motorFreeVisualPerceptionTest';
import motorFreeVisualPerceptionTest3 from './Cognitive/motorFreeVisualPerceptionTest3';
import motorFreeVisualPerceptionTest4 from './Cognitive/motorFreeVisualPerceptionTest4';
import nationalAdultReadingTest from './Cognitive/nationalAdultReadingTest ';
import pictureCompletionTest from './Cognitive/pictureCompletionTest';
import quickNeurologicalScreeningTest from './Cognitive/quickNeurologicalScreeningTest';
import singleLetterCancellation from './Cognitive/singleLetterCancellation';
import snellgroveMazeTest from './Cognitive/snellgroveMazeTest';
import snowyPicturesTest from './Cognitive/snowyPicturesTest';
import standardizedRoadMapTest from './Cognitive/standardizedRoadMapTest';
import StLouisUniversityMentalStatus from './Cognitive/StLouisUniversityMentalStatus';
import symbolDigitModalitiesTest from './Cognitive/symbolDigitModalitiesTest';
import trailMakingA from './Cognitive/trailMakingA';
import trailMakingB from './Cognitive/trailMakingB';
import trailsX from './Cognitive/trialsX';
import ufov from './Cognitive/ufov';
import weiglSortingTask from './Cognitive/weiglSortingTask';
import cumulativeIllnessRatingScale from './Comprehensive/cumulativeIllnessRatingScale';
import driveABLECognitiveAssessmentTool from './Comprehensive/driveABLECognitiveAssessmentTool';
import functionalStatus from './Comprehensive/functionalStatus';
import rookwoodDrivingBattery from './Comprehensive/rookwoodDrivingBattery';
import SaskatchewanPsychiatricOTDrivingScreen from './Comprehensive/SaskatchewanPsychiatricOTDrivingScreen';
import sportConcussionAssessmentTool3 from './Comprehensive/sportConcussionAssessmentTool3';
import driverPerformanceTest from './DrivingSpecific/driverPerformanceTest';
import driverRiskIndex from './DrivingSpecific/driverRiskIndex';
import drivingBehaviorSurvey from './DrivingSpecific/drivingBehaviorSurvey';
import drivingHabitQuestionnaire from './DrivingSpecific/drivingHabitQuestionnaire';
import rulesOfTheRoadTest from './DrivingSpecific/rulesOfTheRoadTest';
import safePerformanceOnRoadTest from './DrivingSpecific/safePerformanceOnRoadTest';
import trafficJudgmentQuestions from './DrivingSpecific/trafficJudgmentQuestions';
import trafficSignRecognitionTest from './DrivingSpecific/trafficSignRecognitionTest';
import bergBalanceScale from './Physical/bergBalanceScale';
import coordination from './Physical/coordination';
import functionalIndependenceMeasure from './Physical/functionalIndependenceMeasure';
import jamarGripDynamometerTest from './Physical/jamarGripDynamometerTest';
import mobilityAndBalance from './Physical/mobilityAndBalance';
import ninePegHoleTest from './Physical/ninePegHoleTest';
import oneLegStance from './Physical/oneLegStance';
import otherPhysicalStatus from './Physical/otherPhysicalStatus';
import pursuitRotorTask from './Physical/pursuitRotorTask';
import quickPhysicalScreen from './Physical/quickPhysicalScreen';
import rangeOfMotion from './Physical/rangeOfMotion';
import reactionTimeTester from './Physical/reactionTimeTester';
import reactionTimeTesterCumulative from './Physical/reactionTimeTesterCumulative';
import scaleForTheAssessmentAndRatingOfAtaxia from './Physical/scaleForTheAssessmentAndRatingOfAtaxia';
import sensationAndProprioception from './Physical/sensationAndProprioception';
import steerClear from './Physical/steerClear';
import strengthTesting from './Physical/strengthTesting';
import theWesternOntarioMcMaster from './Physical/theWesternOntarioMcMaster';
import vericomStationaryReactionTimer from './Physical/vericomStationaryReactionTimer';
import whisperedVoiceTest from './Physical/whisperedVoiceTest';
import contrastSensitivity from './Visual/contrastSensitivity';
import oculomotorControl from './Visual/oculomotorControl';
import stereoDepthPerception from './Visual/stereoDepthPerception';
import titmusVisionScreener from './Visual/titmusVisionScreener';
import verticalAndLateralPhoria from './Visual/verticalAndLateralPhoria';
import visualAcuity from './Visual/visualAcuity';
import visualFields from './Visual/visualFields';

export const ASSESSMENT_DEFINITIONS: (SubHeadingItem | MainHeadingItem)[] = [
  // -- COGNITIVE --
  behavioralInattentionTest,
  bellsTest,
  charronTest,
  clockDrawing,
  cloxTest,
  cognistat,
  cognitiveAssessmentScaleForTheElderly,
  cognitiveBehaviouralDriversInventory,
  colourTrailsTest,
  comprehensiveTrailMaking,
  computerizedMazeTasks,
  d2TestOfAttention,
  developmentTestOfVisualPerception,
  digitSpanTest,
  doubleLetterCancellation,
  dynamicScanCourse,
  dynavisionD2,
  gestaltCompletionTest,
  intersectingPentagonsTest,
  logicalMemoryTest,
  metacognitionInClinicInterview,
  metacognitionOnRoadInterview,
  miniMentalStatusExamination,
  moca,
  mocab,
  modifiedTokenTest,
  monthsInReverseOrder,
  motorFreeVisualPerceptionTest,
  motorFreeVisualPerceptionTest3,
  motorFreeVisualPerceptionTest4,
  nationalAdultReadingTest,
  pictureCompletionTest,
  quickNeurologicalScreeningTest,
  singleLetterCancellation,
  snellgroveMazeTest,
  snowyPicturesTest,
  standardizedRoadMapTest,
  StLouisUniversityMentalStatus,
  symbolDigitModalitiesTest,
  trailMakingA,
  trailMakingB,
  trailsX,
  ufov,
  weiglSortingTask,

  // -- PHYSICAL --
  bergBalanceScale,
  coordination,
  functionalIndependenceMeasure,
  jamarGripDynamometerTest,
  mobilityAndBalance,
  ninePegHoleTest,
  oneLegStance,
  otherPhysicalStatus,
  pursuitRotorTask,
  quickPhysicalScreen,
  rangeOfMotion,
  reactionTimeTester,
  reactionTimeTesterCumulative,
  scaleForTheAssessmentAndRatingOfAtaxia,
  sensationAndProprioception,
  steerClear,
  strengthTesting,
  theWesternOntarioMcMaster,
  vericomStationaryReactionTimer,
  whisperedVoiceTest,

  // -- VISUAL --
  contrastSensitivity,
  oculomotorControl,
  stereoDepthPerception,
  titmusVisionScreener,
  verticalAndLateralPhoria,
  visualAcuity,
  visualFields,

  // -- BEHAV-PSYCH --
  accidentFearQuestionnaire,
  beckAnxietyInventory,
  beckDepressionInventory,
  centerForEpidemiologicStudiesDepression,
  epworthSleepinessScale,
  functionalAbilitiesQuestionnaire,
  generalizedAnxietyDisorderScale,
  geriatricDepressionScale5,
  geriatricDepressionScale15,
  mcGillPainQuestionnaire,
  sleepImpairmentIndex,
  travelAnxietyQuestionnaire,

  // -- COMPREHENSIVE --
  cumulativeIllnessRatingScale,
  driveABLECognitiveAssessmentTool,
  functionalStatus,
  rookwoodDrivingBattery,
  SaskatchewanPsychiatricOTDrivingScreen,
  sportConcussionAssessmentTool3,

  // -- DRIVING-SPECIFIC --
  driverPerformanceTest,
  driverRiskIndex,
  drivingBehaviorSurvey,
  drivingHabitQuestionnaire,
  rulesOfTheRoadTest,
  safePerformanceOnRoadTest,
  trafficJudgmentQuestions,
  trafficSignRecognitionTest,
];

export const ASSESSMENT_IOPTION: IOption[] = ASSESSMENT_DEFINITIONS.map(
  (assessment) => ({
    key: assessment.key.toString(),
    val: assessment.key.toString(),
    label: assessment.label,
  })
);
export const COGNITIVE_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'behavioralInattentionTest' ||
    battery.key === 'bellsTest' ||
    battery.key === 'charronTest' ||
    battery.key === 'clockDrawing' ||
    battery.key === 'cloxTest' ||
    battery.key === 'cognistat' ||
    battery.key === 'cognitiveAssessmentScaleForTheElderly' ||
    battery.key === 'cognitiveBehaviouralDriversInventory' ||
    battery.key === 'colourTrailsTest' ||
    battery.key === 'comprehensiveTrailMaking' ||
    battery.key === 'computerizedMazeTasks' ||
    battery.key === 'd2TestOfAttention' ||
    battery.key === 'developmentTestOfVisualPerception' ||
    battery.key === 'digitSpanTest' ||
    battery.key === 'doubleLetterCancellation' ||
    battery.key === 'dynamicScanCourse' ||
    battery.key === 'dynavisionD2' ||
    battery.key === 'gestaltCompletionTest' ||
    battery.key === 'intersectingPentagonsTest' ||
    battery.key === 'logicalMemoryTest' ||
    battery.key === 'metacognitionInClinicInterview' ||
    battery.key === 'metacognitionOnRoadInterview' ||
    battery.key === 'miniMentalStatusExamination' ||
    battery.key === 'moca' ||
    battery.key === 'mocab' ||
    battery.key === 'modifiedTokenTest' ||
    battery.key === 'monthsInReverseOrder' ||
    battery.key === 'motorFreeVisualPerceptionTest' ||
    battery.key === 'motorFreeVisualPerceptionTest3' ||
    battery.key === 'motorFreeVisualPerceptionTest4' ||
    battery.key === 'nationalAdultReadingTest' ||
    battery.key === 'pictureCompletionTest' ||
    battery.key === 'quickNeurologicalScreeningTest' ||
    battery.key === 'singleLetterCancellation' ||
    battery.key === 'snellgroveMazeTest' ||
    battery.key === 'snowyPicturesTest' ||
    battery.key === 'standardizedRoadMapTest' ||
    battery.key === 'StLouisUniversityMentalStatus' ||
    battery.key === 'symbolDigitModalitiesTest' ||
    battery.key === 'trailMakingA' ||
    battery.key === 'trailMakingB' ||
    battery.key === 'trailsX' ||
    battery.key === 'ufov' ||
    battery.key === 'weiglSortingTask'
);
export const PHYSICAL_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'bergBalanceScale' ||
    battery.key === 'coordination' ||
    battery.key === 'functionalIndependenceMeasure' ||
    battery.key === 'jamarGripDynamometerTest' ||
    battery.key === 'mobilityAndBalance' ||
    battery.key === 'ninePegHoleTest' ||
    battery.key === 'oneLegStance' ||
    battery.key === 'otherPhysicalStatus' ||
    battery.key === 'pursuitRotorTask' ||
    battery.key === 'quickPhysicalScreen' ||
    battery.key === 'rangeOfMotion' ||
    battery.key === 'reactionTimeTester' ||
    battery.key === 'reactionTimeTesterCumulative' ||
    battery.key === 'scaleForTheAssessmentAndRatingOfAtaxia' ||
    battery.key === 'sensationAndProprioception' ||
    battery.key === 'steerClear' ||
    battery.key === 'strengthTesting' ||
    battery.key === 'theWesternOntarioMcMaster' ||
    battery.key === 'vericomStationaryReactionTimer' ||
    battery.key === 'whisperedVoiceTest'
);
export const VISUAL_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'contrastSensitivity' ||
    battery.key === 'oculomotorControl' ||
    battery.key === 'stereoDepthPerception' ||
    battery.key === 'titmusVisionScreener' ||
    battery.key === 'verticalAndLateralPhoria' ||
    battery.key === 'visualAcuity' ||
    battery.key === 'visualFields'
);
export const BEHAVPSYCH_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'accidentFearQuestionnaire' ||
    battery.key === 'beckAnxietyInventory' ||
    battery.key === 'beckDepressionInventory' ||
    battery.key === 'centerForEpidemiologicStudiesDepression' ||
    battery.key === 'epworthSleepinessScale' ||
    battery.key === 'functionalAbilitiesQuestionnaire' ||
    battery.key === 'generalizedAnxietyDisorderScale' ||
    battery.key === 'geriatricDepressionScale5' ||
    battery.key === 'geriatricDepressionScale15' ||
    battery.key === 'mcGillPainQuestionnaire' ||
    battery.key === 'sleepImpairmentIndex' ||
    battery.key === 'travelAnxietyQuestionnaire'
);
export const COMPREHENSIVE_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'cumulativeIllnessRatingScale' ||
    battery.key === 'driveABLECognitiveAssessmentTool' ||
    battery.key === 'functionalStatus' ||
    battery.key === 'rookwoodDrivingBattery' ||
    battery.key === 'SaskatchewanPsychiatricOTDrivingScreen' ||
    battery.key === 'sportConcussionAssessmentTool3'
);
export const DRIVINGSPECIFIC_BATTERIES = ASSESSMENT_IOPTION.filter(
  (battery) =>
    battery.key === 'driverPerformanceTest' ||
    battery.key === 'driverRiskIndex' ||
    battery.key === 'drivingBehaviorSurvey' ||
    battery.key === 'drivingHabitQuestionnaire' ||
    battery.key === 'rulesOfTheRoadTest' ||
    battery.key === 'safePerformanceOnRoadTest' ||
    battery.key === 'trafficJudgmentQuestions' ||
    battery.key === 'trafficSignRecognitionTest'
);
