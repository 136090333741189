import React, { useState } from 'react';

import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';
import { bold, containerWidth, darkBlue, gray } from '../constants';
import Banner from './Helpers/Banner';

const Contact = () => {
  const { t } = useTranslation(websiteNamespaces);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [dialogMessageKey, setDialogMessageKey] = useState('');
  const { handleSubmit, register, reset } = useForm();
  const history = useHistory();

  const largeScreen = useMediaQuery('(min-width:900px)');

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    if (dialogMessageKey === 'messageSuccess') {
      history.push('/');
    }
  };

  const sendMessage = async (data: any) => {
    setIsConfirmDialogOpen(true);
    setDialogMessageKey('inProgress');

    const fetchOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data,
      }),
    };

    fetch('/api/contact', fetchOptions)
      .then((resp: Response) => {
        if (resp.status !== 200) {
          setDialogMessageKey('messageError');
        } else {
          setDialogMessageKey('messageSuccess');
        }
      })
      .catch((err) => {
        setDialogMessageKey('messageError');
        setIsConfirmDialogOpen(true);
      });
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <Banner
        text={getTranslationLabel(
          'website/contact',
          'weWouldLoveToHearFromYou',
          t
        )}
        variant="h2"
        padding="1%"
      />
      <Banner
        text={getTranslationLabel('website/contact', 'contactUsText', t)}
        variant="h3"
        padding="1%"
      />
      <Banner text="Contact Us" variant="h4" padding="1%" />

      <form>
        <Grid container sx={{ marginBottom: largeScreen ? '.5%' : '0px' }}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ paddingRight: largeScreen ? '.5%' : '0px' }}
          >
            <TextField
              id="first-name"
              label={getTranslationLabel('website/contact', 'firstName', t)}
              variant="outlined"
              fullWidth
              {...register('firstName')}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ paddingLeft: largeScreen ? '.5%' : '0px' }}
          >
            <TextField
              id="last-name"
              label={getTranslationLabel('website/contact', 'lastName', t)}
              variant="outlined"
              fullWidth
              {...register('lastName')}
            />
          </Grid>
        </Grid>

        <TextField
          id="email"
          label={getTranslationLabel('website/contact', 'email', t)}
          variant="outlined"
          fullWidth
          {...register('email')}
        />
        <TextField
          id="subject"
          label={getTranslationLabel('website/contact', 'subject', t)}
          variant="outlined"
          fullWidth
          {...register('subject')}
        />
        <TextField
          id="message"
          label={getTranslationLabel('website/contact', 'message', t)}
          variant="outlined"
          multiline
          fullWidth
          rows={5}
          InputProps={{ style: { fontSize: '2vw', color: gray } }}
          {...register('message')}
        />

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: { darkBlue },
                borderRadius: '40px',
                fontFamily: 'Poppins',
                fontSize: 'max(14px, 1.5vw)',
                fontWeight: bold,
                marginLeft: '2.5vw',
                height: '4vw',
                minWidth: '10vw',
                minHeight: '50px',
                textTransform: 'none',
                ':hover': {
                  bgcolor: { darkBlue },
                },
              }}
              onClick={() => reset()}
            >
              {getTranslationLabel('website/contact', 'clear', t)}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: { darkBlue },
                borderRadius: '40px',
                fontFamily: 'Poppins',
                fontSize: 'max(14px, 1.5vw)',
                fontWeight: bold,
                marginLeft: '2.5vw',
                height: '4vw',
                minWidth: '10vw',
                minHeight: '50px',
                textTransform: 'none',
                ':hover': {
                  bgcolor: { darkBlue },
                },
              }}
              onClick={handleSubmit(sendMessage)}
            >
              {getTranslationLabel('website/contact', 'send', t)}
            </Button>
          </Grid>
        </Grid>
      </form>

      <BasicDialog
        label={
          <Typography variant="h3" color={gray}>
            {getTranslationLabel('website/contact', dialogMessageKey, t)}
          </Typography>
        }
        open={isConfirmDialogOpen}
        actions={
          dialogMessageKey !== 'inProgress' ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: { darkBlue },
                borderRadius: '40px',
                fontFamily: 'Poppins',
                fontSize: 'max(14px, 1.5vw)',
                fontWeight: bold,
                marginLeft: '2.5vw',
                height: '4vw',
                minWidth: '10vw',
                minHeight: '50px',
                textTransform: 'none',
                ':hover': {
                  bgcolor: { darkBlue },
                },
              }}
              onClick={handleCloseConfirmDialog}
            >
              OK
            </Button>
          ) : (
            <></>
          )
        }
        handleClose={handleCloseConfirmDialog}
      />
    </Container>
  );
};

export default Contact;
