import React, { FC, useMemo } from 'react';

import { Fab, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { LoadingComponent, ErrorComponent } from '../../errors/QueryUtils';
import useRoadTest from '../../hooks/database/on-road/roadTest';
import { IRoadTest, blankRoadTest } from '../../models/on-road/on-road';
import { getTranslationLabel } from '../../utils/utils';
import AutoSaveStatus from '../common/AutoSaveStatus';
import NonLinearStepper from '../common/NonLinearStepper';
import { PaperHeader } from '../common/PaperHeader';
import Scroll from '../common/Scroll';
import Tab1PreTestDetails from './Tab1PreTestDetails';
import Tab2OperationalOnRoad from './Tab2OperationalOnRoad';
import Tab3RouteDetails from './Tab3RouteDetails/Tab3RouteDetails';
import Tab4TacticalManeuvers from './Tab4TacticalManeuvers/Tab4TacticalManeuvers';
import Tab5StrategicAbilities from './Tab5StrategicAbilities';
import Tab6PostTestDetails from './Tab6PostTestDetails';
import Tab7Summary from './Tab7Summary/Tab7Summary';

export interface OnRoadMainProps {
  clientId: string;
  backLink: string | false;
}

export const OnRoadMain: FC<OnRoadMainProps> = ({ clientId, backLink }) => {
  const { t } = useTranslation(allNamespaces);
  const {
    draft: roadTestDraft,
    setDraft: setRoadTestDraft,
    queryResult: roadTestQueryResult,
    saveStatus,
  } = useRoadTest(clientId);

  const saveRoadTest = (state: IRoadTest) => {
    setRoadTestDraft({ roadTestId: clientId, clientId, ...state });
  };

  const saveComponent = useMemo(
    () => (
      <>
        <AutoSaveStatus saveStatus={saveStatus} />
        <Scroll>
          <Fab variant="extended" sx={{ textTransform: 'none' }}>
            <AutoSaveStatus saveStatus={saveStatus} />
          </Fab>
        </Scroll>
      </>
    ),
    [saveStatus]
  );

  if (roadTestQueryResult.isLoading) {
    return <LoadingComponent />;
  }
  if (roadTestQueryResult.isError) {
    return <ErrorComponent error={roadTestQueryResult.error} />;
  }

  const newRoadTest = {
    roadTestId: clientId,
    clientId,
    ...blankRoadTest,
  };

  const roadTest =
    !roadTestDraft || (roadTestDraft && Object.keys(roadTestDraft).length === 0)
      ? newRoadTest
      : roadTestDraft;

  const getSteps = () => [
    getTranslationLabel('navBar', 'preTestDetails', t),
    getTranslationLabel('navBar', 'operationalAbilities', t),
    getTranslationLabel('navBar', 'route', t),
    getTranslationLabel('navBar', 'tacticalManeuvers', t),
    getTranslationLabel('navBar', 'strategicAbilities', t),
    getTranslationLabel('navBar', 'postTestDetails', t),
    getTranslationLabel('navBar', 'summary', t),
  ];
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Tab1PreTestDetails roadTest={roadTest} handleSave={saveRoadTest} />
        );
      case 1:
        return (
          <Tab2OperationalOnRoad
            roadTest={roadTest}
            handleSave={saveRoadTest}
          />
        );
      case 2:
        return (
          <Tab3RouteDetails
            roadTest={roadTest}
            handleSaveRoadTest={saveRoadTest}
          />
        );
      case 3:
        return <Tab4TacticalManeuvers roadTest={roadTest} />;
      case 4:
        return (
          <Tab5StrategicAbilities
            roadTest={roadTest}
            handleSave={saveRoadTest}
          />
        );
      case 5:
        return (
          <Tab6PostTestDetails roadTest={roadTest} handleSave={saveRoadTest} />
        );
      case 6:
        return <Tab7Summary roadTest={roadTest} />;
      default:
        return <Typography>error accessing step content</Typography>;
    }
  };

  return (
    <Grid item container direction="column">
      <Grid item>
        <PaperHeader
          title={getTranslationLabel('clientsGeneral', 'onRoad', t)}
          clientId={clientId}
          backLink={backLink}
          topRightComponent={saveComponent}
        />
      </Grid>
      <Grid item>
        <Paper sx={{ p: 5, minHeight: '80vh', maxHeight: 'auto' }}>
          <NonLinearStepper
            getStepLabels={getSteps}
            getStepContents={getStepContent}
            onNextClick={() => {}}
            onBackClick={() => {}}
            onSave={() => {}}
            alternativeLabel
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
