const allNamespaces = [
  // general
  'alerts',
  'clientsGeneral',
  'consent',
  'errors',
  'fileUpload',
  'generalNs',
  'navBar',
  'settings',
  'help',

  // website
  'website/general',
  'website/main',
  'website/about',
  'website/driveeval',
  'website/faq',
  'website/contact',

  // assesments general
  'assessmentsGeneral/assessClient',
  'assessmentsGeneral/categoryTitles',
  'assessmentsGeneral/assessmentTitles',
  'assessmentsGeneral/assessmentLabels',

  // intake form
  'intake/category0',
  'intake/category1',
  'intake/category2',
  'intake/category3',
  'intake/category4',
  'intake/category5',
  'intake/category6',
  'intake/category7',
  'intake/category8',

  // road test
  'onroad/chooseRoute',
  'onroad/routeBuilder',
  'onroad/preTestRouteDetails',
  'onroad/operationalOnRoad',
  'onroad/strategicAbilities',
  'onroad/postTestOnRoad',

  // results
  'results',

  // road test maneuvers
  'maneuvers/defaultManeuverTypes',
  'maneuvers/maneuversGeneral',
  'maneuvers/curvedRoad',
  'maneuvers/drivingStraight',
  'maneuvers/exitRamp',
  'maneuvers/leftLaneChange',
  'maneuvers/leftTurn',
  'maneuvers/mergeManeuver',
  'maneuvers/parking',
  'maneuvers/passing',
  'maneuvers/pedestrianCrosswalk',
  'maneuvers/pullOver',
  'maneuvers/railwayCrossing',
  'maneuvers/rightLaneChange',
  'maneuvers/rightTurn',
  'maneuvers/schoolZone',
  'maneuvers/stopSign',
  'maneuvers/trafficCircle',
  'maneuvers/trafficLight',
  'maneuvers/uncontrolledIntersection',
  'maneuvers/unexpectedEvent',
  'maneuvers/yield',

  // cognitive-perceptual assessments
  'assessments/behavioralInattentionTest',
  'assessments/bellsTest',
  'assessments/charronTest',
  'assessments/clockDrawing',
  'assessments/cloxTest',
  'assessments/cognistat',
  'assessments/cognitiveAssessmentScaleForTheElderly',
  'assessments/cognitiveBehaviouralDriversInventory',
  'assessments/colourTrailsTest',
  'assessments/comprehensiveTrailMaking',
  'assessments/computerizedMazeTasks',
  'assessments/d2TestOfAttention',
  'assessments/developmentTestOfVisualPerception',
  'assessments/digitSpanTest',
  'assessments/doubleLetterCancellation',
  'assessments/dynamicScanCourse',
  'assessments/dynavisionD2',
  'assessments/gestaltCompletionTest',
  'assessments/intersectingPentagonsTest',
  'assessments/logicalMemoryTest',
  'assessments/miniMentalStatusExamination',
  'assessments/metacognitionInClinicInterview',
  'assessments/metacognitionOnRoadInterview',
  'assessments/moca',
  'assessments/mocab',
  'assessments/modifiedTokenTest',
  'assessments/monthsInReverseOrder',
  'assessments/motorFreeVisualPerceptionTest',
  'assessments/motorFreeVisualPerceptionTest3',
  'assessments/motorFreeVisualPerceptionTest4',
  'assessments/nationalAdultReadingTest',
  'assessments/pictureCompletionTest',
  'assessments/quickNeurologicalScreeningTest',
  'assessments/singleLetterCancellation',
  'assessments/snellgroveMazeTest',
  'assessments/snowyPicturesTest',
  'assessments/standardizedRoadMapTest',
  'assessments/StLouisUniversityMentalStatus',
  'assessments/symbolDigitModalitiesTest',
  'assessments/trailMakingA',
  'assessments/trailMakingB',
  'assessments/trailsX',
  'assessments/ufov',
  'assessments/weiglSortingTask',

  // physical assessments
  'assessments/bergBalanceScale',
  'assessments/coordination',
  'assessments/functionalIndependenceMeasure',
  'assessments/jamarGripDynamometerTest',
  'assessments/mobilityAndBalance',
  'assessments/ninePegHoleTest',
  'assessments/oneLegStance',
  'assessments/otherPhysicalStatus',
  'assessments/pursuitRotorTask',
  'assessments/quickPhysicalScreen',
  'assessments/rangeOfMotion',
  'assessments/reactionTimeTester',
  'assessments/reactionTimeTesterCumulative',
  'assessments/scaleForTheAssessmentAndRatingOfAtaxia',
  'assessments/sensationAndProprioception',
  'assessments/steerClear',
  'assessments/strengthTesting',
  'assessments/theWesternOntarioMcMaster',
  'assessments/vericomStationaryReactionTimer',
  'assessments/whisperedVoiceTest',

  // visual assessments
  'assessments/contrastSensitivity',
  'assessments/oculomotorControl',
  'assessments/stereoDepthPerception',
  'assessments/titmusVisionScreener',
  'assessments/verticalAndLateralPhoria',
  'assessments/visualAcuity',
  'assessments/visualFields',

  // behavioural-psychological assessments
  'assessments/accidentFearQuestionnaire',
  'assessments/beckAnxietyInventory',
  'assessments/beckDepressionInventory',
  'assessments/centerForEpidemiologicStudiesDepression',
  'assessments/epworthSleepinessScale',
  'assessments/functionalAbilitiesQuestionnaire',
  'assessments/generalizedAnxietyDisorderScale',
  'assessments/geriatricDepressionScale15',
  'assessments/geriatricDepressionScale5',
  'assessments/mcGillPainQuestionnaire',
  'assessments/sleepImpairmentIndex',
  'assessments/travelAnxietyQuestionnaire',

  // comprehensive assessments
  'assessments/cumulativeIllnessRatingScale',
  'assessments/driveABLECognitiveAssessmentTool',
  'assessments/functionalStatus',
  'assessments/rookwoodDrivingBattery',
  'assessments/SaskatchewanPsychiatricOTDrivingScreen',
  'assessments/sportConcussionAssessmentTool3',

  // driving specific assessments
  'assessments/driverPerformanceTest',
  'assessments/driverRiskIndex',
  'assessments/drivingBehaviorSurvey',
  'assessments/drivingHabitQuestionnaire',
  'assessments/rulesOfTheRoadTest',
  'assessments/safePerformanceOnRoadTest',
  'assessments/trafficJudgmentQuestions',
  'assessments/trafficSignRecognitionTest',
];

export const websiteNamespaces = [
  'website/general',
  'website/main',
  'website/about',
  'website/driveeval',
  'website/faq',
  'website/contact',
];

export default allNamespaces;
