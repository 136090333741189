import { SubHeadingItem } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const SUMMARY: SubHeadingItem = {
  label: 'Summary',
  key: 'category8',
  fields: [
    {
      label: 'Date of assessment',
      key: 'dateOfAssessment',
      fieldType: 'DATE',
    },
    {
      label:
        'How likely do you believe that the client will pass this comprehensive driving evaluation?',
      key: 'howLikelyToPass',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Very likely',
        'Likely',
        'Not sure',
        'Unlikely',
        'Very unlikely',
      ]),
    },
  ],
};

export default SUMMARY;
