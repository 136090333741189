import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

const useToken = (): Promise<AuthenticationResult> => {
  const { instance, accounts } = useMsal();
  return instance.acquireTokenSilent({
    scopes: ['https://database.windows.net/.default'],
    account: accounts[0] as AccountInfo,
  });
};

export default useToken;
