import React, { FC, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import AsyncErrorWrapper from '../../../errors/AsyncErrorWrapper';
import { useCreateStandardRoute } from '../../../hooks/database/settings/standardRoutes';
import { IForm } from '../../../models/forms';
import theme from '../../../theme';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel, useUsername } from '../../../utils/utils';
import FieldContainer from '../../common/FieldContainer/FieldContainer';
import LoadingButton from '../../common/LoadingButton';

interface NewStandardRouteProps {
  isOpen: boolean;
  handleClose: () => void;
}

const NewStandardRoute: FC<NewStandardRouteProps> = ({
  isOpen,
  handleClose,
}) => {
  const { t } = useTranslation(allNamespaces);
  const username = useUsername();

  const createStandardRouteMutation = useCreateStandardRoute();
  const { handleSubmit, register, control, watch, setValue, getValues, reset } =
    useForm();

  const standardRouteForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  const onClose = useCallback(() => {
    handleClose();
    setValue('routeName', '');
    setValue('routeDuration', '');
    reset();
  }, [handleClose, reset, setValue]);

  const handleSaveCustomGroup = () => {
    createStandardRouteMutation.mutateAsync({
      standardRouteId: nanoid(),
      username,
      expectedDuration: getValues('routeDuration'),
      routeName: getValues('routeName'),
      headRef: '',
      tailRef: '',
    });
  };

  return (
    <AsyncErrorWrapper
      mutation={createStandardRouteMutation}
      onSuccess={onClose}
    >
      <FormContext.Provider value={standardRouteForm}>
        <form>
          <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="lg"
            PaperProps={{
              style: {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            <DialogTitle id="new-standard-route-group-dialog">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1">
                  {getTranslationLabel(
                    'onroad/routeBuilder',
                    'createNewStandardRoute',
                    t
                  )}
                </Typography>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={onClose}
                  sx={{ paddingLeft: '10px' }}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent dividers>
              <Grid item container direction="column">
                <Grid item>
                  <FieldContainer
                    label="Route Name"
                    name="routeName"
                    type="INPUT"
                    namespace="onroad/routeBuilder"
                  />
                </Grid>

                <Grid item>
                  <FieldContainer
                    label="Expected Duration"
                    namespace="onroad/routeBuilder"
                    name="routeDuration"
                    type="INPUT-NUM"
                    units={<Typography>min</Typography>}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton isLoading={createStandardRouteMutation.isLoading}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                  {getTranslationLabel('onroad/routeBuilder', 'cancel', t)}
                </Button>
                <Button onClick={handleSubmit(handleSaveCustomGroup)}>
                  {getTranslationLabel('onroad/routeBuilder', 'addRoute', t)}
                </Button>
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </form>
      </FormContext.Provider>
    </AsyncErrorWrapper>
  );
};

export default NewStandardRoute;
