import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';

import allNamespaces from '../../allNamespaces';
import AssessClientFallback from '../../errors/fallbacks/AssessClientFallback';
import EditClientFallback from '../../errors/fallbacks/EditClientFallback';
import NewClientFallback from '../../errors/fallbacks/NewClientFallback';
import OnRoadFallback from '../../errors/fallbacks/OnRoadFallback';
import ReportFallback from '../../errors/fallbacks/ReportFallback';
import NoMatch from '../../errors/NoMatch';
import { getTranslationLabel } from '../../utils/utils';
import { Assessments } from '../assessments/Assessments';
import { IntakeForm } from '../intake-form/IntakeForm';
import { OnRoadMain } from '../on-road/OnRoadMain';
import { Results } from '../results/Results';

const ClientRoutes = () => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Switch>
      <Route
        path="/clients/new/:clientId"
        render={({ match }) => (
          <ErrorBoundary FallbackComponent={NewClientFallback}>
            <IntakeForm
              clientId={match.params.clientId}
              title={getTranslationLabel('clientsGeneral', 'newClientText', t)}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/clients/edit/:clientId"
        render={({ match }) => (
          <ErrorBoundary FallbackComponent={EditClientFallback}>
            <IntakeForm clientId={match.params.clientId} />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/clients/assess/:clientId"
        render={({ match }) => (
          <ErrorBoundary FallbackComponent={AssessClientFallback}>
            <Assessments clientId={match.params.clientId} />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/clients/on-road/:clientId"
        render={({ match }) => (
          <ErrorBoundary FallbackComponent={OnRoadFallback}>
            <OnRoadMain clientId={match.params.clientId} backLink="/clients" />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/clients/report/:clientId"
        render={({ match }) => (
          <ErrorBoundary FallbackComponent={ReportFallback}>
            <Results clientId={match.params.clientId} />{' '}
          </ErrorBoundary>
        )}
      />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default ClientRoutes;
