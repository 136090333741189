import {
  axiosDeleteCall,
  axiosGetAllCall,
  axiosPostCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const assessmentSummaryKeys = (clientId: string) =>
  ['summaries', clientId] as const;

const assessmentsSummaryUrl = (clientId: string) =>
  `/api/assessments/summaries/${clientId}`;

const assessmentSummaryUrl = (clientId: string, assessmentKey: string) =>
  `/api/assessments/summaries/${clientId}/assessment/${assessmentKey}`;

export const useAssessmentSummaries = (clientId: string) =>
  useQueryWithToken(assessmentSummaryKeys(clientId), (token: string) =>
    axiosGetAllCall(assessmentsSummaryUrl(clientId), token)
  );

export const useCreateAssessmentSummary = (clientId: string) =>
  useMutationWithToken(
    assessmentSummaryKeys(clientId),
    (summary: any, token: string) =>
      axiosPostCall(assessmentsSummaryUrl(summary.clientId), summary, token)
  );

export const useUpdateAssessmentSummary = (clientId: string) =>
  useMutationWithToken(
    assessmentSummaryKeys(clientId),
    (summary: any, token: string) =>
      axiosPutCall(
        assessmentSummaryUrl(summary.clientId, summary.assessmentKey),
        summary,
        token
      )
  );

export const useDeleteAssessmentSummary = (clientId: string) =>
  useMutationWithToken(
    assessmentSummaryKeys(clientId),
    (assessmentKey: string, token: string) =>
      axiosDeleteCall(assessmentSummaryUrl(clientId, assessmentKey), token)
  );
