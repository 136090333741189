import { FieldTypes, IOption, SubHeadingItem } from '../../../models/forms';
import { VisualResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { VISION_SCREENING_DEVICE_FIELD_WNONE } from './Visual-Utils';

const OCULOMTOR_CATEGORIES = [
  'Optokinetic reflex (OKR) - Head still',
  'Saccades - Head can move',
  'Smooth pursuit',
  'Convergence',
  'Range of motion',
];

const NORMAL_FAIR_POOR: IOption[] = getOptionsFromLabels([
  'Normal',
  'Fair',
  'Poor',
]);

const oculomotorControl: SubHeadingItem = {
  key: 'oculomotorControl',
  label: 'Oculomotor Control',
  fields: [
    VISION_SCREENING_DEVICE_FIELD_WNONE,
    ...OCULOMTOR_CATEGORIES.map((item) => ({
      key: `${convertLabelToKey(item)}Result`,
      label: item,
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: NORMAL_FAIR_POOR,
    })),
    ...VisualResults,
  ],
};

export default oculomotorControl;
