import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getRef } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { WNL_IMPAIRED_OTIONS } from './motorFreeVisualPerceptionTest';

const motorFreeVisualPerceptionTest3: MainHeadingItem = {
  key: 'motorFreeVisualPerceptionTest3',
  label: 'Motor-Free Visual Perception Test (MVPT-3)',
  subheadings: [
    {
      key: 'rawResults',
      label: 'Raw Results',
      fields: [
        {
          key: 'rawScore',
          label: 'Raw score',
          fieldType: 'INPUT-NUM',
          units: <Typography>/65</Typography>,
        },
        {
          key: 'standardScore',
          label: 'Standard score',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'percentileRanking',
          label: 'Percentile ranking',
          fieldType: 'INPUT-NUM',
        },
        {
          key: 'percentileRankingInterpretation',
          label: 'Percentile ranking interpretation',
          fieldType: 'RADIO',
          options: WNL_IMPAIRED_OTIONS,
        },
      ],
    },
    {
      key: 'extrapolationToOriginal',
      label: 'Extrapolation to Original',
      fields: [
        {
          key: 'extrapolatedScore',
          label: 'Extrapolated score (original MVPT)',
          fieldType: 'INPUT-NUM' as FieldTypes,
          units: <Typography>/36</Typography>,
        },
        {
          key: 'extrapolatedScorePredictiveOfFailure',
          label:
            'Is the total score predictive of failure of the on-road evaluation?',
          fieldType: 'RADIO' as FieldTypes,
          options: YES_NO,
          valueDependsOn: ['extrapolatedScore'],
          units: getRef('Ref.: Mazer 2000'),
        },
        {
          key: 'responseTimeIndex',
          label: 'Response time index',
          fieldType: 'INPUT-NUM' as FieldTypes,
          units: <Typography>seconds</Typography>,
        },
        {
          key: 'responseTimeIndexInterpretation',
          label: 'Response time index interpretation',
          fieldType: 'RADIO' as FieldTypes,
          options: WNL_IMPAIRED_OTIONS,
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: Results,
    },
  ],
};

export default motorFreeVisualPerceptionTest3;
