import React, { FC } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IOption } from '../../../models/forms';
import { getTranslationLabel } from '../../../utils/utils';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import SelectMultiple from '../../common/SelectMultiple';
import { ROAD_TYPE_OPTIONS } from '../Constants/ON-ROAD-CONSTANTS';
import {
  TASK_CRITICAL_ERRORS,
  TASK_ERRORS,
} from '../Constants/ON-ROAD-FIELDS/TACTICAL';
import { getRoadTypeLabel, TASK_OPTIONS } from '../Constants/utils';

const infoAvatarStyle = {
  width: 28,
  height: 28,
};

const isCriticalError = (err: string) =>
  err === 'brakeIntervention' ||
  err === 'steeringIntervention' ||
  err === 'verbalIntervention' ||
  err === 'dangerousAction' ||
  err === 'preventableCollision' ||
  err === 'trafficLawViolation';

const NON_ERROR_FIELDS = [
  'roadTestId',
  'maneuverId',
  'maneuverType',
  'subManeuverType',
  'roadType',
  'recordType',
  'location',
  'nextManeuver',
  'prevManeuver',
  'refManeuverId',
];

export const convertAllLabelsToKeys = (listOfLabels: any) =>
  listOfLabels.map((label: string) => convertLabelToKey(label));

export const getTaskLabel = (taskKey: string) => {
  const task = TASK_ERRORS.find((error) => error.key === taskKey);
  return task?.label;
};

export interface ISummaryFilter {
  roadTypes: string[];
  errorTypes: string[];
  maneuverTypes: string[];
}

interface SummaryFiltersProps {
  filters: ISummaryFilter;
  handleChangeFilter: (filters: ISummaryFilter) => void;
}

export const SummaryFilters: FC<SummaryFiltersProps> = ({
  filters,
  handleChangeFilter,
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <Stack spacing={3}>
      <Typography variant="h3">Filters</Typography>
      <Stack direction="row" alignItems="baseline" spacing={3}>
        <SelectMultiple
          label={getTranslationLabel(
            'maneuvers/maneuversGeneral',
            'roadType',
            t
          )}
          namespace="onroad/routeBuilder"
          options={ROAD_TYPE_OPTIONS}
          selected={filters.roadTypes}
          handleChange={(selectedRoadTypes) =>
            handleChangeFilter({ ...filters, roadTypes: selectedRoadTypes })
          }
        />
        <SelectMultiple
          label={getTranslationLabel(
            'maneuvers/maneuversGeneral',
            'maneuverType',
            t
          )}
          namespace="maneuvers/defaultManeuverTypes"
          options={TASK_OPTIONS}
          selected={filters.maneuverTypes}
          handleChange={(newSelection) =>
            handleChangeFilter({ ...filters, maneuverTypes: newSelection })
          }
          width={600}
        />
      </Stack>
    </Stack>
  );
};

const getErrorLabelFromKey = (errorKey: string, taskKey: string) => {
  const taskError = TASK_ERRORS.find((task) => task.key === taskKey);

  const fieldError = taskError?.fields.reduce(
    (resultSoFar: IOption | undefined, errorField) => {
      const error = errorField?.options?.find(
        (option) => option.key === errorKey
      );
      return error || resultSoFar;
    },
    undefined
  );
  return fieldError?.label;
};

const getErrors = (result: any) => {
  const isError = (key: string) =>
    result[key] !== '0' &&
    result[key] !== '' &&
    result[key] !== undefined &&
    result[key] !== null &&
    result[key] !== '-1' &&
    key.substring(key.length - 15, key.length) !== 'SpeedComparedTo';
  const errors = Object.keys(result).reduce((prev: string[], key: string) => {
    if (
      isError(key) &&
      !NON_ERROR_FIELDS.includes(key) &&
      !isCriticalError(key)
    ) {
      if (key.substring(key.length - 5, key.length) === 'Speed') {
        prev.push('Speed error');
      } else if (result[key] === '1' || result[key] === true) {
        prev.push(getErrorLabelFromKey(key, result.maneuverType) || '');
      } else {
        prev.push(getErrorLabelFromKey(result[key], result.maneuverType) || '');
      }
    }
    return prev;
  }, []);
  return errors;
};

interface ErrorSummaryProps {
  maneuver: any;
  showRoadTypes: boolean;
  showDetails: boolean;
}

export const ErrorSummary: FC<ErrorSummaryProps> = ({
  maneuver,
  showRoadTypes,
  showDetails,
}) => {
  const { t } = useTranslation(allNamespaces);
  const filteredResults = maneuver.results.filter(
    (result: any) => result.recordType === 'error'
  );
  const noOfErrors = filteredResults.length;

  const getRoadType = (result: any) =>
    result.roadType ? getRoadTypeLabel(result.roadType) : 'Uknown Road Type';
  const getLocation = (result: any) =>
    result.location ? `, ${result.location}` : '';

  return (
    <Stack direction="row" alignItems="baseline" spacing={3}>
      <Avatar
        sx={{
          ...infoAvatarStyle,
          backgroundColor: 'error.light',
        }}
      >
        <CloseIcon fontSize="small" sx={{ color: 'black' }} />
      </Avatar>
      <Grid item container direction="column">
        <Typography variant="h3">
          {noOfErrors}{' '}
          {getTranslationLabel(
            'maneuvers/maneuversGeneral',
            noOfErrors === 1 ? 'taskWithErrors' : 'tasksWithErrors',
            t
          )}
        </Typography>
        {showDetails &&
          filteredResults.map((result: any) => {
            const errors = getErrors(result);
            return (
              <>
                {errors.map(
                  (err) =>
                    showRoadTypes &&
                    err.length > 0 && (
                      <li key={err}>
                        {`${err}, ${getRoadType(result)}${getLocation(result)}`}
                      </li>
                    )
                )}
              </>
            );
          })}
      </Grid>
    </Stack>
  );
};

interface SuccessSummaryProps {
  maneuver: any;
  showRoadTypes: boolean;
  showDetails: boolean;
}

// const getLabelFromOption = (key: string) => {
//   if (key === null || key === undefined) return '';
//   const arr1 = key.split(/(?=[A-Z])/);
//   const arr2 = arr1.map((s) => s.toLowerCase());
//   const word = arr2.join(' ');
//   return capitalizeFirstLetter(word);
// };

export const SuccessSummary: FC<SuccessSummaryProps> = ({
  maneuver,
  showRoadTypes,
  showDetails,
}) => {
  const { t } = useTranslation(allNamespaces);
  const filteredResults = maneuver.results.filter(
    (result: any) => result.recordType !== 'error'
  );
  const noOfManeuvers = filteredResults.length;

  return (
    <Stack direction="row" alignItems="baseline" spacing={3}>
      <Avatar
        sx={{
          ...infoAvatarStyle,
          backgroundColor: 'success.light',
        }}
      >
        <CheckIcon fontSize="small" sx={{ color: 'black' }} />
      </Avatar>
      <Typography variant="h3">
        {noOfManeuvers}{' '}
        {getTranslationLabel(
          'maneuvers/maneuversGeneral',
          noOfManeuvers === 1
            ? 'taskCompletedSuccessfully'
            : 'tasksCompletedSuccessfully',
          t
        )}
        {showRoadTypes &&
          showDetails &&
          filteredResults.map(
            (result: any) =>
              result.roadType && (
                <Typography>
                  <li>
                    {getTranslationLabel(
                      'onroad/routeBuilder',
                      result.roadType,
                      t
                    )}
                    {result.location !== '' && `, ${result.location}`}
                  </li>
                </Typography>
              )
          )}
      </Typography>
    </Stack>
  );
};

interface CriticalErrorsSummaryProps {
  maneuver: any;
}

export const CriticalErrorsSummary: FC<CriticalErrorsSummaryProps> = ({
  maneuver,
}) => {
  const hasCriticalErrors = (fields: any[]) =>
    fields.filter(
      (field) =>
        field.brakeIntervention === '1' ||
        field.steeringIntervention === '1' ||
        field.verbalIntervention === '1' ||
        field.dangerousAction === '1' ||
        field.preventableCollision === '1' ||
        field.trafficLawViolation === '1'
    );

  const getCriticalErrorLabelFromKey = (key: string) =>
    TASK_CRITICAL_ERRORS('')[0].options?.find((option) => option.key === key)
      ?.label || '';

  return (
    <List>
      {hasCriticalErrors(maneuver.results).map((fields: any) =>
        Object.keys(fields).map(
          (field) =>
            fields[field] === '1' &&
            isCriticalError(field) && (
              <Stack
                direction="row"
                alignItems="center"
                key={field} // TODO: this will not be unique if we have multiple of the same errors
              >
                <Avatar
                  sx={{
                    ...infoAvatarStyle,
                    backgroundColor: 'error.main',
                  }}
                >
                  <PriorityHighIcon fontSize="small" />
                </Avatar>
                <ListItem>
                  <ListItemText>
                    {getCriticalErrorLabelFromKey(field)}
                  </ListItemText>
                </ListItem>
              </Stack>
            )
        )
      )}
    </List>
  );
};
