import React from 'react';

import { useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';
import NewClientButton from './NewClientButton';

const ClientsHeader = () => {
  const { t } = useTranslation(allNamespaces);
  const { accounts } = useMsal();

  const determineFirstName = (): string => {
    const fullName = accounts[0].name;
    const fullNameArray = fullName?.split(',');

    if (fullNameArray && fullNameArray.length === 1) {
      return fullNameArray[0];
    }
    if (fullNameArray) {
      return fullNameArray[1];
    }
    return '';
  };

  return (
    <Grid item container justifyContent="space-between">
      <Grid style={{ padding: '20px 0px 0px 20px' }}>
        <Typography variant="h2">
          {getTranslationLabel('clientsGeneral', 'welcome', t)}{' '}
          {determineFirstName()}!
        </Typography>
      </Grid>
      <NewClientButton
        text={getTranslationLabel('clientsGeneral', 'client', t)}
        variant="contained"
        size="medium"
        icon={<AddIcon />}
      />
    </Grid>
  );
};

export default ClientsHeader;
