import React, { FC } from 'react';

import { IOption } from '../../../models/forms';
import CheckboxSection from '../CheckboxSection';

export interface SelectedAssessmentsCategoryProps {
  categoryKey: string;
  categoryLabel: string;
  loPossibleItems: IOption[];
  loCheckedItems: string[];
  onChangeItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSection: (
    event: React.ChangeEvent<HTMLInputElement>,
    assessmentsInSection: IOption[]
  ) => void;
  renderList?: (name: string, label: string, options: IOption[]) => JSX.Element;
}

export const SelectedAssessmentsCategory: FC<SelectedAssessmentsCategoryProps> =
  ({
    categoryKey,
    categoryLabel,
    loPossibleItems,
    loCheckedItems,
    onChangeSection: handleChangeSection,
    onChangeItem,
    renderList,
  }) => (
    <>
      {renderList ? (
        renderList(categoryKey, categoryLabel, loPossibleItems)
      ) : (
        <CheckboxSection
          sectionKey={categoryKey}
          sectionLabel={categoryLabel}
          loPossibleItems={loPossibleItems}
          loCheckedItems={loCheckedItems}
          onChangeItem={onChangeItem}
          onChangeSection={handleChangeSection}
        />
      )}
    </>
  );
