import React, { FC, useCallback, useContext } from 'react';

import { Chip, chipClasses, FormControl, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IOption } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';

interface ChoiceChipsArrayProps {
  selected?: string | string[];
  chipData: IOption[];
  namespace: string;
  onClick: (data: string) => void;
  selectedIcon?: React.ReactElement;
  isSelected?: (key: string) => void;
}

const ChoiceChipsArray: FC<ChoiceChipsArrayProps> = ({
  selected = '',
  chipData,
  namespace,
  onClick,
  selectedIcon,
  isSelected,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { control } = useContext(FormContext);
  const isSelectedDefault = useCallback(
    (key: string) => {
      if (typeof selected === 'string') {
        return selected === key;
      }
      return selected?.includes(key);
    },
    [selected]
  );

  const getIcon = (key: string) => {
    if (selectedIcon) {
      if (isSelected ? isSelected(key) : isSelectedDefault(key)) {
        return selectedIcon;
      }
    }
    return undefined;
  };

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '2px',
        margin: '1px',
      }}
    >
      {chipData.map((data) => (
        <Grid item key={data.key}>
          <Controller
            name={data.key}
            control={control}
            defaultValue="0"
            render={() => (
              <FormControl variant="outlined">
                <Chip
                  icon={getIcon(data.key)}
                  label={getTranslationLabel(
                    namespace,
                    data.key,
                    t,
                    data.label
                  )}
                  clickable
                  onClick={() => onClick(data.key)}
                  sx={{
                    margin: '2px',
                    color: 'black',
                    backgroundColor: (
                      isSelected
                        ? isSelected(data.key)
                        : isSelectedDefault(data.key)
                    )
                      ? 'itemBackground.main'
                      : 'primary.light',
                    '&&:hover': {
                      backgroundColor: 'itemBackground.main',
                    },
                    [`& .${chipClasses.icon}`]: {
                      color: 'success.dark',
                    },
                  }}
                  variant="outlined"
                />
              </FormControl>
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChoiceChipsArray;
