import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, IOption, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const GAD_QUESTIONS_LIST: string[] = [
  'Feeling nervous, anxious, or on edge',
  'Not being able to stop or control worrying',
  'Worrying too much about different things',
  'Trouble relaxing',
  "Being so restless that it's hard to sit still",
  'Becoming easily annoyed or irritable',
  'Feeling afraid as if something awful might happen',
];

const GAD_QUESTIONS: FieldsLayout[] = GAD_QUESTIONS_LIST.map((item, i) => ({
  key: `question${i + 1}`,
  label: item,
  fieldType: 'CHOICE_CHIPS',
  options: getNumericOptionsXToY(0, 3),
}));

const FOLLOW_UP_QUESTION_LIST: string[] = [
  'Not difficult at all',
  'Somewhat difficult',
  'Very difficult',
  'Extremely difficult',
];

const FOLLOW_UP_QUESTION_OPTIONS: IOption[] = FOLLOW_UP_QUESTION_LIST.map(
  (item) => ({
    key: convertLabelToKey(item),
    val: convertLabelToKey(item),
    label: item,
  })
);

const generalizedAnxietyDisorderScale: SubHeadingItem = {
  key: 'generalizedAnxietyDisorderScale',
  label: 'Generalized Anxiety Disorder 7-item Scale (GAD-7)',
  fields: [
    {
      key: 'prompt',
      label: `Over the last 2 weeks, how often have you been 
      bothered by the following problems? (0 =  Not at all sure,
      1 = Several days, 2 = Over half the days, 3 = Nearly every day)`,
      fieldType: 'LABEL',
    },
    ...GAD_QUESTIONS,
    {
      label: `If you checked off any problems, how difficult have these made it 
      for you to do your work, take care of things at home, or get along with other people? `,
      key: 'followUpQuestion',
      fieldType: 'RADIO',
      options: FOLLOW_UP_QUESTION_OPTIONS,
    },
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 21</Typography>,
      valueDependsOn: getKeysFromOptions(GAD_QUESTIONS),
    },
    ...Results,
  ],
};

export default generalizedAnxietyDisorderScale;
