import React, { FC, useState } from 'react';

import { useDeleteClient } from '../../../hooks/database/clients/clients';
import { DeleteClientDialog } from './DeleteClientDialog';
import DeleteClientMessage from './DeleteClientMessage';

interface DeleteClientProps {
  clientId: string;
  isDeleteClientDialogOpen: boolean;
  setIsDeleteClientDialogOpen: (newState: boolean) => void;
}

const DeleteClient: FC<DeleteClientProps> = ({
  clientId,
  isDeleteClientDialogOpen,
  setIsDeleteClientDialogOpen,
}) => {
  const [isDeleteOkOpen, setIsDeleteOkOpen] = useState<boolean>(false);
  const [isDeleteNotOkOpen, setIsDeleteNotOkOpen] = useState<boolean>(false);

  const deleteClientMutation = useDeleteClient();

  const handleDeleteClient = async (enteredClientId: string) => {
    if (clientId !== enteredClientId) {
      setIsDeleteNotOkOpen(true);
      return;
    }
    await deleteClientMutation.mutateAsync(clientId);
    setIsDeleteOkOpen(true);
    setIsDeleteClientDialogOpen(false);
  };

  return (
    <>
      {isDeleteClientDialogOpen && (
        <DeleteClientDialog
          clientId={clientId}
          handleDeleteClient={handleDeleteClient}
          setIsDeleteClientDialogOpen={setIsDeleteClientDialogOpen}
        />
      )}
      {isDeleteOkOpen && (
        <DeleteClientMessage
          clientId={clientId}
          handleClose={setIsDeleteOkOpen}
          messageKey="deleteOk"
        />
      )}
      {isDeleteNotOkOpen && (
        <DeleteClientMessage
          clientId={clientId}
          handleClose={setIsDeleteNotOkOpen}
          messageKey="deleteNotOk"
        />
      )}
    </>
  );
};

export default DeleteClient;
