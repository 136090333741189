import { FieldsLayout, SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';

const ON_ROAD_ROUTE_DETAILS: FieldsLayout[] = [
  {
    label: 'Type of Route',
    key: 'typeOfRoute',
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels(['Standard', 'Custom']),
    conditionals: [
      {
        key: 'whyCustomRoute',
        label: 'Why are you using a custom route?',
        fieldType: 'TEXT-AREA',
        condition: (values: any) =>
          values.typeOfRoute && values.typeOfRoute === 'custom',
      },
    ],
  },
];

export const ROUTE_SUBHEADING: SubHeadingItem = {
  key: 'route',
  label: 'Route',
  fields: ON_ROAD_ROUTE_DETAILS,
};

export default ON_ROAD_ROUTE_DETAILS;
