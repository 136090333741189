import React, { FC, useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import { useManeuvers } from '../../../hooks/database/on-road/maneuvers';
import { useManeuverSummaries } from '../../../hooks/database/on-road/maneuverSummaries';
import { IRoadTest } from '../../../models/on-road/on-road';
import { getTranslationLabel } from '../../../utils/utils';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { ERROR_TYPES, ROAD_TYPES } from '../Constants/ON-ROAD-CONSTANTS';
import { getTaskIcon, TASK_OPTIONS } from '../Constants/utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CriticalErrorsSummary, ErrorSummary, SuccessSummary } from './utils';

export function uniqueArray2(arr: string | any[]) {
  const a = [];
  for (let i = 0, l = arr.length; i < l; i += 1)
    if (a.indexOf(arr[i]) === -1 && arr[i] !== '') a.push(arr[i]);
  return a;
}

interface Tab7SummaryProps {
  roadTest: IRoadTest;
}

const Tab7Summary: FC<Tab7SummaryProps> = ({ roadTest }) => {
  const { t } = useTranslation(allNamespaces);
  const filters = {
    roadTypes: ROAD_TYPES,
    maneuverTypes: TASK_OPTIONS.map((task) =>
      task.label !== 'Merge' ? task.label : 'Merge Maneuver'
    ),
    errorTypes: ERROR_TYPES,
  };

  const [showDetails, setShowDetails] = useState(true);

  const {
    data: maneuverDetails,
    isLoading,
    isError,
    error,
  } = useManeuvers(
    roadTest.roadTestId || '',
    filters.maneuverTypes.map((filter) => convertLabelToKey(filter))
  ); // name, results: [] for all 20 - errors only

  const {
    data: maneuverSummaries,
    isLoading: isSummariesLoading,
    isError: isSummariesError,
    error: summariesError,
  } = useManeuverSummaries(roadTest.roadTestId || '');

  if (isLoading || isSummariesLoading) {
    return <LoadingComponent />;
  }
  if (isError) {
    return <ErrorComponent error={error} />;
  }
  if (isSummariesError) {
    return <ErrorComponent error={summariesError} />;
  }

  const successfulManeuvers = maneuverSummaries
    .filter((maneuver: any) => maneuver.recordType !== 'error')
    .map((maneuver: any) => ({
      maneuverType: maneuver.maneuverType,
      roadType: maneuver.roadType,
      location: maneuver.location,
    }));

  const maneuverDetailsWithSuccessfulManeuvers = maneuverDetails?.map(
    (maneuverDetail: any) => {
      const successfulManeuverData = successfulManeuvers.filter(
        (maneuver: any) => maneuverDetail.maneuverType === maneuver.maneuverType
      );
      if (!successfulManeuverData) {
        return maneuverDetail;
      }
      const { results } = maneuverDetail;
      const newResults = [...successfulManeuverData, ...results];
      return { maneuverType: maneuverDetail.maneuverType, results: newResults };
    }
  );

  const maneuverDetailsWithSuccessfulManeuversReduced =
    maneuverDetailsWithSuccessfulManeuvers?.filter(
      (maneuverDetail: any) => maneuverDetail.results.length > 0
    );

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel(
            'maneuvers/maneuversGeneral',
            'aggregatedManeuvers',
            t
          )}
        </Typography>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              color="primary"
              checked={showDetails}
              onChange={() => setShowDetails(!showDetails)}
            />
          }
          label={getTranslationLabel(
            'maneuvers/maneuversGeneral',
            'showDetails',
            t
          )}
        />
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {maneuverDetailsWithSuccessfulManeuversReduced?.map(
          (maneuverSummary: any) => (
            <Grid item key={maneuverSummary.maneuverType}>
              <Card
                sx={{
                  width: {
                    xs: '75vw',
                    md: '50vw',
                    lg: '18vw',
                  },
                  height: '100%',
                }}
              >
                <CardHeader
                  avatar={getTaskIcon(maneuverSummary.maneuverType)}
                  title={
                    <Typography>
                      {getTranslationLabel(
                        'maneuvers/defaultManeuverTypes',
                        maneuverSummary.maneuverType,
                        t
                      )}
                    </Typography>
                  }
                  sx={{ backgroundColor: 'primary.light' }}
                />
                <CardContent>
                  <Stack spacing={3}>
                    <CriticalErrorsSummary maneuver={maneuverSummary} />
                    <ErrorSummary
                      maneuver={maneuverSummary}
                      showRoadTypes
                      showDetails={showDetails}
                    />
                    <SuccessSummary
                      maneuver={maneuverSummary}
                      showRoadTypes
                      showDetails={showDetails}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};
export default Tab7Summary;
