export const GDS5_ANSWERS = ['no', 'yes', 'yes', 'yes', 'yes'];
export const GDS15_ANSWERS = [
  'no',
  'yes',
  'yes',
  'yes',
  'no',
  'yes',
  'no',
  'yes',
  'yes',
  'yes',
  'no',
  'yes',
  'no',
  'yes',
  'yes',
];
export const MVPT_ANSWERS = [
  'a',
  'c',
  'c',
  'd',
  'b',
  'b',
  'd',
  'b',
  'b',
  'd',
  'a',
  'a',
  'b',
  'b',
  'd',
  'a',
  'a',
  'a',
  'c',
  'd',
  'a',
  'b',
  'a',
  'b',
  'd',
  'b',
  'd',
  'a',
  'd',
  'c',
  'd',
  'a',
  'b',
  'c',
  'c',
  'b',
];
