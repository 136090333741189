import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { IForm } from '../../models/forms';
import { IRoadTest } from '../../models/on-road/on-road';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import AutoSave from '../common/AutoSave';
import Column from './Common/Column';
import { POST_TEST } from './Constants/ON-ROAD-FIELDS/POST-TEST';

interface Tab6PostTestDetailsProps {
  roadTest: IRoadTest;
  handleSave: (roadTest: IRoadTest) => void;
}

const Tab6PostTestDetails: FC<Tab6PostTestDetailsProps> = ({
  roadTest,
  handleSave,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({
      defaultValues: roadTest,
    });
  const onRoadForm: IForm = { register, control, setValue, getValues, watch };

  return (
    <Grid item container xs={12} direction="column" alignItems="center">
      <FormContext.Provider value={onRoadForm}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid item container spacing={5} sx={{ p: 4 }}>
            {POST_TEST.subheadings.map((subheading) => (
              <Grid
                item
                container
                direction="column"
                lg={6}
                xl={3}
                key={subheading.key}
              >
                <Column
                  title={
                    <Typography variant="h2">
                      {getTranslationLabel(
                        'onroad/postTestOnRoad',
                        subheading.key,
                        t
                      )}
                    </Typography>
                  }
                  namespace="onroad/postTestOnRoad"
                  fields={subheading.fields}
                  values={watch()}
                  sectionKey={subheading.key}
                />
              </Grid>
            ))}
            <Grid item>
              <AutoSave watch={watch} handleSave={handleSave} />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Grid>
  );
};

export default Tab6PostTestDetails;
