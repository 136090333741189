import React, { FC, useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import AsyncErrorWrapper from '../../../../errors/AsyncErrorWrapper';
import { useCreateCustomGroup } from '../../../../hooks/database/settings/customGroups';
import { IForm, IOption } from '../../../../models/forms';
import theme from '../../../../theme';
import FormContext from '../../../../utils/formContext';
import { getTranslationLabel, useUsername } from '../../../../utils/utils';
import CustomizeMyList from '../../../common/Assessments/CustomizeMyList';
import { SelectedAssessments } from '../../../common/Assessments/SelectedAssessments';
import PrimitiveField from '../../../common/FieldContainer/PrimitiveField';
import LoadingButton from '../../../common/LoadingButton';
import RenderCustomList from './utils';

interface NewCustomGroupProps {}

const NewCustomGroup: FC<NewCustomGroupProps> = () => {
  const { t } = useTranslation(allNamespaces);
  const username = useUsername();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const GROUP_LABEL_NAME = 'groupLabel';

  const addCustomGroupMutation = useCreateCustomGroup(username);
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const customGroupForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    setValue('groupLabel', '');
    reset();
  }, [reset, setValue]);

  const handleSaveCustomGroup = () => {
    const customGroup = getValues();
    const assessmentKeys = Object.keys(customGroup);
    const filteredAssesmentsInGroup: string[] = assessmentKeys.filter(
      (assessmentKey) =>
        assessmentKey !== GROUP_LABEL_NAME &&
        assessmentKey !== 'groupKey' &&
        customGroup[assessmentKey] === true
    );

    const groupKey = nanoid();
    addCustomGroupMutation.mutate({
      groupKey,
      groupLabel: customGroup[GROUP_LABEL_NAME],
      username,
      assessments: filteredAssesmentsInGroup,
    });
  };

  const handleChangeSection = (
    event: React.ChangeEvent<HTMLInputElement>,
    asmts: IOption[]
  ) => {
    asmts.map((asmt) => setValue(asmt.key, event.target.checked));
  };

  return (
    <>
      <Button
        color="secondary"
        aria-label="add"
        onClick={handleOpen}
        startIcon={<AddIcon />}
        variant="outlined"
        size="small"
      >
        {getTranslationLabel('assessmentsGeneral/assessClient', 'group', t)}
      </Button>

      <AsyncErrorWrapper
        mutation={addCustomGroupMutation}
        onSuccess={handleClose}
      >
        <FormContext.Provider value={customGroupForm}>
          <form>
            <Dialog
              open={isModalOpen}
              onClose={handleClose}
              fullWidth
              maxWidth="lg"
              PaperProps={{
                style: {
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            >
              <DialogTitle id="new-custom-group-dialog">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h1">
                    {getTranslationLabel(
                      'assessmentsGeneral/assessClient',
                      'addAssessmentGroup',
                      t
                    )}
                  </Typography>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent dividers>
                <Grid item container direction="column">
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      <PrimitiveField
                        label="Custom Assessment Group Name"
                        name={GROUP_LABEL_NAME}
                        namespace="assessmentsGeneral/assessClient"
                        type="INPUT"
                        required
                        error={
                          errors.groupLabel &&
                          errors.groupLabel.type === 'required'
                        }
                      />
                    </Grid>
                    <Grid item>
                      {errors.groupLabel &&
                        errors.groupLabel.type === 'required' && (
                          <Stack direction="row" spacing={2}>
                            <ErrorOutlineIcon color="error" fontSize="small" />
                            <Typography color="error" variant="body2">
                              {getTranslationLabel(
                                'assessmentsGeneral/assessClient',
                                'aGroupNameRequired',
                                t
                              )}
                            </Typography>
                          </Stack>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item container direction="column">
                    <Grid item xs={12}>
                      <CustomizeMyList />
                    </Grid>
                    <SelectedAssessments
                      loCheckedItems={[]}
                      onChangeItem={() => {}} // TODO: RenderCustomList handles this - this is not clear in the way this is setup
                      renderList={(name, label, options) => (
                        <RenderCustomList
                          name={name}
                          label={label}
                          options={options}
                          checked={watch()}
                          onChangeSection={handleChangeSection}
                        />
                      )}
                      colSize={4}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <LoadingButton isLoading={addCustomGroupMutation.isLoading}>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                  >
                    {getTranslationLabel(
                      'assessmentsGeneral/assessClient',
                      'cancel',
                      t
                    )}
                  </Button>
                  <Button onClick={handleSubmit(handleSaveCustomGroup)}>
                    {getTranslationLabel(
                      'assessmentsGeneral/assessClient',
                      'addGroup',
                      t
                    )}
                  </Button>
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </form>
        </FormContext.Provider>
      </AsyncErrorWrapper>
    </>
  );
};

export default NewCustomGroup;
