import {
  axiosDeleteCall,
  axiosGetAllCall,
  axiosPostCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const selectedAssessmentsKey = ['selectedAssessments'] as const;

const assessmentSettingsUrl = (username: string) =>
  `/api/settings/${username}/selected-assessments`;

const assessmentSettingUrl = (username: string, assessmentKey: string) =>
  `/api/settings/${username}/selected-assessments/${assessmentKey}`;

export const useSelectedAssessments = (username: string) =>
  useQueryWithToken(selectedAssessmentsKey, (token: string) =>
    axiosGetAllCall(assessmentSettingsUrl(username), token)
  );

export const useCreateSelectedAssessment = (username: string) =>
  useMutationWithToken(
    selectedAssessmentsKey,
    (assessmentKey: string, token: string) =>
      axiosPostCall(assessmentSettingsUrl(username), assessmentKey, token)
  );

export const useDeleteSelectedAssessment = (username: string) =>
  useMutationWithToken(
    selectedAssessmentsKey,
    (assessmentKey: string, token: string) =>
      axiosDeleteCall(assessmentSettingUrl(username, assessmentKey), token)
  );
