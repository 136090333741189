import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SnackbarContent, Snackbar } from '@mui/material';

interface AlertProps {
  alertKey: string;
  open: boolean;
  handleClose: () => void;
  variant: string;
  message: string;
}

const BasicAlert: FC<AlertProps> = ({
  alertKey,
  open,
  handleClose,
  variant,
  message,
}) => {
  const autoHideDuration = variant === 'error' ? null : 2000;

  return (
    <Snackbar
      key={alertKey}
      style={{}}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
    >
      <SnackbarContent
        message={message}
        color="inherit"
        action={
          variant !== '' && (
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )
        }
        sx={{
          backgroundColor:
            variant === 'success'
              ? 'success.main'
              : variant === 'warning'
              ? 'warning.main'
              : variant === 'error'
              ? 'error.main'
              : 'secondary.main',
        }}
      />
    </Snackbar>
  );
};

export default BasicAlert;
