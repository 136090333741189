import { FieldsLayout, SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import CURVED_ROAD_ERRORS from '../Tasks/CurvedRoad';
import DRIVING_STRAIGHT_ERRORS from '../Tasks/DrivingStraight';
import { UNEXPECTED_EVENT_ERRORS, UNEXPECTED_EVENT_META } from '../Tasks/Event';
import EXIT_RAMP_ERRORS from '../Tasks/ExitRamp';
import { LEFT_LANE_CHANGE, RIGHT_LANE_CHANGE } from '../Tasks/LaneChange';
import { MERGE_ERRORS, MERGE_META } from '../Tasks/Merge';
import { PARKING_ERRORS, PARKING_META } from '../Tasks/Parking';
import { PASSING_META, PASSING_ERRORS } from '../Tasks/Passing';
import PEDESTRIAN_CROSSWALK, {
  PEDESTRIAN_CROSSWALK_META,
} from '../Tasks/PedestrianCrosswalk';
import { PULLOVER_ERRORS, PULLOVER_META } from '../Tasks/Pullover';
import {
  RAILWAY_CROSSING_ERRORS,
  RAILWAY_CROSSING_META,
} from '../Tasks/RailwayCrossing';
import SCHOOL_ZONE_ERRORS from '../Tasks/SchoolZone';
import { STOP_SIGN_ERRORS, STOP_SIGN_META } from '../Tasks/StopSign';
import {
  TRAFFIC_CIRCLE_ERRORS,
  TRAFFIC_CIRCLE_META,
} from '../Tasks/TrafficCircle';
import TRAFFIC_LIGHT, { TRAFFIC_LIGHT_META } from '../Tasks/TrafficLight';
import {
  LEFT_TURN_ERRORS,
  LEFT_TURN_META,
  RIGHT_TURN_ERRORS,
  RIGHT_TURN_META,
} from '../Tasks/Turn';
import UNCONTROLLED_INTERSECTION from '../Tasks/UncontrolledIntersection';
import { YIELD_ERRORS, YIELD_META } from '../Tasks/Yield';

export const TASK_CRITICAL_ERRORS = (taskKey: string): FieldsLayout[] => [
  {
    label: 'Critical Errors',
    key: `${taskKey}CriticalErrors`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Verbal Intervention',
      'Brake Intervention',
      'Steering Intervention',
      'Dangerous Action',
      'Traffic law violation',
      'Preventable collision',
    ]),
  },
];

export const TASK_META: SubHeadingItem[] = [
  TRAFFIC_LIGHT_META,
  STOP_SIGN_META,
  YIELD_META,
  TRAFFIC_CIRCLE_META,
  PEDESTRIAN_CROSSWALK_META,
  RAILWAY_CROSSING_META,
  LEFT_TURN_META,
  RIGHT_TURN_META,
  PASSING_META,
  MERGE_META,
  PARKING_META,
  PULLOVER_META,
  UNEXPECTED_EVENT_META,
];

export const TASK_ERRORS: SubHeadingItem[] = [
  DRIVING_STRAIGHT_ERRORS,
  CURVED_ROAD_ERRORS,
  TRAFFIC_LIGHT,
  STOP_SIGN_ERRORS,
  YIELD_ERRORS,
  TRAFFIC_CIRCLE_ERRORS,
  PEDESTRIAN_CROSSWALK,
  RAILWAY_CROSSING_ERRORS,
  UNCONTROLLED_INTERSECTION,
  LEFT_TURN_ERRORS,
  RIGHT_TURN_ERRORS,
  LEFT_LANE_CHANGE,
  RIGHT_LANE_CHANGE,
  PASSING_ERRORS,
  MERGE_ERRORS,
  EXIT_RAMP_ERRORS,
  PARKING_ERRORS,
  PULLOVER_ERRORS,
  SCHOOL_ZONE_ERRORS,
  UNEXPECTED_EVENT_ERRORS,
].map((item) => ({
  key: item.key,
  label: item.label,
  fields: [...TASK_CRITICAL_ERRORS(item.key), ...item.fields],
}));
