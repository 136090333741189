import React, { FC } from 'react';

import { Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import ClientSearch from './ClientSearch';

interface ClientsTableHeaderProps {
  searchQueryText: string;
  setSearchQueryText: (queryText: string) => void;
}

const ClientsTableHeader: FC<ClientsTableHeaderProps> = ({
  searchQueryText,
  setSearchQueryText,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Toolbar
      sx={{
        bgcolor: (theme) =>
          alpha(
            theme.palette.primary.dark,
            theme.palette.action.activatedOpacity
          ),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h2"
        id="tableTitle"
        component="div"
      >
        {getTranslationLabel('clientsGeneral', 'clients', t)}
      </Typography>
      <ClientSearch
        searchQueryText={searchQueryText || ''}
        onChangeSearchQueryText={setSearchQueryText}
      />
    </Toolbar>
  );
};

export default ClientsTableHeader;
