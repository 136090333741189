import { FC, useEffect } from 'react';

import { UseFormWatch } from 'react-hook-form';

import { IRoadTest } from '../../models/on-road/on-road';

export interface AutoSaveProps {
  watch: UseFormWatch<any>;
  handleSave: (onRoad: any) => void;
}

const AutoSave: FC<AutoSaveProps> = ({ handleSave, watch }) => {
  useEffect(() => {
    const subscription = watch((data: IRoadTest) => {
      handleSave(data);
    });
    return () => subscription.unsubscribe();
  }, [handleSave, watch]);

  return null;
};

export default AutoSave;
