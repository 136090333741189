import React, { useEffect, useMemo, useState } from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { containerWidth, darkBlue, lightBlue } from '../constants';
import FallbackNoLogo from '../FallBackNoLogo';
import about1 from '../img/about-1.jpeg';
import about2 from '../img/about-2.jpeg';
import about3 from '../img/about-3.jpeg';
import alex from '../img/about-alex.jpg';
import icon1 from '../img/about-icon-1-clock.png';
import icon2 from '../img/about-icon-2-graph.png';
import icon3 from '../img/about-icon-3-presentation.png';
import icon4 from '../img/about-icon-4-heart.png';
import sasha from '../img/about-sasha.png';
import Banner from './Helpers/Banner';
import Card from './Helpers/Card';
import Icons from './Helpers/Icons';

const iconPaneData = [
  {
    id: 1,
    icon: icon1,
  },
  {
    id: 2,
    icon: icon2,
  },
  {
    id: 3,
    icon: icon3,
  },
  {
    id: 4,
    icon: icon4,
  },
];

const About = () => {
  const { t } = useTranslation(websiteNamespaces);
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const IMAGES = useMemo(
    () => [about1, about2, about3, sasha, alex, icon1, icon2, icon3, icon4],
    []
  );

  useEffect(() => {
    const loadImage = (image: any) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          resolve(image.url);
        };
        img.onerror = (err) => reject(err);
      });

    Promise.all(IMAGES.map((image) => loadImage(image))).then(() =>
      setImgsLoaded(true)
    );
  }, [IMAGES]);

  return imgsLoaded ? (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <Banner
        text={getTranslationLabel('website/about', 'streetSureCombines', t)}
        variant="h2"
        padding="1%"
      />
      <Banner
        text={getTranslationLabel('website/about', 'streetSureOriginated', t)}
        variant="h3"
        padding="1%"
      />
      <Banner
        text={getTranslationLabel('website/about', 'streetSureCommitted', t)}
        variant="h3"
        padding="1%"
      />
      <Banner
        text={getTranslationLabel('website/about', 'whyChooseUs', t)}
        variant="h4"
        padding="1%"
      />

      <Card
        text1={getTranslationLabel('website/about', 'highQualityProduct', t)}
        text2={getTranslationLabel('website/about', 'driveEvalBuiltForOTs', t)}
        text3={getTranslationLabel('website/about', 'inDevelopingThisApp', t)}
        image={about1}
        rightSide
      />

      <Card
        text1={getTranslationLabel('website/about', 'unmatchedExpertise', t)}
        text2={getTranslationLabel(
          'website/about',
          'streetSureHasExperience',
          t
        )}
        text3={getTranslationLabel('website/about', 'weHaveExperience', t)}
        image={about2}
      />

      <Container
        sx={{
          backgroundColor: darkBlue,
          opacity: 0.89,
          marginTop: '2vw',
          padding: '2.5%',
        }}
        maxWidth={false}
      >
        <div style={{ paddingLeft: '1.5%' }}>
          <Banner
            text={getTranslationLabel('website/about', 'preciseResult', t)}
            variant="h2"
            padding="1%"
            textColor="white"
          />
          <Banner
            text={getTranslationLabel(
              'website/about',
              'driveEvalHasBeenDesigned',
              t
            )}
            variant="h4"
            padding="1%"
            textColor="white"
          />
          <Banner
            text={getTranslationLabel('website/about', 'driveEvalBenefits', t)}
            variant="h5"
            padding="1%"
            textColor="white"
          />
        </div>
      </Container>

      <Icons iconData={iconPaneData} namespace="website/about" />

      <Card
        text1={getTranslationLabel('website/about', 'dataManagement', t)}
        text2={getTranslationLabel('website/about', 'driveEvalManages', t)}
        text3={getTranslationLabel('website/about', 'appSecurity', t)}
        image={about3}
        rightSide
      />

      <Container
        sx={{
          backgroundColor: lightBlue,
          opacity: 0.89,
          marginTop: '2vw',
          padding: '2.5%',
        }}
        maxWidth={false}
      >
        <Typography variant="h2" color="white" padding="1% 0px 0px 2.5%">
          {getTranslationLabel('website/about', 'ourExperts', t)}
        </Typography>

        {/* Alex */}
        <Grid container paddingTop="2%" alignItems="center">
          <Grid item xs={6} sm={4} padding="2%">
            <Box
              component="img"
              sx={{
                width: '100%',
              }}
              src={alex}
            />
          </Grid>
          <Grid item xs={12} sm={8} paddingLeft="2%">
            <Typography variant="h2" color="white" padding="1.5%">
              {getTranslationLabel('website/about', 'drAlexanderCrizzle', t)}
            </Typography>
            <Typography variant="h4" padding="1.5%">
              {getTranslationLabel('website/about', 'alexCrizzleBlurb1', t)}
            </Typography>
            <Typography variant="h5" padding="1.5%">
              {getTranslationLabel('website/about', 'alexCrizzleBlurb2', t)}
            </Typography>
          </Grid>
        </Grid>

        {/* Sasha */}
        <Grid container paddingTop="2%" alignItems="center">
          <Grid item xs={6} sm={4} padding="2%">
            <Box
              component="img"
              sx={{
                width: '100%',
              }}
              src={sasha}
            />
          </Grid>
          <Grid item xs={12} sm={8} paddingLeft="2%">
            <Typography variant="h2" color="white" padding="1.5%">
              {getTranslationLabel('website/about', 'sashaAvreline', t)}
            </Typography>
            <Typography variant="h4" padding="1.5%">
              {getTranslationLabel('website/about', 'sashaAvrelineBlurb1', t)}
            </Typography>
            <Typography variant="h5" padding="1.5%">
              {getTranslationLabel('website/about', 'sashaAvrelineBlurb2', t)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  ) : (
    <FallbackNoLogo />
  );
};

export default About;
