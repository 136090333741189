import React, { useCallback, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';

import ClientsTableFallback from '../../errors/fallbacks/ClientsTableFallback';
import { useClients } from '../../hooks/database/clients/clients';
import { IClientMetaData } from '../../models/client';
import ClientsTableContents from './ClientsTable/ClientsTableContents';
import ClientsTableHeader from './ClientsTable/ClientsTableHeader';

const ClientsTable = () => {
  const { accounts } = useMsal();
  const {
    isLoading,
    error,
    data: entireClientsMetaData,
  } = useClients(accounts[0].username);
  useErrorHandler(error);

  const [searchQueryText, setSearchQueryText] = useState('');
  const [filteredClientsMetaData, setFilteredClientsMetaData] = useState<
    IClientMetaData[]
  >([]);

  const executeSearchQuery = useCallback(
    (value: string): IClientMetaData[] => {
      if (entireClientsMetaData) {
        return entireClientsMetaData.filter((clientEntry: IClientMetaData) =>
          clientEntry.clientId?.toLowerCase().includes(value.toLowerCase())
        );
      }
      return [];
    },
    [entireClientsMetaData]
  );

  useEffect(() => {
    if (entireClientsMetaData) {
      if (!searchQueryText) {
        setFilteredClientsMetaData(entireClientsMetaData);
      } else {
        setFilteredClientsMetaData(executeSearchQuery(searchQueryText));
      }
    }
  }, [searchQueryText, entireClientsMetaData, executeSearchQuery]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid item>
      <ErrorBoundary FallbackComponent={ClientsTableFallback}>
        <Paper color="secondary" elevation={2} sx={{ width: '100%', mb: 2 }}>
          <ClientsTableHeader
            searchQueryText={searchQueryText}
            setSearchQueryText={setSearchQueryText}
          />
          <ClientsTableContents clientsMetaData={filteredClientsMetaData} />
        </Paper>
      </ErrorBoundary>
    </Grid>
  );
};

export default ClientsTable;
