import React, { FC } from 'react';

import { Box, Paper, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';

// TODO: send errors to logs
// TODO: modify the look of this.
const NewClientFallback: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Paper color="secondary" elevation={2} sx={{ width: '100%', mb: 2 }}>
      <Box sx={{ p: 6 }}>
        <Typography>
          {getTranslationLabel('errors', 'newClientFallback', t)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default NewClientFallback;
