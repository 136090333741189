import { FieldTypes } from '../../../models/forms';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const VISION_SCREENING_DEVICES = getOptionsFromLabels([
  'OPTEC',
  'Titmus vision screener',
  'Other (please specify in comments)',
]);

const VISION_SCREENING_DEVICES_WNONE = getOptionsFromLabels([
  'OPTEC',
  'Titmus vision screener',
  'None',
  'Other (please specify in comments)',
]);

const VISION_SCREENING_DEVICE_FIELD = {
  label: 'Device used for measurement',
  key: 'deviceUsedForMeasurement',
  fieldType: 'SELECT' as FieldTypes,
  options: VISION_SCREENING_DEVICES,
};

export const VISION_SCREENING_DEVICE_FIELD_WNONE = {
  label: 'Device used for measurement',
  key: 'deviceUsedForMeasurement',
  fieldType: 'SELECT' as FieldTypes,
  options: VISION_SCREENING_DEVICES_WNONE,
};

export const VISION_QUADRANTS = [
  'Inferior temporal',
  'Inferior nasal',
  'Superior temporal',
  'Superior nasal',
];

export default VISION_SCREENING_DEVICE_FIELD;
