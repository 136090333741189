import React, { FC, useState } from 'react';

import { Typography, Grid, DialogContent } from '@mui/material';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import AsyncErrorWrapper from '../../../../errors/AsyncErrorWrapper';
import {
  LoadingComponent,
  ErrorComponent,
} from '../../../../errors/QueryUtils';
import {
  useCreateStandardRouteManeuver,
  useDeleteStandardRouteManeuver,
  useStandardRoute,
  useStandardRouteManeuvers,
} from '../../../../hooks/database/settings/standardRoutes';
import { IForm } from '../../../../models/forms';
import {
  IStandardRouteManeuver,
  IOrderedStandardRouteManeuver,
} from '../../../../models/on-road/route';
import FormContext from '../../../../utils/formContext';
import { getTranslationLabel } from '../../../../utils/utils';
import { SpinningDialog } from '../../../common/BasicDialog';
import FullScreenDialog from '../../../common/FullScreenDialog';
import AddTask from './AddTask';
import ListOfTasks from './ListOfTasks';

interface RouteBuilderProps {
  isOpen: boolean;
  handleClose: () => void;
  standardRouteId: string;
}

const RouteBuilder: FC<RouteBuilderProps> = ({
  isOpen,
  handleClose,
  standardRouteId,
}) => {
  const { t } = useTranslation(allNamespaces);

  const { register, control, setValue, getValues, watch } = useForm();
  const routeForm: IForm = { register, control, setValue, getValues, watch };

  const routeMetaData = useStandardRoute(standardRouteId);
  const standardRouteManeuvers = useStandardRouteManeuvers(standardRouteId);
  const createStandardRouteManeuverMutation =
    useCreateStandardRouteManeuver(standardRouteId);
  const deleteStandardRouteManeuverMutation =
    useDeleteStandardRouteManeuver(standardRouteId);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  if (standardRouteManeuvers.isLoading || routeMetaData.isLoading) {
    return <LoadingComponent />;
  }
  if (standardRouteManeuvers.isError || routeMetaData.isError) {
    return <ErrorComponent error={standardRouteManeuvers.error} />;
  }

  const addTask = () => {
    createStandardRouteManeuverMutation.mutate({
      standardManeuverId: nanoid(),
      maneuverType: getValues('taskKey'),
      standardRouteId,
      subManeuverType: watch('subManeuverType') || '',
      roadType: getValues('roadType'),
      recordType: getValues('recordType') || 'success',
      location: getValues('location'),
      nextManeuver: '',
      prevManeuver: '',
      comments: getValues('maneuverSummaryComments') || '',
    });
    setPage(Math.floor(standardRouteManeuvers.data.length / rowsPerPage));
  };

  const findNextNode = (headRef: any) =>
    standardRouteManeuvers.data.find(
      (elem: any) => elem.standardManeuverId === headRef.nextManeuver
    );

  const findHead = (headRef: string) =>
    standardRouteManeuvers.data.find(
      (elem: any) => elem.standardManeuverId === headRef
    );

  const sortManeuverSummaries = () => {
    if (standardRouteManeuvers.data.length === 0) return [];

    let idx: number = 1;
    const { headRef } = standardRouteManeuvers.data[0];
    let head: IStandardRouteManeuver = findHead(headRef);
    const result: IOrderedStandardRouteManeuver[] = [];

    while (head) {
      result.push({ idx, ...head });
      head = findNextNode(head);
      idx += 1;
    }

    return result;
  };

  const sortedStandardManeuvers = sortManeuverSummaries();

  const removeTask = (task: any) => {
    deleteStandardRouteManeuverMutation.mutate(task);
  };

  return (
    <AsyncErrorWrapper mutation={createStandardRouteManeuverMutation}>
      <FullScreenDialog
        isOpen={isOpen}
        onClose={handleClose}
        title={
          <Typography variant="h1">
            {getTranslationLabel('onroad/routeBuilder', 'routeBuilder', t)}
            {': '}
            {routeMetaData.data[0].routeName}
          </Typography>
        }
      >
        <FormContext.Provider value={routeForm}>
          <form>
            <DialogContent>
              <Grid item container direction="column">
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={5}
                >
                  <AddTask
                    addTask={addTask}
                    taskType={watch('taskKey')}
                    subType={watch('subManeuverType')}
                  />
                  <ListOfTasks
                    sortedTasks={sortedStandardManeuvers}
                    removeTask={removeTask}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    page={page}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        </FormContext.Provider>
      </FullScreenDialog>
      <SpinningDialog
        isSpinning={createStandardRouteManeuverMutation.isLoading}
        messageKey="addingOnRoadManeuver"
      />
      <SpinningDialog
        isSpinning={deleteStandardRouteManeuverMutation.isLoading}
        messageKey="deleting"
      />
    </AsyncErrorWrapper>
  );
};
export default RouteBuilder;
