export type ICustomGroupSQL = {
  groupKey: string;
  groupLabel: string;
  username: string;
  assessments?: string;
};

export type ICustomGroup = {
  groupKey: string;
  groupLabel: string;
  username: string;
  assessments?: string[];
};

export type NameScore = {
  name: string;
  score: number | string;
};

export type SpecialCalculationFunction = {
  field: string;
  func: Function;
};

export const AssessmentCategoryKeys = [
  'cognitive-perceptual',
  'physical',
  'visual',
  'behavioural-psychological',
  'comprehensive',
  'driving-specific',
] as string[];

export const AssessmentCategoryLabels = [
  'Cognitive-Perceptual',
  'Physical',
  'Visual',
  'Behavioural-Psychological',
  'Comprehensive',
  'Driving-Specific',
] as string[];

export interface IAssessments {
  moca?: IMoCA;
  mocab?: IMoCAB;
  jamarGripDynamometerTest: IJamar;
}

export interface IJamar {
  leftHandGripIsometricUnits: string;
  leftTwoFingerPinchUnits: string;
  leftTripodPinchUnits: string;
  leftLateralPinchUnits: string;
  leftHandGripIsometricGripSetting: string;
  leftTwoFingerPinchGripSetting: string;
  leftTripodPinchGripSetting: string;
  leftLateralPinchGripSetting: string;
  rightHandGripIsometricUnits: string;
  rightTwoFingerPinchUnits: string;
  rightTripodPinchUnits: string;
  rightLateralPinchUnits: string;
  rightHandGripIsometricGripSetting: string;
  rightTwoFingerPinchGripSetting: string;
  rightTripodPinchGripSetting: string;
  rightLateralPinchGripSetting: string;
}

export interface IMoCA {
  totalScoreOutOf: number;
}

export interface IMoCAB {
  totalScoreOutOf: number;
}

export const blankAssessment: IAssessments = {
  jamarGripDynamometerTest: {
    leftHandGripIsometricUnits: 'kilogramsKg',
    leftTwoFingerPinchUnits: 'poundsLbs',
    leftTripodPinchUnits: 'poundsLbs',
    leftLateralPinchUnits: 'poundsLbs',
    leftHandGripIsometricGripSetting: '2',
    leftTwoFingerPinchGripSetting: '2',
    leftTripodPinchGripSetting: '2',
    leftLateralPinchGripSetting: '2',
    rightHandGripIsometricUnits: 'kilogramsKg',
    rightTwoFingerPinchUnits: 'poundsLbs',
    rightTripodPinchUnits: 'poundsLbs',
    rightLateralPinchUnits: 'poundsLbs',
    rightHandGripIsometricGripSetting: '2',
    rightTwoFingerPinchGripSetting: '2',
    rightTripodPinchGripSetting: '2',
    rightLateralPinchGripSetting: '2',
  },
  moca: {
    totalScoreOutOf: 30,
  },
  mocab: {
    totalScoreOutOf: 30,
  },
};
