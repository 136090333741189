import React, { FC } from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import ClickableCard from './ClickableCard';

interface ClickableCardWithIconProps {
  label: string;
  onClick: () => void;
  icon: React.ReactElement;
}

const ClickableCardWithIcon: FC<ClickableCardWithIconProps> = ({
  label,
  onClick,
  icon,
}) => (
  <Grid item alignItems="center">
    <ClickableCard id="new-route" onClick={onClick} clicked={false}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {icon}
        <Typography variant="h3">{label}</Typography>
      </Stack>
    </ClickableCard>
  </Grid>
);

export default ClickableCardWithIcon;
