import React, { FC } from 'react';

import { CircularProgress, Typography } from '@mui/material';

interface ErrorComponentProps {
  error: any;
  errorMessage?: string;
}

export const ErrorComponent: FC<ErrorComponentProps> = ({
  error,
  errorMessage = 'The following error occured:',
}) => (
  <Typography>
    {errorMessage} {`Error: `} {error.errorCode}
  </Typography>
);

export const LoadingComponent = () => <CircularProgress />;
