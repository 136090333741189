import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getNumbersXToY } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const GDS_5_QUESTIONS = [
  '1. Are you basically satisfied with your life?',
  '2. Do you often get bored?',
  '3. Do you often feel helpless?',
  '4. Do you prefer to stay at home, rather than going out and doing new things?',
  '5. Do you feel pretty worthless the way you are now?',
];

const geriatricDepressionScale5: SubHeadingItem = {
  key: 'geriatricDepressionScale5',
  label: 'Geriatric Depression Scale (GDS-5)',
  fields: [
    ...GDS_5_QUESTIONS.map((item, i) => ({
      key: `question${i + 1}`,
      label: item,
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    })),
    {
      label: 'Total score',
      key: 'totalScoreGDS5',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 5 max</Typography>,
      valueDependsOn: getNumbersXToY(1, 5).map((no) => `question${no}`),
    },
    ...Results,
  ],
};

export default geriatricDepressionScale5;
