import {
  IOption,
  FieldsLayout,
  FieldTypes,
  SubHeadingItem,
} from '../../../models/forms';
import {
  getOptionsFromLabels,
  convertLabelToKey,
  allButFirstColumn,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { getKeyWithAdditionalParameter } from '../../common/FieldContainer/MyTable-Utils';

export const ROM_ST_LABEL: FieldsLayout = {
  key: 'scoringLabel',
  label: `WNL = Within normal limits, WFL = Within functional limits, NWFL = Not within functional limits. 
    Please leave non-assessed parts blank.`,
  fieldType: 'LABEL',
};

const LEFT_RIGHT_COLUMN_HEADINGS: string[] = ['', 'Left', 'Right'];

export const ROM_ST_OPTIONS: IOption[] = getOptionsFromLabels([
  'WFL',
  'WNL',
  'NWFL',
  'Absent',
]);

const MMT_OPTIONS = [
  { key: 'five', val: 'five', label: '5' },
  { key: 'fourPlus', val: 'fourPlus', label: '4+' },
  { key: 'four', val: 'four', label: '4' },
  { key: 'fourMinus', val: 'fourMinus', label: '4-' },
  { key: 'threePlus', val: 'threePlus', label: '3+' },
  { key: 'three', val: 'three', label: '3' },
  { key: 'threeMinus', val: 'threeMinus', label: '3-' },
  { key: 'twoPlus', val: 'twoPlus', label: '2+' },
  { key: 'two', val: 'two', label: '2' },
  { key: 'twoMinus', val: 'twoMinus', label: '2-' },
  { key: 'one', val: 'one', label: '1' },
  { key: 'zero', val: 'zero', label: '0' },
];

const getColumnHeadings = (tableTitle: string) =>
  tableTitle === 'Neck' ? ['', 'Result'] : LEFT_RIGHT_COLUMN_HEADINGS;

export const getROMSTTable = (
  testTitle: string,
  tableTitle: string,
  rowTitles: string[]
): FieldsLayout => ({
  key: `${convertLabelToKey(tableTitle)}Table`,
  label: '',
  fieldType: 'TABLE',
  columnHeadings: getColumnHeadings(tableTitle),
  cells: rowTitles.map((rowTitle) => [
    [
      {
        key: convertLabelToKey(rowTitle),
        label: rowTitle,
        fieldType: 'LABEL' as FieldTypes,
      },
    ],
    ...allButFirstColumn(getColumnHeadings(tableTitle)).map((columnHeading) => {
      const key = getKeyWithAdditionalParameter(
        tableTitle,
        columnHeading,
        rowTitle
      );

      if (testTitle === 'ROM') {
        return [
          {
            key,
            label: '',
            fieldType: 'CHOICE_CHIPS' as FieldTypes,
            options: ROM_ST_OPTIONS,
          },
          {
            key: `${key}Degrees`,
            label: 'degrees',
            fieldType: 'INPUT-NUM' as FieldTypes,
            size: 100,
          },
        ];
      }
      return [
        {
          key,
          label: '',
          fieldType: 'CHOICE_CHIPS' as FieldTypes,
          options: ROM_ST_OPTIONS,
        },
        {
          key: `${key}MMT`,
          label: 'MMT',
          fieldType: 'SELECT' as FieldTypes,
          options: MMT_OPTIONS,
          size: 100,
        },
      ];
    }),
  ]),
  selectAll: 'WFL',
});

export const processROMSTSubheading = (
  testTitle: string,
  tableTitle: string,
  rowTitles: string[]
): SubHeadingItem => ({
  key: `${convertLabelToKey(tableTitle)}Section`,
  label: tableTitle,
  fields: [getROMSTTable(testTitle, tableTitle, rowTitles)],
});
