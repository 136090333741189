import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  VISION_QUADRANTS,
  VISION_SCREENING_DEVICE_FIELD_WNONE,
} from './Visual-Utils';

const visualFields: SubHeadingItem = {
  key: 'visualFields',
  label: 'Visual Fields',
  fields: [
    VISION_SCREENING_DEVICE_FIELD_WNONE,
    {
      label:
        'Does the client have awarness of the following areas of their vision?',
      key: 'awarnessOfVisionAreas',
      fieldType: 'CHECKBOX',
      options: [...VISION_QUADRANTS, 'Central vision'].map((area) => ({
        key: `${convertLabelToKey(area)}VisualField`,
        val: `${convertLabelToKey(area)}VisualField`,
        label: area,
      })),
    },
    {
      label: 'Periometer measurement (both eyes)',
      key: 'periometerMeasurementBothEyes',
      fieldType: 'INPUT-NUM',
      units: <Typography>degrees</Typography>,
    },
    {
      label: 'Periometer measurement (left eye)',
      key: 'periometerMeasurementLeft',
      fieldType: 'INPUT-NUM',
      units: <Typography>degrees</Typography>,
    },
    {
      label: 'Periometer measurement (right eye)',
      key: 'periometerMeasurementRight',
      fieldType: 'INPUT-NUM',
      units: <Typography>degrees</Typography>,
    },
    {
      label: 'Overall OT assigned result',
      key: 'overallOTAssignedResult',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Meets licensing standards',
        'Does not meet licensing standards',
        'Undetermined',
      ]),
    },
  ],
};

export default visualFields;
