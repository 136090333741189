import React from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './components/common/ScrollToTop';
import WebsiteHomePage from './components/website/WebsiteHomePage';
import Protected from './Protected';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Router>
      <ScrollToTop />
      <CssBaseline />
      {!isAuthenticated && <WebsiteHomePage />}
      {isAuthenticated && <Protected />}
    </Router>
  );
};

export default App;
