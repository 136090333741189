import React, { FC, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import AsyncErrorWrapper from '../../../errors/AsyncErrorWrapper';
import {
  useCreateManeuverSummaries,
  useDeleteManeuverSummaries,
  useManeuverSummaries,
  useUpdateListRefs,
} from '../../../hooks/database/on-road/maneuverSummaries';
import { useStandardRoutes } from '../../../hooks/database/settings/standardRoutes';
import useModal from '../../../hooks/useModal';
import { IRoadTest } from '../../../models/on-road/on-road';
import {
  IManeuverSummary,
  IStandardRouteManeuver,
  IStandardRoute,
} from '../../../models/on-road/route';
import { getTranslationLabel } from '../../../utils/utils';
import ClickableCardWithIcon from '../../common/ClickableCardWithIcon';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import StandardRouteCard from './StandardRouteCard';

interface ListOfStandardRoutesProps {
  roadTest: IRoadTest;
  openNewStandardRouteDialog: () => void;
  handleEditStandardRoute: any;
  selectedStandardRoute: string | null;
  setStandardRouteId: (standardRouteId: string | null) => void;
}

const ListOfStandardRoutes: FC<ListOfStandardRoutesProps> = ({
  roadTest,
  openNewStandardRouteDialog,
  handleEditStandardRoute,
  selectedStandardRoute,
  setStandardRouteId,
}) => {
  const { t } = useTranslation(allNamespaces);
  const roadTestId = roadTest.roadTestId || '';
  const [confirmationStandardRoute, setConfirmationStandardRoute] = useState<{
    standardRoute: IStandardRoute | null;
    standardRouteManeuvers: IStandardRouteManeuver[];
  }>({ standardRoute: null, standardRouteManeuvers: [] });
  const confirmationModal = useModal();

  const standardRoutes = useStandardRoutes();
  const addManeuverSummariesMutation = useCreateManeuverSummaries(roadTestId);
  const deleteManeuverSummariesMutation =
    useDeleteManeuverSummaries(roadTestId);
  const maneuverSummaries = useManeuverSummaries(roadTestId);
  const updateListRefs = useUpdateListRefs(roadTestId);

  if (roadTestId === '')
    return <Typography>Road Test does not exist.</Typography>;

  const handleSaveStandardRoute = async (
    newStandardRoute: IStandardRoute | null,
    standardRouteManeuvers: IStandardRouteManeuver[]
  ) => {
    try {
      if (maneuverSummaries.data.length > 0) {
        await deleteManeuverSummariesMutation.mutateAsync(roadTestId);
      }
    } finally {
      if (newStandardRoute && roadTestId) {
        const standardManeuversToAdd: IManeuverSummary[] =
          standardRouteManeuvers.map((maneuver: IStandardRouteManeuver) => ({
            maneuverId: nanoid(),
            roadTestId,
            maneuverType: maneuver.maneuverType,
            refManeuverId: maneuver.standardManeuverId,
            recordType: 'success',
            location: maneuver.location,
            roadType: maneuver.roadType,
            subManeuverType: maneuver.subManeuverType,
            maneuverSummaryComments: maneuver.comments,
            nextManeuver: maneuver.nextManeuver,
            prevManeuver: maneuver.prevManeuver,
          }));

        await addManeuverSummariesMutation.mutateAsync(standardManeuversToAdd);
        await updateListRefs.mutateAsync({
          roadTestId,
          headRef: newStandardRoute.headRef,
          tailRef: newStandardRoute.tailRef,
        });
      }
    }

    if (newStandardRoute) {
      setStandardRouteId(newStandardRoute.standardRouteId);
    } else {
      setStandardRouteId(null);
    }
    confirmationModal.close();
  };

  const handleChooseStandardRoute = (
    standardRoute: IStandardRoute,
    standardRouteManeuvers: IStandardRouteManeuver[]
  ) => {
    if (roadTest.standardRouteId === standardRoute.standardRouteId) {
      setConfirmationStandardRoute({
        standardRoute: null,
        standardRouteManeuvers: [],
      });
      confirmationModal.show();
    } else {
      setConfirmationStandardRoute({ standardRoute, standardRouteManeuvers });
      confirmationModal.show();
    }
  };

  const getStandardRouteLabel = (route: any) =>
    `${route.routeName} ${
      route.expectedDuration ? `(${route.expectedDuration} min)` : ''
    }`;

  return (
    <AsyncErrorWrapper
      mutation={addManeuverSummariesMutation}
      pairedMutation={deleteManeuverSummariesMutation}
    >
      <Grid item container direction="column" alignItems="flex-start" lg={4}>
        <Grid item>
          <Typography variant="h2">
            {getTranslationLabel(
              'onroad/chooseRoute',
              'selectStandardRoute',
              t
            )}
          </Typography>
        </Grid>
        <ClickableCardWithIcon
          label={getTranslationLabel(
            'onroad/chooseRoute',
            'standardRouteLabel',
            t
          )}
          onClick={openNewStandardRouteDialog}
          icon={<AddIcon color="secondary" />}
        />
        <Grid
          item
          container
          direction="column"
          spacing={1}
          alignItems="flex-start"
        >
          {standardRoutes.data && standardRoutes.data.length !== 0 ? (
            standardRoutes.data.map(
              (route: IStandardRoute) =>
                route &&
                route.standardRouteId && (
                  <StandardRouteCard
                    handleChooseStandardRoute={handleChooseStandardRoute}
                    handleEditStandardRoute={handleEditStandardRoute}
                    selectedStandardRouteId={selectedStandardRoute}
                    cardStandardRoute={route}
                    cardLabel={getStandardRouteLabel(route)}
                    key={route.standardRouteId}
                  />
                )
            )
          ) : (
            <Grid item>
              <Typography>
                {getTranslationLabel(
                  'onroad/chooseRoute',
                  'haveNotCreatedStandardRoute',
                  t
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
        {confirmationModal.isShowing && (
          <ConfirmationDialog
            isOpen={confirmationModal.isShowing}
            confirmationHeading={getTranslationLabel(
              'onroad/chooseRoute',
              'sureWantToChangeStandardRoute',
              t
            )}
            confirmationMessage={getTranslationLabel(
              'onroad/chooseRoute',
              'thisWillChangeManeuversInTacticalManeuvers',
              t
            )}
            confirmationButtonMessage={getTranslationLabel(
              'onroad/chooseRoute',
              'changeStandardRoute',
              t
            )}
            handleCancel={() => {
              confirmationModal.close();
            }}
            handleClose={() => {
              confirmationModal.close();
            }}
            handleConfirm={() =>
              handleSaveStandardRoute(
                confirmationStandardRoute.standardRoute,
                confirmationStandardRoute.standardRouteManeuvers
              )
            }
          />
        )}
      </Grid>
    </AsyncErrorWrapper>
  );
};

export default ListOfStandardRoutes;
