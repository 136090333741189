import {
  FieldsLayout,
  FieldTypes,
  IOption,
  SubHeadingItem,
} from '../../../models/forms';
import { PhysicalResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  allButFirstColumn,
  convertLabelToKey,
  capitalizeFirstLetter,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const SENSATION_COLUMNS = ['', 'Test No. 1', 'Test No. 2', 'Test No. 3'];

const EXTREMITY_SENSATION_CATEGORIES = [
  'Proprioception',
  'Kinesthesia',
  'Light touch',
  'Pressure',
  'Temperature',
  'Pinprick',
  'Stereognosis',
  'Other (specify in comments)',
];

const EXTREMITY_SENSATION_OPTIONS: IOption[] = getOptionsFromLabels([
  'Functional',
  'Impaired',
  'Absent',
]);

const ES_LABEL: FieldsLayout = {
  key: 'scoringLabel',
  label: 'Please leave non-assessed parts blank.',
  fieldType: 'LABEL',
};

const sensationAndProprioception: SubHeadingItem = {
  key: 'sensationAndProprioception',
  label: 'Sensation and Proprioception',
  fields: [
    ES_LABEL,
    {
      key: 'reactionTimeTesterTable',
      label: '',
      fieldType: 'TABLE',
      columnHeadings: SENSATION_COLUMNS,
      cells: [
        'Method',
        'Left Upper Extremity',
        'Right Upper Extremity',
        'Left Leg',
        'Right Leg',
        'Left Foot',
        'Right Foot',
        'Comments',
      ].map((rowTitle) => [
        [
          {
            key: convertLabelToKey(rowTitle),
            label: rowTitle,
            fieldType: 'LABEL' as FieldTypes,
          },
        ],
        ...allButFirstColumn(SENSATION_COLUMNS).map((columnHeading) => {
          const key = `${convertLabelToKey(
            columnHeading
          )}${capitalizeFirstLetter(convertLabelToKey(rowTitle))}`;

          switch (rowTitle) {
            case 'Method':
              return [
                {
                  key,
                  label: '',
                  fieldType: 'SELECT' as FieldTypes,
                  options: getOptionsFromLabels(EXTREMITY_SENSATION_CATEGORIES),
                  size: 200,
                },
              ];
            case 'Comments':
              return [
                {
                  key,
                  label: '',
                  fieldType: 'TEXT-AREA' as FieldTypes,
                  size: 200,
                },
              ];
            default:
              return [
                {
                  key,
                  label: '',
                  fieldType: 'CHOICE_CHIPS' as FieldTypes,
                  options: EXTREMITY_SENSATION_OPTIONS,
                  size: 200,
                },
              ];
          }
        }),
      ]),
    },
    ...PhysicalResults,
  ],
};

export default sensationAndProprioception;
