import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import {
  TRUE_FALSE_OPTIONS,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';
import {
  OTHER_VECHILE_CONDITIONAL,
  EQUIPMENT_CONDITIONAL,
  DISORIENTATION_CONDITIONAL,
  DRIVING_GOALS_CONDITIONAL,
  OTHER_TRANSPORTATION_CONDITIONAL,
  SUPPORT_PERSON_CONDITIONAL,
  TYPE_OF_TRANSMISSION,
  DRIVING_FREQUENCY,
  TYPE_OF_VECHILE,
  ROAD_CONDITIONS,
  ROAD_TYPES,
} from './DrivingHistoryConditionals';
import {
  BEFORE_DIAGNOSIS_ROAD_TYPES_FIELDS,
  AFTER_DIAGNOSIS_ROAD_TYPES_FIELDS,
  AFTER_DIAGNOSIS_AVOID_FIELDS,
  BEFORE_DIAGNOSIS_AVOID_FIELDS,
} from './DrivingHistoryFieldLists';

const beforeAfterHeadings = ['', 'Before diagnosis', 'After diagnosis'];

const selectAllTypesOfRoadsBeforeDiagnosis = (
  getValues: any,
  setValue: any
) => {
  BEFORE_DIAGNOSIS_ROAD_TYPES_FIELDS.forEach((field) => setValue(field, '1'));
};
const clearSelectionTypesOfRoadsBeforeDiagnosis = (setValue: any) => {
  BEFORE_DIAGNOSIS_ROAD_TYPES_FIELDS.forEach((field) => setValue(field, '0'));
};
const copyToRoadsAfterDiagnosis = (getValues: any, setValue: any) => {
  BEFORE_DIAGNOSIS_ROAD_TYPES_FIELDS.forEach((field, idx) => {
    setValue(AFTER_DIAGNOSIS_ROAD_TYPES_FIELDS[idx], getValues(field));
  });
};

const selectAllAvoidsBeforeDiagnosis = (getValues: any, setValue: any) => {
  BEFORE_DIAGNOSIS_AVOID_FIELDS.forEach((field) => setValue(field, '1'));
};
const clearSelectionAllAvoidsBeforeDiagnosis = (setValue: any) => {
  BEFORE_DIAGNOSIS_AVOID_FIELDS.forEach((field) => setValue(field, '0'));
};
const copyToAvoidsAfterDiagnosis = (getValues: any, setValue: any) => {
  BEFORE_DIAGNOSIS_AVOID_FIELDS.forEach((field, idx) => {
    setValue(AFTER_DIAGNOSIS_AVOID_FIELDS[idx], getValues(field));
  });
};

const getDrivingHistorySpec = (key: string) => [
  {
    rowTitle: 'What types of roads did/do they drive on?',
    element: {
      key,
      label: '',
      fieldType: 'CHECKBOXES_WITHBUTTON' as FieldTypes,
      options: getOptionsFromLabels(
        ROAD_TYPES,
        undefined,
        `drivingOn${capitalizeFirstLetter(key)}`
      ),
      onClick:
        key === 'beforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOn'
          ? selectAllTypesOfRoadsBeforeDiagnosis
          : copyToRoadsAfterDiagnosis,
      clearSelection:
        key === 'beforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOn'
          ? clearSelectionTypesOfRoadsBeforeDiagnosis
          : undefined,
    },
  },
  {
    rowTitle: 'Did/do they avoid any of those?',
    element: {
      key,
      label: '',
      fieldType: 'CHECKBOXES_WITHBUTTON' as FieldTypes,
      options: getOptionsFromLabels(
        ROAD_CONDITIONS,
        undefined,
        `avoided${capitalizeFirstLetter(key)}`
      ),
      onClick:
        key === 'beforeDiagnosisDiddoTheyAvoidAnyOfThose'
          ? selectAllAvoidsBeforeDiagnosis
          : copyToAvoidsAfterDiagnosis,
      clearSelection:
        key === 'beforeDiagnosisDiddoTheyAvoidAnyOfThose'
          ? clearSelectionAllAvoidsBeforeDiagnosis
          : undefined,
    },
  },
  {
    rowTitle: 'What was/is their driving frequency?',
    element: {
      key,
      label: '',
      fieldType: 'RADIO' as FieldTypes,
      options: getOptionsFromLabels(DRIVING_FREQUENCY),
    },
  },
  {
    rowTitle: 'Number of km per week',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT' as FieldTypes,
    },
  },
];

const DRIVING_HISTORY: SubHeadingItem = {
  label: 'Driving History',
  key: 'category3',
  fields: [
    {
      label: 'Are they currently driving?',
      key: 'isCurrentlyDriving',
      fieldType: 'CHOICE_CHIPS',
      options: YES_NO,
    },
    {
      key: 'dateLastDrove',
      label: 'Date last drove',
      fieldType: 'DATE',
    },
    {
      label: 'Vehicle type',
      key: 'vehicleType',
      fieldType: 'RADIO',
      options: TYPE_OF_VECHILE,
    },
    {
      label: 'Vehicle transmission',
      key: 'vehicleTransmission',
      fieldType: 'RADIO',
      options: TYPE_OF_TRANSMISSION,
    },
    {
      label: 'Do they use any other vehicle?',
      key: 'otherVehicle',
      fieldType: 'CHOICE_CHIPS',
      options: TRUE_FALSE_OPTIONS,
      conditionals: OTHER_VECHILE_CONDITIONAL,
    },
    {
      label: '',
      key: 'drivingHistoryTable',
      fieldType: 'TABLE',
      columnHeadings: beforeAfterHeadings,
      cells: generateCellsForTableWithUniformCells(
        beforeAfterHeadings,
        [
          'What types of roads did/do they drive on?',
          'Did/do they avoid any of those?',
          'What was/is their driving frequency?',
          'Number of km per week',
        ],
        getDrivingHistorySpec,
        undefined
      ),
    },
    {
      label: 'Any history of disorientation or getting lost?',
      key: 'historyOfDisorientationOrGettingLost',
      fieldType: 'CHOICE_CHIPS',
      options: TRUE_FALSE_OPTIONS,
      conditionals: DISORIENTATION_CONDITIONAL,
    },
    {
      label: 'Any specialized equipment?',
      key: 'anySpecializedEquipment',
      fieldType: 'CHOICE_CHIPS',
      options: TRUE_FALSE_OPTIONS,
      conditionals: EQUIPMENT_CONDITIONAL,
    },
    {
      label: 'Strategies being used to compensate',
      key: 'strategiesBeingUsedToCompensateImpactsOnDrivingPerformance',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Driving goals',
      key: 'drivingGoals',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(
        [
          'Return to the previous level of driving',
          'Drive for work',
          'Drive for errands',
          'Drive to work',
          'Drive to school',
          'Drive to medical appointments',
          'Drive to leisure activities',
          'Drive their family',
          'Drive X km per week',
          'Other driving goals',
        ],
        undefined,
        'drivingGoals'
      ),
      conditionals: DRIVING_GOALS_CONDITIONAL,
    },
    {
      label: 'Who do they currently rely on for transportation?',
      key: 'currentlyReliesOn',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(
        [
          'Self',
          'Family',
          'Friends',
          'Taxi',
          'Paratransit',
          'Public transit',
          'Other transportation',
        ],
        undefined,
        'currentlyReliesOn'
      ),
      conditionals: OTHER_TRANSPORTATION_CONDITIONAL,
    },
    {
      label: 'Support person (if applicable) confirms all of the above',
      key: 'supportPersonConfirmsDrivingHistory',
      fieldType: 'RADIO',
      options: YES_NO,
      conditionals: SUPPORT_PERSON_CONDITIONAL,
    },
  ],
};

export default DRIVING_HISTORY;
