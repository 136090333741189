import React from 'react';

import { Typography } from '@mui/material';

import { FieldsLayout, IOption, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const ROOKWOOD_SCORE_OPTIONS_1: IOption[] = [
  {
    key: 'pass',
    val: 'pass',
    label: 'Pass (0)',
  },
  {
    key: 'failOne',
    val: 'failOne',
    label: 'Fail (1)',
  },
];

const ROOKWOOD_SCORE_OPTIONS_2: IOption[] = [
  ROOKWOOD_SCORE_OPTIONS_1[0],
  {
    key: 'borderline',
    val: 'borderlineOne',
    label: 'Borderline (1)',
  },
  {
    key: 'failTwo',
    val: 'failTwo',
    label: 'Fail (2)',
  },
];

const ROOKWOOD_FIELDS_HEADINGS_1: string[] = [
  'Visual attention task (Es and Fs)',
  'Divided attention task',
];

const ROOKWOOD_FIELDS_HEADINGS_2: string[] = [
  'Incomplete letters',
  'Position discrimination',
  'Cube analysis',
  'Copying, gesture and objects',
  'Tapping and sequencing',
  'Key search test',
  'Action programme test',
  'Rule shift cards test',
  'Sorting test',
  'Comprehension',
];

const ROOKWOOD_FIELDS_1: FieldsLayout[] = ROOKWOOD_FIELDS_HEADINGS_1.map(
  (heading) => ({
    key: `${convertLabelToKey(heading)}Score`,
    label: heading,
    fieldType: 'SCALE',
    options: ROOKWOOD_SCORE_OPTIONS_1,
  })
);

const ROOKWOOD_FIELDS_2: FieldsLayout[] = ROOKWOOD_FIELDS_HEADINGS_2.map(
  (heading) => ({
    key: `${convertLabelToKey(heading)}Score`,
    label: heading,
    fieldType: 'SCALE',
    options: ROOKWOOD_SCORE_OPTIONS_2,
  })
);

const rookwoodDrivingBattery: MainHeadingItem = {
  key: 'rookwoodDrivingBattery',
  label: 'Rookwood Driving Battery (RDB)',
  subheadings: [
    {
      key: 'visualPerceptionSection',
      label: 'Visual Perception',
      fields: ROOKWOOD_FIELDS_2.slice(0, 3),
    },
    {
      key: 'attentionSection',
      label: 'Attention',
      fields: ROOKWOOD_FIELDS_1,
    },
    {
      key: 'praxisSkillsSection',
      label: 'Praxis Skills',
      fields: ROOKWOOD_FIELDS_2.slice(3, 5),
    },
    {
      key: 'executiveFunctioningSection',
      label: 'Executive Functioning',
      fields: ROOKWOOD_FIELDS_2.slice(5, 9),
    },
    {
      key: 'comprehensionSection',
      label: 'Comprehension',
      fields: ROOKWOOD_FIELDS_2.slice(9, 10),
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          key: 'totalRookwoodScore',
          label: 'Total score',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 22</Typography>,
          valueDependsOn: [
            ...ROOKWOOD_FIELDS_HEADINGS_1.map(
              (item) => `${convertLabelToKey(item)}Score`
            ),
            ...ROOKWOOD_FIELDS_HEADINGS_2.map(
              (item) => `${convertLabelToKey(item)}Score`
            ),
          ],
        },
        {
          key: 'totalRookwoodNumberOfTestsFailed',
          label: 'Total number of tests failed',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: [
            ...ROOKWOOD_FIELDS_HEADINGS_1.map(
              (item) => `${convertLabelToKey(item)}Score`
            ),
            ...ROOKWOOD_FIELDS_HEADINGS_2.map(
              (item) => `${convertLabelToKey(item)}Score`
            ),
          ],
        },
        {
          key: 'interpretationRookwood',
          label: 'Interpretation of the score',
          fieldType: 'RADIO',
          options: [
            {
              key: 'interpretationFail',
              val: 'interpretationFail',
              label: '10+: Fail, 90% chance of failing an on-road test',
            },
            {
              key: 'difficultiesWithDriving',
              val: 'difficultiesWithDriving',
              label:
                '6-10: Difficulties with driving, refer to an on-road test',
            },
            {
              key: 'normal',
              val: 'normal',
              label: '0-5: Normal',
            },
          ],
          valueDependsOn: ['totalRookwoodScore'],
        },
        ...Results,
      ],
    },
  ],
};

export default rookwoodDrivingBattery;
