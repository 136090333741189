import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const pictureCompletionTest: SubHeadingItem = {
  key: 'pictureCompletionTest',
  label: 'Picture Completion Test',
  fields: [
    {
      label: 'Number of pictures completed',
      key: 'numberOfPicturesCompleted',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 20</Typography>,
    },
    {
      label: 'Percent',
      key: 'pictureCompletionTestPercent',
      fieldType: 'INPUT-NUM',
      units: <Typography>%</Typography>,
      valueDependsOn: ['numberOfPicturesCompleted'],
    },
    ...Results,
  ],
};

export default pictureCompletionTest;
