import { IOption, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const TRAFFIC_JUDGMENT_OPTIONS: IOption[] = [
  'Able to identify safety concern',
  'Able to describe correct response given risk',
  'Unable to reason in simple scenarios',
  'Unable to reason in complex scenarios',
  'Needed assistance',
  'Gave impulsive answers',
  'Acted inappropriately in general',
].map((item) => ({
  key: convertLabelToKey(item),
  val: convertLabelToKey(item),
  label: item,
}));

const TRAFFIC_JUDGMENT_ANALYSIS_LEVELS: IOption[] = [
  'Good analysis',
  'Fair analysis',
  'Poor analysis',
].map((item) => ({
  key: convertLabelToKey(item),
  val: convertLabelToKey(item),
  label: item,
}));

const trafficJudgmentQuestions: SubHeadingItem = {
  key: 'trafficJudgmentQuestions',
  label: 'Traffic Judgment Questions',
  fields: [
    {
      key: 'observations',
      label: 'Observations',
      fieldType: 'CHECKBOX',
      options: TRAFFIC_JUDGMENT_OPTIONS,
    },
    {
      key: 'overallPerformance',
      label: 'Overall performance',
      fieldType: 'RADIO',
      options: TRAFFIC_JUDGMENT_ANALYSIS_LEVELS,
    },
    ...Results,
  ],
};

export default trafficJudgmentQuestions;
