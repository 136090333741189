import { SubHeadingItem } from '../../../models/forms';
import { TRUE_FALSE_OPTIONS } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import LICENCE_CONDITIONAL from './LicensingInformationConditionals';

const LICENSING_INFORMATION: SubHeadingItem = {
  label: 'Licensing Information',
  key: 'category1',
  fields: [
    {
      label: "Do they have a driver's licence?",
      key: 'hasDriversLicence',
      disabled: true,
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: LICENCE_CONDITIONAL,
    },
    {
      label: 'letters from licensing body',
      key: 'lettersFromLicensingBody',
      fieldType: 'UPLOAD',
    },
  ],
};

export default LICENSING_INFORMATION;
