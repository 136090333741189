import React, { FC, useContext } from 'react';

import { Grid } from '@mui/material';

import { IOption } from '../../models/forms';
import FormContext from '../../utils/formContext';
import ChoiceChipsArray from '../on-road/Common/ChoiceChipsArray';

interface ChoiceChipsProps {
  chipData: IOption[];
  fieldName: string;
  namespace: string;
}

const ChoiceChips: FC<ChoiceChipsProps> = ({
  chipData,
  fieldName,
  namespace,
}) => {
  const { setValue, getValues, watch } = useContext(FormContext);

  const handleOnClick = (data: string) => {
    if (data !== getValues(fieldName)) {
      setValue(fieldName, data);
    } else {
      setValue(fieldName, '');
    }
  };

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '2px',
        margin: '1px',
      }}
    >
      <ChoiceChipsArray
        selected={watch(fieldName)}
        chipData={chipData}
        namespace={namespace}
        onClick={handleOnClick}
      />
    </Grid>
  );
};

export default ChoiceChips;
