import React, { FC } from 'react';

import { Box } from '@mui/material';
import { UseFormWatch, FieldValues } from 'react-hook-form';

import { SubHeadingItem } from '../../../models/forms';
import { SectionHeading } from '../../common/FieldContainer/SectionHeading';
import FormSection from '../../common/FormSection';

export interface AssessmentFormSubHeadingProps {
  clientId: string;
  assessmentDefinition: SubHeadingItem;
  watch: UseFormWatch<FieldValues>;
}

export const AssessmentFormSubHeading: FC<AssessmentFormSubHeadingProps> = ({
  clientId,
  assessmentDefinition,
  watch,
}) => (
  <Box margin={0.5}>
    {assessmentDefinition.selectAll && (
      <SectionHeading
        title=""
        fields={assessmentDefinition.fields}
        selectAll={assessmentDefinition.selectAll}
      />
    )}
    <FormSection
      fields={assessmentDefinition.fields}
      namespace={`assessments/${assessmentDefinition.key}`}
      values={watch()}
      clientId={clientId}
      commentsPosition="bottom"
      sectionKey={assessmentDefinition.key}
    />
  </Box>
);
