import React, { FC, useContext } from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { Conditional, FieldsLayout } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import ClearConditional from '../ClearConditional';
import PrimitiveField from './PrimitiveField';
import { SectionHeading } from './SectionHeading';

interface MyTableProps {
  title: string;
  name: string;
  namespace: string;
  selectAll?: string;
  columnHeadings?: string[];
  cells?: FieldsLayout[][][];
}

const getTable = (
  title: string,
  name: string,
  namespace: string,
  getValues: any,
  t: any,
  selectAll?: string,
  columnHeadings?: string[],
  cells?: FieldsLayout[][][]
) => (
  <>
    <SectionHeading title={title} selectAll={selectAll} cells={cells} />
    <Table key={name}>
      <TableHead>
        <TableRow>
          {columnHeadings &&
            columnHeadings.map((column) => (
              <TableCell
                key={column}
                align="left"
                width={
                  name === 'referralInformationTable'
                    ? `${100 / columnHeadings.length}%`
                    : 'auto'
                }
              >
                <Typography variant="h3">
                  {getTranslationLabel(
                    namespace,
                    convertLabelToKey(column),
                    t,
                    column
                  )}
                </Typography>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {cells &&
          cells.map((row) => (
            <TableRow key={`${title}${row[0][0].key}Row`}>
              {row.map((cell) => (
                <TableCell
                  key={`${title}${cell[0].key}Cell`}
                  align="left"
                  style={{ verticalAlign: 'top' }}
                >
                  {cell.map(
                    ({
                      key,
                      label,
                      fieldType,
                      units,
                      conditionals,
                      defaultValue,
                      options,
                      size,
                      valueDependsOn,
                      onClick,
                      clearSelection,
                    }) => (
                      <span key={`${key}Cell`}>
                        <PrimitiveField
                          key={key}
                          name={key}
                          namespace={namespace}
                          label={label}
                          units={units}
                          defaultValue={defaultValue}
                          type={fieldType}
                          options={options}
                          size={size}
                          valueDependsOn={valueDependsOn}
                          onClick={onClick}
                          clearSelection={clearSelection}
                          insideTable
                        />
                        {conditionals &&
                          conditionals.map((conditional: Conditional) =>
                            conditional.condition(
                              getValues(),
                              conditional.conditionalKeys
                            ) ? (
                              <Grid
                                item
                                key={`${conditional.key}Grid`}
                                style={{ margin: ' 10px 0' }}
                              >
                                <PrimitiveField
                                  label={conditional.label}
                                  name={conditional.key}
                                  namespace={namespace}
                                  type={conditional.fieldType}
                                  options={conditional.options}
                                  units={conditional.units}
                                  defaultValue={conditional.defaultValue}
                                  valueDependsOn={valueDependsOn}
                                  onClick={onClick}
                                  clearSelection={clearSelection}
                                />
                              </Grid>
                            ) : (
                              <ClearConditional
                                key={conditional.key}
                                name={conditional.key}
                                type={conditional.fieldType}
                                options={conditional.options}
                              />
                            )
                          )}
                      </span>
                    )
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </>
);

const MyTable: FC<MyTableProps> = ({
  title,
  name,
  namespace,
  columnHeadings,
  cells,
  selectAll,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { getValues } = useContext(FormContext);

  return title ? (
    <Paper variant="outlined" style={{ padding: '20px', margin: '10px' }}>
      {getTable(
        title,
        name,
        namespace,
        getValues,
        t,
        selectAll,
        columnHeadings,
        cells
      )}
    </Paper>
  ) : (
    getTable(
      title,
      name,
      namespace,
      getValues,
      t,
      selectAll,
      columnHeadings,
      cells
    )
  );
};

export default MyTable;
