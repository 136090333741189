import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getNumbersXToY } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const GDS_15_QUESTIONS = [
  '1. Are you basically satisfied with your life?',
  '2. Have you dropped many of your activities and interests?',
  '3. Do you feel that your life is empty?',
  '4. Do you often get bored?',
  '5. Are you in good spirits most of the time?',
  '6. Are you afraid that something bad is going to happen to you?',
  '7. Do you feel happy most of the time?',
  '8. Do you often feel helpless?',
  '9. Do you prefer to stay at home, rather than going out and doing new things?',
  '10. Do you feel you have more problems with memory than most?',
  '11. Do you think it is wonderful to be alive now?',
  '12. Do you feel pretty worthless the way you are now?',
  '13. Do you feel full of energy?',
  '14. Do you feel that your situation is hopeless?',
  '15. Do you think that most people are better off than you are?',
];

const geriatricDepressionScale15: SubHeadingItem = {
  key: 'geriatricDepressionScale15',
  label: 'Geriatric Depression Scale (GDS-15)',
  fields: [
    ...GDS_15_QUESTIONS.map((item, i) => ({
      key: `question${i + 1}`,
      label: item,
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    })),
    {
      label: 'Total score',
      key: 'totalScoreGDS15',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 15 max</Typography>,
      valueDependsOn: getNumbersXToY(1, 15).map((no) => `question${no}`),
    },
    ...Results,
  ],
};

export default geriatricDepressionScale15;
