import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { YES_NO } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const P2Q5_COLUMN_HEADINGS = ['Question', 'Answer'];

const P2Q5_ROW_TITLES = [
  'Walk in a crowded mall',
  'Find items on the grocery store shelves',
  'Respond in an emergency situation',
  'Ride a bike',
  'Ride other motorized devices such as a golf cart, Go cart, etc',
  'Play catch, ping pong, tennis, badminton, baseball, etc',
  'Ability to see on an overcast or cloudy day',
  'Walking into a movie theatre late and it is already dark',
  'After turning off the bathroom light in the middle of the night, how long does it take you before you can start walking',
  'Other (appropriate to this specific client based on other information that has been provided)',
];

const getP2Q5TableRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
    },
  },
];

const metacognitionInClinicInterview: MainHeadingItem = {
  key: 'metacognitionInClinicInterview',
  label: 'Metacognition Interview (In-Clinic)',
  subheadings: [
    {
      key: 'part2',
      label: 'Self-Awareness of Deficits',
      fields: [
        {
          key: 'p2Q1Label',
          label: `Why were you referred for the Functional Vision Driver Assessment? (Elaborate on diagnosis, deficits, etc). 
          Are you any different now compared to what you were like before your injury/accident/illness?`,
          fieldType: 'LABEL',
        },
        {
          key: 'p2Q1',
          label: '',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p2Q2',
          label: 'Have your abilities changed?',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        {
          key: 'p2Q3',
          label: 'What would your friends and family say?',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p2Q4',
          label: 'Where is your vision deficit?',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p2Q5Label',
          label: `It is important that we gain an understanding of your abilities and your limitations related to your vision. It is really important that
          you are aware of your own strengths and weaknesses. It allows me to understand how well you know yourself and helps me to
          determine your ability to benefit from rehabilitation if you need any.`,
          fieldType: 'LABEL',
        },
        {
          label: 'Describe/discuss/explain how you are able to:',
          key: 'p2Q5Table',
          fieldType: 'TABLE',
          columnHeadings: P2Q5_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            P2Q5_COLUMN_HEADINGS,
            P2Q5_ROW_TITLES,
            getP2Q5TableRowSpec,
            undefined
          ),
        },
        {
          key: 'p2Q6Label',
          label:
            'Tell me about a time when you bumped into something? How often does this occur? What have you now done to lessen this?',
          fieldType: 'LABEL',
        },
        {
          key: 'p2Q6',
          label: '',
          fieldType: 'TEXT-AREA',
        },
        {
          key: 'p2Q7',
          label: `You are likely here today for this assessment because you feel that you may be able to drive. Do you feel that there
          are any changes in your driving habits that you will make to ensure your safety of minimize your risk given that you do have a
          vision deficit? Check all that the client states:`,
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels([
            'Drive only in familiar places',
            'Avoid high traffic areas',
            'No night driving',
            'Stay in local city',
            'Drive when traffic is less heavy (am, no rush hour)',
            'Dont drive with kids in the car',
            'Keep distractions to a minimum',
            'Only drive when I am not tired',
            'Drive more slowly',
            'Drive with awareness of the effects of my medication',
            'Other (specify in comments)',
            'Does not state or plan to make any changes',
          ]),
        },
      ],
    },
    {
      key: 'part3',
      label: 'Analysis of Clinic Metacognition Interview',
      fields: [
        {
          key: 'p3Q1',
          label:
            'Does the Occupational Therapist have enough data to complete this analysis?',
          fieldType: 'RADIO',
          options: YES_NO,
        },
        ...[
          `The clients's self reports refl ect the objective data that is known`,
          'Client reports visual issues in response to the questioning',
          'Client does not deny or minimize his/her deficits',
          'Client acknowledges responsibility for circumstances',
        ].map((item, idx) => ({
          key: `p3Q${idx + 2}`,
          label: item,
          fieldType: 'RADIO' as FieldTypes,
          options: YES_NO,
        })),
      ],
    },
    {
      key: 'part4',
      label: 'Summary of Findings',
      fields: [
        {
          key: 'summaryOfFindings',
          label: 'Please check one (1) of the following:',
          fieldType: 'RADIO',
          options: [
            'This client is demonstrating awareness of the implications of his/her visual condition based on what is known at the time of this assessment.',
            'This client is not demonstrating awareness of the implications of his/her visual condition based on what is know at the time of this assessment.',
            `This client's awareness is indeterminate based on what is known at the time of this assessment 
            as not enough objective data was available to complete a true analysis of the client's skill in this
            area. Proceed with on-road testing if client passes other criteria for proceeding with on-road
            testing (not enough data to determine the client's skill in this area). On-road testing will be the best
            determinant of the client's awareness.`,
          ].map((item, id) => ({
            key: `summaryOption${id}`,
            val: `summaryOption${id}`,
            label: item,
          })),
        },
      ],
    },
  ],
};

export default metacognitionInClinicInterview;
