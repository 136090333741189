import React, { FC, useEffect, useState } from 'react';

import { CardContent, Grid, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import {
  ErrorComponent,
  LoadingComponent,
} from '../../../../errors/QueryUtils';
import { useCreateManeuverSummary } from '../../../../hooks/database/on-road/maneuverSummaries';
import useDefaultManeuvers from '../../../../hooks/database/settings/onRoadSettings';
import { FieldsLayout, IForm } from '../../../../models/forms';
import { IManeuverSummary } from '../../../../models/on-road/route';
import theme from '../../../../theme';
import FormContext from '../../../../utils/formContext';
import { getTranslationLabel } from '../../../../utils/utils';
import { SpinningDialog } from '../../../common/BasicDialog';
import FieldContainer from '../../../common/FieldContainer/FieldContainer';
import FormSection from '../../../common/FormSection';
import StyledListCard from '../../../common/StyledListCard';
import { ROAD_TYPE_OPTIONS } from '../../Constants/ON-ROAD-CONSTANTS';
import { RecordType } from '../utils';
import ManeuverCard from './ManeuverCard';

interface AllManeuversColumnProps {
  roadTestId: string;
}

const AllManeuversColumn: FC<AllManeuversColumnProps> = ({ roadTestId }) => {
  const { t } = useTranslation(allNamespaces);
  const { register, control, setValue, getValues, watch } = useForm({});

  const newManeuverForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  const addManeuverSummaryMutation = useCreateManeuverSummary(roadTestId);
  const defaultVisibleManeuvers = useDefaultManeuvers();

  const [visibleManeuvers, setVisibleManeuvers] = useState(
    defaultVisibleManeuvers.queryResult.data
      ? defaultVisibleManeuvers.queryResult.data.defaultTacticalManeuvers.split(
          ','
        )
      : []
  );

  useEffect(() => {
    if (defaultVisibleManeuvers.queryResult.data !== undefined) {
      setVisibleManeuvers(
        defaultVisibleManeuvers.queryResult.data.defaultTacticalManeuvers.split(
          ','
        )
      );
    }
  }, [defaultVisibleManeuvers.queryResult.data]);

  if (defaultVisibleManeuvers.queryResult.isLoading) {
    return <LoadingComponent />;
  }

  if (defaultVisibleManeuvers.queryResult.isError) {
    return <ErrorComponent error={defaultVisibleManeuvers.queryResult.error} />;
  }

  const handleCreateTask = (recordType: RecordType, maneuverType: string) => {
    // TODO: use a linked listed for the queue instead of position
    const maneuverId = nanoid();
    const maneuverSummaryToAdd: IManeuverSummary = {
      roadTestId,
      maneuverId,
      recordType,
      maneuverType,
      subManeuverType: '',
      refManeuverId: '',
      roadType: watch('roadType') || null,
      location: watch('locationField') || null,
      maneuverSummaryComments: '',
      nextManeuver: '',
      prevManeuver: '',
    };
    addManeuverSummaryMutation.mutate(maneuverSummaryToAdd);
  };

  const TASK_DETAILS: FieldsLayout[] = [
    {
      label: 'Select a Road Type',
      key: 'roadType',
      fieldType: 'CHOICE_CHIPS',
      options: ROAD_TYPE_OPTIONS,
    },
  ];

  return (
    <FormContext.Provider value={newManeuverForm}>
      <form>
        <Grid item container direction="column">
          <FormSection
            fields={TASK_DETAILS}
            values={watch()}
            sectionKey="taskDetails"
            namespace="onroad/routeBuilder"
            commentsPosition="none" // TODO: DO WE WANT COMMENTS HERE SO THE OT CAN PROVIDE ADDITIONAL INFO ABOUT THAT TASK?
          />
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <FieldContainer
                label="Location"
                namespace="onroad/routeBuilder"
                name="location"
                type="LABEL"
              />
              <FieldContainer
                label=""
                namespace="onroad/routeBuilder"
                name="locationField"
                type="INPUT"
              />
            </Stack>
          </Grid>
          <Grid item>
            <Typography>
              {getTranslationLabel('onroad/routeBuilder', 'selectAManeuver', t)}
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            {visibleManeuvers.map(
              (task: string) =>
                task && (
                  <Grid item key={task}>
                    <StyledListCard
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                      }}
                    >
                      <CardContent>
                        <ManeuverCard
                          taskType={task}
                          handleCreateTask={handleCreateTask}
                        />
                      </CardContent>
                    </StyledListCard>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <SpinningDialog
          isSpinning={addManeuverSummaryMutation.isLoading}
          messageKey="addingOnRoadManeuver"
        />
      </form>
    </FormContext.Provider>
  );
};
export default AllManeuversColumn;
