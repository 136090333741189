import React, { FC } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Tooltip, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';

interface RevokeConsentButtonProps {
  setIsRevokeModalOpen: (newState: boolean) => void;
}

export const RevokeConsentButton: FC<RevokeConsentButtonProps> = ({
  setIsRevokeModalOpen,
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Tooltip
          title={getTranslationLabel(
            'consent',
            'hasProvidedConsentAndFormUploaded',
            t
          )}
        >
          <CheckIcon color="success" />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Button color="primary" onClick={() => setIsRevokeModalOpen(true)}>
          {getTranslationLabel('consent', 'revoke', t)}
        </Button>
      </Grid>
    </Grid>
  );
};

interface ConsentActionRequiredButtonProps {
  setIsConsentDialogOpen: (newState: boolean) => void;
  colorVariant: string;
}

export const ConsentActionRequiredButton: FC<ConsentActionRequiredButtonProps> =
  ({ setIsConsentDialogOpen, colorVariant }) => {
    const { t } = useTranslation(allNamespaces);
    const theme = useTheme();

    const backgroundColor =
      colorVariant === 'main'
        ? theme.palette.error.main
        : theme.palette.error.dark;

    return (
      <Grid item>
        <Button
          onClick={() => setIsConsentDialogOpen(true)}
          style={{
            backgroundColor,
          }}
        >
          <Typography>
            {getTranslationLabel('consent', 'actionRequired', t)}
          </Typography>
        </Button>
      </Grid>
    );
  };

export const ConsentRevokedCell = () => {
  const { t } = useTranslation(allNamespaces);
  return (
    <Grid item xs={12}>
      <Tooltip
        title={getTranslationLabel('consent', 'clientDoesNotProvideConsent', t)}
      >
        <ClearIcon color="error" />
      </Tooltip>
    </Grid>
  );
};
