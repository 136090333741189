import React, { FC, useState } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { LoadingComponent, ErrorComponent } from '../../../errors/QueryUtils';
import {
  useListRefs,
  useManeuverSummaries,
} from '../../../hooks/database/on-road/maneuverSummaries';
import useModal from '../../../hooks/useModal';
import {
  IManeuverSummary,
  IOrderedManeuverSummary,
} from '../../../models/on-road/route';
import { getTranslationLabel } from '../../../utils/utils';
import AddedManeuverColumn from './AddedManeuversColumn/AddedManeuverColumn';
import AllManeuversColumn from './AllManeuversColumn/AllManeuversColumn';
import OpenManeuverColumn from './OpenManeuverColumn/OpenManeuverColumn';

interface TacticalManeuversMainProps {
  roadTestId: string;
}

const TacticalManeuversMain: FC<TacticalManeuversMainProps> = ({
  roadTestId,
}) => {
  const { t } = useTranslation(allNamespaces);
  const [openTask, setOpenTask] = useState<IOrderedManeuverSummary>();
  const { isShowing: isAllTasksColumnShowing, toggle: toggleAllTasksColumn } =
    useModal();
  const {
    isShowing: isAddedTasksColumnShowing,
    toggle: toggleAddedTasksColumn,
  } = useModal(true);

  const maneuverSummaries = useManeuverSummaries(roadTestId);
  const listRefs = useListRefs(roadTestId);

  if (maneuverSummaries.isLoading || listRefs.isLoading) {
    return <LoadingComponent />;
  }
  if (maneuverSummaries.isError || listRefs.isError) {
    return <ErrorComponent error={maneuverSummaries.error} />;
  }

  const findNextNode = (headRef: any) =>
    maneuverSummaries.data.find(
      (elem: any) => elem.refManeuverId === headRef.nextManeuver
    );

  const findHead = (headRef: string) =>
    maneuverSummaries.data.find((elem: any) => elem.refManeuverId === headRef);

  const sortManeuverSummaries = () => {
    if (maneuverSummaries.data.length === 0) return [];

    let idx: number = 1;
    const { headRef } = listRefs.data;
    let head: IManeuverSummary = findHead(headRef);
    const result: IOrderedManeuverSummary[] = [];

    while (head) {
      result.push({ idx, ...head });
      head = findNextNode(head);
      idx += 1;
    }

    return result;
  };

  const sortedManueverSummary = sortManeuverSummaries();

  const sizeOfAllTasks = () => (isAllTasksColumnShowing ? 4 : 1);

  const sizeOfAddedTasks = () => {
    if (isAddedTasksColumnShowing && !isAllTasksColumnShowing) {
      return 5.5;
    }
    if (isAllTasksColumnShowing) {
      return 4;
    }
    return 1;
  };

  const sizeOfOpenManeuver = () => (isAllTasksColumnShowing ? 4 : 5.5);

  return (
    <Grid item container spacing={5}>
      <Grid item container direction="column" xs={12} lg={sizeOfAllTasks()}>
        <Paper sx={{ p: 3, height: '100%' }}>
          <Grid item container direction="column">
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton onClick={toggleAllTasksColumn}>
                  {isAllTasksColumnShowing ? (
                    <ArrowBackIosIcon />
                  ) : (
                    <ArrowForwardIosIcon />
                  )}
                </IconButton>
                {isAllTasksColumnShowing && (
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/routeBuilder',
                      'addManeuversToRoute',
                      t
                    )}
                  </Typography>
                )}
              </Stack>
            </Grid>

            {isAllTasksColumnShowing && (
              <Grid item>
                <AllManeuversColumn roadTestId={roadTestId} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item container direction="column" xs={12} lg={sizeOfAddedTasks()}>
        <Paper sx={{ p: 3, height: '100%' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton onClick={toggleAddedTasksColumn}>
              {isAddedTasksColumnShowing ? (
                <ArrowBackIosIcon />
              ) : (
                <ArrowForwardIosIcon />
              )}
            </IconButton>
            {isAddedTasksColumnShowing && (
              <Typography variant="h2">
                {getTranslationLabel('navBar', 'route', t)}
              </Typography>
            )}
          </Stack>

          {isAddedTasksColumnShowing && (
            <AddedManeuverColumn
              sortedManueverSummary={sortedManueverSummary}
              openTask={openTask}
              handleOpenTask={setOpenTask}
            />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} lg={sizeOfOpenManeuver()}>
        {openTask && (
          <Paper sx={{ p: 3, height: '100%' }}>
            <OpenManeuverColumn
              key={openTask.maneuverId}
              maneuverSummaries={sortedManueverSummary}
              handleOpenTask={setOpenTask}
              openManeuver={openTask}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default TacticalManeuversMain;
