const storageAccountURL = `https://${process.env.REACT_APP_STORAGE_ACCOUNT_NAME}.dfs.core.windows.net/${process.env.REACT_APP_STORAGE_FILESYSTEM_NAME}`;

export async function uploadFileToStorage(
  accessToken: string,
  username: string,
  file: File,
  fileName: string,
  size: number
): Promise<Response> {
  const header = new Headers();
  const bearer = `Bearer ${accessToken}`;

  header.append('Authorization', bearer);
  const path = `${storageAccountURL}/${username}/${fileName}`;

  const createOptions = {
    method: 'PUT',
    headers: header,
  };

  const appendOptions = {
    method: 'PATCH',
    headers: header,
    body: file,
  };

  const flushOptions = {
    method: 'PATCH',
    headers: header,
    body: '',
  };

  return new Promise((fulfill, reject) => {
    fetch(`${path}?resource=file`, createOptions)
      .then(() => fetch(`${path}?action=append&position=0`, appendOptions))
      .then(() => fetch(`${path}?action=flush&position=${size}`, flushOptions))
      .then((response) => fulfill(response))
      .catch((error) => reject(error));
  });
}

export async function getFileFromStorage(
  accessToken: string,
  username: string,
  fileName: string
): Promise<Response> {
  const header = new Headers();
  const bearer = `Bearer ${accessToken}`;

  header.append('Authorization', bearer);
  const path = `${storageAccountURL}/${username}/${fileName}`;

  const createOptions = {
    method: 'GET',
    headers: header,
  };

  return new Promise((fulfill, reject) => {
    fetch(`${path}`, createOptions)
      .then((response) => fulfill(response))
      .catch((err) => reject(err));
  });
}

export async function deleteFileFromStorage(
  accessToken: string,
  username: string,
  fileName: string
): Promise<Response> {
  const header = new Headers();
  const bearer = `Bearer ${accessToken}`;

  header.append('Authorization', bearer);
  const path = `${storageAccountURL}/${username}/${fileName}`;

  const createOptions = {
    method: 'DELETE',
    headers: header,
  };

  return new Promise((fulfill, reject) => {
    fetch(`${path}`, createOptions)
      .then((response) => fulfill(response))
      .catch((err) => reject(err));
  });
}
