import { FieldsLayout, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const DTVP_SUBSCALE_FIELDS: FieldsLayout[] = [
  'Eye-hand coordination',
  'Copying',
  'Figure-ground',
  'Visual closure',
  'Form constancy',
].map((item) => ({
  key: `${convertLabelToKey(item)}Score`,
  label: `${item} score`,
  fieldType: 'INPUT-NUM',
}));

const DTVP_RESULTS_FIELDS: FieldsLayout[] = [
  'Visual motor integration (VMI)',
  'Motor-Reduced visual perception',
  'General visual perception',
].map((item) => ({
  key: `${convertLabelToKey(item)}Score`,
  label: item,
  fieldType: 'INPUT-NUM',
}));

const developmentTestOfVisualPerception: MainHeadingItem = {
  key: 'developmentTestOfVisualPerception',
  label: 'Development Test of Visual Perception (DTVP-3)',
  subheadings: [
    {
      label: 'Subscale scores',
      key: 'subscaleScores',
      fields: DTVP_SUBSCALE_FIELDS,
    },
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: [...DTVP_RESULTS_FIELDS, ...Results],
    },
  ],
};

export default developmentTestOfVisualPerception;
