import { Conditional } from '../../../models/forms';

const otherDriversInHousehold = (values: any) =>
  values.otherDriversInHousehold === 'yes';
const currentlyWorking = (values: any) =>
  values.vocationalStatusWorking === true ||
  values.vocationalStatusWorking === '1' ||
  values.vocationalStatusVolunteer === true ||
  values.vocationalStatusVolunteer === '1' ||
  values.vocationalStatusOther === true ||
  values.vocationalStatusOther === '1' ||
  values.vocationalStatusStudent === true ||
  values.vocationalStatusStudent === '1';
const currentlyWorkingOther = (values: any) =>
  values.vocationalStatusOther === true || values.vocationalStatusOther === '1';

export const OTHER_DRIVERS_CONDITIONAL: Conditional[] = [
  {
    key: 'howManyDrivers',
    label: 'How many total drivers?',
    fieldType: 'INPUT-NUM',
    condition: otherDriversInHousehold,
  },
];

export const CURRENTLY_WORKING_CONDITIONAL: Conditional[] = [
  {
    key: 'workingOther',
    label: 'Please specify vocational status',
    fieldType: 'TEXT-AREA',
    condition: currentlyWorkingOther,
  },
  {
    key: 'workingHoursPerWeek',
    label: 'How many hours per week?',
    fieldType: 'INPUT-NUM',
    condition: currentlyWorking,
  },
];
