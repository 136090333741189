import React, { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IForm } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';
import PrimitiveField from '../../common/FieldContainer/PrimitiveField';

export interface DeleteClientDialogProps {
  clientId: string;
  handleDeleteClient: (enteredClientId: string) => void;
  setIsDeleteClientDialogOpen: (newState: boolean) => void;
}

export const DeleteClientDialog: FC<DeleteClientDialogProps> = ({
  clientId,
  handleDeleteClient,
  setIsDeleteClientDialogOpen,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { register, control, watch, setValue, getValues } = useForm();
  const deleteClientDialogForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  return (
    <BasicDialog
      open
      handleClose={() => {}}
      label={
        <Typography variant="h2" component="span">
          {getTranslationLabel('clientsGeneral', 'deleteClientTitle', t)}
        </Typography>
      }
      maxWidth="sm"
      actions={
        <>
          <Button onClick={() => setIsDeleteClientDialogOpen(false)}>
            {getTranslationLabel('generalNs', 'cancel', t)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={() => handleDeleteClient(watch('enterClientsId'))}
          >
            {getTranslationLabel('clientsGeneral', 'delete', t)}
          </Button>
        </>
      }
    >
      <FormContext.Provider value={deleteClientDialogForm}>
        <form>
          <Typography sx={{ paddingBottom: '20px' }}>
            {getTranslationLabel('clientsGeneral', 'deletePrompt1', t)}
            {clientId}
            {'? '}
            {getTranslationLabel('clientsGeneral', 'deletePrompt2', t)}
          </Typography>
          <PrimitiveField
            label="Enter client's id"
            name="enterClientsId"
            namespace="clientsGeneral"
            type="INPUT"
          />
        </form>
      </FormContext.Provider>
    </BasicDialog>
  );
};
