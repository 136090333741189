import React, { FC } from 'react';

import { Grid, CircularProgress } from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { useAssessmentSummaries } from '../../hooks/database/assessments/assessmentSummaries';
import { getTranslationLabel } from '../../utils/utils';
import { PaperHeader } from '../common/PaperHeader';
import AssessmentsMainPane from './AssessmentsMainPane';
import AssessmentsSideBar from './AssessmentsSideBar';

export interface AssessmentsProps {
  clientId: string;
}

export const Assessments: FC<AssessmentsProps> = ({ clientId }) => {
  const { t } = useTranslation(allNamespaces);
  const {
    isLoading,
    error,
    data: assessmentSummaries,
  } = useAssessmentSummaries(clientId);
  useErrorHandler(error);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid item container direction="column" justifyContent="center" spacing={3}>
      <Grid item>
        <PaperHeader
          title={getTranslationLabel(
            'assessmentsGeneral/assessClient',
            'clinicalAssessmentTools',
            t
          )}
          clientId={clientId}
          backLink="/clients"
        />
      </Grid>
      <Grid item container direction="row">
        <AssessmentsSideBar
          clientId={clientId}
          assessmentSummaries={assessmentSummaries}
        />
        <AssessmentsMainPane
          clientId={clientId}
          assessmentSummaries={assessmentSummaries}
        />
      </Grid>
    </Grid>
  );
};
