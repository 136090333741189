import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import allNamespaces from '../allNamespaces';
import {
  lowercaseFirstLetter,
  getKeyFromLabel,
} from '../components/assessments/AssessmentCard/AssessmentCard-utils';
import { getTranslationLabel } from '../utils/utils';

const useAlert = (open = false, variant = '', message = '') => {
  const { t } = useTranslation(allNamespaces);
  const [alertState, setAlertState] = useState({ open, variant, message });

  const noAlert = {
    open: false,
    variant: '',
    message: '',
  };

  const handleCloseAlert = () => {
    setAlertState({ ...noAlert });
  };

  const showAlert = useCallback(
    (alertVariant: string, alertMessage: string) => {
      const alertKey = lowercaseFirstLetter(
        getKeyFromLabel(alertMessage).slice(-20)
      ).replace(/[!/]/g, '');
      setAlertState({
        open: true,
        variant: alertVariant,
        message: getTranslationLabel('alerts', alertKey, t),
      });
    },
    []
  );

  return {
    alertState,
    handleCloseAlert,
    showAlert,
  };
};

export default useAlert;
