import React, { FC } from 'react';

import { Box, Fade, useScrollTrigger } from '@mui/material';

interface ScrollProps {}

const Scroll: FC<ScrollProps> = ({ children }) => {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 80,
  });

  return (
    <Fade in={trigger}>
      <Box
        role="presentation"
        sx={{ position: 'fixed', top: 20, right: 30, zIndex: 'fab' }}
      >
        {children}
      </Box>
    </Fade>
  );
};
export default Scroll;
