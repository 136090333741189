import {
  axiosGetCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const accountKey = ['account'] as const;

const accountUrl = (username: string) => `/api/account/${username}/all`;

export const useAccount = (username: string) =>
  useQueryWithToken(accountKey, (token: string) =>
    axiosGetCall(accountUrl(username), token)
  );

export const useUpdateAccount = () =>
  useMutationWithToken(accountKey, (account: any, token: string) =>
    axiosPutCall(accountUrl(account.username), account, token)
  );
