import { FieldsLayout, SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  getCommonSignallingFields,
  OPERATIONAL_ERRORS_STOP_OPTIONS,
  TASK_SPEED_OPTIONS,
} from './utils';

const TYPE_OF_RIGHT_TURNS = [
  'Traffic light: plain green',
  'Traffic light: green arrow',
  'Traffic light: yellow',
  'Traffic light: red',
  'Stop sign: 2-way (facing)',
  'Stop sign: 2-way (perpendicular)',
  'Stop sign: all-way',
  'Yield',
  'No sign or traffic light',
];

const TYPE_OF_LEFT_TURNS = [
  'Traffic light: plain green',
  'Traffic light: green arrow',
  'Traffic light: flashing green',
  'Traffic light: yellow',
  'Stop sign: 2-way (facing)',
  'Stop sign: 2-way (perpendicular)',
  'Stop sign: all-way',
  'Yield',
  'No Sign  or traffic light',
];

const getTurnFields = (side: string): FieldsLayout[] => [
  {
    label: 'Adjustment to stimuli / traffic signs',
    key: `${side}TurnAdjustmentToStimuliTrafficSigns`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: [
      ...(side === 'right'
        ? getOptionsFromLabels([
            'Made turn when prohibited',
            'Made turn on red when prohibited',
            'Failed to initiate turn on red when safe to do so',
          ])
        : getOptionsFromLabels(['Made turn when prohibited'])),
    ],
  },
  {
    label: 'Speed',
    key: `${side}TurnSpeed`,
    fieldType: 'CHOICE_CHIPS',
    options: [...TASK_SPEED_OPTIONS, ...OPERATIONAL_ERRORS_STOP_OPTIONS],
  },
  {
    label: 'Gap Acceptance',
    key: `${side}TurnGapAcceptance`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Turned with insufficient time / space',
      'Excessive hesitation / delay in proceeding when way was clear',
      'Failed to turn when way was clear / no hazards',
    ]),
  },
  {
    label: 'Lane Maintenance',
    key: `${side}TurnLaneMaintenance`,
    fieldType: 'MULTISELECT_CHOICE_CHIPS',
    options: getOptionsFromLabels([
      'Drifted wide',
      'Cut corner on turn',
      'Turned into incorrect lane',
      'Turned into an incorrect lane - creating conflict with other vehicle',
    ]),
  },
  {
    label: 'Visual Scanning',
    key: `${side}VisualScanning`,
    fieldType: 'CHECKBOX',
    options: getOptionsFromLabels([
      'Failed to scan for pedestrians',
      'Turned with limited vision / poor line of sight of oncoming traffic',
      'Missed blindspot check',
    ]),
  },
  ...getCommonSignallingFields(`${side}TurnSignalling`),
];

const getTurnMeta = (side: string): FieldsLayout[] => [
  {
    label: 'Type of Turn',
    key: `subManeuverType`,
    fieldType: 'CHOICE_CHIPS',
    options: getOptionsFromLabels(
      side === 'right' ? TYPE_OF_RIGHT_TURNS : TYPE_OF_LEFT_TURNS
    ),
  },
];

export const LEFT_TURN_META: SubHeadingItem = {
  key: 'leftTurn',
  label: 'Left Turn',
  fields: getTurnMeta('left'),
};
export const RIGHT_TURN_META: SubHeadingItem = {
  key: 'rightTurn',
  label: 'Right Turn',
  fields: getTurnMeta('right'),
};

export const LEFT_TURN_ERRORS: SubHeadingItem = {
  key: 'leftTurn',
  label: 'Left Turn',
  fields: getTurnFields('left'),
};

export const RIGHT_TURN_ERRORS: SubHeadingItem = {
  key: 'rightTurn',
  label: 'Right Turn',
  fields: getTurnFields('right'),
};
