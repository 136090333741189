import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  PhysicalResults,
  RESULTS_CATEGORIES,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const SIT_TO_STAND_COLUMN_HEADINGS = ['', 'Sit to stand', 'Stand to sit'];
const SIT_TO_STAND_ROW_TITLES = ['Result', 'Comments'];

export const TRANSFER_OPTIONS = getOptionsFromLabels([
  'Independent',
  'Dependent',
  'Assisted',
  'Transfer board',
  'Stand pivot',
  'Squat pivot',
  'Mechanical lift',
  'Remains in mobility aid',
  'Other (specify in comments)',
]);

const getSitToStandRowSpec = (key: string) => [
  {
    rowTitle: 'Result',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: TRANSFER_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];

const QUICK_BALANCE_SCREENS_COLUMN_HEADINGS = [
  '',
  'Rapid pace walk',
  'Timed up and go',
  'Tandem walk',
];
const QUICK_BALANCE_SCREENS_ROW_TITLES = [
  'Time (s)',
  'Aid used',
  'Result',
  'Comments',
];

export const MOBILITY_AIDS = getOptionsFromLabels([
  'Simple cane',
  'Quad cane',
  'Two-wheel walker',
  'Four-wheel walker',
  'Manual wheelchair',
  'Power wheelchair',
  'Forearm crutches',
  'Scooter',
  'Other (specify in comments)',
]);

const getQuickBalanceScreensRowSpec = (key: string) => [
  {
    rowTitle: 'Time (s)',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
  {
    rowTitle: 'Aid used',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: [
        ...MOBILITY_AIDS.slice(0, 4),
        ...getOptionsFromLabels(['Other (specify in comments)']),
      ],
      size: 200,
    },
  },
  {
    rowTitle: 'Result',
    element: {
      key,
      label: '',
      fieldType: 'RADIO' as FieldTypes,
      options: RESULTS_CATEGORIES,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];

const PROSTHETICS_COLUMN_HEADINGS = [
  '',
  'Upper right prosthetic',
  'Upper left prosthetic',
  'Lower right prosthetic',
  'Lower left prosthetic',
];
const PROSTHETICS_ROW_TITLES = [
  'Type',
  'Mechanical or myoelectric?',
  'Used for driving?',
  'On while driving?',
];

const UPPER_PROSTHETICS_OPTIONS = getOptionsFromLabels([
  'Transradial',
  'Transhumeral',
  'Other (specify in comments)',
]);

const LOWER_PROSTHETICS_OPTIONS = getOptionsFromLabels([
  'Transtibial',
  'Transfemoral',
  'AFO',
  'Other (specify in comments)',
]);

const getProstheticsRowSpec = (key: string, columnHeading: string) => [
  {
    rowTitle: 'On while driving?',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  },
  {
    rowTitle: 'Used for driving?',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  },
  {
    rowTitle: 'Mechanical or myoelectric?',
    element: {
      key,
      label: '',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: getOptionsFromLabels(['Mechanical', 'Myoelectric']),
    },
  },
  {
    rowTitle: 'Type',
    element: {
      key,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options:
        columnHeading === 'Upper right prosthetic' ||
        columnHeading === 'Upper left prosthetic'
          ? UPPER_PROSTHETICS_OPTIONS
          : LOWER_PROSTHETICS_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];
const TYPES_OF_GAIT = [
  'Normal',
  'Unsteady',
  'Hemiparetic',
  'Hemiplegic',
  'Circumductive',
  'Shuffling',
  'Broad-based',
  'Narrow-based',
  'Antalgic (painful)',
  'Ataxia',
  'Spastic',
  'Neuropathic',
  'Myopathic',
  'Waddling',
  'Choreiform',
  'Hyperkinetic',
  'Parkinsonian',
  'Festinating',
  'Propulsive',
  'NA',
  'Other (specify in comments)',
];

const MOBILITY_AIDS_COLUMN_HEADINGS = [
  '',
  'Mobility aid 1',
  'Mobility aid 2',
  'Mobility aid 3',
];
const MOBILITY_AIDS_ROW_TITLES = ['Type of aid', 'Result', 'Comments'];
const getMobilityAidsRowSpec = (key: string) => [
  {
    rowTitle: 'Type of aid',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: MOBILITY_AIDS,
      size: 200,
    },
  },
  {
    rowTitle: 'Result',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'SELECT' as FieldTypes,
      options: TRANSFER_OPTIONS,
      size: 200,
    },
  },
  {
    rowTitle: 'default',
    element: {
      key: `${key}Transfer`,
      label: '',
      fieldType: 'TEXT-AREA' as FieldTypes,
      size: 200,
    },
  },
];

const BALANCE_SUBHEADINGS = ['Sitting Balance', 'Standing Balance'];

const mobilityAndBalance: MainHeadingItem = {
  key: 'mobilityAndBalance',
  label: 'Mobility and Balance',
  subheadings: [
    {
      key: 'sitToStandTransfer',
      label: 'Sit to Stand / Stand to Sit Transfers',
      fields: [
        {
          key: 'sitToStandTable',
          label: '',
          fieldType: 'TABLE',
          columnHeadings: SIT_TO_STAND_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            SIT_TO_STAND_COLUMN_HEADINGS,
            SIT_TO_STAND_ROW_TITLES,
            getSitToStandRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      key: 'transfersOnAndOffMobilityAids',
      label: 'Transfers On and Off Mobility Aids',
      fields: [
        {
          key: 'transfersOnAndOffMobilityAidsTable',
          label: '',
          fieldType: 'TABLE',
          columnHeadings: MOBILITY_AIDS_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            MOBILITY_AIDS_COLUMN_HEADINGS,
            MOBILITY_AIDS_ROW_TITLES,
            getMobilityAidsRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      key: 'gaitSection',
      label: 'Gait',
      fields: [
        {
          key: 'gait',
          label: 'Gait',
          fieldType: 'SELECT',
          options: getOptionsFromLabels(TYPES_OF_GAIT),
        },
      ],
    },
    {
      key: 'quickBalanceScreens',
      label: 'Quick Balance Screens',
      fields: [
        {
          key: 'quickBalanceScreensTable',
          label: '',
          fieldType: 'TABLE',
          columnHeadings: QUICK_BALANCE_SCREENS_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            QUICK_BALANCE_SCREENS_COLUMN_HEADINGS,
            QUICK_BALANCE_SCREENS_ROW_TITLES,
            getQuickBalanceScreensRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      key: 'overallBalanceAssessment',
      label: 'Overall Balance Assessment',
      fields: BALANCE_SUBHEADINGS.map((item) => ({
        key: `${convertLabelToKey(item)}Result`,
        label: item,
        fieldType: 'RADIO' as FieldTypes,
        options: RESULTS_CATEGORIES,
      })),
    },
    {
      key: 'useOfProstheticsAndOrthotics',
      label: 'Use of Prosthetics and Orthotics',
      fields: [
        {
          key: 'useOfProstheticsAndOrthoticsTable',
          label: '',
          fieldType: 'TABLE',
          columnHeadings: PROSTHETICS_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            PROSTHETICS_COLUMN_HEADINGS,
            PROSTHETICS_ROW_TITLES,
            getProstheticsRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: PhysicalResults,
    },
  ],
};

export default mobilityAndBalance;
