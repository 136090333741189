import React, { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';

const PREFIX = 'Search';

const classes = {
  searchBoxPosition: `${PREFIX}-searchBoxPosition`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputInput: `${PREFIX}-inputInput`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.searchBoxPosition}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '7px',
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    border: '1px solid',
    padding: '1px 0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputInput}`]: {
    paddingLeft: `calc(1em + ${theme.spacing(5)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

interface ClientSearchProps {
  searchQueryText: string;
  onChangeSearchQueryText: (value: string) => void;
}

const ClientSearch: FC<ClientSearchProps> = ({
  searchQueryText,
  onChangeSearchQueryText,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <StyledGrid>
      <Toolbar className={classes.searchBoxPosition}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchQueryText}
            onChange={(e) => onChangeSearchQueryText(e.target.value)}
            type="text"
            placeholder={getTranslationLabel('clientsGeneral', 'search', t)}
            classes={{ input: classes.inputInput }}
          />
        </div>
      </Toolbar>
    </StyledGrid>
  );
};

export default ClientSearch;
