import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';

const oneLegStance: SubHeadingItem = {
  label: 'One Leg Stance',
  key: 'oneLegStance',
  fields: [
    {
      label: 'Eyes open',
      key: 'timeWithEyesOpen',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 10 Seconds</Typography>,
    },
    {
      label: 'Eyes closed',
      key: 'timeWithEyesClosed',
      fieldType: 'INPUT-NUM',
      units: <Typography>/ 10 Seconds</Typography>,
    },
    ...Results,
  ],
};

export default oneLegStance;
