import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://www.physio-pedia.com/Scale_for_the_Assessment_and_Rating_of_Ataxia_(SARA)
export const SARA_CATEGORIES = [
  { title: 'Gait', points: 8 },
  { title: 'Stance', points: 6 },
  { title: 'Sitting', points: 4 },
  { title: 'Speech distrubance', points: 6 },
  { title: 'Finger chase', points: 4 },
  { title: 'Nose-finger test', points: 4 },
  { title: 'Fast alternating hand movement', points: 4 },
  { title: 'Heel-shin slide', points: 4 },
];

const SARA_HEADINGS_1: SubHeadingItem[] = SARA_CATEGORIES.slice(0, 4).map(
  (item) => ({
    key: `${convertLabelToKey(item.title)}Section`,
    label: item.title,
    fields: [
      {
        key: `${convertLabelToKey(item.title)}Score`,
        label: `${item.title} score`,
        fieldType: 'INPUT-NUM',
        units: <Typography>/ {item.points}</Typography>,
      },
    ],
  })
);

const SARA_HEADINGS_2: SubHeadingItem[] = SARA_CATEGORIES.slice(4, 8).map(
  (item) => ({
    key: `${convertLabelToKey(item.title)}Section`,
    label: item.title,
    fields: [
      {
        key: `${convertLabelToKey(item.title)}LeftScore`,
        label: 'Left score',
        fieldType: 'INPUT-NUM',
        units: <Typography>/ {item.points}</Typography>,
      },
      {
        key: `${convertLabelToKey(item.title)}RightScore`,
        label: 'Right score',
        fieldType: 'INPUT-NUM',
        units: <Typography>/ {item.points}</Typography>,
      },
      {
        key: `${convertLabelToKey(item.title)}MeanScore`,
        label: 'Mean score',
        fieldType: 'INPUT-NUM',
        units: <Typography>/ {item.points}</Typography>,
        valueDependsOn: [
          `${convertLabelToKey(item.title)}LeftScore`,
          `${convertLabelToKey(item.title)}RightScore`,
        ],
      },
    ],
  })
);

const scaleForTheAssessmentAndRatingOfAtaxia: MainHeadingItem = {
  key: 'scaleForTheAssessmentAndRatingOfAtaxia',
  label: 'Scale for the Assessment and Rating of Ataxia (SARA)',
  subheadings: [
    ...SARA_HEADINGS_1,
    ...SARA_HEADINGS_2,
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          label: 'Total score',
          key: 'totalScore',
          fieldType: 'INPUT-SWITCH',
          units: <Typography>/ 40 </Typography>,
          valueDependsOn: [
            ...SARA_CATEGORIES.slice(0, 4).map(
              (item) => `${convertLabelToKey(item.title)}Score`
            ),
            ...SARA_CATEGORIES.slice(4, 8).map(
              (item) => `${convertLabelToKey(item.title)}MeanScore`
            ),
          ],
        },
        {
          label: 'Classification',
          key: 'classification',
          fieldType: 'SCALE',
          options: [
            { key: 'mildAtaxia', val: 'mildAtaxia', label: 'Mild ataxia' },
            {
              key: 'moderateAtaxia',
              val: 'moderateAtaxia',
              label: 'Moderate ataxia',
            },
            {
              key: 'severeAtaxia',
              val: 'severeAtaxia',
              label: 'Severe ataxia',
            },
          ],
        },
        ...Results,
      ],
    },
  ],
};

export default scaleForTheAssessmentAndRatingOfAtaxia;
