import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { websiteNamespaces } from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import { containerWidth, gray, medium } from '../constants';
import icon1 from '../img/driveeval-icon-1-pad.png';
import icon2 from '../img/driveeval-icon-2-calculator.png';
import icon3 from '../img/driveeval-icon-3-car.png';
import icon4 from '../img/driveeval-icon-4-paper.png';
import Banner from './Helpers/Banner';
import Icons from './Helpers/Icons';

const iconPaneData = [
  {
    id: 1,
    icon: icon1,
  },
  {
    id: 2,
    icon: icon2,
  },
  {
    id: 3,
    icon: icon3,
  },
  {
    id: 4,
    icon: icon4,
  },
];

const additionalFeatures = [
  {
    id: 1,
    icon: icon1,
  },
  {
    id: 2,
    icon: icon2,
  },
  {
    id: 3,
    icon: icon3,
  },
  {
    id: 4,
    icon: icon4,
  },
];

const DriveEval = () => {
  const { t } = useTranslation(websiteNamespaces);
  const largeScreen = useMediaQuery('(min-width:900px)');

  return (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth}%`,
      }}
    >
      <Banner
        text={getTranslationLabel(
          'website/driveeval',
          'driveEvalWebBasedApp',
          t
        )}
        variant="h2"
        padding="1%"
      />

      <Banner
        text={getTranslationLabel('website/driveeval', 'driveEvalFeatures', t)}
        variant="h3"
        padding="1%"
      />
      <Banner
        text={getTranslationLabel(
          'website/driveeval',
          'theAppIncludesTheAbility',
          t
        )}
        variant="h3"
        padding="1%"
      />

      <Icons iconData={iconPaneData} namespace="website/driveeval" />

      <Banner
        text={getTranslationLabel('website/driveeval', 'additionalFeatures', t)}
        variant="h3"
        padding="1%"
      />

      {additionalFeatures.map((item) => (
        <Grid
          container
          padding="1%"
          alignItems={largeScreen ? 'top' : 'center'}
        >
          <Grid item xs={1}>
            <CheckIcon sx={{ fontSize: medium, color: gray }} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h5" color={gray}>
              {getTranslationLabel(
                'website/driveeval',
                `additionalFeature${item.id}`,
                t
              )}
            </Typography>
          </Grid>
        </Grid>
      ))}

      <Link to="/contact" component={NavLink}>
        <Banner
          text={getTranslationLabel('website/driveeval', 'requestADemo', t)}
          variant="h3"
          padding="1%"
        />
      </Link>
    </Container>
  );
};

export default DriveEval;
