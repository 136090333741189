import React, { FC } from 'react';

import { useMsal } from '@azure/msal-react';
import { Button, Grid } from '@mui/material';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

import AsyncErrorWrapper from '../../errors/AsyncErrorWrapper';
import { useCreateClient } from '../../hooks/database/clients/clients';
import { IClient, blankClient } from '../../models/client';
import { SpinningDialog } from '../common/BasicDialog';

interface NewClientButtonProps {
  text: string;
  variant: 'text' | 'outlined' | 'contained';
  size: 'small' | 'medium' | 'large';
  icon: React.ReactNode;
}

const NewClientButton: FC<NewClientButtonProps> = ({
  text,
  variant,
  size,
  icon,
}) => {
  const { accounts } = useMsal();
  const history = useHistory();
  const newCId = nanoid();

  const createClientMutation = useCreateClient(accounts[0].username);

  const initiateNewClientObject = (): IClient => ({
    clientId: newCId,
    username: accounts[0].username,
    ...blankClient,
  });

  const handleCreateClient = async () => {
    const newClient = initiateNewClientObject();
    await createClientMutation.mutateAsync(newClient);
    history.push(`/clients/new/${newCId}/category0`);
  };

  return (
    <AsyncErrorWrapper mutation={createClientMutation}>
      <Grid item style={{ paddingRight: '6px' }}>
        <Button
          color="secondary"
          variant={variant}
          size={size}
          aria-label="add"
          startIcon={icon}
          onClick={() => handleCreateClient()}
        >
          {text}
        </Button>
        <SpinningDialog
          isSpinning={createClientMutation.isLoading}
          messageKey="creatingClient"
        />
      </Grid>
    </AsyncErrorWrapper>
  );
};

export default NewClientButton;
