import React, { FC } from 'react';

import { CircularProgress, Grid, GridSize } from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';

import AsyncErrorWrapper from '../../../errors/AsyncErrorWrapper';
import useAssessmentOptions from '../../../hooks/database/assessments/assessmentOptions';
import {
  useCreateSelectedAssessment,
  useDeleteSelectedAssessment,
  useSelectedAssessments,
} from '../../../hooks/database/settings/assessmentSettings';
import { IAssessmentSummary } from '../../../models/assessments/assessment-summaries';
import { IOption } from '../../../models/forms';
import { useUsername } from '../../../utils/utils';
import { compareAssessmentGroupOrder } from '../../assessments/common';
import CheckboxSection from '../CheckboxSection';

interface AssessmentOptionsListProps {
  colSize?: GridSize;
}

/* A list of all of the possible assessment options, with checked items for assessments in the users list */
const AssessmentOptionsList: FC<AssessmentOptionsListProps> = ({ colSize }) => {
  const username = useUsername();

  // All assessment options
  const { isLoading, error, data: assessmentOptions } = useAssessmentOptions();
  useErrorHandler(error);

  // Selected Assessments
  const {
    isLoading: isSelectedLoading,
    error: useSelectedError,
    data: selectedAssessments,
  } = useSelectedAssessments(username);
  useErrorHandler(useSelectedError);
  const selecteAssessmentMutation = useCreateSelectedAssessment(username);
  const deleteAssessmentMutation = useDeleteSelectedAssessment(username);

  if (isLoading || isSelectedLoading) {
    return <CircularProgress />;
  }

  // Get the predefined groups from all assessments
  const groups = assessmentOptions?.reduce(
    (groupsSoFar: any, item: any) => ({
      // TODO: type for groupsSoFar
      ...groupsSoFar,
      [item.categoryKey]: {
        label: item.categoryLabel,
        items: [...(groupsSoFar[item.categoryKey]?.items || []), item],
      },
    }),
    {}
  );

  // select / deselect an assessment from the assessment list
  const handleChangeSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      selecteAssessmentMutation.mutate({ assessmentKey: event.target.name });
    } else {
      deleteAssessmentMutation.mutate(event.target.name);
    }
  };

  // Select / deselect an entire section
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      groups[event.target.name].items.forEach((assessment: any) => {
        if (
          selectedAssessments?.find(
            (selectedAssessment: any) =>
              selectedAssessment.assessmentKey === assessment.assessmentKey
          )
        ) {
          return;
        }
        selecteAssessmentMutation.mutate({
          assessmentKey: assessment.assessmentKey,
        });
      });
    } else {
      groups[event.target.name].items.map((assessment: any) =>
        deleteAssessmentMutation.mutate(assessment.assessmentKey)
      );
    }
  };

  return (
    <AsyncErrorWrapper mutation={selecteAssessmentMutation}>
      <AsyncErrorWrapper mutation={deleteAssessmentMutation}>
        {Object.keys(groups)
          .sort((group1, group2) => compareAssessmentGroupOrder(group1, group2))
          .map((key) => {
            const groupItems = groups[key].items;
            const loPossibleItems: IOption[] = groups[key].items.map(
              (assessment: any) => ({
                key: assessment.assessmentKey,
                val: assessment.assessmentKey,
                label: assessment.label,
              })
            );
            const loCheckedItems = selectedAssessments
              ? selectedAssessments.map(
                  (assessment: any) => assessment.assessmentKey
                )
              : [];

            const loCheckedItemsInGroup = groupItems.reduce(
              (itemsSoFar: IAssessmentSummary[], item: IAssessmentSummary) =>
                loCheckedItems.includes(item.assessmentKey)
                  ? [...itemsSoFar, item.assessmentKey]
                  : itemsSoFar,
              []
            );
            return (
              <Grid item xs={colSize || 12} key={key}>
                <CheckboxSection
                  sectionLabel={groups[key].label}
                  sectionKey={key}
                  loPossibleItems={loPossibleItems}
                  loCheckedItems={loCheckedItemsInGroup}
                  onChangeItem={handleChangeSelection}
                />
              </Grid>
            );
          })}
      </AsyncErrorWrapper>
    </AsyncErrorWrapper>
  );
};

export default AssessmentOptionsList;
