import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, getSpeedFields } from './utils';

const VISUAL_OPTIONS = getOptionsFromLabels([
  'Missed blindspot check',
  'Failed to check mirrors',
]);
const GAP_ACCEPTANCE = getOptionsFromLabels([
  'Passed with insufficient time / space',
  'Excessive hesitation / delay in proceeding when way was clear',
  'Failed to pass when way was clear / no hazards',
]);
const LANE_MAINTENANCE = getOptionsFromLabels([
  'Sharp turn into passing lane',
  'Sharp turn back into original lane',
]);
const TYPE_OF_ROAD = getOptionsFromLabels([
  'Two-lane road way',
  'Multi-lane road way',
  'Designated passing lane',
]);
const ADJUSTMENT_TO_STIMULI_SIGNS_PASSING = getOptionsFromLabels([
  'Passed when illegal',
  'Passed on the right',
  'Failed to notice the oncoming vechile',
  'Passed too many vechiles at once',
  'Passed vechile that was travelling near the speed limit',
]);

export const PASSING_META: SubHeadingItem = {
  key: 'passing',
  label: 'Passing Meta',
  fields: [
    {
      label: 'Type of Road',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: TYPE_OF_ROAD,
    },
  ],
};

export const PASSING_ERRORS: SubHeadingItem = {
  key: 'passing',
  label: 'Passing',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'passingAdjustmentToStimuliTrafficSigns',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_PASSING,
    },
    ...getSpeedFields('passing'),
    {
      label: 'Lane Maintenance',
      key: 'passingLaneMaintenance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: LANE_MAINTENANCE,
    },
    {
      label: 'Gap Acceptance',
      key: 'passingGapAcceptance',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: GAP_ACCEPTANCE,
    },
    {
      label: 'Visual Scanning',
      key: 'passingVisual',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: VISUAL_OPTIONS,
    },
    ...getCommonSignallingFields('passing'),
  ],
};
