import React from 'react';

import { Typography } from '@mui/material';

import { IOption, FieldsLayout, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
  getNumbersXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const BERG_SCORES_OPTIONS: IOption[] = getNumbersXToY(0, 4).map((score) => ({
  key: convertLabelToKey(score.toString()),
  val: convertLabelToKey(score.toString()),
  label: score.toString(),
}));

// https://www.physio-pedia.com/Berg_Balance_Scale
const BERG_QUESTIONS: string[] = [
  'Sitting to standing',
  'Standing unsupported',
  'Sitting unsupported',
  'Standing to sitting',
  'Transfers',
  'Standing with eyes closed',
  'Standing with feet together',
  'Reaching forward with outstretched arm',
  'Retrieving object from floor',
  'Turning to look behind',
  'Turning 360 degrees',
  'Placing alternate foot on stool',
  'Standing with one foot in front',
  'Standing on one foot',
];

const BERG_FIELDS: FieldsLayout[] = BERG_QUESTIONS.map((question) => ({
  label: question,
  key: `${convertLabelToKey(question)}Score`,
  fieldType: 'CHOICE_CHIPS',
  options: BERG_SCORES_OPTIONS,
}));

const bergBalanceScale: SubHeadingItem = {
  label: 'Berg Balance Scale (BBS)',
  key: 'bergBalanceScale',
  fields: [
    {
      key: 'historyOfFalls',
      label: 'Does the client have a history of falls?',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    ...BERG_FIELDS,
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography> / 56</Typography>,
      valueDependsOn: getKeysFromOptions(BERG_FIELDS),
    },
    {
      label: 'Interpretation of the score',
      key: 'interpretationBBS',
      fieldType: 'RADIO',
      options: [
        {
          key: 'lowFallRisk',
          val: 'lowFallRisk',
          label: '41-56: Low fall risk',
        },
        {
          key: 'mediumFallRisk',
          val: 'mediumFallRisk',
          label: '21-40: Medium fall risk',
        },
        {
          key: 'highFallRisk',
          val: 'highFallRisk',
          label: '0-20: High fall risk',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    {
      label: 'Interpretation of the score (according to Berg, 1992)',
      key: 'interpretationBBSBerg',
      fieldType: 'RADIO',
      options: [
        {
          key: 'minimalConcernsBerg',
          val: 'minimalConcernsBerg',
          label: '45+: Minimal concerns',
        },
        {
          key: 'greaterFallRiskBerg',
          val: 'greaterFallRiskBerg',
          label: '0-44: Individuals may be at greater risk of falling',
        },
      ],
      valueDependsOn: ['totalScore'],
    },
    {
      label: 'Interpretation of the score (according to Shumway-Cook, 1997)',
      key: 'interpretationBBSShumwayCook',
      fieldType: 'RADIO',
      options: [
        {
          key: 'minimalConcernsCook',
          val: 'minimalConcernsCook',
          label:
            'Minimal concerns (51+ with history of falls, 42+ with no history of falls)',
        },
        {
          key: 'greaterFallRiskCook',
          val: 'greaterFallRiskCook',
          label: 'Individuals may be at greater risk of falling',
        },
      ],
      valueDependsOn: ['historyOfFalls', 'totalScore'],
    },
    ...Results,
  ],
  selectAll: '4',
};

export default bergBalanceScale;
