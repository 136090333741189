import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  capitalizeFirstLetter,
  convertLabelToKey,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const CTMT_COLUMN_HEADINGS = [
  '',
  'Task One',
  'Task Two',
  'Task Three',
  'Task Four',
  'Task Five',
];

const CTMT_ROW_TITLES = [
  'Time',
  'Number of errors',
  'T-score',
  'Percentile ranking',
];

const getCTMTRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 125,
    },
  },
];

const comprehensiveTrailMaking: MainHeadingItem = {
  key: 'comprehensiveTrailMaking',
  label: 'Comprehensive Trail Making Test (CTMT)',
  subheadings: [
    {
      label: 'CTMT Table',
      key: 'CTMTTableSection',
      fields: [
        {
          label: '',
          key: 'CTMTTable',
          fieldType: 'TABLE',
          columnHeadings: CTMT_COLUMN_HEADINGS,
          cells: generateCellsForTableWithUniformCells(
            CTMT_COLUMN_HEADINGS,
            CTMT_ROW_TITLES,
            getCTMTRowSpec,
            undefined
          ),
        },
      ],
    },
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: [
        {
          label: 'trail drawings',
          key: 'cTMTFile',
          fieldType: 'UPLOAD',
        },
        {
          label: 'CTMT composite index (sum of all times)',
          key: 'compositeIndex',
          fieldType: 'INPUT-SWITCH',
          valueDependsOn: CTMT_COLUMN_HEADINGS.map(
            (columnHeading) =>
              `${convertLabelToKey(columnHeading)}${capitalizeFirstLetter(
                convertLabelToKey('Time')
              )}`
          ),
        },
        {
          label: 'Further analysis',
          key: 'furtherAnalysis',
          fieldType: 'LABEL',
        },
        {
          label: 'Composite index t-score',
          key: 'compositeIndexTScore',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Percentile rank based on age',
          key: 'overallPercentileRank',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Analysis of the percentile',
          key: 'analysisOfThePercentileCTMT',
          fieldType: 'RADIO',
          options: [
            {
              key: 'severelyImpaired',
              val: 'severelyImpaired',
              label: '0-29: Severely impaired',
            },
            {
              key: 'mildlyImpaired',
              val: 'mildlyImpaired',
              label: '30-35: Mildly impaired',
            },
            {
              key: 'belowAverage',
              val: 'belowAverage',
              label: '36-42: Below average',
            },
            {
              key: 'average',
              val: 'average',
              label: '43-57: Average',
            },
            {
              key: 'highAverage',
              val: 'highAverage',
              label: '58-64: High average',
            },
            {
              key: 'superior',
              val: 'superior',
              label: '65-70: Superior',
            },
            {
              key: 'verySuperior',
              val: 'verySuperior',
              label: '71+: Very superior',
            },
          ],
          valueDependsOn: ['overallPercentileRank'],
        },
        ...Results,
      ],
    },
  ],
};

export default comprehensiveTrailMaking;
