import {
  FieldsLayout,
  FieldTypes,
  IOption,
  SubHeadingItem,
} from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  allButFirstColumn,
  allButLastRow,
  convertLabelToKey,
  getNumbersXToY,
  getNumericOptionsXToY,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import { getKeyWithoutAdditionalParameter } from '../../common/FieldContainer/MyTable-Utils';

const DRIVING_TABLE_COLUMN_HEADINGS = [
  'Place',
  'Number of Trips per Week',
  'Kilometers from Home (one-way)',
  'Total Kilometers',
];

export const DRIVING_TABLE_ROW_TITLES = [
  'Store',
  'Church',
  'Work',
  'Volunteer',
  'Relatives home',
  'Out to eat',
  'Appoitments',
  'OtherDrivingDestination1',
  'OtherDrivingDestination2',
  'OtherDrivingDestination3',
  'Totals',
];

const getDrivingTableValueDependsOn = (
  rowTitle: string,
  columnHeading: string
) => {
  if (
    rowTitle === 'Totals' &&
    (columnHeading === 'Number of Trips per Week' ||
      columnHeading === 'Total Kilometers')
  ) {
    return allButLastRow(DRIVING_TABLE_ROW_TITLES).map((row) =>
      getKeyWithoutAdditionalParameter(columnHeading, row)
    );
  }
  if (columnHeading === 'Total Kilometers') {
    return DRIVING_TABLE_COLUMN_HEADINGS.slice(1, 3).map((column) =>
      getKeyWithoutAdditionalParameter(column, rowTitle)
    );
  }
  return undefined;
};

const DRIVING_TABLE_CELLS: FieldsLayout[][][] = DRIVING_TABLE_ROW_TITLES.map(
  (rowTitle) => [
    [
      {
        key: convertLabelToKey(rowTitle),
        label:
          rowTitle.substring(0, 5) === 'Other'
            ? 'Other (please specify)'
            : rowTitle,
        fieldType: (rowTitle.substring(0, 5) === 'Other'
          ? 'INPUT'
          : 'LABEL') as FieldTypes,
        size: 200,
      },
    ],
    ...allButFirstColumn(DRIVING_TABLE_COLUMN_HEADINGS).map((columnHeading) => [
      columnHeading === 'Kilometers from Home (one-way)' &&
      rowTitle === 'Totals'
        ? {
            key: `drivingTableNALabel`,
            label: 'NA',
            fieldType: 'LABEL' as FieldTypes,
          }
        : {
            key: getKeyWithoutAdditionalParameter(columnHeading, rowTitle),
            label: '',
            fieldType: 'INPUT-NUM' as FieldTypes,
            size: 200,
            valueDependsOn: getDrivingTableValueDependsOn(
              rowTitle,
              columnHeading
            ),
          },
    ]),
  ]
);

const DEPENDENCE_TABLE_COLUMN_HEADINGS = ['', 'Relationship', 'Who is driving'];
const DEPENDENCE_TABLE_ROW_TITLES = [...getNumbersXToY(1, 6), 'Totals'];

const DEPENDENCE_TABLE_CELLS: FieldsLayout[][][] =
  DEPENDENCE_TABLE_ROW_TITLES.map((rowTitle) => [
    [
      {
        key: rowTitle.toString(),
        label: rowTitle.toString(),
        fieldType: 'LABEL' as FieldTypes,
      },
    ],
    rowTitle !== 'Totals'
      ? [
          {
            key: `dependenceTableRelationship${rowTitle}`,
            label: '',
            fieldType: 'INPUT' as FieldTypes,
          },
        ]
      : [
          {
            key: `dependenceTableTotalNumberOfIndividuals`,
            label: 'Total number of individuals',
            fieldType: 'INPUT-NUM' as FieldTypes,
            valueDependsOn: getNumbersXToY(1, 6).map(
              (no) => `dependenceTableRelationship${no}`
            ),
          },
        ],
    rowTitle !== 'Totals'
      ? [
          {
            key: `dependenceTableWhoIsDriving${rowTitle}`,
            label: '',
            fieldType: 'SELECT' as FieldTypes,
            options: getNumericOptionsXToY(1, 3),
          },
        ]
      : [
          {
            key: `totalDependencyScore`,
            label: 'Total dependency score',
            fieldType: 'INPUT-NUM' as FieldTypes,
            valueDependsOn: getNumbersXToY(1, 6).map(
              (no) => `dependenceTableWhoIsDriving${no}`
            ),
          },
        ],
  ]);

const PAST_THREE_MONTH_QUESTIONS_TEXT: string[] = [
  'Driven when it has been raining or snowing?',
  'Made left-hand turns across oncoming traffic?',
  'Driven on any expressways?',
  'Driven on high traffic roads?',
  'Driven in rush hour traffic?',
  'Driven at night?',
];

const PAST_THREE_MONTH_OPTIONS: IOption[] = getOptionsFromLabels([
  'No difficulty at all',
  'A little difficulty',
  'Moderate difficulty',
  'Extreme difficulty',
]);

const PAST_THREE_MONTH_QUESTIONS = PAST_THREE_MONTH_QUESTIONS_TEXT.map(
  (item, i) => [
    {
      key: `question${i + 10}`,
      label: `${i + 10}. ${item}`,
      fieldType: 'RADIO' as FieldTypes,
      options: YES_NO,
    },
    {
      key: `question${i + 10}FollowUp1`,
      label: 'Under this circumstance, you drive with:',
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: PAST_THREE_MONTH_OPTIONS,
    },
    {
      key: `question${i + 10}FollowUp2`,
      label: `It is mostly your visual problems that affect your ability to drive 
      under this curcumstance?`,
      fieldType: 'CHOICE_CHIPS' as FieldTypes,
      options: YES_NO,
    },
  ]
).flat(1);

const PAST_YEAR_QUESTIONS_TEXT: string[] = [
  'Driven in your immediate neighbourhood?',
  'Driven to places beyond your neighbourhood?',
  'Driven to neighbouring towns?',
  'Driven to more distant towns?',
  'Driven to places outside of the province?',
  'Driven to places outside of this region?',
  'Driven to places outside of the country?',
];

const PAST_YEAR_QUESTIONS = PAST_YEAR_QUESTIONS_TEXT.map((item, i) => ({
  key: `question${i + 21}`,
  label: `${i + 21}. ${item}`,
  fieldType: 'RADIO' as FieldTypes,
  options: YES_NO,
}));

const QUESTIONS_16_20_TEXT: string[] = [
  '16. In an average week, how many days per week do you normally drive?',
  '17. How many crashes have been involved in over the past year when you were the driver?',
  `18. How many accidents have you been involved in over the past year when you were the driver
  where the police were called to the scene?`,
  `19. How many times in the past year have you been pulled over by the police, 
        regardless of whether you received a ticket?`,
  `20. How many times in the past year have you received a traffic ticket
      (other than a parking ticket) where  you were found guilty regardless of whether 
      or not you think you were at fault?`,
];

const QUESTIONS_16_20 = QUESTIONS_16_20_TEXT.map((item, i) => [
  {
    key: `question${i + 16}Label`,
    label: item,
    fieldType: 'LABEL' as FieldTypes,
  },
  {
    key: `question${i + 16}`,
    label: 'No. of times',
    fieldType: 'INPUT-NUM' as FieldTypes,
  },
]).flat(1);

const drivingHabitQuestionnaire: SubHeadingItem = {
  key: 'drivingHabitQuestionnaire',
  label: 'Driving Habit Questionnaire (DHQ)',
  fields: [
    {
      key: 'question1',
      label: '1. Do you current drive?',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      key: 'question1FollowUp',
      label: 'If no, why did you stop driving?',
      fieldType: 'INPUT',
    },
    {
      key: 'question2Label',
      label: '2. When was the last time you drove?',
      fieldType: 'LABEL',
    },
    {
      key: 'question2',
      label: 'Date',
      fieldType: 'DATE',
    },
    {
      key: 'question3',
      label: '3. Do you wear glasses or contact lenses when you drive?',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      key: 'question4',
      label: '4. Do you wear a seatbelt when you drive?',
      fieldType: 'RADIO',
      options: getOptionsFromLabels(['Always', 'Sometimes', 'Never']),
    },
    {
      key: 'question5',
      label: '5. Which way do you prefer to get around?',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Drive myself',
        'Have someone drive me',
        'Use public transportation or taxi',
      ]),
    },
    {
      key: 'question6',
      label:
        '6. How fast do you usually drive compared to the general flow of traffic? Would you say:',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Much faster',
        'Somewhat faster',
        'About the same',
        'Somewhat slower',
        'Much slower',
      ]),
    },
    {
      key: 'question7',
      label:
        '7. Over the past year, has anyone suggested that you limit or stop driving?',
      fieldType: 'RADIO',
      options: YES_NO,
    },
    {
      key: 'question8',
      label: '8. How would you rate the quality of your driving?',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Excellent',
        'Good',
        'Average',
        'Fair',
        'Poor',
      ]),
    },
    {
      key: 'question9',
      label: `9. If you had to go somewhere and didn't want to drive yourself, what would you do?`,
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Ask a friend or relative to drive you',
        'Call a taxi or take the bus',
        'Use a community transportation service',
        'Drive yourself regardless of how you feel',
        'Cancel or postpone the plan and stay hom',
        'Other',
      ]),
    },
    {
      key: 'question9FollowUp',
      label: 'If other, please specify',
      fieldType: 'INPUT',
    },
    {
      key: 'question1015Label',
      label: 'During the past 3 months, have you...',
      fieldType: 'LABEL',
    },
    ...PAST_THREE_MONTH_QUESTIONS,
    ...QUESTIONS_16_20,
    {
      key: 'question2025FollowUp',
      label: 'During the past year, have you...',
      fieldType: 'LABEL',
    },
    ...PAST_YEAR_QUESTIONS,
    {
      key: 'drivingTableLabel',
      label:
        '28. Please consider all the places you drive in a typical week and follow the chart below',
      fieldType: 'LABEL',
    },
    {
      key: 'drivingTable',
      label: '',
      fieldType: 'TABLE',
      columnHeadings: DRIVING_TABLE_COLUMN_HEADINGS,
      cells: DRIVING_TABLE_CELLS,
    },
    {
      key: 'totalNumberOfPlaceTravelledTo',
      label: 'Total number of places travelled to',
      fieldType: 'INPUT-NUM',
      valueDependsOn: allButLastRow(DRIVING_TABLE_ROW_TITLES).map((rowTitle) =>
        getKeyWithoutAdditionalParameter('Number of Trips per Week', rowTitle)
      ),
    },
    {
      key: 'dependenceTableLabel',
      label: `29. Please list your friends and/or family members that you regularly travel with in a 
      car over the past year. In the "who is driving" specify 1 = I am usually the driver,
      2 = About half and half, 3 = This person is usually the driver`,
      fieldType: 'LABEL',
    },
    {
      key: 'dependenceTable',
      label: '',
      fieldType: 'TABLE',
      columnHeadings: DEPENDENCE_TABLE_COLUMN_HEADINGS,
      cells: DEPENDENCE_TABLE_CELLS,
    },
    {
      key: `lastQuestionLabel`,
      label:
        '30. What is the total number of years that you have been a licenced driver?',
      fieldType: 'LABEL',
    },
    {
      key: `lastQuestion`,
      label: 'No. of years',
      fieldType: 'INPUT-NUM',
    },
    ...Results,
  ],
};

export default drivingHabitQuestionnaire;
