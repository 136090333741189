import React, { useState, FC } from 'react';

import { Grid, Paper, Stack } from '@mui/material';

import { IAssessmentSummary } from '../../models/assessments/assessment-summaries';
import { AssessmentCategoryKeys } from '../../models/assessments/assessments';
import AssessmentCategory from './AssessmentsMainPane/AssessmentCategory';
import EmptyMainPane from './AssessmentsMainPane/EmptyMainPane';
import ShowResultsSwitch from './AssessmentsMainPane/ShowResultsSwitch';
import { categorizeAssessments } from './common';

interface AssessmentsMainPaneProps {
  clientId: string;
  assessmentSummaries: IAssessmentSummary[];
}

const AssessmentsMainPane: FC<AssessmentsMainPaneProps> = ({
  clientId,
  assessmentSummaries,
}) => {
  const [showResults, setShowResults] = useState<boolean>(true);

  const isEmptyMainPane = () => assessmentSummaries.length === 0;

  const categorizedAssessmentSummaries =
    categorizeAssessments(assessmentSummaries);

  return (
    <Grid item xs={12} lg={9}>
      <Paper square style={{ padding: '20px', backgroundColor: 'primary' }}>
        {isEmptyMainPane() ? (
          <EmptyMainPane />
        ) : (
          <Stack spacing={3}>
            <ShowResultsSwitch
              showResults={showResults}
              setShowResults={setShowResults}
            />
            {AssessmentCategoryKeys.map((categoryKey) => {
              const assessmentSummariesGivenCategory =
                categorizedAssessmentSummaries[categoryKey]?.assessments || [];

              if (assessmentSummariesGivenCategory.length === 0) {
                return <React.Fragment key={categoryKey} />;
              }

              return (
                <AssessmentCategory
                  key={categoryKey}
                  categoryKey={categoryKey}
                  clientId={clientId}
                  showResults={showResults}
                  assessmentSummaries={assessmentSummariesGivenCategory}
                />
              );
            })}
          </Stack>
        )}
      </Paper>
    </Grid>
  );
};

export default AssessmentsMainPane;
