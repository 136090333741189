import React, { FC, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import {
  useAssessment,
  useCreateAssessment,
  useUpdateAssessment,
} from '../../../hooks/database/assessments/assessments';
import { useUpdateAssessmentSummary } from '../../../hooks/database/assessments/assessmentSummaries';
import { IForm, MainHeadingItem, SubHeadingItem } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import { SpinningDialog } from '../../common/BasicDialog';
import LoadingButton from '../../common/LoadingButton';
import { isThereAnyUnsavedData } from '../AssessmentCard/AssessmentCard-utils';
import { AssessmentFormHeader } from './AssessmentFormHeader';
import { AssessmentFormMainHeading } from './AssessmentFormMainHeading';
import { AssessmentFormSubHeading } from './AssessmentFormSubHeading';
import { UnsavedDataWarningModal } from './UnsavedDataWarningModal';

export interface AssessmentFormProps {
  clientId: string;
  assessmentId: string;
  assessmentDefinition: MainHeadingItem | SubHeadingItem;
  setIsAssessmentOpen: (isOpen: boolean) => void;
}

const AssessmentForm: FC<AssessmentFormProps> = ({
  clientId,
  assessmentId,
  assessmentDefinition,
  setIsAssessmentOpen,
}) => {
  const assessmentKey = assessmentDefinition.key;
  const theme = useTheme();
  const { t } = useTranslation(allNamespaces);

  const {
    isLoading,
    error,
    data: assessmentData,
  } = useAssessment(assessmentDefinition.key, assessmentId);
  useErrorHandler(error);

  const createMutation = useCreateAssessment(assessmentId);
  const updateMutation = useUpdateAssessment(assessmentId);
  const updateSummaryMutation = useUpdateAssessmentSummary(clientId);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);

  const { handleSubmit, register, control, watch, setValue, getValues, reset } =
    useForm();

  const assessmentForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  useEffect(() => {
    if (assessmentData) {
      reset(assessmentData);
    }
  }, [reset, assessmentData]);

  if (isLoading) {
    return (
      <SpinningDialog isSpinning={isLoading} messageKey="loadingAssessment" />
    );
  }

  const getResultSummaryField = (data: any) => {
    if (!data) return 'undetermined';

    const result =
      data.overallOTAssignedResult ||
      data.analysisOfTheRawScore ||
      data.overallInterpretation ||
      data.overallRiskScore ||
      data.resultClassification ||
      data.recomendation ||
      data.overallOTAssignedResultFarAcuity;

    if (!result) return 'undetermined';
    return result;
  };

  const handleSave = async (dataToSubmit: any) => {
    const assessment = {
      ...dataToSubmit,
      clientId,
      assessmentKey,
      assessmentId,
    };
    const assessmentSummary = {
      clientId,
      assessmentKey,
      assessmentId,
      resultSummary: getResultSummaryField(dataToSubmit),
    };

    if (Object.keys(assessmentData).length === 0) {
      await createMutation.mutateAsync(assessment);
      await updateSummaryMutation.mutateAsync(assessmentSummary);
    } else {
      await updateMutation.mutateAsync(assessment);
      await updateSummaryMutation.mutateAsync(assessmentSummary);
    }
  };

  const handleSaveAndClose = async (dataToSubmit: any) => {
    await handleSave(dataToSubmit);
    setIsAssessmentOpen(false);
  };

  const handleClose = () => {
    const currentData = watch();
    if (isThereAnyUnsavedData(currentData, assessmentData)) {
      setIsWarningModalOpen(true);
    } else {
      setIsAssessmentOpen(false);
    }
  };

  const handleIgnoreData = () => {
    setIsWarningModalOpen(false);
    setIsAssessmentOpen(false);
  };

  const handleGoBackAndSave = () => {
    setIsWarningModalOpen(false);
  };

  const isMutating = () =>
    createMutation.isLoading ||
    updateMutation.isLoading ||
    updateSummaryMutation.isLoading;

  return (
    <>
      <Dialog
        open
        onClose={() => {}}
        fullWidth
        scroll="paper"
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      >
        <FormContext.Provider value={assessmentForm}>
          <DialogTitle>
            <AssessmentFormHeader
              clientId={clientId}
              assessmentKey={assessmentKey}
              handleClose={handleClose}
            />
          </DialogTitle>

          <DialogContent dividers>
            {'subheadings' in assessmentDefinition &&
              assessmentDefinition.subheadings.map((section: any) => (
                <AssessmentFormMainHeading
                  clientId={clientId}
                  assessmentKey={assessmentKey}
                  section={section}
                  watch={watch}
                />
              ))}

            {'fields' in assessmentDefinition && (
              <AssessmentFormSubHeading
                clientId={clientId}
                assessmentDefinition={assessmentDefinition}
                watch={watch}
              />
            )}
          </DialogContent>

          <DialogActions>
            <LoadingButton isLoading={isMutating()}>
              <Button onClick={() => handleSaveAndClose(watch())}>
                {getTranslationLabel('generalNs', 'saveAndClose', t)}
              </Button>
              <Button onClick={handleSubmit(handleSave)}>
                {getTranslationLabel('generalNs', 'save', t)}
              </Button>
            </LoadingButton>
          </DialogActions>
        </FormContext.Provider>
      </Dialog>

      <SpinningDialog isSpinning={isMutating()} messageKey="savingAssessment" />
      {isWarningModalOpen && (
        <UnsavedDataWarningModal
          handleGoBack={handleGoBackAndSave}
          handleIgnoreData={handleIgnoreData}
        />
      )}
    </>
  );
};

export default AssessmentForm;
