import { FILE_NAME_LENGTH, ILLEGAL_CHARS } from './FileUpload-constants';

export const verifyFileExtension = async (file: Blob): Promise<boolean> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onloadend = () => {
      const arr = new Uint8Array(fileReader.result as ArrayBuffer);

      const header = arr.reduce<string>((result, element) => {
        let converted = element.toString(16).toUpperCase();
        converted = converted.length === 1 ? `0${converted}` : converted;
        return result + converted;
      }, '');

      if (
        header.substring(0, 4) === '424D' || // BMP
        header.substring(0, 12) === '474946383761' || // GIF
        header.substring(0, 12) === '474946383961' ||
        header.substring(0, 8) === 'FFD8FFDB' || // JPG
        header.substring(0, 8) === 'FFD8FFE0' ||
        header.substring(0, 8) === 'FFD8FFE1' ||
        header.substring(0, 8) === 'FFD8FFE2' ||
        header.substring(0, 8) === 'FFD8FFE3' ||
        header.substring(0, 8) === 'FFD8FFE8' ||
        header.substring(0, 10) === '255044462D' || // PDG
        header === '89504E470D0A1A0A' || // PNG
        header.substring(0, 6) === '492049' || // TIFF
        header.substring(0, 8) === '49492A00' ||
        header.substring(0, 8) === '4D4D002A' ||
        header.substring(0, 8) === '4D4D002B'
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    fileReader.readAsArrayBuffer(file.slice(0, 8));
  });
};

export const verifyFileName = (fileName: string): boolean => {
  if (fileName.length > FILE_NAME_LENGTH) {
    return false;
  }

  if (ILLEGAL_CHARS.test(fileName)) {
    return false;
  }

  return true;
};
