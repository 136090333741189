import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import { RESULTS_CATEGORIES } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getOptionsFromLabels,
  convertLabelToKey,
  capitalizeFirstLetter,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';

const VISUAL_ACUITY_MEASUREMENT_TYPES = ['20/x', '10/x', '6/x'];
const TYPES_OF_ACUITY = ['Far Acuity', 'Night Vision Acuity'];

const VISUAL_ACUITY_COLUMN_HEADINGS = ['', 'Uncorrected', 'Corrected'];
const VISUAL_ACUITY_ROW_TITLES = ['Both eyes', 'Left eye', 'Right eye'];

const getVisualAcuityRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT-NUM' as FieldTypes,
      size: 200,
    },
  },
];

const getAcuityFields = (typeOfAcuity: string) => [
  {
    key: `${convertLabelToKey(typeOfAcuity)}UnitsExplanation`,
    label: `Note: Record just the value of the x in the cells below. 
      For example, for 20/40, just record 40.`,
    fieldType: 'LABEL' as FieldTypes,
  },
  {
    key: `${convertLabelToKey(typeOfAcuity)}Table`,
    label: '',
    fieldType: 'TABLE' as FieldTypes,
    columnHeadings: VISUAL_ACUITY_COLUMN_HEADINGS,
    cells: generateCellsForTableWithUniformCells(
      VISUAL_ACUITY_COLUMN_HEADINGS,
      VISUAL_ACUITY_ROW_TITLES,
      getVisualAcuityRowSpec,
      typeOfAcuity
    ),
  },
  {
    label: 'Result',
    key: `${convertLabelToKey(typeOfAcuity)}OverallResult`,
    fieldType: 'RADIO' as FieldTypes,
    options: getOptionsFromLabels([
      'Meets licensing standards',
      'Does not meet licensing standards',
      'Undetermined',
    ]),
  },
  {
    label: 'Overall OT interpretation',
    key: `overallOTAssignedResult${capitalizeFirstLetter(
      convertLabelToKey(typeOfAcuity)
    )}`,
    fieldType: 'RADIO' as FieldTypes,
    options: RESULTS_CATEGORIES,
  },
];

const visualAcuity: MainHeadingItem = {
  key: 'visualAcuity',
  label: 'Visual Acuity',
  subheadings: [
    {
      key: 'administrative',
      label: 'Administrative',
      fields: [
        {
          label: 'Type of chart used',
          key: 'typeOfAcuityChart',
          fieldType: 'SELECT',
          options: getOptionsFromLabels([
            'Snellen (eye chart)',
            'ETDRS',
            'Bailey-Lovey',
            'OPTEC',
            'Titmus vision screener',
            'Other (please specify in comments)',
          ]),
        },
        {
          label: 'Type of eye chart',
          key: 'typeOfEyeChart',
          fieldType: 'SELECT',
          options: getOptionsFromLabels([
            'Letters',
            'Numbers',
            'Letters and numbers',
            'Tumbling Es',
            'Other',
          ]),
        },
        {
          key: `typeOfUnits`,
          label: 'Select measurement units',
          fieldType: 'SELECT',
          options: getOptionsFromLabels(VISUAL_ACUITY_MEASUREMENT_TYPES),
        },
      ],
    },
    ...TYPES_OF_ACUITY.map((type) => ({
      key: convertLabelToKey(type),
      label: type,
      fields: getAcuityFields(type),
    })),
  ],
};

export default visualAcuity;
