import React, { FC } from 'react';

import { Stack, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IAssessmentSummary } from '../../../models/assessments/assessment-summaries';
import { getTranslationLabel } from '../../../utils/utils';
import AssessmentCard from '../AssessmentCard/AssessmentCard';
import { getAssessmentDefinition, sortAssessmentSummaries } from '../common';

interface AssessmentCategoryProps {
  clientId: string;
  categoryKey: string;
  showResults: boolean;
  assessmentSummaries: IAssessmentSummary[];
}

const AssessmentCategory: FC<AssessmentCategoryProps> = ({
  clientId,
  categoryKey,
  showResults,
  assessmentSummaries,
}) => {
  const { t } = useTranslation(allNamespaces);

  const sortedAssessmentSummaries =
    sortAssessmentSummaries(assessmentSummaries);

  return (
    <Stack spacing={1} key={categoryKey}>
      <Typography variant="h3">
        {getTranslationLabel(
          'assessmentsGeneral/categoryTitles',
          categoryKey,
          t
        )}
      </Typography>
      <Grid container direction="row">
        {sortedAssessmentSummaries.map(
          (assessmentSummary: IAssessmentSummary) => {
            const assessmentDefinition = getAssessmentDefinition(
              assessmentSummary.assessmentKey
            );

            return (
              assessmentDefinition && (
                <Grid item key={assessmentSummary.assessmentKey}>
                  <AssessmentCard
                    clientId={clientId}
                    showResults={showResults}
                    assessmentSummary={assessmentSummary}
                    assessmentDefinition={assessmentDefinition}
                  />
                </Grid>
              )
            );
          }
        )}
      </Grid>
    </Stack>
  );
};

export default AssessmentCategory;
