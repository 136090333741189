import { useMsal } from '@azure/msal-react';

import i18n from '../i18n';

export const deepCloneObj = <T extends object>(input: T): T =>
  JSON.parse(JSON.stringify(input));

export const useUsername = () => {
  const { accounts } = useMsal();
  const { username } = accounts[0];
  return username;
};

export const languageAbbr = () =>
  i18n.resolvedLanguage === 'en' ? 'fr' : 'en';

export const getTranslationLabel = (
  namespace: string,
  name: string,
  t: any,
  label?: string,
  resolvedLanguage?: string
) => {
  if (label !== '' && label !== 'Comments'! && !Number.isNaN(label)) {
    return t(`${namespace}:${name}`);
  }
  if (label === 'Comments' && resolvedLanguage === 'en') {
    return 'Comments';
  }
  if (label === 'Comments' && resolvedLanguage === 'fr') {
    return 'Commentaires';
  }
  return '';
};

export const getAllSelectedAsString = (selected: any) =>
  Object.keys(selected)
    .filter((key) => selected[key] === 1)
    .toString();

export const getAllSelectedAsObj = (selected: string[]) =>
  (selected &&
    selected.reduce(
      (accumulator, value) => ({ ...accumulator, [value]: 1 }),
      {}
    )) ||
  [];

export const fetchPlus = (url: string, options = {}, retries: number): any =>
  fetch(url, options)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (retries > 0) {
        return fetchPlus(url, options, retries - 1);
      }
      throw new Error(res.status.toString());
    })
    .catch((e) => {});
