import React, { FC, useEffect, useState } from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useRouteMatch,
  useHistory,
  useLocation,
  Route,
  Switch,
} from 'react-router-dom';

import allNamespaces from '../../allNamespaces';
import AsyncErrorWrapper from '../../errors/AsyncErrorWrapper';
import NoMatch from '../../errors/NoMatch';
import {
  useClient,
  useUpdateClient,
  useUpdateConsent,
} from '../../hooks/database/clients/clients';
import { IClient, IClientMetaData } from '../../models/client';
import { IForm } from '../../models/forms';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import { ChangeConsentDialog } from '../clients/Consent/ChangeConsentDialog';
import { showConsentPrompt } from '../clients/Consent/consent-utils';
import { SpinningDialog } from '../common/BasicDialog';
import { PaperHeader } from '../common/PaperHeader';
import TabsComp, { MenuItemProp } from '../common/Tabs';
import INTAKE_FORM_FIELDS from './Constants/IntakeFormConstants';
import { IntakeFormPage } from './IntakeFormPage';

export interface IntakeFormProps {
  clientId: string;
  title?: string;
}

export const IntakeForm: FC<IntakeFormProps> = ({ clientId, title }) => {
  const { isLoading, isError, data: client } = useClient(clientId);
  const updateMutation = useUpdateClient(clientId);
  const consentMutation = useUpdateConsent(clientId);

  const { t } = useTranslation(allNamespaces);
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [isConsentDialogOpen, setIsConsentDialogOpen] =
    useState<boolean>(false);
  const [isConsentDialogShown, setIsConsentDialogShown] =
    useState<boolean>(false);

  const { handleSubmit, register, control, watch, setValue, getValues, reset } =
    useForm();
  const intakeForm: IForm = { register, control, setValue, getValues, watch };

  useEffect(() => {
    if (client) {
      reset(client);
      if (
        showConsentPrompt(client as unknown as IClientMetaData) &&
        !isConsentDialogShown
      ) {
        setIsConsentDialogOpen(true);
        setIsConsentDialogShown(true);
      }
    }
  }, [reset, client, isConsentDialogShown]);

  const pageTitle =
    title || getTranslationLabel('clientsGeneral', 'editClientPageTitle', t);

  const menuItems: MenuItemProp[] = INTAKE_FORM_FIELDS.reduce(
    (result: MenuItemProp[], item: any) => {
      result.push({
        key: item.key,
        label: item.label,
        path: `${match.url}/${item.key}`,
      });
      return result;
    },
    []
  );

  const currentTab = parseInt(location.pathname.slice(-1), 10);

  const pushNextPageToHistory = () => {
    const nextSectionNumber = currentTab + 1;
    if (nextSectionNumber <= 8) {
      const nextPage = `${location.pathname.slice(0, -1)}${nextSectionNumber}`;
      history.push(nextPage);
    } else {
      history.push(`/clients/assess/${clientId}`);
    }
  };

  const saveAndContinue = async (data: IClient) => {
    await updateMutation.mutateAsync(data);
    pushNextPageToHistory();
  };

  const justSave = async (data: IClient) => {
    await updateMutation.mutateAsync(data);
  };

  const handleChangeConsent = async (consent: IClientMetaData) => {
    consentMutation.mutate(consent);
  };

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (isError) {
    return (
      <Typography>
        {getTranslationLabel('errors', 'editClientFallback', t)}
      </Typography>
    );
  }

  return (
    <AsyncErrorWrapper mutation={consentMutation}>
      <AsyncErrorWrapper mutation={updateMutation}>
        <Grid
          item
          container
          xs={12}
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Grid item>
            <PaperHeader
              title={pageTitle}
              clientId={clientId}
              backLink="/clients"
            />
          </Grid>
          <Grid item container direction="row">
            <Grid
              item
              container
              xs={12}
              sm="auto"
              md={3}
              lg={2}
              direction="column"
              alignItems="center"
            >
              <Grid item>
                <TabsComp menuItems={menuItems} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm="auto" md={9} lg={10}>
              <FormContext.Provider value={intakeForm}>
                <form onSubmit={handleSubmit(saveAndContinue)}>
                  <Switch>
                    {INTAKE_FORM_FIELDS.map((subheading) => (
                      <Route
                        path={`${match.url}/${subheading.key}`}
                        key={subheading.key}
                      >
                        <IntakeFormPage
                          subheading={subheading}
                          watch={watch}
                          handleSubmit={handleSubmit}
                          justSave={justSave}
                          saveAndContinue={saveAndContinue}
                        />
                      </Route>
                    ))}
                    <Route path="*">
                      <NoMatch />
                    </Route>
                  </Switch>
                  {isConsentDialogOpen && (
                    <ChangeConsentDialog
                      clientId={clientId}
                      setIsConsentDialogOpen={setIsConsentDialogOpen}
                      handleChangeConsent={handleChangeConsent}
                    />
                  )}
                </form>
              </FormContext.Provider>
              <SpinningDialog
                isSpinning={updateMutation.isLoading}
                messageKey="savingChanges"
              />
            </Grid>
          </Grid>
        </Grid>
      </AsyncErrorWrapper>
    </AsyncErrorWrapper>
  );
};
