import React from 'react';

import { Typography } from '@mui/material';

import { SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const vericomStationaryReactionTimer: SubHeadingItem = {
  label: 'Vericom Stationary Reaction Timer',
  key: 'vericomStationaryReactionTimer',
  fields: [
    {
      label: 'Scenario type',
      key: 'scenarioType',
      fieldType: 'RADIO',
      options: getOptionsFromLabels([
        'Simple (just brake)',
        'Complex (brake and steering)',
      ]),
    },
    {
      label: 'Number of trials',
      key: 'numberOfTrials',
      fieldType: 'INPUT-NUM',
    },
    {
      label: 'Average gas off time',
      key: 'averageGasOffTime',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Average transition time',
      key: 'averageTransitionTime',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Average reaction time',
      key: 'averageReactionTime',
      fieldType: 'INPUT-NUM',
      units: <Typography>seconds</Typography>,
    },
    {
      label: 'Average travel distance',
      key: 'averageTravelDistance',
      fieldType: 'INPUT-NUM',
      units: <Typography>meters</Typography>,
    },
    {
      label: 'Average pass rate',
      key: 'averagePassRate',
      fieldType: 'INPUT-NUM',
      units: <Typography>%</Typography>,
    },
    ...Results,
  ],
};

export default vericomStationaryReactionTimer;
