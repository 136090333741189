import React, { FC, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Grid,
  Stack,
  IconButton,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { LoadingComponent, ErrorComponent } from '../../../errors/QueryUtils';
import {
  useStandardRouteManeuvers,
  useStandardRoute,
  useDeleteStandardRoute,
} from '../../../hooks/database/settings/standardRoutes';
import {
  IStandardRoute,
  IStandardRouteManeuver,
} from '../../../models/on-road/route';
import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';
import StyledListCard from '../../common/StyledListCard';
import DeleteError from './DeleteError';

interface StandardRouteCardProps {
  handleChooseStandardRoute: (
    standardRoute: IStandardRoute,
    standardRouteManeuvers: IStandardRouteManeuver[]
  ) => void;
  handleEditStandardRoute: (standardRouteId: string) => void;
  selectedStandardRouteId: string | null;
  cardStandardRoute: IStandardRoute;
  cardLabel: string;
}

const StandardRouteCard: FC<StandardRouteCardProps> = ({
  handleChooseStandardRoute,
  handleEditStandardRoute,
  selectedStandardRouteId,
  cardStandardRoute,
  cardLabel,
}) => {
  const { t } = useTranslation(allNamespaces);
  const cardStandardRouteId = cardStandardRoute.standardRouteId;
  const standardRouteManeuvers = useStandardRouteManeuvers(cardStandardRouteId);
  const standardRoute = useStandardRoute(cardStandardRouteId);
  const deleteStandardRouteMutation = useDeleteStandardRoute();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCancelClear = () => {
    setIsModalOpen(false);
  };

  const handleCancelConfirm = () => {
    deleteStandardRouteMutation.mutate(standardRoute.data[0]);
    setIsModalOpen(false);
  };

  const handleDeleteStandardRoute = () => {
    setIsModalOpen(true);
  };

  if (standardRouteManeuvers.isLoading) {
    return <LoadingComponent />;
  }
  if (standardRouteManeuvers.isError) {
    return <ErrorComponent error={standardRouteManeuvers.error} />;
  }

  return (
    <Grid item key={cardStandardRouteId}>
      <StyledListCard
        sx={{
          backgroundColor:
            cardStandardRouteId === selectedStandardRouteId
              ? 'secondary.light'
              : 'primary.light',
        }}
      >
        <Stack direction="row">
          <IconButton
            aria-label="edit standard route"
            size="small"
            onClick={() => handleEditStandardRoute(cardStandardRouteId)}
            sx={{
              paddingLeft: '10px',
            }}
          >
            <EditIcon />
          </IconButton>
          <CardActionArea
            onClick={() =>
              handleChooseStandardRoute(
                cardStandardRoute,
                standardRouteManeuvers.data
              )
            }
          >
            <CardContent>
              <Typography variant="h4">{cardLabel}</Typography>
            </CardContent>
          </CardActionArea>
          <IconButton
            aria-label="delete standard route"
            size="small"
            onClick={handleDeleteStandardRoute}
            sx={{
              paddingRight: '10px',
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </StyledListCard>
      {isModalOpen && (
        <BasicDialog
          label={
            <Typography variant="h2">
              {getTranslationLabel('onroad/routeBuilder', 'areYouSureTitle', t)}
            </Typography>
          }
          open={isModalOpen}
          handleClose={() => {}}
          actions={
            <>
              <Button onClick={handleCancelClear}>
                {getTranslationLabel('generalNs', 'cancel', t)}
              </Button>
              <Button onClick={handleCancelConfirm} variant="outlined">
                {getTranslationLabel('generalNs', 'delete', t)}
              </Button>
            </>
          }
        >
          {getTranslationLabel('onroad/routeBuilder', 'areYouSureText', t)}
          {selectedStandardRouteId}?
        </BasicDialog>
      )}
      {deleteStandardRouteMutation.isError && (
        <DeleteError resetMutation={deleteStandardRouteMutation.reset} />
      )}
    </Grid>
  );
};

export default StandardRouteCard;
