import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getSpeedFields } from './utils';

const ADJUSTMENT_TO_STIMULI_SIGNS_SCHOOL_ZONE = [
  'Failed to notice the school zone',
  "Failed to follow crossing guard's commands",
  'Failed to yield to pedestrians',
  'Stopped unnecessarily (pedestrians and crossing guard not present)',
  'Other',
];
const ADJUSTMENT_TO_STIMULI_SIGNS_SCHOOL_ZONE_OPTIONS = getOptionsFromLabels(
  ADJUSTMENT_TO_STIMULI_SIGNS_SCHOOL_ZONE,
  'AdjustmentToStimuliInSchoolZone'
);

const SCHOOL_ZONE_SPEED = getOptionsFromLabels(
  [
    'Sped up too early',
    'Slowed down too late',
    'Failed to speed up at the end of the school zone',
  ],
  'InSchoolZone'
);

const SCHOOL_ZONE_ERRORS: SubHeadingItem = {
  key: 'schoolZone',
  label: 'School Zone',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'schoolZoneAdjustmentToStimuliTrafficSigns',
      fieldType: 'CHECKBOX',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_SCHOOL_ZONE_OPTIONS,
    },
    ...getSpeedFields('schoolZone'),
    {
      label: 'Speed Regulation',
      key: 'schoolZoneSpeedRegulation',
      fieldType: 'CHECKBOX',
      options: SCHOOL_ZONE_SPEED,
    },
  ],
};

export default SCHOOL_ZONE_ERRORS;
