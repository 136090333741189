import React, { FC } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Typography, Fab, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';

export interface AssessmentFormHeaderProps {
  clientId: string;
  assessmentKey: string;
  handleClose: () => void;
}

export const AssessmentFormHeader: FC<AssessmentFormHeaderProps> = ({
  clientId,
  assessmentKey,
  handleClose,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <Grid container direction="column">
          <Typography
            variant="h1"
            component="span"
            style={{ margin: '15px 0px 0px 15px' }}
          >
            {getTranslationLabel(
              'assessmentsGeneral/assessmentTitles',
              assessmentKey,
              t
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ margin: '0px 0px 0px 15px' }}
          >
            {clientId}
          </Typography>
        </Grid>
      </Grid>
      <Fab
        sx={{ textTransform: 'none' }}
        style={{ margin: '15px 0px 0px 0px' }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          onClick={handleClose}
          style={{ position: 'sticky', top: 0 }}
        >
          <ClearIcon color="primary" fontSize="large" />
        </IconButton>
      </Fab>
    </Grid>
  );
};
