import React, { FC, useMemo, useRef } from 'react';

import { Button, Fab, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { ReactQueryAutoSyncSaveStatus } from '../../../../hooks/auto-sync/utils/ReactQueryAutoSyncStatus';
import { IForm } from '../../../../models/forms';
import { IOrderedManeuverSummary } from '../../../../models/on-road/route';
import theme from '../../../../theme';
import FormContext from '../../../../utils/formContext';
import { getTranslationLabel } from '../../../../utils/utils';
import AutoSave from '../../../common/AutoSave';
import AutoSaveStatus from '../../../common/AutoSaveStatus';
import Scroll from '../../../common/Scroll';
import { RecordType } from '../utils';
import ManeuverErrors from './ManeuverErrors';
import ManeuverMeta from './ManeuverMeta';

interface OpenManeuverProps {
  maneuver: any;
  maneuverType: string;
  maneuverSummaries: IOrderedManeuverSummary[];
  recordType: RecordType;
  handleOpenTask: (task: IOrderedManeuverSummary) => void;
  handleSaveSummary: (summary: any) => void;
  handleSaveErrors: (errors: any) => void;
  saveStatus: ReactQueryAutoSyncSaveStatus;
}

const OpenManeuver: FC<OpenManeuverProps> = ({
  maneuver,
  maneuverSummaries,
  handleOpenTask,
  handleSaveSummary,
  handleSaveErrors,
  maneuverType,
  recordType,
  saveStatus,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({ defaultValues: maneuver });
  const form: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  const isRecordingError = recordType === 'error';

  const handleSave = (maneuverDraft: any) => {
    if (isRecordingError) {
      handleSaveErrors(maneuverDraft);
      handleSaveSummary({ ...maneuverDraft, recordType: 'error' });
    } else {
      handleSaveSummary({ ...maneuverDraft, recordType: 'success' });
    }
  };

  const findManeuver = (maneuverRef: string) =>
    maneuverSummaries.find(
      (elem: IOrderedManeuverSummary) => elem.refManeuverId === maneuverRef
    );

  const topofOpenManeuver = useRef(null);
  const executeScroll = () =>
    (topofOpenManeuver.current as unknown as HTMLElement)?.scrollIntoView();

  const onClickNext = () => {
    const nextManeuver = findManeuver(maneuver.nextManeuver);
    if (nextManeuver) {
      handleOpenTask(nextManeuver);
      executeScroll();
    }
  };

  const onClickPrev = () => {
    const prevManeuver = findManeuver(maneuver.prevManeuver);
    if (prevManeuver) {
      handleOpenTask(prevManeuver);
      executeScroll();
    }
  };

  const areButtonsDisabled = () =>
    saveStatus === 'saving' ||
    saveStatus === 'unsaved' ||
    saveStatus === 'error';

  const saveComponent = useMemo(
    () => (
      <>
        <AutoSaveStatus saveStatus={saveStatus} />
        <Scroll>
          <Fab variant="extended" sx={{ textTransform: 'none' }}>
            <AutoSaveStatus saveStatus={saveStatus} />
          </Fab>
        </Scroll>
      </>
    ),
    [saveStatus]
  );

  return (
    <Stack direction="column" spacing={3}>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit((values) => handleSave(values))}>
          <Grid
            item
            container
            direction="column"
            spacing={3}
            ref={topofOpenManeuver}
          >
            <Grid item>
              <Stack direction="row" spacing={3} alignItems="baseline">
                <Typography variant="h2">
                  {`${maneuver.idx}. ${getTranslationLabel(
                    'maneuvers/defaultManeuverTypes',
                    maneuverType,
                    t
                  )}`}
                </Typography>
                <Typography
                  variant="h3"
                  color={
                    isRecordingError
                      ? theme.palette.error.main
                      : theme.palette.success.main
                  }
                >
                  {isRecordingError
                    ? getTranslationLabel(
                        'maneuvers/maneuversGeneral',
                        'recordingErrors',
                        t
                      )
                    : getTranslationLabel(
                        'maneuvers/maneuversGeneral',
                        'recordingSuccess',
                        t
                      )}
                </Typography>
              </Stack>
            </Grid>
            <Grid item>{saveComponent}</Grid>
            <Grid item container direction="column">
              <ManeuverMeta
                maneuverType={maneuverType}
                subType={watch('subManeuverType')}
                key={maneuver.maneuverId}
              />
            </Grid>
            {isRecordingError && (
              <Grid item container direction="column">
                <ManeuverErrors maneuverType={maneuverType} values={watch()} />
              </Grid>
            )}
            <Grid item container direction="row">
              <Grid item>
                <Button onClick={onClickPrev} disabled={areButtonsDisabled()}>
                  Prev
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onClickNext} disabled={areButtonsDisabled()}>
                  Next
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <AutoSave
                watch={watch}
                handleSave={(values) => handleSave(values)}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </Stack>
  );
};

export default OpenManeuver;
