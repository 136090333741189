import React from 'react';

import { Typography, Button } from '@mui/material';
import { t } from 'i18next';

import { getTranslationLabel } from '../../../utils/utils';
import BasicDialog from '../../common/BasicDialog';

interface DeleteErrorProps {
  resetMutation: () => void;
}

const DeleteError: React.FC<DeleteErrorProps> = ({ resetMutation }) => (
  <BasicDialog
    label={
      <Typography variant="h2">
        {getTranslationLabel(
          'onroad/routeBuilder',
          'standardRouteDeleteErrorTitle',
          t
        )}
      </Typography>
    }
    actions={
      <>
        <Button onClick={() => resetMutation()}>OK</Button>
      </>
    }
    maxWidth="sm"
    open
    handleClose={() => {}}
  >
    {getTranslationLabel(
      'onroad/routeBuilder',
      'standardRouteDeleteErrorMessage',
      t
    )}
  </BasicDialog>
);

export default DeleteError;
