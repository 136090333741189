import { IOption } from '../../../models/forms';
import IRoute from '../../../models/on-road/route';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

export const blankRoute: IRoute = {
  routeName: '',
  typeOfRoute: '',
  tasks: [],
};

export const ERROR_TYPES = [
  'Adjustment to stimuli and traffic signs',
  'Speed',
  'Gap acceptance',
  'Lane maintenance',
  'Vehicle positioning',
  'Visual scanning',
  'Signalling',
];

export const ROAD_TYPES = [
  'Clients Neighborhood',
  'Downtown',
  'Main Road',

  'Commercial',
  'Industrial',
  'Residential',
  'Rural',
  'One-Way',
  'Narrow Road',
  'Hills',
  'Parking Lot',

  'Undivided Highway',
  'Divided Highway',
  'Freeway',
];

export const ROAD_TYPE_OPTIONS: IOption[] = ROAD_TYPES.map((roadType) => ({
  key: convertLabelToKey(roadType),
  val: convertLabelToKey(roadType),
  label: roadType,
}));
export const ERROR_TYPE_OPTIONS: IOption[] = ERROR_TYPES.map((errorType) => ({
  key: convertLabelToKey(errorType),
  val: convertLabelToKey(errorType),
  label: errorType,
}));

const RATING_SCALE_LABELS = [
  'Functional',
  'Functional with Aids',
  'Not functional',
];
const RATING_SCALE_LABELS_CABIN = [
  'Functional',
  'Functional with Physical Aids',
  'Functional with Verbal Aid',
  'Not functional',
];
export const RATING_SCALE_OPTIONS = getOptionsFromLabels(RATING_SCALE_LABELS);
export const RATING_SCALE_OPTIONS_CABIN = getOptionsFromLabels(
  RATING_SCALE_LABELS_CABIN
);
