import React from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';

const NavListDrawer = ({ navArrayLinks, NavLink, setOpen }: any) => {
  const { t } = useTranslation(websiteNamespaces);

  return (
    <Box sx={{ width: 250 }}>
      <nav>
        <List>
          {navArrayLinks.map((item: any) => (
            <ListItem disablePadding key={item.title}>
              <ListItemButton
                component={NavLink}
                to={item.path ? item.path : {}}
                onClick={() => {
                  setOpen(false);
                  if (item.onClick) {
                    item.onClick();
                  }
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>
                  {getTranslationLabel('website/general', item.key, t)}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
    </Box>
  );
};

export default NavListDrawer;
