import React from 'react';

import { Grid, Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { websiteNamespaces } from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import playbutton from '../../img/play-button.png';

const MainPageCard = ({ text, image, rightSide }: any) => {
  const { t } = useTranslation(websiteNamespaces);

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '2vw',
      }}
    >
      <Grid container justifyContent={rightSide ? 'flex-end' : 'flex-start'}>
        <Box
          component="img"
          sx={{
            width: 'min(100%, calc(100vw * 0.51515 + 290.909px))',
            height: 'auto',
          }}
          src={image}
        />
      </Grid>
      <Grid container justifyContent={rightSide ? 'flex-start' : 'flex-end'}>
        <div
          style={{
            backgroundColor: rightSide ? '#1d577d' : '#4f8dbf',
            opacity: 0.89,
            height: '20vw',
            width: 'min(100%, calc(100vw * 0.34848 + 390.909px))',
            padding: '2.5%',
            position: 'absolute',
            transform: 'translateY(-135%)',
          }}
        >
          <Typography variant="h3">{text}</Typography>
          <Link component={NavLink} to={rightSide ? '/about' : '/driveeval'}>
            <Grid container alignItems="center" sx={{ marginTop: '1vh' }}>
              <Box
                component="img"
                src={playbutton}
                title="Click to learn more"
                alt="Click to learn more"
                sx={{
                  width: '2vw',
                  height: '2vw',
                }}
              />
              <Typography
                style={{
                  fontSize: '2.44vw',
                  fontWeight: 500,
                  color: 'white',
                  opacity: 0.68,
                  marginLeft: '10px',
                }}
              >
                {getTranslationLabel('website/main', 'learnMore', t)}
              </Typography>
            </Grid>
          </Link>
        </div>
      </Grid>
    </div>
  );
};

export default MainPageCard;
