import React from 'react';

import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NoMatch = () => (
  <>
    <h2>Oops! We can not seem to find the page you are looking for.</h2>
    <h3>
      Not to worry, try navigating using the toolbar or follow one of the links
      below:
    </h3>
    {/* TODO: include a search option */}
    <Link to="/clients">
      <Typography variant="body1">Handle your clients</Typography>
    </Link>
    <Link to="/help">
      <Typography variant="body1">Help</Typography>
    </Link>
    <Link to="/user/settings">
      <Typography variant="body1">Settings</Typography>
    </Link>
  </>
);

export default NoMatch;
