import React, { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import { SubHeadingItem } from '../../models/forms';
import { getTranslationLabel } from '../../utils/utils';

export interface BasicAccordionProps extends AccordionProps {
  subheadings: SubHeadingItem[];
  namespace: string;
  children: (subheading: SubHeadingItem) => JSX.Element;
}

const BasicAccordion: FC<BasicAccordionProps> = ({
  children,
  namespace,
  subheadings,
  ...rest
}) => {
  const { t } = useTranslation(allNamespaces);
  return (
    <Grid item>
      {subheadings.map((subheading) => (
        <Accordion key={subheading.key} {...rest}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={subheading.key}>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 'typography.fontWeightRegular',
              }}
            >
              {getTranslationLabel(namespace, subheading.key, t)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '20px' }}>
            {children(subheading)}
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default BasicAccordion;
