import React, { FC, useState } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import allNamespaces from '../../../allNamespaces';
import { getTranslationLabel } from '../../../utils/utils';
import DeleteClient from '../DeleteClient/DeleteClient';

interface ActionsTableCellProps {
  clientId: string;
}

const ActionsTableCell: FC<ActionsTableCellProps> = ({ clientId }) => {
  const { t } = useTranslation(allNamespaces);
  const [isDeleteClientDialogOpen, setIsDeleteClientDialogOpen] =
    useState<boolean>(false);

  const actionCellIconsSpec = [
    {
      key: 'edit',
      icon: <EditIcon color="primary" />,
      to: `/clients/edit/${clientId}/category0`,
    },
    {
      key: 'assess',
      icon: <AssignmentIcon />,
      to: `/clients/assess/${clientId}`,
    },
    {
      key: 'onRoad',
      icon: <DirectionsCarIcon />,
      to: `/clients/on-road/${clientId}/category0`,
    },
    {
      key: 'report',
      icon: <SummarizeIcon />,
      to: `/clients/report/${clientId}`,
    },
    {
      key: 'delete',
      icon: <DeleteIcon />,
      onClick: () => setIsDeleteClientDialogOpen(true),
    },
  ];

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        {actionCellIconsSpec.map((actionCellIcon) => (
          <Grid item key={actionCellIcon.key}>
            <Tooltip
              title={getTranslationLabel(
                'clientsGeneral',
                actionCellIcon.key,
                t
              )}
            >
              <IconButton
                component={actionCellIcon.to ? Link : 'label'}
                color="primary"
                to={actionCellIcon.to || undefined}
                onClick={actionCellIcon.onClick || undefined}
              >
                {actionCellIcon.icon}
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      <DeleteClient
        clientId={clientId}
        isDeleteClientDialogOpen={isDeleteClientDialogOpen}
        setIsDeleteClientDialogOpen={setIsDeleteClientDialogOpen}
      />
    </>
  );
};

export default ActionsTableCell;
