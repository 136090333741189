import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import {
  SupportPersonClientsPerspective,
  TRUE_FALSE_OPTIONS,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import generateCellsForTableWithUniformCells from '../../common/FieldContainer/MyTable-Utils';
import {
  MEDICATIONS_CONDITIONALS,
  ADDITIONAL_PRESCRIBED_MEDICATIONS,
  SUPPORT_PERSON_DOESNT_CONFIRM,
} from './MedicalHistoryConditionals';

const DOMINANCE_OPTIONS = getOptionsFromLabels([
  'Right',
  'Left',
  'Both',
  'No preference',
  'Changed left-to-right during childhood',
  'Changed left-to-right due to a medical condition',
  'Changed right-to-left during childhood',
  'Changed right-to-left due to a medical condition',
]);

const getMedicationRowSpec = (key: string) => [
  {
    rowTitle: 'default',
    element: {
      key,
      label: '',
      fieldType: 'INPUT' as FieldTypes,
    },
  },
];

const MEDICAL_HISTORY: SubHeadingItem = {
  label: 'Medical Background',
  key: 'category5',
  fields: [
    {
      label: 'Sources of medical information',
      key: 'sourcesOfMedicalInformation',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'List of medications client is taking',
      key: 'medicationsList',
      fieldType: 'TEXT-AREA',
    },
    {
      label:
        'Does the support person (if applicable) confirms the client is taking their medications?',
      key: 'supportPersonConfirmsMedications',
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: SUPPORT_PERSON_DOESNT_CONFIRM,
    },
    {
      label:
        'Are there any additional prescribed medications the client is not taking?',
      key: 'anyAdditionalMedicationsClientNotTaking',
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: ADDITIONAL_PRESCRIBED_MEDICATIONS,
    },
    {
      label: 'Any medication side effects? ',
      key: 'medicationSideEffects',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(
        [
          'None',
          'Drowsiness',
          'Blurred vision',
          'Headaches',
          'Dizziness',
          'Other side effects',
        ],
        undefined,
        'medicationSideEffects'
      ),
      conditionals: MEDICATIONS_CONDITIONALS,
    },
    {
      label: 'Allergies',
      key: 'allergies',
      fieldType: 'TEXT-AREA',
    },
    {
      label: '',
      key: 'substanceUseHistory',
      fieldType: 'TABLE',
      columnHeadings: SupportPersonClientsPerspective,
      cells: generateCellsForTableWithUniformCells(
        SupportPersonClientsPerspective,
        [
          'Alcohol consumption (days / week)',
          'Illicit drug use (days / week)',
          'Cannabis use (days / week)',
        ],
        getMedicationRowSpec,
        undefined
      ),
    },
    {
      label: 'Substance abuse details',
      key: 'substanceAbuseDetails',
      fieldType: 'TEXT-AREA',
    },
    {
      label: 'Dominant hand',
      key: 'dominantHand',
      fieldType: 'SELECT',
      options: DOMINANCE_OPTIONS,
    },
    {
      label: 'Dominant foot',
      key: 'dominantFoot',
      fieldType: 'SELECT',
      options: DOMINANCE_OPTIONS,
    },
  ],
};

export default MEDICAL_HISTORY;
