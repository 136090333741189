import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IOption } from '../../../models/forms';
import { getTranslationLabel } from '../../../utils/utils';
import FileUpload from '../FileUpload/FileUpload';
import {
  MyChoiceChips,
  MyRadio,
  MyTextField,
  MyTextArea,
  MyLabel,
  MyScale,
  MyCheckbox,
  MySelect,
  MyMultiSelectChips,
  MyDatePicker,
  MyTimePicker,
  MyCheckBoxWithButton,
} from './PrimitiveField-utils';

interface PrimitiveFieldProps {
  label: string;
  name: string;
  namespace: string;
  type: string;
  options?: IOption[];
  units?: JSX.Element;
  defaultValue?: any;
  valueDependsOn?: string[];
  clientId?: string;
  size?: number;
  insideTable?: boolean;
  required?: boolean;
  error?: boolean;
  onClick?: any;
  clearSelection?: any;
}

// Gets the primitive field component for placing in a react-hook-form
const PrimitiveField: FC<PrimitiveFieldProps> = ({
  label,
  name,
  namespace,
  options,
  type,
  units,
  defaultValue,
  clientId,
  size,
  insideTable,
  valueDependsOn,
  onClick,
  clearSelection,
  required = false,
  ...rest
}) => {
  const { t } = useTranslation(allNamespaces);

  switch (type) {
    case 'INPUT': {
      return (
        <MyTextField
          name={name}
          namespace={namespace}
          label={label}
          units={units}
          type="input"
          size={size}
          insideTable={insideTable}
          required={required}
          {...rest}
        />
      );
    }
    case 'TEXT-AREA': {
      return (
        <MyTextArea
          name={name}
          namespace={namespace}
          label={label}
          size={size}
        />
      );
    }
    case 'CHECKBOXES_WITHBUTTON':
      if (options === undefined) {
        throw new Error(
          'Options cannot be undefined in checkboxes with button'
        );
      } else {
        return (
          <MyCheckBoxWithButton
            name={name}
            namespace={namespace}
            label={label}
            options={options}
            onClick={onClick}
            clearSelection={clearSelection}
          />
        );
      }
    case 'LABEL': {
      return (
        <MyLabel
          name={name}
          namespace={namespace}
          label={label}
          valueDependsOn={valueDependsOn}
        />
      );
    }
    case 'INPUT-SWITCH':
      return (
        <MyTextField
          name={name}
          namespace={namespace}
          label={label}
          units={units}
          type="INPUT-SWITCH"
          size={size}
          insideTable={insideTable}
          valueDependsOn={valueDependsOn}
        />
      );
    case 'INPUT-NUM': {
      return (
        <MyTextField
          name={name}
          namespace={namespace}
          label={label}
          units={units}
          defaultValue={defaultValue}
          type="number"
          size={size}
          insideTable={insideTable}
          valueDependsOn={valueDependsOn}
        />
      );
    }
    case 'DATE':
    case 'DATE_SHORT':
      return (
        <MyDatePicker
          name={name}
          namespace={namespace}
          label={label}
          type={type}
        />
      );
    case 'TIME': {
      return <MyTimePicker name={name} namespace={namespace} label={label} />;
    }
    case 'UPLOAD': {
      return (
        <FileUpload
          name={name}
          namespace={namespace}
          label={label}
          shrinkLabel
          clientId={clientId}
        />
      );
    }
    case 'RADIO': {
      if (options === undefined) {
        throw new Error('Options cannot be undefined in radio');
      } else {
        return (
          <MyRadio
            label={label}
            name={name}
            namespace={namespace}
            options={options}
            units={units}
            valueDependsOn={valueDependsOn}
          />
        );
      }
    }
    case 'CHOICE_CHIPS': {
      if (options === undefined) {
        throw new Error('Options cannot be undefined in choice chips');
      } else {
        return (
          <MyChoiceChips
            label={label}
            name={name}
            namespace={namespace}
            options={options}
          />
        );
      }
    }
    case 'MULTISELECT_CHOICE_CHIPS': {
      if (options === undefined) {
        throw new Error(
          'options cannot be undefined in multiselect choice chips'
        );
      } else {
        return (
          <MyMultiSelectChips
            label={label}
            name={name}
            namespace={namespace}
            options={options}
          />
        );
      }
    }
    case 'SCALE': {
      if (options === undefined) {
        throw new Error('Options cannot be undefined in scale');
      } else {
        return (
          <MyScale
            label={label}
            name={name}
            namespace={namespace}
            options={options}
          />
        );
      }
    }
    case 'CHECKBOX': {
      if (options === undefined) {
        throw new Error('Options cannot be undefined in checkbox');
      } else {
        return (
          <MyCheckbox
            label={label}
            name={name}
            namespace={namespace}
            options={options}
          />
        );
      }
    }
    case 'SELECT': {
      if (options === undefined) {
        throw new Error('Options cannot be undefined in select');
      }
      return (
        <MySelect
          label={label}
          name={name}
          namespace={namespace}
          defaultValue={defaultValue}
          size={size}
        >
          {options.map((option) => (
            <option key={option.key} value={option.val}>
              {getTranslationLabel(namespace, option.key, t, option.label)}
            </option>
          ))}
        </MySelect>
      );
    }
    default:
      // returns a text field by default
      return (
        <MyTextField
          name={name}
          namespace={namespace}
          label={label}
          type="input"
        />
      );
  }
};

export default PrimitiveField;
