import React, { FC, useState } from 'react';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { IClientMetaData } from '../../../models/client';
import { getTranslationLabel } from '../../../utils/utils';
import TablePaginationComp from '../../common/TablePaginationComp';
import ConsentTableCell from '../Consent/ConsentTableCell';
import ActionsTableCell from './ActionsTableCell';
import EmptyTable from './EmptyTable';

const EMPTY_ROW_HEIGHT = 53;
const clientsTableColumnHeadings = ['clientId', 'actions', 'consent'];

interface ClientsTableContentsProps {
  clientsMetaData: IClientMetaData[];
}

const ClientsTableContents: FC<ClientsTableContentsProps> = ({
  clientsMetaData,
}) => {
  const { t } = useTranslation(allNamespaces);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const emptyRows = clientsMetaData
    ? rowsPerPage -
      Math.min(rowsPerPage, clientsMetaData.length - page * rowsPerPage)
    : rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
        }}
      >
        <Table
          aria-label="simple table"
          sx={{
            minWidth: 600,
          }}
        >
          <TableHead>
            <TableRow>
              {clientsTableColumnHeadings.map((clientsTableColumnHeading) => (
                <TableCell key={clientsTableColumnHeading}>
                  {getTranslationLabel(
                    'clientsGeneral',
                    clientsTableColumnHeading,
                    t
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {clientsMetaData.length === 0 ? (
            <EmptyTable />
          ) : (
            <TableBody>
              {clientsMetaData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ clientId, isConsentGiven, isConsentFormUploaded }) => (
                  <TableRow key={clientId}>
                    <TableCell>{clientId}</TableCell>
                    <TableCell>
                      <ActionsTableCell clientId={clientId} />
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        <ConsentTableCell
                          clientMetaData={{
                            clientId,
                            isConsentGiven: isConsentGiven || '',
                            isConsentFormUploaded: isConsentFormUploaded || '',
                          }}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: EMPTY_ROW_HEIGHT * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        <TablePaginationComp
          currentPage={page}
          rowsPerPage={rowsPerPage}
          rowsCount={clientsMetaData ? clientsMetaData.length : 0}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default ClientsTableContents;
