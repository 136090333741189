import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import AsyncErrorWrapper from '../../../../errors/AsyncErrorWrapper';
import {
  useCreateCustomGroupAssessment,
  useDeleteCustomGroupAssessment,
} from '../../../../hooks/database/settings/customGroups';
import { ICustomGroup } from '../../../../models/assessments/assessments';
import { IForm, IOption } from '../../../../models/forms';
import FormContext from '../../../../utils/formContext';
import { getTranslationLabel, useUsername } from '../../../../utils/utils';
import { SelectedAssessments } from '../../../common/Assessments/SelectedAssessments';

interface EditCustomGroupFormProps {
  handleClose: () => void;
  group: ICustomGroup;
}

const EditCustomGroupForm: FC<EditCustomGroupFormProps> = ({
  handleClose,
  group,
}) => {
  const { t } = useTranslation(allNamespaces);
  const username = useUsername();
  const { handleSubmit, register, control, watch, setValue, getValues } =
    useForm({ defaultValues: group || undefined });

  const addAsmtToGroup = useCreateCustomGroupAssessment(
    username,
    group.groupKey
  );
  const removeAsmtFromGroup = useDeleteCustomGroupAssessment(
    username,
    group.groupKey
  );

  const customGroupForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  const handleChangeItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    // adding an item
    if (event.target.checked) {
      addAsmtToGroup.mutate(event.target.name);
    }
    // removing an assessment
    else {
      removeAsmtFromGroup.mutate(event.target.name);
    }
  };

  // add/remove an entire section in the custom group
  const handleChangeSection = (
    event: React.ChangeEvent<HTMLInputElement>,
    asmtsInSection: IOption[]
  ) => {
    if (event.target.checked) {
      asmtsInSection.map((asmt: IOption) => addAsmtToGroup.mutate(asmt.key));
    } else {
      asmtsInSection.map((asmt: IOption) =>
        removeAsmtFromGroup.mutate(asmt.key)
      );
    }
  };

  const onSubmit = () => {
    handleClose();
  };

  return (
    <AsyncErrorWrapper mutation={addAsmtToGroup}>
      <AsyncErrorWrapper mutation={removeAsmtFromGroup}>
        <FormContext.Provider value={customGroupForm}>
          <Grid item container direction="column">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item paddingBottom={3}>
                <Typography variant="subtitle1">
                  {getTranslationLabel('settings', 'editCustomGroup2', t)}
                </Typography>
              </Grid>
              <Grid item container>
                <SelectedAssessments
                  loCheckedItems={group?.assessments || []}
                  onChangeItem={handleChangeItem}
                  onChangeSection={handleChangeSection}
                  colSize={4}
                />
              </Grid>
            </form>
          </Grid>
        </FormContext.Provider>
      </AsyncErrorWrapper>
    </AsyncErrorWrapper>
  );
};

export default EditCustomGroupForm;
