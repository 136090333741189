import React, { FC, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import useModal from '../../../hooks/useModal';
import { IForm } from '../../../models/forms';
import { IRoadTest } from '../../../models/on-road/on-road';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import AutoSave from '../../common/AutoSave';
import Column from '../Common/Column';
import ON_ROAD_ROUTE_DETAILS from '../Constants/ON-ROAD-FIELDS/ROUTE';
import ListOfStandardRoutes from './ListOfStandardRoutes';
import NewStandardRoute from './NewStandardRoute';
import RouteBuilder from './RouteBuilder/RouteBuilder';

interface Tab3RouteDetailsProps {
  roadTest: IRoadTest;
  handleSaveRoadTest: (roadTest: IRoadTest) => void;
}

const Tab3RouteDetails: FC<Tab3RouteDetailsProps> = ({
  roadTest,
  handleSaveRoadTest,
}) => {
  const { t } = useTranslation(allNamespaces);
  const {
    close: closeRouteBuilder,
    show: showRouteBuilder,
    isShowing: isShowRouteBuilder,
  } = useModal();
  const {
    close: closeNewStandardRoute,
    show: showNewStandardRoute,
    isShowing: isShowNewStandardRoute,
  } = useModal();

  const { handleSubmit, register, control, setValue, getValues, watch } =
    useForm({ defaultValues: roadTest });
  const onRoadForm: IForm = { register, control, setValue, getValues, watch };

  const [editableRouteId, setEditableRouteId] = useState<string>('');

  const handleEditStandardRoute = (standardRouteId: string) => {
    setEditableRouteId(standardRouteId);
    showRouteBuilder();
  };

  const showListOfRoutes = watch('typeOfRoute') === 'standard';

  if (!roadTest.roadTestId) {
    return (
      <h2>
        The road test you are trying to edit does not exist. Try going back to
        the clients page and editing a road test for a client.
      </h2>
    );
  }

  return (
    <>
      <FormContext.Provider value={onRoadForm}>
        <form onSubmit={handleSubmit(handleSaveRoadTest)}>
          <Grid item container alignItems="flex-start">
            <Grid item container lg={6}>
              <Column
                title={
                  <Typography variant="h2">
                    {getTranslationLabel(
                      'onroad/chooseRoute',
                      'routeDetailsLabel',
                      t
                    )}
                  </Typography>
                }
                namespace="onroad/chooseRoute"
                fields={ON_ROAD_ROUTE_DETAILS}
                values={watch()}
                sectionKey="route"
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              lg={6}
            >
              {showListOfRoutes && (
                <ListOfStandardRoutes
                  roadTest={roadTest}
                  openNewStandardRouteDialog={showNewStandardRoute}
                  handleEditStandardRoute={handleEditStandardRoute}
                  selectedStandardRoute={watch('standardRouteId')}
                  setStandardRouteId={(standardRouteId: string | null) =>
                    setValue('standardRouteId', standardRouteId)
                  }
                />
              )}
              <Grid item>
                <AutoSave watch={watch} handleSave={handleSaveRoadTest} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
      {isShowRouteBuilder && (
        <RouteBuilder
          isOpen={isShowRouteBuilder}
          handleClose={closeRouteBuilder}
          standardRouteId={editableRouteId}
        />
      )}
      {isShowNewStandardRoute && (
        <NewStandardRoute
          isOpen={isShowNewStandardRoute}
          handleClose={closeNewStandardRoute}
        />
      )}
    </>
  );
};

export default Tab3RouteDetails;
