import { useQueryClient } from '@tanstack/react-query';

import { useUsername } from '../../../utils/utils';
import { useReactQueryAutoSync } from '../../auto-sync/useReactQueryAutoSync';
import useToken from '../../useToken';
import { MAX_WAIT, SHORT_WAIT, axiosGetCall, axiosPutCall } from '../utils';

const defaultManeuversKey = ['defaultManeuvers'] as const;

const defaultTacticalManeuversUrl = (username: string) =>
  `/api/settings/${username}/defaultTacticalManeuvers`;

// Hooks
const useDefaultManeuvers = () => {
  const getSilentToken = useToken();
  const username = useUsername();
  const queryClient = useQueryClient();

  return useReactQueryAutoSync({
    queryOptions: {
      queryKey: defaultManeuversKey,
      queryFn: () =>
        getSilentToken
          .then((response) =>
            axiosGetCall(
              defaultTacticalManeuversUrl(username),
              response.accessToken
            )
          )
          .catch((err) => {
            throw new Error(err);
          }),
    },
    mutationOptions: {
      mutationFn: (defaultTacticalManeuvers: any) =>
        getSilentToken
          .then((response) =>
            axiosPutCall(
              defaultTacticalManeuversUrl(username),
              defaultTacticalManeuvers,
              response.accessToken
            )
          )
          .then((res) => res.data),
      onSuccess: () => queryClient.invalidateQueries(defaultManeuversKey),
    },
    autoSaveOptions: {
      wait: SHORT_WAIT,
      maxWait: MAX_WAIT,
    },
    merge: (remote, local) => ({
      ...remote,
      ...local,
    }),
  });
};

export default useDefaultManeuvers;
