import React from 'react';

import { Typography } from '@mui/material';

import { MainHeadingItem, SubHeadingItem } from '../../../models/forms';
import {
  Results,
  YES_NO,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';

export const TRAIL_MAKING_OPTIONS = getOptionsFromLabels([
  'Below average',
  'Normal',
  'Above average',
]);

export const getTrailMakingSubheadings = (
  variant: string
): SubHeadingItem[] => [
  {
    label: 'Raw Data',
    key: 'rawDataSection',
    fields: [
      {
        label: `Trail making ${variant} completed?`,
        key: `trail${variant}IsCompleted`,
        fieldType: 'RADIO',
        options: YES_NO,
      },
      {
        label: `Trail making ${variant} time`,
        key: `trail${variant}Time`,
        fieldType: 'INPUT-NUM',
        units: <Typography>seconds</Typography>,
      },
      {
        label: `Trail making ${variant} errors`,
        key: `trail${variant}NoOfErrors`,
        fieldType: 'INPUT-NUM',
      },
      {
        label: `Trail making ${variant} interventions`,
        key: `trail${variant}Interventions`,
        fieldType: 'INPUT-NUM',
      },
      {
        label: `trail making ${variant}`,
        key: `trail${variant}File`,
        fieldType: 'UPLOAD',
      },
    ],
  },
  {
    label: 'Analysis',
    key: 'analysisSection',
    fields: [
      {
        label: `Trail making ${variant} age-based mean`,
        key: `trail${variant}AgeBasedMean`,
        fieldType: 'INPUT-NUM',
      },
      {
        label: `Trail making ${variant} age-based SD`,
        key: `trail${variant}AgeBasedSD`,
        fieldType: 'INPUT-NUM',
      },
      {
        label: `Trail making ${variant} standard score`,
        key: `trail${variant}StandardScore`,
        fieldType: 'INPUT-SWITCH',
        valueDependsOn: [
          `trail${variant}Time`,
          `trail${variant}AgeBasedMean`,
          `trail${variant}AgeBasedSD`,
        ],
      },
      {
        label: `Trail making ${variant} percentile ranking`,
        key: `trail${variant}PercentileRanking`,
        fieldType: 'INPUT-SWITCH',
        valueDependsOn: [`trail${variant}StandardScore`],
      },
      {
        label: `Trail making ${variant} norms data source`,
        key: `trail${variant}NormsDataSource`,
        fieldType: 'SELECT',
        options: getOptionsFromLabels([
          'A Compendium of Neuropsychological Tests (1st edition)',
          'A Compendium of Neuropsychological Tests (2nd edition)',
          'A Compendium of Neuropsychological Tests (3rd edition)',
          'A Compendium of Neuropsychological Tests (4th edition)',
          'Tombaugh TN (2004)',
          'Other (specify in comments)',
        ]),
      },
    ],
  },
];

const trailMakingA: MainHeadingItem = {
  label: 'Trail Making A',
  key: 'trailMakingA',
  subheadings: [
    ...getTrailMakingSubheadings('A'),
    {
      key: 'resultsSection',
      label: 'Results',
      fields: [
        {
          key: 'overallScanningRating',
          label: 'Overall scanning',
          fieldType: 'RADIO',
          options: TRAIL_MAKING_OPTIONS,
        },
        {
          key: 'processingSpeedRating',
          label: 'Processing speed',
          fieldType: 'RADIO',
          options: TRAIL_MAKING_OPTIONS,
        },
        ...Results,
      ],
    },
  ],
};

export default trailMakingA;
