import React, { FC, useState } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Card, CardHeader, CardActionArea } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { useDeleteAssessmentSummary } from '../../../hooks/database/assessments/assessmentSummaries';
import { IAssessmentSummary } from '../../../models/assessments/assessment-summaries';
import { SubHeadingItem, MainHeadingItem } from '../../../models/forms';
import { getTranslationLabel } from '../../../utils/utils';
import AssessmentForm from '../AssessmentForm/AssessmentForm';
import { DeleteAssessmentDialog } from '../DeleteAssessment/DeleteAssessmentDialog';
import {
  ASSESSMENT_FAIL_KEYS,
  ASSESSMENT_PASS_KEYS,
  ASSESSMENT_WARN_KEYS,
} from './AssessmentCard-constants';
import {
  AssessmentDefaultCardChip,
  AssessmentCardChip,
} from './AssessmentCardChips';

export type AssessmentCardProps = {
  clientId: string;
  showResults: boolean;
  assessmentSummary: IAssessmentSummary;
  assessmentDefinition: SubHeadingItem | MainHeadingItem;
};

const AssessmentCard: FC<AssessmentCardProps> = ({
  clientId,
  showResults,
  assessmentSummary,
  assessmentDefinition,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { resultSummary, assessmentId, assessmentKey } = assessmentSummary;

  const deleteMutation = useDeleteAssessmentSummary(clientId);
  const [isAssessmentOpen, setIsAssessmentOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsAssessmentOpen(true);
  };

  const handleDelete = () => {
    deleteMutation.mutate(assessmentKey);
    setIsDeleteDialogOpen(false);
  };

  const getResultLabel = () => {
    if (resultSummary === undefined || resultSummary === '') {
      return (
        <AssessmentDefaultCardChip resultSummary={resultSummary} type="" />
      );
    }
    if (ASSESSMENT_PASS_KEYS.includes(resultSummary)) {
      return <AssessmentCardChip resultSummary={resultSummary} type="pass" />;
    }
    if (ASSESSMENT_WARN_KEYS.includes(resultSummary)) {
      return (
        <AssessmentCardChip resultSummary={resultSummary} type="warning" />
      );
    }
    if (ASSESSMENT_FAIL_KEYS.includes(resultSummary)) {
      return <AssessmentCardChip resultSummary={resultSummary} type="fail" />;
    }
    return <AssessmentDefaultCardChip resultSummary={resultSummary} type="" />;
  };

  const getAvatar = () =>
    resultSummary === undefined || resultSummary === '' ? (
      <AssignmentIcon color="action" onClick={handleOpen} />
    ) : (
      <EditIcon color="action" onClick={handleOpen} />
    );

  return (
    <>
      <Card
        variant="outlined"
        key={assessmentKey}
        sx={{
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          width: {
            xs: '75vw',
            md: '50vw',
            lg: '18vw',
          },
          height: '90%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'space-between',
        }}
      >
        <CardActionArea onClick={handleOpen}>
          <CardHeader
            title={getTranslationLabel(
              'assessmentsGeneral/assessmentTitles',
              assessmentKey,
              t
            )}
            avatar={getAvatar()}
            subheader={showResults && getResultLabel()}
          />
        </CardActionArea>
        <IconButton
          aria-label="delete"
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          <HighlightOffIcon />
        </IconButton>
      </Card>

      {isAssessmentOpen && (
        <AssessmentForm
          clientId={clientId}
          assessmentId={assessmentId || ''}
          assessmentDefinition={assessmentDefinition}
          setIsAssessmentOpen={setIsAssessmentOpen}
        />
      )}

      <DeleteAssessmentDialog
        clientId={clientId}
        assessmentId={assessmentId || ''}
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default AssessmentCard;
