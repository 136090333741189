import React, { FC } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import { getTaskIcon } from '../../Constants/utils';
import { RecordType } from '../utils';

interface ManeuverCardProps {
  taskType: string;
  handleCreateTask: (recordType: RecordType, maneuverType: string) => void;
}

const ManeuverCard: FC<ManeuverCardProps> = ({
  taskType,
  handleCreateTask,
}) => {
  const { t } = useTranslation(allNamespaces);

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>{getTaskIcon(taskType)}</Grid>
        <Grid item>
          <Typography variant="h4">
            {getTranslationLabel('maneuvers/defaultManeuverTypes', taskType, t)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleCreateTask('error', taskType)}>
            <CloseIcon color="secondary" />
          </IconButton>
          <IconButton onClick={() => handleCreateTask('success', taskType)}>
            <CheckIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ManeuverCard;
