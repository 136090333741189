import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const CIRS_SYSTEM_CATEGORIES = [
  'Cardiac rating',
  'Vascular rating',
  'Hermatological rating',
  'Respiratory rating',
  'Ophthalmological and ORL rating',
  'Upper gastrointestinal rating',
  'Lower gastrointestinal rating',
  'Hepatic & pancreatic rating',
  'Renal rating',
  'Genitourinary rating',
  'Musculoskeletal & tegumental rating',
  'Neurological rating',
  'Endocrine, metabolic, breast rating',
  'Psychiatric rating',
];

const CIRS_FIELDS = CIRS_SYSTEM_CATEGORIES.map((item) => ({
  label: item,
  key: convertLabelToKey(item),
  fieldType: 'CHOICE_CHIPS' as FieldTypes,
  options: getNumericOptionsXToY(0, 4),
}));

const cumulativeIllnessRatingScale: SubHeadingItem = {
  key: 'cumulativeIllnessRatingScale',
  label: 'Cumulative Illness Rating Scale',
  fields: [
    ...CIRS_FIELDS,
    {
      label: 'Total rating',
      key: 'totalRating',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 56</Typography>,
      valueDependsOn: getKeysFromOptions(CIRS_FIELDS),
    },
    ...Results,
  ],
  selectAll: '0',
};

export default cumulativeIllnessRatingScale;
