export const BEFORE_DIAGNOSIS_ROAD_TYPES_FIELDS = [
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving',
  'drivingOnBeforeDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas',
];
export const AFTER_DIAGNOSIS_ROAD_TYPES_FIELDS = [
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnClientsNeighborhood',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDowntown',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnCommercial',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnIndustrial',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnResidential',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnRural',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnOneWay',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnParkingLot',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUndividedHighway',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnDividedHighway',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnFreeway',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnLightTraffic',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnModerateTraffic',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnBusyTraffic',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnNightDriving',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnWinterDriving',
  'drivingOnAfterDiagnosisWhatTypesOfRoadsDiddoTheyDriveOnUnfamiliarAreas',
];
export const BEFORE_DIAGNOSIS_AVOID_FIELDS = [
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseNight',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRain',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseFog',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSnow',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseRushHour',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseHighway',
  'avoidedBeforeDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic',
];
export const AFTER_DIAGNOSIS_AVOID_FIELDS = [
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseNight',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRain',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseFog',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSnow',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseSunriseSunset',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseUnfamiliarAreas',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseRushHour',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseHighway',
  'avoidedAfterDiagnosisDiddoTheyAvoidAnyOfThoseLeftTurnsAcrossOncomingTraffic',
];
