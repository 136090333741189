import { MainHeadingItem } from '../../../models/forms';
import { PhysicalResults } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getOptionsFromLabels } from '../../assessments/AssessmentCard/AssessmentCard-utils';
import {
  processROMSTSubheading,
  getROMSTTable,
  ROM_ST_LABEL,
  ROM_ST_OPTIONS,
} from './ROM-ST-Utils';

const strengthTesting: MainHeadingItem = {
  label: 'Strength Testing',
  key: 'strengthTesting',
  subheadings: [
    {
      label: '',
      key: 'strengthTestingUnits',
      fields: [ROM_ST_LABEL],
    },
    {
      label: 'Trunk',
      key: 'strengthTestingTrunkSection',
      fields: [
        {
          key: 'trunkControlPosturalStability',
          label: 'Trunk control / postural stability',
          fieldType: 'CHOICE_CHIPS',
          options: ROM_ST_OPTIONS,
        },
      ],
    },
    processROMSTSubheading('ST', 'Shoulder', [
      'Flexion',
      'Extension',
      'Abduction',
      'Adduction',
      'Internal rotation',
      'External rotation',
    ]),
    processROMSTSubheading('ST', 'Elbow', ['Flexion', 'Extension']),
    processROMSTSubheading('ST', 'Forearm', ['Pronation', 'Supination']),
    processROMSTSubheading('ST', 'Wrist', ['Flexion', 'Extension']),
    {
      label: 'Grasp',
      key: 'strengthTestingGraspSection',
      fields: [
        {
          key: 'seeJamarGripDynamometerCheckbox',
          label: '',
          fieldType: 'CHECKBOX',
          options: getOptionsFromLabels(['See Jamar Grip Dynamometer']),
        },
        getROMSTTable('ST', 'Grasp', [
          'General grasp and release',
          'Cylindrical',
          'Spherical',
          'Pincer',
          'Tenodesis',
        ]),
      ],
    },
    processROMSTSubheading('ST', 'Hip', [
      'Flexion',
      'Extension',
      'Abduction',
      'Adduction',
    ]),
    processROMSTSubheading('ST', 'Knee', ['Flexion', 'Extension']),
    processROMSTSubheading('ST', 'Ankle', [
      'Plantar flexion',
      'Dorsal flexion',
    ]),
    {
      label: 'Overall results',
      key: 'strengthTestingOverallResults',
      fields: PhysicalResults,
    },
  ],
};

export default strengthTesting;
