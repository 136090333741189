import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import { ErrorComponent, LoadingComponent } from '../../../errors/QueryUtils';
import { useStandardRoutes } from '../../../hooks/database/settings/standardRoutes';
import useModal from '../../../hooks/useModal';
import { IStandardRoute } from '../../../models/on-road/route';
import { getTranslationLabel } from '../../../utils/utils';
import ClickableCardWithIcon from '../../common/ClickableCardWithIcon';
import NewStandardRoute from '../../on-road/Tab3RouteDetails/NewStandardRoute';
import RouteBuilder from '../../on-road/Tab3RouteDetails/RouteBuilder/RouteBuilder';
import StandardRouteCard from '../../on-road/Tab3RouteDetails/StandardRouteCard';

const EditStandardRoutes = () => {
  const { t } = useTranslation(allNamespaces);
  const standardRoutes = useStandardRoutes();

  const { isShowing, close, show } = useModal();
  const [editableRouteId, setEditableRouteId] = useState<string>('');

  const {
    close: closeNewStandardRoute,
    show: openNewStandardRouteDialog,
    isShowing: isShowNewStandardRoute,
  } = useModal();

  if (standardRoutes.isLoading) {
    return <LoadingComponent />;
  }
  if (standardRoutes.isError) {
    return <ErrorComponent error={standardRoutes.error} />;
  }

  const handleEditStandardRoute = (standardRouteId: string) => {
    setEditableRouteId(standardRouteId);
    show();
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel('settings', 'myStandardRoutes', t)}
        </Typography>
      </Grid>
      <ClickableCardWithIcon
        label={getTranslationLabel(
          'onroad/chooseRoute',
          'standardRouteLabel',
          t
        )}
        onClick={openNewStandardRouteDialog}
        icon={<AddIcon color="secondary" />}
      />
      <Grid item container direction="column">
        {standardRoutes.data && standardRoutes.data.length !== 0 ? (
          standardRoutes.data.map(
            (route: IStandardRoute) =>
              route &&
              route.standardRouteId && (
                <Grid item key={route.standardRouteId}>
                  <StandardRouteCard
                    selectedStandardRouteId={null}
                    handleChooseStandardRoute={() => {}}
                    handleEditStandardRoute={handleEditStandardRoute}
                    cardStandardRoute={route}
                    cardLabel={route.routeName}
                    key={route.standardRouteId}
                  />
                </Grid>
              )
          )
        ) : (
          <Grid item>
            <Typography>
              {getTranslationLabel(
                'onroad/chooseRoute',
                'haveNotCreatedStandardRoute',
                t
              )}
            </Typography>
          </Grid>
        )}
      </Grid>

      {isShowing && (
        <RouteBuilder
          isOpen={isShowing}
          handleClose={close}
          standardRouteId={editableRouteId}
        />
      )}
      {isShowNewStandardRoute && (
        <NewStandardRoute
          isOpen={isShowNewStandardRoute}
          handleClose={closeNewStandardRoute}
        />
      )}
    </Grid>
  );
};

export default EditStandardRoutes;
