import React from 'react';

import { Typography } from '@mui/material';

import { IOption, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  getKeysFromOptions,
  getNumericOptionsXToY,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

// https://www.slu.edu/medicine/internal-medicine/geriatric-medicine/aging-successfully/pdfs/slums_form.pdf
const SUMS_ZERO_ONE_SCORE: IOption[] = [
  { key: 'zero', val: '0', label: '0: Incorrect' },
  { key: 'one', val: '1', label: '1: Correct' },
];

const SUMS_ZERO_TWO_SCORE: IOption[] = [
  { key: 'zero', val: '0', label: '0: Incorrect' },
  { key: 'two', val: '2', label: '2: Correct' },
];

const SLUMS_ANIMAL_OPTIONS: IOption[] = [
  { key: 'zero', val: '0', label: '0: 0-4 animals' },
  { key: 'one', val: '1', label: '1: 5-9 animals' },
  { key: 'two', val: '2', label: '2: 10-14 animals' },
  { key: 'three', val: '3', label: '3: 15+ animals' },
];

const SLUMS_NUMBER_OPTIONS: IOption[] = [
  { key: 'zero', val: '0', label: '0: 87' },
  { key: 'one', val: '1', label: '1: 648' },
  { key: 'two', val: '2', label: '2: 8537' },
];

const SLUMS_CLOCK_OPTIONS: IOption[] = [
  {
    key: 'clockDrawingHourMarkersOkay',
    val: '2',
    label: '2: Hour markers okay',
  },
  { key: 'clockDrawingTimeCorrect', val: '2', label: '2: Time correct' },
];

const StLouisUniversityMentalStatus: SubHeadingItem = {
  key: 'StLouisUniversityMentalStatus',
  label: 'St. Louis University Mental Status Examination (SLUMS)',
  fields: [
    {
      label: '1. What day of the week is it?',
      key: 'question1',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: '2. What is the year?',
      key: 'question2',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: '3. What province are we in?',
      key: 'question3',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: `4. Please remember these five objects. I will ask you what they are later.
        (Apple Pen Tie House Car)`,
      key: 'question4',
      fieldType: 'LABEL',
    },
    {
      label: `5. You have $100 and you go to the store and buy a dozen apples for $3 
      and a tricycle for $20.`,
      key: 'question5',
      fieldType: 'LABEL',
    },
    {
      label: 'How much did you spend?',
      key: 'question5a',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: 'How much do you have left?',
      key: 'question5b',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_TWO_SCORE,
    },
    {
      label: '6. Please name as many animals as you can in one minute.',
      key: 'question6',
      fieldType: 'CHOICE_CHIPS',
      options: SLUMS_ANIMAL_OPTIONS,
    },
    {
      label:
        '7. What were the five objects I asked you to remember? 1 point for each one correct.',
      key: 'question7',
      fieldType: 'CHOICE_CHIPS',
      options: getNumericOptionsXToY(0, 5),
    },
    {
      label: `8. I am going to give you a series of numbers and I would like you to give them to me 
      backwards. For example, if I say 42, you would say 24.`,
      key: 'question8',
      fieldType: 'CHOICE_CHIPS',
      options: SLUMS_NUMBER_OPTIONS,
    },
    {
      label: `9. This is a clock face. Please put in the hour markers and the time at 
      ten minutes to eleven o'clock.`,
      key: 'question9',
      fieldType: 'CHECKBOX',
      options: SLUMS_CLOCK_OPTIONS,
    },
    {
      label: 'Clock drawing total points',
      key: 'question9TotalPoints',
      fieldType: 'INPUT-NUM',
      units: <Typography>/4</Typography>,
      valueDependsOn: getKeysFromOptions(SLUMS_CLOCK_OPTIONS),
    },
    {
      label: 'clock drawing',
      key: 'sLUMSClockDrawingFile',
      fieldType: 'UPLOAD',
    },
    {
      label: '10. Please place an X in the triangle.',
      key: 'question10a',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: 'Which of the above figures is largest?',
      key: 'question10b',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_ONE_SCORE,
    },
    {
      label: `11. I am going to tell you a story. 
      Please listen carefully because afterwards, 
      I'm going to ask you some questions about it.
      Jill was a very successful stockbroker. 
      She made a lot of money on the stock market. 
      She then met Jack, a devastatingly handsome man. 
      She married him and had three children. 
      They lived in Toronto. 
      She then stopped work and stayed at home to bring up her children. 
      When they were teenagers, she went back to work.
      She and Jack lived happily ever after.`,
      key: 'question11',
      fieldType: 'LABEL',
    },
    {
      label: "What was the female's name?",
      key: 'question11a',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_TWO_SCORE,
    },
    {
      label: 'What work did she do?',
      key: 'question11b',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_TWO_SCORE,
    },
    {
      label: 'When did she go back to work?',
      key: 'question11c',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_TWO_SCORE,
    },
    {
      label: 'What province did she live in?',
      key: 'question11d',
      fieldType: 'CHOICE_CHIPS',
      options: SUMS_ZERO_TWO_SCORE,
    },
    {
      label: 'Total score',
      key: 'totalScore',
      fieldType: 'INPUT-SWITCH',
      units: <Typography>/ 30</Typography>,
      valueDependsOn: [
        'question1',
        'question2',
        'question3',
        'question5a',
        'question5b',
        'question6',
        'question7',
        'question8',
        'question9TotalPoints',
        'question10a',
        'question10b',
        'question11a',
        'question11b',
        'question11c',
        'question11d',
      ],
    },
    ...Results,
  ],
};

export default StLouisUniversityMentalStatus;
