import React, { FC } from 'react';

import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';

import { getTranslationLabel } from '../../../utils/utils';

interface ShowResultsSwitchProps {
  showResults: boolean;
  setShowResults: (newState: boolean) => void;
}

const ShowResultsSwitch: FC<ShowResultsSwitchProps> = ({
  showResults,
  setShowResults,
}) => (
  <FormControlLabel
    control={<Switch checked={showResults} color="secondary" />}
    label={getTranslationLabel(
      'assessmentsGeneral/assessClient',
      'showResults',
      t
    )}
    onClick={() => setShowResults(!showResults)}
  />
);

export default ShowResultsSwitch;
