import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getCommonSignallingFields, TASK_SPEED_OPTIONS } from './utils';

const TYPE_OF_PARKING = getOptionsFromLabels([
  'Angle parking',
  'Forward stall parking',
  'Parallel Parking',
  'Parking on a Hill',
  'Reverse stall parking',
  'Other',
]);

const VISUAL_LABELS = [
  'Insufficient scanning for cars/cyclists/pedestrians',
  'Missed blindspot check',
  'Failed to check mirrors',
  'Failed to check for pedestrians',
];

const PARKING_OPERATIONAL = getOptionsFromLabels([
  'Wrong pedal selection',
  'Unable to identify direction to turn steering wheel',
  'Incorrect position of gear selector',
  'Incorrect position of wheels on a hill',
  'Failed to apply parking brake',
]);

export const PARKING_META: SubHeadingItem = {
  key: 'parking',
  label: 'Parking',
  fields: [
    {
      label: 'Type of Parking',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: TYPE_OF_PARKING,
    },
    {
      label: 'Pulling out or into a stall?',
      key: 'pullingInOrOut',
      fieldType: 'CHOICE_CHIPS',
      options: getOptionsFromLabels([
        'Pulling into a stall',
        'Pulling out of a stall',
        'NA',
      ]),
    },
  ],
};
export const PARKING_ERRORS: SubHeadingItem = {
  key: 'parking',
  label: 'Parking',
  fields: [
    {
      label: 'Speed Regulation',
      key: 'parkingSpeed',
      fieldType: 'CHOICE_CHIPS',
      options: TASK_SPEED_OPTIONS,
    },
    {
      label: 'Vehicle Positioning',
      key: 'parkingVehiclePositioning',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(['Poor vehicle position']),
    },
    {
      label: 'Visual Scanning',
      key: 'parkingVisualScanning',
      fieldType: 'CHECKBOX',
      options: getOptionsFromLabels(VISUAL_LABELS),
    },
    ...getCommonSignallingFields('Parking'),
    {
      label: 'Behaviour',
      key: 'parkingBehaviour',
      fieldType: 'MULTISELECT_CHOICE_CHIPS',
      options: getOptionsFromLabels(['Poor problem solving']),
    },
    {
      label: 'Operational',
      key: 'parkingOperational',
      fieldType: 'CHECKBOX',
      options: PARKING_OPERATIONAL,
    },
  ],
};
