import React, { FC } from 'react';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Alert, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import AsyncErrorWrapper from '../../../../errors/AsyncErrorWrapper';
import {
  useDeleteManeuverSummariesAfterThisOne,
  useDeleteManeuverSummary,
  useUpdateManeuverSummary,
} from '../../../../hooks/database/on-road/maneuverSummaries';
import useModal from '../../../../hooks/useModal';
import { IOrderedManeuverSummary } from '../../../../models/on-road/route';
import { getTranslationLabel } from '../../../../utils/utils';
import { SpinningDialog } from '../../../common/BasicDialog';
import BasicMenu from '../../../common/BasicMenu';
import {
  getRoadTypeLabel,
  getTaskIcon,
  getTaskLabel,
} from '../../Constants/utils';
import OpenManeuverColumn from '../OpenManeuverColumn/OpenManeuverColumn';

interface AddedManeuverCardProps {
  maneuver: IOrderedManeuverSummary;
  maneuverSummaries: IOrderedManeuverSummary[];
  handleOpenTask: (task: IOrderedManeuverSummary) => void;
}

const AddedManeuverCard: FC<AddedManeuverCardProps> = ({
  maneuver,
  maneuverSummaries,
  handleOpenTask,
}) => {
  const { t } = useTranslation(allNamespaces);
  const { isShowing } = useModal();
  const updateManeuverSummaryMutation = useUpdateManeuverSummary(
    maneuver.roadTestId
  );
  const deleteManeuverSummaryMutation = useDeleteManeuverSummary(
    maneuver.roadTestId
  );
  const deleteManeuverSummariesAfterThisOneMutation =
    useDeleteManeuverSummariesAfterThisOne(maneuver.roadTestId);

  const handleDeleteManeuver = () => {
    deleteManeuverSummaryMutation.mutate(maneuver);
  };

  const handleDeleteAllManeuversAfterThisOne = () => {
    deleteManeuverSummariesAfterThisOneMutation.mutate(maneuver);
  };

  const markAsError = () => {
    const taskToMarkAsError: IOrderedManeuverSummary = {
      ...maneuver,
      recordType: 'error',
    };

    updateManeuverSummaryMutation.mutate(taskToMarkAsError);
    handleOpenTask(taskToMarkAsError);
  };

  const markAsSuccess = () => {
    const taskToMarkAsSuccess: IOrderedManeuverSummary = {
      ...maneuver,
      recordType: 'success',
    };

    updateManeuverSummaryMutation.mutate(taskToMarkAsSuccess);
    handleOpenTask(taskToMarkAsSuccess);
  };

  const editManeuver = () => {
    handleOpenTask(maneuver);
  };

  const TaskLabel = () => {
    const roadTypeLabel = getRoadTypeLabel(maneuver.roadType);
    return (
      <Stack alignItems="center">
        <Typography variant="h4">
          {`${maneuver.idx}. ${getTranslationLabel(
            'maneuvers/defaultManeuverTypes',
            maneuver.maneuverType,
            t
          )} ${roadTypeLabel ? `| ${roadTypeLabel}` : ''} `}
        </Typography>
        {maneuver.location && (
          <Typography variant="h4">{maneuver.location}</Typography>
        )}
      </Stack>
    );
  };
  const taskLabel = getTaskLabel(maneuver.maneuverType);

  if (!maneuver.maneuverId || !taskLabel) {
    return <Alert>The task is not defined. Try again.</Alert>; // TODO
  }

  return (
    <AsyncErrorWrapper mutation={updateManeuverSummaryMutation}>
      <AsyncErrorWrapper mutation={deleteManeuverSummaryMutation}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {getTaskIcon(maneuver.maneuverType, maneuver.recordType)}
            {maneuver.recordType && maneuver.recordType === 'success' ? (
              <IconButton
                aria-label="mark maneuver with error"
                size="small"
                onClick={markAsError}
              >
                <RemoveDoneIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="mark maneuver with success"
                size="small"
                onClick={markAsSuccess}
              >
                <DoneAllIcon />
              </IconButton>
            )}
          </Stack>
          <TaskLabel />
          {isShowing && (
            <OpenManeuverColumn
              openManeuver={maneuver}
              maneuverSummaries={maneuverSummaries}
              handleOpenTask={handleOpenTask}
              key={maneuver.maneuverId}
            />
          )}
          <Stack direction="row">
            <IconButton
              aria-label="edit maneuver"
              size="small"
              onClick={editManeuver}
            >
              <EditIcon />
            </IconButton>
            <BasicMenu icon={<MoreVertIcon />}>
              <MenuItem onClick={handleDeleteManeuver}>
                {getTranslationLabel(
                  'onroad/routeBuilder',
                  'deleteManeuver',
                  t
                )}
              </MenuItem>
              <MenuItem onClick={handleDeleteAllManeuversAfterThisOne}>
                {getTranslationLabel(
                  'onroad/routeBuilder',
                  'deleteAllManeuversAfterThisOne',
                  t
                )}
              </MenuItem>
            </BasicMenu>
          </Stack>
        </Stack>
        <SpinningDialog
          isSpinning={
            deleteManeuverSummaryMutation.isLoading ||
            deleteManeuverSummariesAfterThisOneMutation.isLoading
          }
          messageKey="deleting"
        />
      </AsyncErrorWrapper>
    </AsyncErrorWrapper>
  );
};

export default AddedManeuverCard;
