import { SubHeadingItem } from '../../../models/forms';
import { TRUE_FALSE_OPTIONS } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import PREVIOUS_CDE_CONDITIONALS from './PreviousEvaluationsConditionals';

const PREVIOUS_EVALUATIONS: SubHeadingItem = {
  label: 'Previous Evaluations',
  key: 'category2',
  fields: [
    {
      label: 'Previous comprehensive driving evaluation?',
      key: 'previousCDE',
      fieldType: 'RADIO',
      options: TRUE_FALSE_OPTIONS,
      conditionals: PREVIOUS_CDE_CONDITIONALS,
    },
  ],
};

export default PREVIOUS_EVALUATIONS;
