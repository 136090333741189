import React from 'react';

import { CircularProgress, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { websiteNamespaces } from '../../allNamespaces';
import { getTranslationLabel } from '../../utils/utils';
import { normal, gray } from './constants';

const FallbackNoLogo = () => {
  const { t } = useTranslation(websiteNamespaces);

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5vw',
          fontWeight: normal,
          color: gray,
        }}
      >
        {getTranslationLabel('website/general', 'loading', t)}
      </Typography>
      <CircularProgress style={{ marginTop: '20px' }} />
    </Container>
  );
};

export default FallbackNoLogo;
