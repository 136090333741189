import { SubHeadingItem } from '../../../models/forms';
import {
  RawScoreAnalysis,
  Results,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { getScoreAndOutOf } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const clockDrawing: SubHeadingItem = {
  label: 'Clock Drawing',
  key: 'clockDrawing',
  fields: [
    ...getScoreAndOutOf('Clock drawing'),
    {
      label: 'clock drawing',
      key: 'clockDrawingFile',
      fieldType: 'UPLOAD',
    },
    RawScoreAnalysis,
    ...Results,
  ],
};

export default clockDrawing;
