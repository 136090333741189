import React, { Suspense } from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import ReactDOM from 'react-dom';
import '@mui/styles';

import './index.css';
import App from './App';
import { msalConfig } from './auth/authConfig';
import Fallback from './components/website/FallBack';
import theme from './theme';
import './i18n';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const msalInstance = new PublicClientApplication(msalConfig);

const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Fallback />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'));
