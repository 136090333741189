import React, { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../allNamespaces';
import AsyncErrorWrapper from '../../../errors/AsyncErrorWrapper';
import {
  useAccount,
  useUpdateAccount,
} from '../../../hooks/database/settings/accountSettings';
import { IAccount } from '../../../models/account';
import { FieldsLayout, IForm } from '../../../models/forms';
import FormContext from '../../../utils/formContext';
import { getTranslationLabel } from '../../../utils/utils';
import FormSection from '../../common/FormSection';

const AccountSettings = () => {
  const { t } = useTranslation(allNamespaces);
  const { accounts } = useMsal();
  const { username } = accounts[0];
  const { isLoading, isError, data: accountData } = useAccount(username);
  const updateMutation = useUpdateAccount();

  const { handleSubmit, register, control, watch, setValue, getValues, reset } =
    useForm();
  const accountForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  useEffect(() => {
    if (accountData) {
      reset(accountData);
    }
  }, [reset, accountData]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Typography>
        There was a problem getting your account information.
      </Typography>
    );
  }

  const onSubmit = async (dataToSubmit: IAccount) => {
    updateMutation.mutate({
      ...dataToSubmit,
      username,
    });
  };

  const accountFields: FieldsLayout[] = [
    {
      key: 'nameOfAssessmentCentre',
      label: 'Name of Assessment Centre',
      fieldType: 'INPUT',
    },
    {
      key: 'addressOfAssessmentCentre',
      label: 'Address of Assessment Centre',
      fieldType: 'TEXT-AREA',
    },
    {
      key: 'aptNo',
      label: 'Apt. No.',
      fieldType: 'INPUT',
    },
    {
      key: 'cityTownOrVillage',
      label: 'City, Town or Village',
      fieldType: 'INPUT',
    },
    {
      key: 'postalCode',
      label: 'Postal Code',
      fieldType: 'INPUT',
    },
    {
      key: 'telephone',
      label: 'Telephone',
      fieldType: 'INPUT',
    },
    {
      key: 'professionalLicenceNumber',
      label: 'Professional Licence Number',
      fieldType: 'INPUT',
    },
  ];

  return (
    <>
      <Grid item>
        <Typography variant="h2">
          {getTranslationLabel('settings', 'accountInformationTitle', t)}
        </Typography>
      </Grid>
      <Paper square style={{ padding: '20px' }}>
        <Grid container spacing={3}>
          <AsyncErrorWrapper mutation={updateMutation}>
            <FormContext.Provider value={accountForm}>
              <FormSection
                fields={accountFields}
                values={watch()}
                namespace="settings"
                sectionKey="accountInformation"
                commentsPosition="none"
              />
              <DialogActions style={{ padding: '20px' }}>
                <Button onClick={handleSubmit(onSubmit)}>
                  {getTranslationLabel('generalNs', 'save', t)}
                </Button>
              </DialogActions>
            </FormContext.Provider>
          </AsyncErrorWrapper>
        </Grid>
      </Paper>
    </>
  );
};

export default AccountSettings;
