import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { websiteNamespaces } from './allNamespaces';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // .use(i18nextPlugin)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: websiteNamespaces,
    supportedLngs: ['en', 'fr'],
    debug: true,
    fallbackLng: 'en',
    // backend: {
    //   loadPath: './locales/{{lng}}/{{ns}}.json',
    // },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    // translationStats: {
    //   // optional options, if not provided it will guess based on your i18next config
    //   queryStringParam: 'showtranslations',
    //   sourceLng: 'en',
    //   targetLngs: ['fr'],
    //   preserveEmptyStrings: false,
    // },
  });

export default i18n;
