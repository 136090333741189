import { axiosGetAllCall, useQueryWithToken } from '../utils';

const maneuverOptionsKey = ['maneuverOptions'] as const;

const maneuverOptionsUrl = `/api/on-road/settings/maneuver-options`;

const useManeuverOptions = () =>
  useQueryWithToken(maneuverOptionsKey, (token: string) =>
    axiosGetAllCall(maneuverOptionsUrl, token)
  );

export default useManeuverOptions;
