import React from 'react';

import { Box, CircularProgress, Container, Typography } from '@mui/material';

import { normal, gray } from './constants';
import logo from './img/logo-streetsure-small.png';

const Fallback = () => (
  <Container
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <Box
      component="img"
      sx={{
        maxWidth: '30vw',
        marginBottom: '20px',
      }}
      alt="Streetsure logo"
      src={logo}
    />
    <Typography
      sx={{
        fontSize: '1.5vw',
        fontWeight: normal,
        color: gray,
      }}
    >
      Loading, please wait...
    </Typography>
    <CircularProgress style={{ marginTop: '20px' }} />
  </Container>
);

export default Fallback;
