import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import {
  PhysicalResults,
  PHYSICAL_RESULTS_CATEGORIES,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

const QPS_SUBHEADINGS = [
  'Range of Motion',
  'Strength Testing',
  'Sensation and Proprioception',
  'Standing Balance',
  'Sitting Balance',
];

const quickPhysicalScreen: SubHeadingItem = {
  key: 'quickPhysicalScreen',
  label: 'Quick Physical Screen',
  fields: [
    ...QPS_SUBHEADINGS.map((item) => ({
      key: `${convertLabelToKey(item)}Result`,
      label: item,
      fieldType: 'RADIO' as FieldTypes,
      options: PHYSICAL_RESULTS_CATEGORIES,
    })),
    ...PhysicalResults,
  ],
};

export default quickPhysicalScreen;
