import React, { FC } from 'react';

import { useDeleteAssessmentSummary } from '../../../hooks/database/assessments/assessmentSummaries';
import { DeleteAssessmentDialog } from './DeleteAssessmentDialog';

export interface DeleteAssessmentsProps {
  clientId: string;
  keysOfAssessmentsToDelete: string[];
  setKeysOfAssessmentToDelete: (assessments: string[]) => void;
}

export const DeleteAssessments: FC<DeleteAssessmentsProps> = ({
  clientId,
  keysOfAssessmentsToDelete,
  setKeysOfAssessmentToDelete,
}) => {
  const deleteMutation = useDeleteAssessmentSummary(clientId);

  const deleteAssessment = async (assessmentKey: string) => {
    deleteMutation.mutate(assessmentKey);
  };

  const removeAssessmentNameFromState = (assessmentKey: string) => {
    const newAssessmentsToDelete = [...keysOfAssessmentsToDelete];
    const idx = newAssessmentsToDelete.indexOf(assessmentKey);
    if (idx > -1) {
      newAssessmentsToDelete.splice(idx, 1);
    }
    setKeysOfAssessmentToDelete(newAssessmentsToDelete);
  };

  const handleCancelDelete = (assessmentKey: string) => {
    removeAssessmentNameFromState(assessmentKey);
  };

  const handleConfirmDelete = (assessmentKey: string) => {
    deleteAssessment(assessmentKey);
    removeAssessmentNameFromState(assessmentKey);
  };

  return (
    <>
      {keysOfAssessmentsToDelete.map((assessmentKey) => (
        <DeleteAssessmentDialog
          key={assessmentKey}
          clientId={clientId}
          assessmentId={assessmentKey}
          isDeleteDialogOpen
          handleConfirmDelete={handleConfirmDelete}
          handleCancelDelete={handleCancelDelete}
        />
      ))}
    </>
  );
};
