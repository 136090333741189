import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, Card, CardContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../../../allNamespaces';
import { getTranslationLabel } from '../../../../utils/utils';
import TablePaginationComp from '../../../common/TablePaginationComp';

interface ListOfTasksProps {
  sortedTasks: any; // TODO
  removeTask: any;
  setRowsPerPage: any;
  rowsPerPage: number;
  setPage: any;
  page: number;
}

const ListOfTasks: FC<ListOfTasksProps> = ({
  sortedTasks,
  removeTask,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  page,
}) => {
  const { t } = useTranslation(allNamespaces);
  const isLocation = (task: any) => (task.location ? `| ${task.location}` : '');

  const emptyRows = sortedTasks
    ? rowsPerPage -
      Math.min(rowsPerPage, sortedTasks.length - page * rowsPerPage)
    : rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid item xs={6}>
      <Grid item style={{ padding: '0px 0px 10px 0px' }}>
        <Typography variant="h2">
          {getTranslationLabel('onroad/routeBuilder', 'maneuvers', t)}
        </Typography>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        {sortedTasks
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((task: any, index: number) => (
            <Grid item key={task.standardManeuverId}>
              <Card
                variant="outlined"
                key={task.standardManeuverId}
                sx={{
                  width: {
                    xs: '40vw',
                    md: '40vw',
                    lg: '40vw',
                    xl: '30vw',
                    xxl: '25vw',
                  },
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: 'primary.contrastText',
                  backgroundColor: 'primary.light',
                }}
              >
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography>
                        {`${task.idx}. ${getTranslationLabel(
                          'onroad/routeBuilder',
                          task.roadType,
                          t
                        )} ${isLocation(task)}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => removeTask(task)}>
                        <CloseIcon color="secondary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography>
                    {getTranslationLabel(
                      'maneuvers/defaultManeuverTypes',
                      task.maneuverType,
                      t
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        {emptyRows > 0 && (
          <Card
            sx={{
              height: 53 * emptyRows,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: 'primary.contrastText',
              backgroundColor: 'primary.light',
              border: 'none',
              boxShadow: 'none',
            }}
          />
        )}
        <TablePaginationComp
          currentPage={page}
          rowsPerPage={rowsPerPage}
          rowsCount={sortedTasks ? sortedTasks.length : 0}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default ListOfTasks;
