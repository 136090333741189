import React, { FC } from 'react';

import { useMsal } from '@azure/msal-react';
import { CircularProgress, Grid, GridSize } from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';

import { useSelectedAssessments } from '../../../hooks/database/settings/assessmentSettings';
import {
  AssessmentCategoryKeys,
  AssessmentCategoryLabels,
} from '../../../models/assessments/assessments';
import { IOption } from '../../../models/forms';
import {
  categorizeAssessments,
  getIOptionFromAssessmentList,
} from '../../assessments/common';
import { EmptySelectedAssessments } from './EmptySelectedAssessments';
import { SelectedAssessmentsCategory } from './SelectedAssessmentsCategory';

export interface SelectedAssessmentsListProps {
  loCheckedItems: string[];
  onChangeItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSection?: (
    event: React.ChangeEvent<HTMLInputElement>,
    assessmentsInSection: IOption[]
  ) => void;
  renderList?: (name: string, label: string, options: IOption[]) => JSX.Element;
  colSize?: GridSize;
}

export const SelectedAssessments: FC<SelectedAssessmentsListProps> = ({
  loCheckedItems,
  onChangeItem,
  onChangeSection: onChangeSectionProp,
  renderList,
  colSize,
}) => {
  const {
    accounts: [{ username }],
  } = useMsal();

  const {
    isLoading,
    error,
    data: selectedAssessments,
  } = useSelectedAssessments(username);
  useErrorHandler(error);

  if (isLoading) {
    return <CircularProgress />;
  }

  const categorizedSelectedAssessments =
    categorizeAssessments(selectedAssessments);

  const isSelectedAssessmentListEmpty = () => selectedAssessments.length === 0;

  const onChangeSection = (
    event: React.ChangeEvent<HTMLInputElement>,
    assessmentsInSection: IOption[]
  ) => {
    if (onChangeSectionProp) {
      onChangeSectionProp(event, assessmentsInSection);
    }
  };

  return (
    <>
      {isSelectedAssessmentListEmpty() ? (
        <EmptySelectedAssessments />
      ) : (
        <>
          {AssessmentCategoryKeys.map((categoryKey, idx) => {
            const selectedAssessmentsGivenCategory =
              categorizedSelectedAssessments[categoryKey]?.assessments || [];

            if (selectedAssessmentsGivenCategory.length === 0) {
              return <React.Fragment key={categoryKey} />;
            }

            const loPossibleItems = getIOptionFromAssessmentList(
              selectedAssessmentsGivenCategory
            );

            return (
              <Grid item xs={colSize || 12} key={categoryKey}>
                <SelectedAssessmentsCategory
                  renderList={renderList}
                  categoryKey={categoryKey}
                  categoryLabel={AssessmentCategoryLabels[idx]}
                  loPossibleItems={loPossibleItems}
                  loCheckedItems={loCheckedItems}
                  onChangeItem={onChangeItem}
                  onChangeSection={onChangeSection}
                />
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};
