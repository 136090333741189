import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, SubHeadingItem } from '../../../models/forms';
import { Results } from '../../assessments/AssessmentCard/AssessmentCard-constants';
import {
  convertLabelToKey,
  getKeysFromOptions,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const DPT_CATEGORIES = [
  { name: 'Search score', score: 45 },
  { name: 'Identify score', score: 45 },
  { name: 'Predict score', score: 30 },
  { name: 'Decide score', score: 35 },
  { name: 'Execute score', score: 45 },
];

const DPT_FIELDS = DPT_CATEGORIES.map((item) => ({
  key: convertLabelToKey(item.name),
  label: item.name,
  fieldType: 'INPUT-NUM' as FieldTypes,
  units: <Typography> / {item.score} </Typography>,
}));

// https://encompass.eku.edu/cgi/viewcontent.cgi?article=1113&context=etd page 21
// https://www.youtube.com/watch?v=vpM6SKVc_kA
const driverPerformanceTest: SubHeadingItem = {
  key: 'driverPerformanceTest',
  label: 'Driver Performance Test (DPT)',
  fields: [
    ...DPT_FIELDS,
    {
      key: 'totalScore',
      label: 'Total score',
      fieldType: 'INPUT-SWITCH',
      units: <Typography> / 200 </Typography>,
      valueDependsOn: getKeysFromOptions(DPT_FIELDS),
    },
    ...Results,
  ],
};

export default driverPerformanceTest;
