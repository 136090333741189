import React, { FC } from 'react';

import { Card, CardActions, CardContent, CardProps } from '@mui/material';

interface BasicCardsProps extends CardProps {
  id: string;
  actions: React.ReactElement;
}

const BasicCard: FC<BasicCardsProps> = ({ id, actions, children }) => (
  <Card
    variant="outlined"
    key={id}
    sx={{ backgroundColor: 'primary.light', color: 'primary.contrastText' }}
  >
    <CardContent>{children}</CardContent>
    <CardActions disableSpacing>{actions}</CardActions>
  </Card>
);

export default BasicCard;
