export interface IRoadTest {
  clientId?: string;
  roadTestId?: string;
  standardRouteId: string | null;
  behaviouralPsychological: string;
  clear: string;
  cloudy: string;
  cognitivePerceptual: string;
  commentsPreparation: string;
  commentsRoadConditions: string;
  commentsTestingDetails: string;
  commentsTestingVehicleDetails: string;
  dateOfRoadTest: string;
  day: string;
  drivingInstructorName: string;
  dry: string;
  dusk: string;
  fog: string;
  heavy: string;
  light: string;
  mixed: string;
  moderate: string;
  night: string;
  otherDeficits: string;
  otherRoadConditions: string;
  otherTypeOfClient: string;
  otherTypeOfDeficit: string;
  otherWeatherConditions: string;
  other: string;
  overcast: string;
  payAttentionTo: string;
  physical: string;
  rain: string;
  requiredAccomodations: string;
  slippery: string;
  snowCovered: string;
  snow: string;
  testingStage: string;
  transmission: string;
  typeOfClient: string;
  vehicleMake: string;
  vehicleModel: string;
  wet: string;
  commentsRoute: string;
  typeOfRoute: string;
  whyCustomRoute: string;
  accelerator: string;
  brake: string;
  clutch: string;
  commentsCockpit: string;
  commentsLoadingAndUnloadingOfMobilityAids: string;
  commentsOnRoadEquipment: string;
  commentsPrimaryControl: string;
  commentsSecondaryControl: string;
  commentsVehicleIngressAndEgressAndMobilityAids: string;
  convexMirrors: string;
  cruiseControl: string;
  customSeating: string;
  defrost: string;
  gearShift: string;
  highLowBeams: string;
  horn: string;
  ignition: string;
  leftFootAccelerator: string;
  leftHandControls: string;
  leftHandPosition: number | null;
  mobilityAid1CommentsLoadingUnloading: string;
  mobilityAid1ResultLoadingUnloading: string;
  mobilityAid1StorageLocationLoadingUnloading: string;
  mobilityAid1TypeOfAidLoadingUnloading: string;
  mobilityAid2CommentsLoadingUnloading: string;
  mobilityAid2ResultLoadingUnloading: string;
  mobilityAid2StorageLocationLoadingUnloading: string;
  mobilityAid2TypeOfAidLoadingUnloading: string;
  mobilityAid3CommentsLoadingUnloading: string;
  mobilityAid3ResultLoadingUnloading: string;
  mobilityAid3StorageLocationLoadingUnloading: string;
  mobilityAid3TypeOfAidLoadingUnloading: string;
  noEquipmentUsed: string;
  otherEquipment: string;
  parkingBrake: string;
  pedalExtensions: string;
  prehensionAid: string;
  rearViewMirrors: string;
  rightHandControls: string;
  rightHandPosition: number | null;
  seatAdjustments: string;
  seatBelt: string;
  sideMirrors: string;
  signals: string;
  spinnerKnob: string;
  steeringWheel: string;
  turnSignalCrossOver: string;
  turnSignalExtension: string;
  typeOfElectronicHandControl: string;
  typeOfHandControl: string;
  typeOfMechanicalHandControl: string;
  vehicleEgressMobilityAid1CommentsTransfer: string;
  vehicleEgressMobilityAid1ResultTransfer: string;
  vehicleEgressMobilityAid1TypeOfAidTransfer: string;
  vehicleEgressMobilityAid2CommentsTransfer: string;
  vehicleEgressMobilityAid2ResultTransfer: string;
  vehicleEgressMobilityAid2TypeOfAidTransfer: string;
  vehicleIngressMobilityAid1CommentsTransfer: string;
  vehicleIngressMobilityAid1ResultTransfer: string;
  vehicleIngressMobilityAid1TypeOfAidTransfer: string;
  vehicleIngressMobilityAid2CommentsTransfer: string;
  vehicleIngressMobilityAid2ResultTransfer: string;
  vehicleIngressMobilityAid2TypeOfAidTransfer: string;
  washerFluidSwitch: string;
  windshieldWipers: string;
  affect: string;
  anxietyManagement: string;
  awarenessOfDrivingEnvironment: string;
  commentsBehaviour: string;
  commentsExecutiveFunctionSkills: string;
  commentsRehabilitationPotential: string;
  commentsRoadKnowledge: string;
  commentsaffect: string;
  commentsanxietyManagement: string;
  commentsawarenessOfDrivingEnvironment: string;
  commentsfocus: string;
  commentsfrustrationTolerance: string;
  commentshazardRecognition: string;
  commentshazardResponse: string;
  commentsimpulsivityTolerance: string;
  commentsinitiation: string;
  commentsjudgment: string;
  commentslandmarkRecognition: string;
  commentsproblemSolving: string;
  commentsrecognizingErrorsAndAcceptingResponsibility: string;
  commentsresponseToAdverseConditions: string;
  commentsresponseToFeedback: string;
  commentsresponseToUnusualSituations: string;
  commentsriskTakingBehaviour: string;
  commentsroadSignDetectionAndAdherence: string;
  commentsrouteFinding: string;
  commentsrulesOfTheRoadUnderstandingAndExecution: string;
  commentstopographicalOrientation: string;
  commentsunderstandingOfFeedback: string;
  commentsuseOfCompensatoryTechniques: string;
  dIInterventionCommentsaffect: string;
  dIInterventionCommentsanxietyManagement: string;
  dIInterventionCommentsawarenessOfDrivingEnvironment: string;
  dIInterventionCommentsfocus: string;
  dIInterventionCommentsfrustrationTolerance: string;
  dIInterventionCommentshazardRecognition: string;
  dIInterventionCommentshazardResponse: string;
  dIInterventionCommentsimpulsivityTolerance: string;
  dIInterventionCommentsinitiation: string;
  dIInterventionCommentsjudgment: string;
  dIInterventionCommentslandmarkRecognition: string;
  dIInterventionCommentsproblemSolving: string;
  dIInterventionCommentsrecognizingErrorsAndAcceptingResponsibility: string;
  dIInterventionCommentsresponseToAdverseConditions: string;
  dIInterventionCommentsresponseToFeedback: string;
  dIInterventionCommentsresponseToUnusualSituations: string;
  dIInterventionCommentsriskTakingBehaviour: string;
  dIInterventionCommentsroadSignDetectionAndAdherence: string;
  dIInterventionCommentsrouteFinding: string;
  dIInterventionCommentsrulesOfTheRoadUnderstandingAndExecution: string;
  dIInterventionCommentstopographicalOrientation: string;
  dIInterventionCommentsunderstandingOfFeedback: string;
  dIInterventionCommentsuseOfCompensatoryTechniques: string;
  focus: string;
  frustrationTolerance: string;
  hazardRecognition: string;
  hazardResponse: string;
  impulsivityTolerance: string;
  initiation: string;
  judgment: string;
  landmarkRecognition: string;
  problemSolving: string;
  recognizingErrorsAndAcceptingResponsibility: string;
  responseToAdverseConditions: string;
  responseToFeedback: string;
  responseToUnusualSituations: string;
  riskTakingBehaviour: string;
  roadSignDetectionAndAdherence: string;
  routeFinding: string;
  rulesOfTheRoadUnderstandingAndExecution: string;
  topographicalOrientation: string;
  understandingOfFeedback: string;
  useOfCompensatoryTechniques: string;
  verbalHelpCommentsaffect: string;
  verbalHelpCommentsanxietyManagement: string;
  verbalHelpCommentsawarenessOfDrivingEnvironment: string;
  verbalHelpCommentsfocus: string;
  verbalHelpCommentsfrustrationTolerance: string;
  verbalHelpCommentshazardRecognition: string;
  verbalHelpCommentshazardResponse: string;
  verbalHelpCommentsimpulsivityTolerance: string;
  verbalHelpCommentsinitiation: string;
  verbalHelpCommentsjudgment: string;
  verbalHelpCommentslandmarkRecognition: string;
  verbalHelpCommentsproblemSolving: string;
  verbalHelpCommentsrecognizingErrorsAndAcceptingResponsibility: string;
  verbalHelpCommentsresponseToAdverseConditions: string;
  verbalHelpCommentsresponseToFeedback: string;
  verbalHelpCommentsresponseToUnusualSituations: string;
  verbalHelpCommentsriskTakingBehaviour: string;
  verbalHelpCommentsroadSignDetectionAndAdherence: string;
  verbalHelpCommentsrouteFinding: string;
  verbalHelpCommentsrulesOfTheRoadUnderstandingAndExecution: string;
  verbalHelpCommentstopographicalOrientation: string;
  verbalHelpCommentsunderstandingOfFeedback: string;
  verbalHelpCommentsuseOfCompensatoryTechniques: string;
  changeRouteDuringTest: string;
  clientsPerceptionAgreesWithOT: string;
  clientsPerception: string;
  commentsDIImpressions: string;
  commentsOnRoadClientInsight: string;
  commentsOnRoadDuration: string;
  commentsOnRoadRoute: string;
  dIScore: number | null;
  duration: string;
  endTime: string;
  endedEarly: string;
  overallDIImpressions: string;
  scoreOutOf: number | null;
  startTime: string;
  whyRouteChanged: string;
  whyTestEndedEarly: string;
}

export const blankRoadTest: IRoadTest = {
  standardRouteId: null,
  behaviouralPsychological: '',
  clear: '',
  cloudy: '',
  cognitivePerceptual: '',
  commentsPreparation: '',
  commentsRoadConditions: '',
  commentsTestingDetails: '',
  commentsTestingVehicleDetails: '',
  dateOfRoadTest: '',
  day: '',
  drivingInstructorName: '',
  dry: '',
  dusk: '',
  fog: '',
  heavy: '',
  light: '',
  mixed: '',
  moderate: '',
  night: '',
  otherDeficits: '',
  otherRoadConditions: '',
  otherTypeOfClient: '',
  otherTypeOfDeficit: '',
  otherWeatherConditions: '',
  other: '',
  overcast: '',
  payAttentionTo: '',
  physical: '',
  rain: '',
  requiredAccomodations: '',
  slippery: '',
  snowCovered: '',
  snow: '',
  testingStage: '',
  transmission: '',
  typeOfClient: '',
  vehicleMake: '',
  vehicleModel: '',
  wet: '',
  commentsRoute: '',
  typeOfRoute: '',
  whyCustomRoute: '',
  accelerator: '',
  brake: '',
  clutch: '',
  commentsCockpit: '',
  commentsLoadingAndUnloadingOfMobilityAids: '',
  commentsOnRoadEquipment: '',
  commentsPrimaryControl: '',
  commentsSecondaryControl: '',
  commentsVehicleIngressAndEgressAndMobilityAids: '',
  convexMirrors: '',
  cruiseControl: '',
  customSeating: '',
  defrost: '',
  gearShift: '',
  highLowBeams: '',
  horn: '',
  ignition: '',
  leftFootAccelerator: '',
  leftHandControls: '',
  leftHandPosition: null,
  mobilityAid1CommentsLoadingUnloading: '',
  mobilityAid1ResultLoadingUnloading: '',
  mobilityAid1StorageLocationLoadingUnloading: '',
  mobilityAid1TypeOfAidLoadingUnloading: '',
  mobilityAid2CommentsLoadingUnloading: '',
  mobilityAid2ResultLoadingUnloading: '',
  mobilityAid2StorageLocationLoadingUnloading: '',
  mobilityAid2TypeOfAidLoadingUnloading: '',
  mobilityAid3CommentsLoadingUnloading: '',
  mobilityAid3ResultLoadingUnloading: '',
  mobilityAid3StorageLocationLoadingUnloading: '',
  mobilityAid3TypeOfAidLoadingUnloading: '',
  noEquipmentUsed: '',
  otherEquipment: '',
  parkingBrake: '',
  pedalExtensions: '',
  prehensionAid: '',
  rearViewMirrors: '',
  rightHandControls: '',
  rightHandPosition: null,
  seatAdjustments: '',
  seatBelt: '',
  sideMirrors: '',
  signals: '',
  spinnerKnob: '',
  steeringWheel: '',
  turnSignalCrossOver: '',
  turnSignalExtension: '',
  typeOfElectronicHandControl: '',
  typeOfHandControl: '',
  typeOfMechanicalHandControl: '',
  vehicleEgressMobilityAid1CommentsTransfer: '',
  vehicleEgressMobilityAid1ResultTransfer: '',
  vehicleEgressMobilityAid1TypeOfAidTransfer: '',
  vehicleEgressMobilityAid2CommentsTransfer: '',
  vehicleEgressMobilityAid2ResultTransfer: '',
  vehicleEgressMobilityAid2TypeOfAidTransfer: '',
  vehicleIngressMobilityAid1CommentsTransfer: '',
  vehicleIngressMobilityAid1ResultTransfer: '',
  vehicleIngressMobilityAid1TypeOfAidTransfer: '',
  vehicleIngressMobilityAid2CommentsTransfer: '',
  vehicleIngressMobilityAid2ResultTransfer: '',
  vehicleIngressMobilityAid2TypeOfAidTransfer: '',
  washerFluidSwitch: '',
  windshieldWipers: '',
  affect: '',
  anxietyManagement: '',
  awarenessOfDrivingEnvironment: '',
  commentsBehaviour: '',
  commentsExecutiveFunctionSkills: '',
  commentsRehabilitationPotential: '',
  commentsRoadKnowledge: '',
  commentsaffect: '',
  commentsanxietyManagement: '',
  commentsawarenessOfDrivingEnvironment: '',
  commentsfocus: '',
  commentsfrustrationTolerance: '',
  commentshazardRecognition: '',
  commentshazardResponse: '',
  commentsimpulsivityTolerance: '',
  commentsinitiation: '',
  commentsjudgment: '',
  commentslandmarkRecognition: '',
  commentsproblemSolving: '',
  commentsrecognizingErrorsAndAcceptingResponsibility: '',
  commentsresponseToAdverseConditions: '',
  commentsresponseToFeedback: '',
  commentsresponseToUnusualSituations: '',
  commentsriskTakingBehaviour: '',
  commentsroadSignDetectionAndAdherence: '',
  commentsrouteFinding: '',
  commentsrulesOfTheRoadUnderstandingAndExecution: '',
  commentstopographicalOrientation: '',
  commentsunderstandingOfFeedback: '',
  commentsuseOfCompensatoryTechniques: '',
  dIInterventionCommentsaffect: '',
  dIInterventionCommentsanxietyManagement: '',
  dIInterventionCommentsawarenessOfDrivingEnvironment: '',
  dIInterventionCommentsfocus: '',
  dIInterventionCommentsfrustrationTolerance: '',
  dIInterventionCommentshazardRecognition: '',
  dIInterventionCommentshazardResponse: '',
  dIInterventionCommentsimpulsivityTolerance: '',
  dIInterventionCommentsinitiation: '',
  dIInterventionCommentsjudgment: '',
  dIInterventionCommentslandmarkRecognition: '',
  dIInterventionCommentsproblemSolving: '',
  dIInterventionCommentsrecognizingErrorsAndAcceptingResponsibility: '',
  dIInterventionCommentsresponseToAdverseConditions: '',
  dIInterventionCommentsresponseToFeedback: '',
  dIInterventionCommentsresponseToUnusualSituations: '',
  dIInterventionCommentsriskTakingBehaviour: '',
  dIInterventionCommentsroadSignDetectionAndAdherence: '',
  dIInterventionCommentsrouteFinding: '',
  dIInterventionCommentsrulesOfTheRoadUnderstandingAndExecution: '',
  dIInterventionCommentstopographicalOrientation: '',
  dIInterventionCommentsunderstandingOfFeedback: '',
  dIInterventionCommentsuseOfCompensatoryTechniques: '',
  focus: '',
  frustrationTolerance: '',
  hazardRecognition: '',
  hazardResponse: '',
  impulsivityTolerance: '',
  initiation: '',
  judgment: '',
  landmarkRecognition: '',
  problemSolving: '',
  recognizingErrorsAndAcceptingResponsibility: '',
  responseToAdverseConditions: '',
  responseToFeedback: '',
  responseToUnusualSituations: '',
  riskTakingBehaviour: '',
  roadSignDetectionAndAdherence: '',
  routeFinding: '',
  rulesOfTheRoadUnderstandingAndExecution: '',
  topographicalOrientation: '',
  understandingOfFeedback: '',
  useOfCompensatoryTechniques: '',
  verbalHelpCommentsaffect: '',
  verbalHelpCommentsanxietyManagement: '',
  verbalHelpCommentsawarenessOfDrivingEnvironment: '',
  verbalHelpCommentsfocus: '',
  verbalHelpCommentsfrustrationTolerance: '',
  verbalHelpCommentshazardRecognition: '',
  verbalHelpCommentshazardResponse: '',
  verbalHelpCommentsimpulsivityTolerance: '',
  verbalHelpCommentsinitiation: '',
  verbalHelpCommentsjudgment: '',
  verbalHelpCommentslandmarkRecognition: '',
  verbalHelpCommentsproblemSolving: '',
  verbalHelpCommentsrecognizingErrorsAndAcceptingResponsibility: '',
  verbalHelpCommentsresponseToAdverseConditions: '',
  verbalHelpCommentsresponseToFeedback: '',
  verbalHelpCommentsresponseToUnusualSituations: '',
  verbalHelpCommentsriskTakingBehaviour: '',
  verbalHelpCommentsroadSignDetectionAndAdherence: '',
  verbalHelpCommentsrouteFinding: '',
  verbalHelpCommentsrulesOfTheRoadUnderstandingAndExecution: '',
  verbalHelpCommentstopographicalOrientation: '',
  verbalHelpCommentsunderstandingOfFeedback: '',
  verbalHelpCommentsuseOfCompensatoryTechniques: '',
  changeRouteDuringTest: '',
  clientsPerceptionAgreesWithOT: '',
  clientsPerception: '',
  commentsDIImpressions: '',
  commentsOnRoadClientInsight: '',
  commentsOnRoadDuration: '',
  commentsOnRoadRoute: '',
  dIScore: null,
  duration: '',
  endTime: '',
  endedEarly: '',
  overallDIImpressions: '',
  scoreOutOf: null,
  startTime: '',
  whyRouteChanged: '',
  whyTestEndedEarly: '',
};
