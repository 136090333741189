import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, MainHeadingItem } from '../../../models/forms';
import {
  PASS_FAIL,
  Results,
} from '../../assessments/AssessmentCard/AssessmentCard-constants';
import { convertLabelToKey } from '../../assessments/AssessmentCard/AssessmentCard-utils';

export interface ICognistatSection {
  title: string;
  averageUB: number;
  averageLB: number;
  mild: number;
  moderate: number;
  severe: number;
}

const CONGNISTAT_SECTIONS: ICognistatSection[] = [
  {
    title: 'Orientation',
    averageUB: 12,
    averageLB: 10,
    mild: 8,
    moderate: 6,
    severe: 4,
  },
  {
    title: 'Attention',
    averageUB: 8,
    averageLB: 6,
    mild: 5,
    moderate: 3,
    severe: 1,
  },
  {
    title: 'Memory registration',
    averageUB: 2,
    averageLB: 3,
    mild: 4,
    moderate: 5,
    severe: 6,
  },
  {
    title: 'Language: comprehension',
    averageUB: 6,
    averageLB: 5,
    mild: 4,
    moderate: 3,
    severe: 2,
  },
  {
    title: 'Language: repetition',
    averageUB: 12,
    averageLB: 11,
    mild: 9,
    moderate: 7,
    severe: 5,
  },
  {
    title: 'Language: naming',
    averageUB: 8,
    averageLB: 7,
    mild: 5,
    moderate: 3,
    severe: 2,
  },
  {
    title: 'Constructional ability',
    averageUB: 6,
    averageLB: 4,
    mild: 3,
    moderate: 2,
    severe: 0,
  },
  {
    title: 'Memory',
    averageUB: 12,
    averageLB: 10,
    mild: 8,
    moderate: 6,
    severe: 4,
  },
  {
    title: 'Calculations',
    averageUB: 4,
    averageLB: 3,
    mild: 2,
    moderate: 1,
    severe: 0,
  },
  {
    title: 'Reason/Exec: Similarities',
    averageUB: 8,
    averageLB: 5,
    mild: 4,
    moderate: 3,
    severe: 2,
  },
  {
    title: 'Reason/Exec: Judgment',
    averageUB: 6,
    averageLB: 4,
    mild: 3,
    moderate: 2,
    severe: 1,
  },
];

const CONGNISTAT_SECTIONS_WITHOUT_SCREEN = [
  'Orientation',
  'Memory registration',
  'Memory',
];

const getCognistatOption = (section: ICognistatSection) => [
  {
    label: `Average (${section.averageLB}-${section.averageUB})`,
    key: `${convertLabelToKey(section.title)}Average`,
    val: `${convertLabelToKey(section.title)}Average`,
  },
  {
    label: `Mildly Impaired (${section.mild})`,
    key: `${convertLabelToKey(section.title)}Mild`,
    val: `${convertLabelToKey(section.title)}Mild`,
  },
  {
    label: `Moderately Impaired (${section.moderate})`,
    key: `${convertLabelToKey(section.title)}Moderate`,
    val: `${convertLabelToKey(section.title)}Moderate`,
  },
  {
    label: `Severely Impaired (${section.severe})`,
    key: `${convertLabelToKey(section.title)}Severe`,
    val: `${convertLabelToKey(section.title)}Severe`,
  },
  { label: `Other`, key: 'other', val: 'other' },
];

const CONGNISTAT_SUBHEADINGS = CONGNISTAT_SECTIONS.map((section) => ({
  key: `${convertLabelToKey(section.title)}`,
  label: `${section.title}`,
  fields: CONGNISTAT_SECTIONS_WITHOUT_SCREEN.includes(section.title)
    ? [
        {
          key: `${convertLabelToKey(section.title)}Score`,
          label: `Score`,
          fieldType: 'INPUT-NUM' as FieldTypes,
          units: (
            <Typography>
              /{' '}
              {section.title === 'Memory registration'
                ? section.severe
                : section.averageUB}
            </Typography>
          ),
        },
        {
          key: `${convertLabelToKey(section.title)}Interpretation`,
          label: `Interpretation`,
          fieldType: 'RADIO' as FieldTypes,
          options: getCognistatOption(section),
          valueDependsOn: [`${convertLabelToKey(section.title)}Score`],
        },
      ]
    : [
        {
          key: `${convertLabelToKey(section.title)}ScreenPassed`,
          label: 'Client passed screen?',
          fieldType: 'RADIO' as FieldTypes,
          options: PASS_FAIL,
        },
        {
          key: `${convertLabelToKey(section.title)}Score`,
          label: `Score`,
          fieldType: 'INPUT-NUM' as FieldTypes,
          units: <Typography>/ {section.averageUB}</Typography>,
        },
        {
          key: `${convertLabelToKey(section.title)}Interpretation`,
          label: `Interpretation`,
          fieldType: 'RADIO' as FieldTypes,
          options: getCognistatOption(section),
          valueDependsOn: [
            `${convertLabelToKey(section.title)}Score`,
            `${convertLabelToKey(section.title)}ScreenPassed`,
          ],
        },
      ],
}));

const cognistat: MainHeadingItem = {
  label: 'Cognistat',
  key: 'cognistat',
  subheadings: [
    ...CONGNISTAT_SUBHEADINGS,
    {
      label: 'Overall Results',
      key: 'overallResults',
      fields: [
        {
          label: `Patient's impression`,
          key: 'patientsImpression',
          fieldType: 'TEXT-AREA',
        },
        ...Results,
      ],
    },
  ],
};

export default cognistat;
