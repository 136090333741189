import { SubHeadingItem } from '../../../../models/forms';
import { getOptionsFromLabels } from '../../../assessments/AssessmentCard/AssessmentCard-utils';
import { getIntersectionOptions } from './utils';

const TYPES_RAILWAY_CROSSINGS = getOptionsFromLabels([
  'With signals and barrier',
  'With signals and without barrier',
  'Without either signals or barrier',
  'Unsigned',
]);

const ADJUSTMENT_TO_STIMULI_SIGNS_RAILWAYCROSSING = [
  'Failed to notice the railway crossing',
];
const ADJUSTMENT_TO_STIMULI_SIGNS_RAILWAYCROSSING_OPTIONS =
  getOptionsFromLabels(ADJUSTMENT_TO_STIMULI_SIGNS_RAILWAYCROSSING);

export const RAILWAY_CROSSING_META: SubHeadingItem = {
  key: 'railwayCrossing',
  label: 'Railway Crossing',
  fields: [
    {
      label: 'Type of Railway Crossing',
      key: 'subManeuverType',
      fieldType: 'CHOICE_CHIPS',
      options: TYPES_RAILWAY_CROSSINGS,
    },
  ],
};

export const RAILWAY_CROSSING_ERRORS: SubHeadingItem = {
  key: 'railwayCrossing',
  label: 'Railway Crossing',
  fields: [
    {
      label: 'Adjustment to stimuli / traffic signs',
      key: 'railwayCrossingAdjustmentToStimuliTrafficSigns',
      fieldType: 'CHECKBOX',
      options: ADJUSTMENT_TO_STIMULI_SIGNS_RAILWAYCROSSING_OPTIONS,
    },
    ...getIntersectionOptions('railwayCrossing'),
  ],
};

export default RAILWAY_CROSSING_ERRORS;
