import { IClient, IClientMetaData } from '../../../models/client';
import {
  axiosDeleteCall,
  axiosGetAllCall,
  axiosGetCall,
  axiosPostCall,
  axiosPutCall,
  useMutationWithToken,
  useQueryWithToken,
} from '../utils';

const clientsKey = ['clients'] as const;
const clientKeys = (clientId: string) => ['client', clientId] as const;

const clientsUsernameUrl = (username: string) => `/api/clients/${username}/all`;
const clientUrl = (clientId: string) => `/api/clients/${clientId}`;
const consentUrl = (clientId: string) => `/api/clients/${clientId}/consent`;

export const useClient = (clientId: string) =>
  useQueryWithToken(clientKeys(clientId), (token: string) =>
    axiosGetCall(clientUrl(clientId), token)
  );

export const useClients = (username: string) =>
  useQueryWithToken(clientsKey, (token: string) =>
    axiosGetAllCall(clientsUsernameUrl(username), token)
  );

export const useCreateClient = (username: string) =>
  useMutationWithToken([], (client: IClient, token: string) =>
    axiosPostCall(clientsUsernameUrl(username), client, token)
  );

export const useUpdateClient = (clientId: string) =>
  useMutationWithToken(clientKeys(clientId), (client: IClient, token: string) =>
    axiosPutCall(clientUrl(client.clientId), client, token)
  );

export const useUpdateConsent = (clientId: string) =>
  useMutationWithToken(clientsKey, (consent: IClientMetaData, token: string) =>
    axiosPutCall(consentUrl(consent.clientId), consent, token)
  );

export const useDeleteClient = () =>
  useMutationWithToken(clientsKey, (clientId: string, token: string) =>
    axiosDeleteCall(clientUrl(clientId), token)
  );
