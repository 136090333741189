import React from 'react';

import { Typography } from '@mui/material';

import { FieldTypes, IOption, MainHeadingItem } from '../../../models/forms';
import {
  convertLabelToKey,
  getOptionsFromLabels,
} from '../../assessments/AssessmentCard/AssessmentCard-utils';

const RISK_SCORES: IOption[] = getOptionsFromLabels([
  '1. Very Low',
  '2. Low',
  '3. Low To Moderate',
  '4. Moderate To High',
  '5. High',
]);

const getUFOVInterpretationOptions = (subheading: string) => {
  switch (subheading) {
    case 'Processing Speed (Subtest 1)':
      return [
        {
          key: 'normalSubtest1',
          val: 'normalSubtest1',
          label: '0-30: Normal central vision and processing speed',
        },
        {
          key: 'slowProcessingSubtest1',
          val: 'slowProcessingSubtest1',
          label:
            '31-60: Normal central vision but somewhat slowed processing speed',
        },
        {
          key: 'centralVisionLossSubtest1',
          val: 'centralVisionLossSubtest1',
          label: '61-349: Central vision loss and/or slowed processing speed',
        },
        {
          key: 'severeSubtest1',
          val: 'severeSubtest1',
          label:
            '350-500: Severe Central vision loss and/or very slowed processing speed',
        },
      ];
    case 'Divided Attention (Subtest 2)':
      return [
        {
          key: 'normalSubtest2',
          val: 'normalSubtest2',
          label: '0-99: Normal divided attention ability',
        },
        {
          key: 'someDifficultySubtest2',
          val: 'someDifficultySubtest2',
          label: '100-349: Some difficulty with divided attention',
        },
        {
          key: 'severeDifficultySubtest2',
          val: 'severeDifficultySubtest2',
          label: '350-500: Severe difficulty with divided attention',
        },
      ];
    default:
      return [
        {
          key: 'normalSubtest3',
          val: 'normalSubtest3',
          label: '0-349: Normal selective attention ability',
        },
        {
          key: 'someDifficultySubtest3',
          val: 'someDifficultySubtest3',
          label: '350-499: Difficulty with selective attention',
        },
        {
          key: 'severeDifficultySubtest3',
          val: 'severeDifficultySubtest3',
          label: '500: Severe difficulty with selective attention',
        },
      ];
  }
};

const UFOV_SUBHEADINGS = [
  'Processing Speed (Subtest 1)',
  'Divided Attention (Subtest 2)',
  'Selective Attention (Subtest 3)',
].map((subheading) => ({
  key: convertLabelToKey(subheading),
  label: subheading,
  fields: [
    {
      label: 'Score',
      key: `${convertLabelToKey(subheading)}Score`,
      fieldType: 'INPUT-NUM' as FieldTypes,
      units: <Typography>milliseconds</Typography>,
    },
    {
      label: 'Interpretation',
      key: `${convertLabelToKey(subheading)}Interpretation`,
      fieldType: 'RADIO' as FieldTypes,
      options: getUFOVInterpretationOptions(subheading),
      valueDependsOn: [`${convertLabelToKey(subheading)}Score`],
    },
  ],
}));

const ufov: MainHeadingItem = {
  label: 'UFOV',
  key: 'ufov',
  subheadings: [
    ...UFOV_SUBHEADINGS,
    {
      key: 'overallResults',
      label: 'Overall Results',
      fields: [
        {
          key: 'testVersion',
          label: 'Test version (e.g. 6, 7, 10)',
          fieldType: 'INPUT-NUM',
        },
        {
          label: 'Overall risk score',
          key: 'overallRiskScore',
          fieldType: 'RADIO',
          options: RISK_SCORES,
        },
      ],
    },
  ],
};

export default ufov;
